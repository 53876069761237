import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
import { buildLoader, destroyLoader } from "../../../notification/buildLoader";

export const fetch_yt_channel_details = ({
  channel_id,
  youtube,
  setYoutube,
}) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  const url = `${API_BASE}:LiqXQBP3/Fetch-YT-Channel-ID`;

  const loader = buildLoader();

  try {
    fetch(url + `?channel_id=${channel_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data || data?.code || data?.message) return;
        console.log("Channel fetch details result", data);
        const { yt_title, yt_description, yt_channel_thumbnail } = data;
        const youtubeCopy = { ...youtube };
        youtubeCopy.yt_title = yt_title;
        youtubeCopy.yt_description = yt_description;
        youtubeCopy.yt_channel_thumbnail = yt_channel_thumbnail;
        setYoutube(youtubeCopy);
        destroyLoader(loader);
      });
  } catch (error) {
    destroyLoader(loader);
    console.error(error);
  }
};
