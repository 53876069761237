import React from "react";
import { ReactSVG } from "react-svg";

import Modal from "../modal/modal";
import Button from "../button/button";

import styles from "./confirmationPopup.module.scss";

import close_icon from "../../../assets/global/icon_cross_grey_invert.svg";
import warning_icon from "../../../assets/global/confirmationPopup/warning.svg";
import subscription_icon from "../../../assets/global/confirmationPopup/subscription.svg";
import review_icon from "../../../assets/global/confirmationPopup/review.svg";

const ConfrimationPopup = ({
  type = "warning", // simple, warning, subscription, privacy
  privacyContactName = "",
  heading,
  subHeading,
  yesBtnText = "Yes",
  noBtnText = "No",
  show,
  setShow,
  yesCallback,
}) => {
  const cancelCallback = () => {
    setShow(false);
  };
  const confirmCallback = () => {
    setShow(false);
    yesCallback();
  };

  return (
    <Modal open={show} closer={setShow} overflow={true}>
      <div
        className={styles.ConfrimationPopup}
        style={{ paddingTop: type === "simple" && "40px" }}
      >
        <button className={styles.close} onClick={cancelCallback}>
          <ReactSVG src={close_icon} wrapper={"span"} />
        </button>
        {type === "warning" && (
          <div className={styles.icon}>
            <img src={warning_icon} alt="Warning" />
          </div>
        )}
        {type === "subscription" && (
          <div className={styles.icon}>
            <img src={subscription_icon} alt="Subscription" />
          </div>
        )}
        {type === "privacy" && (
          <div className={styles.icon}>
            <img src={review_icon} alt="Privacy" />
          </div>
        )}
        <h2 className={styles.heading}>{heading}</h2>
        {type !== "privacy" && (
          <h5 className={styles.sub_heading}>{subHeading}</h5>
        )}
        {type === "privacy" && (
          <h5 className={styles.sub_heading}>
            I allow UmiCard to share my contact detail with {privacyContactName}{" "}
            in accordance with UmiCard 's{" "}
            <a href="/Privacy-Policy-policy" target={"_blank"}>
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/Terms-and-Conditions" target={"_blank"}>
              Terms and Conditions
            </a>
            .
          </h5>
        )}
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button
              title={noBtnText}
              extraClass={"confirmation_cancel"}
              callback={cancelCallback}
            />
          </div>
          <div className={styles.button}>
            <Button
              title={yesBtnText}
              extraClass={"confirmation_confirm"}
              callback={confirmCallback}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfrimationPopup;
