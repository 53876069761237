import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  checkLoginWithExistingCookie,
  loginWithEmailAndPassword,
  getMagicLinkViaEmail,
  loginWithMagicToken,
  forgotPassword,
  resetPassword,
} from "./login.service";

import { verifyUserEmail } from "../signup/signup.service";

import AuthFlowBasicContainer from "../authFlowBasicContainer/authFlowBasicContainer";
import InputField from "../../common/inputField/inputField";

import styles from "../formStyling.module.scss";

import un_pw_svg from "../../../assets/authFlow/un_pw.svg";
import magic_link_svg from "../../../assets/authFlow/magic_link.svg";
import magic_sms_svg from "../../../assets/authFlow/magic_sms.svg";
import cover from "../../../assets/authFlow/cover.png";

const Login = () => {
  const history = useHistory();

  const [un_pw_form, show_un_pw_form] = useState(true);
  const [magic_link_form, show_magic_link_form] = useState(false);
  const [magic_sms_form, show_magic_sms_form] = useState(false);
  const [forgot_password_form, show_forgot_password_form] = useState(false);
  const [reset_password_form, show_reset_password_form] = useState(false);
  const [email_verification_form, show_email_verification_form] =
    useState(false);

  // Error states
  const [signIn_U_PW_error, set_signIn_U_PW_error] = useState("");
  const [signIn_magic_link_error, set_signIn_magic_link_error] = useState("");
  const [signIn_magic_link_info, set_signIn_magic_link_info] = useState("");
  const [verify_email_error, set_verify_email_error] = useState("");
  const [verify_email_info, set_verify_email_info] = useState("");
  const [forgot_password_error, set_forgot_password_error] = useState("");
  const [forgot_password_info, set_forgot_password_info] = useState("");
  const [reset_password_error, set_reset_password_error] = useState("");
  const [reset_password_info, set_reset_password_info] = useState("");

  const showThisForm = (showFormSet) => {
    show_un_pw_form(false);
    show_magic_link_form(false);
    show_magic_sms_form(false);
    show_forgot_password_form(false);
    show_reset_password_form(false);
    show_email_verification_form(false);

    showFormSet(true);
  };

  const { magicToken } = useParams();

  useEffect(() => {
    console.log("token via params", magicToken);
    // const magicToken = new URLSearchParams(window.location.search).get("token");
    // console.log("Search params", window.location.href, magicToken);

    if (magicToken) {
      loginWithMagicToken(
        magicToken,
        showThisForm,
        show_magic_link_form,
        set_signIn_magic_link_error
      );
      return;
    }

    checkLoginWithExistingCookie((data) => {
      if (!data?.status) {
        set_signIn_U_PW_error(
          "Your login is expired. Please login again with any of given methods"
        );
        return;
      }
      console.log("Already logged in", data);
      if (data.status === "pending") {
        showThisForm(show_email_verification_form);
      } else {
        console.log("Take user to next step");
        if (!data.intro_details_entered) {
          // Go to enter first time details
          console.log("Enter first time details");
          history.push("/intro");
        } else {
          console.log("Go to Designer");
          history.push("/designer");
        }
      }
    });
  }, []);

  return (
    <AuthFlowBasicContainer
      title={
        email_verification_form
          ? "Awesome! Now Let's verify your email."
          : "Log in to your UmiCard Account"
      }
      help={
        email_verification_form
          ? "We've sent a verification code to the email address you used for sing up, Please copy that code and past it in the box below to verify your email."
          : "How would you like to sign in?"
      }
      cover={cover}
    >
      {!email_verification_form && (
        <div className={styles.login_options}>
          <div
            className={`${styles.login_option} ${un_pw_form && styles.active}`}
            onClick={() => showThisForm(show_un_pw_form)}
          >
            <div className={styles.login_option_logo}>
              <ReactSVG src={un_pw_svg} wrapper={"span"} />
            </div>
            <p className={styles.logo_option_text}>Email & password</p>
          </div>
          <div
            className={`${styles.login_option} ${
              magic_link_form && styles.active
            }`}
            onClick={() => showThisForm(show_magic_link_form)}
          >
            <div className={styles.login_option_logo}>
              <ReactSVG src={magic_link_svg} wrapper={"span"} />
            </div>
            <p className={styles.logo_option_text}>Magic Link</p>
          </div>
          <div
            className={`${styles.login_option} ${
              magic_sms_form && styles.active
            }`}
            onClick={() => showThisForm(show_magic_sms_form)}
          >
            <div className={styles.login_option_logo}>
              <ReactSVG src={magic_sms_svg} wrapper={"span"} />
            </div>
            <p className={styles.logo_option_text}>Magic SMS</p>
          </div>
        </div>
      )}
      <form
        className={styles.user_form}
        style={{ display: un_pw_form ? "block" : "none" }}
        onSubmit={(e) => {
          loginWithEmailAndPassword(e, set_signIn_U_PW_error);
        }}
      >
        <InputField
          label={"Email"}
          inputFieldId={"email"}
          type={"email"}
          required={true}
          hideError={set_signIn_U_PW_error}
        />
        <InputField
          label={"Password"}
          inputFieldId={"password"}
          type={"password"}
          required={true}
          hideError={set_signIn_U_PW_error}
        />
        <button
          type="button"
          className={styles.forgot_text}
          onClick={() => showThisForm(show_forgot_password_form)}
        >
          Forgot your username or password?
        </button>
        <p
          className={`${styles.error_message} ${
            signIn_U_PW_error && styles.show
          }`}
        >
          {signIn_U_PW_error}
        </p>
        <button type="submit">Sign In</button>
      </form>
      <form
        className={styles.user_form}
        style={{ display: magic_link_form ? "block" : "none" }}
        onSubmit={(e) => {
          getMagicLinkViaEmail(
            e,
            set_signIn_magic_link_error,
            set_signIn_magic_link_info
          );
        }}
      >
        <InputField
          label={"Email"}
          type={"email"}
          inputFieldId={"magic_link_email"}
          required={true}
          hideError={set_signIn_magic_link_error}
        />
        <p
          className={`${styles.error_message} ${
            signIn_magic_link_error && styles.show
          }`}
        >
          {signIn_magic_link_error}
        </p>
        <p
          className={`${styles.info_message} ${
            signIn_magic_link_info && styles.show
          }`}
        >
          {signIn_magic_link_info}
        </p>
        <button type="submit">Submit</button>
      </form>
      <form
        className={styles.user_form}
        style={{ display: magic_sms_form ? "block" : "none" }}
      >
        <InputField
          label={"Phone Number"}
          type={"tel"}
          inputFieldId={"magic_link_sms"}
          required={true}
        />
        <button type="submit">Submit</button>
      </form>
      <form
        className={styles.user_form}
        style={{ display: email_verification_form ? "block" : "none" }}
        onSubmit={(e) => {
          verifyUserEmail(e, set_verify_email_error, set_verify_email_info);
        }}
      >
        <InputField
          label={"Verification Code"}
          inputFieldId={"verification_code"}
          required={true}
          hideError={set_verify_email_error}
          helperClasses={"mtop_25"}
        />
        <p
          className={`${styles.error_message} ${
            verify_email_error && styles.show
          }`}
        >
          {verify_email_error}
        </p>
        <p
          className={`${styles.info_message} ${
            verify_email_info && styles.show
          }`}
        >
          {verify_email_info}
        </p>
        <button type="submit">Submit</button>
      </form>
      <form
        className={styles.user_form}
        style={{ display: forgot_password_form ? "block" : "none" }}
        onSubmit={(e) => {
          e.preventDefault();
          forgotPassword(
            e.target.forgot_password_email.value,
            set_forgot_password_error,
            set_forgot_password_info,
            () => {
              showThisForm(show_reset_password_form);
            }
          );
        }}
      >
        <InputField
          label={
            "Enter email associated with your account for forgot password reset code"
          }
          inputFieldId={"forgot_password_email"}
          type={"email"}
          required={true}
          hideError={set_forgot_password_error}
        />
        <p
          className={`${styles.error_message} ${
            forgot_password_error && styles.show
          }`}
        >
          {forgot_password_error}
        </p>
        <p
          className={`${styles.info_message} ${
            forgot_password_info && styles.show
          }`}
        >
          {forgot_password_info}
        </p>
        <button type="submit">Submit</button>
      </form>
      <form
        className={styles.user_form}
        style={{ display: reset_password_form ? "block" : "none" }}
        onSubmit={(e) => {
          resetPassword(e, set_reset_password_error, set_reset_password_info);
        }}
        autoComplete="new-password"
      >
        <InputField
          label={"Reset Code"}
          inputFieldId={"forgot_password_code"}
          required={true}
          hideError={set_reset_password_error}
        />
        <InputField
          label={"Enter Password"}
          inputFieldId={"forgot_password_one"}
          type={"password"}
          required={true}
          hideError={set_reset_password_error}
        />
        <InputField
          label={"Enter Password again"}
          inputFieldId={"forgot_password_two"}
          type={"password"}
          required={true}
          hideError={set_reset_password_error}
        />
        <p
          className={`${styles.error_message} ${
            reset_password_error && styles.show
          }`}
        >
          {reset_password_error}
        </p>
        <p
          className={`${styles.info_message} ${
            reset_password_info && styles.show
          }`}
        >
          {reset_password_info}
        </p>
        <button type="submit">Submit</button>
      </form>
      <p className={styles.second_option_text}>
        Don’t have an account? <Link to={"/signup"}>Create one</Link>
      </p>
    </AuthFlowBasicContainer>
  );
};

export default Login;
