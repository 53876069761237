import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { TrackingCodesContext } from "../../contexts/settingsContexts/trackingCodes.context";

import CollapsibleContainer from "../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../common/inputField/inputField";
import Button from "../../common/button/button";

import {
  update_google_analytics_id,
  update_google_tag_manager_id,
  update_facebook_pixel_id,
  update_facebook_conversions_api_token,
  update_linkedin_insight_id,
  update_pinterest_tag_id,
  update_tiktok_pixel_id,
  update_seo_meta_title,
  update_seo_meta_description,
} from "./updateFunctions";

import { uploadTrackingCodes } from "./uploadFunction";

import google_svg from "../../../assets/settings/tracking_pixels/icon_google.svg";
import fb_svg from "../../../assets/settings/tracking_pixels/icon_fb.svg";
import linkedin_svg from "../../../assets/settings/tracking_pixels/icon_linkedin.svg";
import pinterest_svg from "../../../assets/settings/tracking_pixels/icon_pinterest.svg";
import tiktok_svg from "../../../assets/settings/tracking_pixels/icon_tiktok.svg";
import tracking_pixels_svg from "../../../assets/settings_sidebar/icon_tracking_pixels.svg";
import seo_svg from "../../../assets/settings_sidebar/icon_seo.svg";

const Tracking = ({ codesRef, seoRef, styles }) => {
  const [tracking, setTracking] = useContext(TrackingCodesContext);

  const { google, facebook, linkedIn, pinterest, tiktok, seo } = tracking;

  console.log(tracking);

  return (
    <>
      <div
        ref={codesRef}
        className={`${styles.designer_white_box} ${styles.details_editor}`}
      >
        <CollapsibleContainer
          title={"Tracking Pixels"}
          inputId={`settings_tracking_pixels_enable`}
          showIconLeft={tracking_pixels_svg}
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <h4
              className={`${styles.section_heading} ${styles.heading_with_icon_left}`}
            >
              <ReactSVG src={google_svg} wrapper={"span"} />{" "}
              <span className={styles.inner_text}>Google</span>
            </h4>
            <InputField
              inputFieldId={"google_analytics_id"}
              label={"Google Analytics ID"}
              placeholder={"UA-90411031-1"}
              defaultInputValue={google?.anlyticsId}
              callback={update_google_analytics_id}
              context={tracking}
              updateContext={setTracking}
            />
            <InputField
              inputFieldId={"google_tag_manager_id"}
              label={"Google Tag Manager ID"}
              placeholder={"GTM-90411031-1"}
              defaultInputValue={google?.tagManagerId}
              callback={update_google_tag_manager_id}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <div className={styles.editable_section}>
            <h4
              className={`${styles.section_heading} ${styles.heading_with_icon_left}`}
            >
              <ReactSVG src={fb_svg} wrapper={"span"} />{" "}
              <span className={styles.inner_text}>Facebook</span>
            </h4>
            <InputField
              inputFieldId={"pixel_id"}
              label={"Pixel ID"}
              placeholder={"UA-90411031-1"}
              defaultInputValue={facebook?.pixelId}
              callback={update_facebook_pixel_id}
              context={tracking}
              updateContext={setTracking}
            />
            <InputField
              inputFieldId={"fb_conversions_api_access_token"}
              label={"Facebook Conversions API Access Token"}
              placeholder={"GTM-90411031-1"}
              defaultInputValue={facebook?.conversionsAPIToken}
              callback={update_facebook_conversions_api_token}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <div className={styles.editable_section}>
            <h4
              className={`${styles.section_heading} ${styles.heading_with_icon_left}`}
            >
              <ReactSVG src={linkedin_svg} wrapper={"span"} />{" "}
              <span className={styles.inner_text}>LinkedIn</span>
            </h4>
            <InputField
              inputFieldId={"linkedin_insight_id"}
              label={"LinkedIn Insight ID"}
              placeholder={"UA-90411031-1"}
              defaultInputValue={linkedIn?.insightTagId}
              callback={update_linkedin_insight_id}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <div className={styles.editable_section}>
            <h4
              className={`${styles.section_heading} ${styles.heading_with_icon_left}`}
            >
              <ReactSVG src={pinterest_svg} wrapper={"span"} />{" "}
              <span className={styles.inner_text}>Pinterest</span>
            </h4>
            <InputField
              inputFieldId={"pinterest_tag_id"}
              label={"Pinterest Tag ID"}
              placeholder={"UA-90411031-1"}
              defaultInputValue={pinterest?.tagId}
              callback={update_pinterest_tag_id}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <div className={styles.editable_section}>
            <h4
              className={`${styles.section_heading} ${styles.heading_with_icon_left}`}
            >
              <ReactSVG src={tiktok_svg} wrapper={"span"} />{" "}
              <span className={styles.inner_text}>TikTok</span>
            </h4>
            <InputField
              inputFieldId={"tikctok_pixel_id"}
              label={"TikTok Pixel ID"}
              placeholder={"UA-90411031-1"}
              defaultInputValue={tiktok?.pixelId}
              callback={update_tiktok_pixel_id}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <Button
            title={"Save Tracking Settings"}
            callback={uploadTrackingCodes}
            callbackData={tracking}
          />
        </CollapsibleContainer>
      </div>

      <div
        ref={seoRef}
        className={`${styles.designer_white_box} ${styles.details_editor}`}
      >
        <CollapsibleContainer
          title={"SEO"}
          inputId={`settings_tracking_seo_enable`}
          showIconLeft={seo_svg}
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <h4 className={`${styles.section_heading}`}>
              <span>Tracking Pixels</span>
            </h4>
            <InputField
              inputFieldId={"tracking_pixels_title"}
              label={"Title"}
              placeholder={"Meta Title"}
              defaultInputValue={seo?.metaTitle}
              callback={update_seo_meta_title}
              context={tracking}
              updateContext={setTracking}
            />
            <InputField
              inputFieldId={"tracking_pixels_description"}
              label={"Description"}
              placeholder={"Meta Description"}
              defaultInputValue={seo?.metaDescription}
              callback={update_seo_meta_description}
              context={tracking}
              updateContext={setTracking}
            />
          </div>
          <Button
            title={"Save SEO Settings"}
            callback={uploadTrackingCodes}
            callbackData={tracking}
          />
        </CollapsibleContainer>
      </div>
    </>
  );
};

export default Tracking;
