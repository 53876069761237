import React from "react";

import styles from "./button.module.scss";

const Button = ({
  title,
  callback = null,
  callbackData = null,
  extraClass = "",
  type = "button",
}) => {
  return (
    <button
      onClick={() => {
        if (callback && !callbackData) callback();
        if (callback && callbackData) callback(callbackData);
      }}
      className={`${styles.umi_button} ${extraClass && styles[extraClass]}`}
      type={type}
    >
      {title}
    </button>
  );
};

export default Button;
