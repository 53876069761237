import { StyleSheet, css } from "aphrodite";

export const parseRGBA = (rgba) => {
  if (!rgba) return;
  const { r, g, b, a } = rgba;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const chooseBGImage = (uploadable, stored) => {
  if (uploadable) {
    return `url(${uploadable})`;
  } else if (stored) return `url(${stored})`;
  else return "";
};

export const buildImageEditedClass = (adjustments) => {
  const { size, top, left, rotate } = adjustments;

  const customImageAdjustments = StyleSheet.create({
    customImageSettings: {
      width: `${100 + 10 * size}%`,
      height: `${100 + 10 * size}%`,
      objectPosition: `${left}px ${top}px`,
      transform: `rotate(${rotate}deg)`,
    },
  });

  return css(customImageAdjustments.customImageSettings);
};

export const buildZeroBorderRadiusClass = () => {
  const zeroBorder = StyleSheet.create({
    zeroBorder: {
      borderRadius: 0,
    },
  });

  return css(zeroBorder.zeroBorder);
};

export const buildTextClass = (
  enable,
  font,
  bold,
  italic,
  underline,
  align_left,
  align_right,
  align_center,
  color
) => {
  const customText = StyleSheet.create({
    customText: {
      display: enable ? "block" : "none",
      fontFamily: `${font}, sans-serif`,
      fontWeight: bold && "bold",
      fontStyle: italic && "italic",
      textDecoration: underline && "underline",
      textAlign: align_left
        ? "left"
        : align_right
        ? "right"
        : align_right
        ? "right"
        : align_center
        ? "center"
        : "center",
      color: parseRGBA(color?.rgb),
    },
  });

  return css(customText.customText);
};

export const buildContactIconsClass = ({ enable, color }) => {
  const customColors = StyleSheet.create({
    customContactIconsColor: {
      fill: enable && parseRGBA(color?.rgb),
    },
  });

  return css(customColors.customContactIconsColor);
};

export const buildCTAIconColorClass = (classname, color) => {
  let styleTag = document.getElementById(classname);
  if (!styleTag) {
    styleTag = document.createElement("style");
    styleTag.id = classname;
    document.body.appendChild(styleTag);
  }
  styleTag.innerHTML = `
  .${classname} svg path, .${classname} svg circle {
     fill: ${parseRGBA(color?.rgb)};
  }
  `;

  return classname;
};

export const insertClassToSVGCircle = (err, svg, className) => {
  if (!err) svg.querySelector("circle").classList.add(className);
};

export const insertClassToSVGPath = (err, svg, className) => {
  if (!err)
    svg.querySelectorAll("path").forEach((path) => {
      path.classList.add(className);
    });
};
