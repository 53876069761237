import React from "react";
import { ReactSVG } from "react-svg";

import styles from "./header.module.scss";
import lock_svg from "../../../../assets/global/icon_lock.svg";
import help_svg from "../../../../assets/global/icon_help.svg";
import SelectDropdown from "../../selectDropdown/selectDropdown";

const CollapsibleHeader = ({
  smaller = false,
  title,
  subtitle = "",
  showUnlockBtn = true,
  showDropdown = false,
}) => {
  return (
    <>
      <div
        className={`${styles.header_container} ${
          smaller && styles.container_smaller_header
        }`}
      >
        <div className={styles.header_left}>
          <p className={styles.collapsible_heading}>{title}</p>
          <ReactSVG src={help_svg} wrapper={"span"} />
        </div>
        <div className={`${styles.header_right}`}>
          {showUnlockBtn && (
            <button
              className={`${styles.floating_save_button} ${styles.show} vertical_align_center`}
              onClick={() => 1}
            >
              Unlock All <ReactSVG src={lock_svg} wrapper={"span"} />
            </button>
          )}
          {showDropdown && (
            <SelectDropdown
              options={[
                { value: "today", label: "Today", locked: false },
                { value: "7d", label: "Last 7 Days", locked: false },
                { value: "14d", label: "Last 14 Days", locked: false },
                { value: "30d", label: "Last 30 Days", locked: false },
                { value: "90d", label: "Last 90 Days", locked: true },
                { value: "180d", label: "Last 180 Days", locked: true },
                { value: "custom", label: "Custom Date", locked: true }
              ]}
            />
          )}
        </div>
      </div>
      <div
        className={`${styles.header_container} ${
          smaller && styles.container_smaller_header
        }`}
      >
        <div className={styles.header_left}>
          <p className={styles.collapsible_heading}>
            {subtitle && (
              <span className={styles.collapsible_subtitle}>{subtitle}</span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default CollapsibleHeader;
