import React from "react";

import CollapsibleContainer from "../collapsibleContainer/collapsibleContainer";
import InputField from "../inputField/inputField";
import AnalyticsFooter from "../analyticsFooter/analyticsFooter";
import ColorPicker from "../colorPicker/colorPicker";

const ImageLinkEditableItem = ({ styles, title, inputId }) => {
  return (
    <CollapsibleContainer title={title} inputId={inputId} draggableItem={true}>
      <div className={styles.editable_section}>
        <InputField
          inputFieldId={`${inputId}_title`}
          label={`Link title`}
          defaultInputValue={"Our Image Collection"}
        />
        <InputField
          inputFieldId={`${inputId}__url`}
          label={"Link URL"}
          defaultInputValue={"https://yourdomain.com/landingpage"}
        />
        <div
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            smaller={true}
            title={"Link Background Color"}
            inputId={`${inputId}_background_color`}
          >
            <ColorPicker
              inputFieldId={`${inputId}_background_color_input_field`}
            />
          </CollapsibleContainer>
        </div>
        <div
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            smaller={true}
            title={"Link Border Color"}
            inputId={`${inputId}_border_color`}
          >
            <ColorPicker inputFieldId={`${inputId}_border_color_input_field`} />
          </CollapsibleContainer>
        </div>
        <div
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            smaller={true}
            title={"Link Hover Color"}
            inputId={`${inputId}_hover_color`}
          >
            <ColorPicker inputFieldId={`${inputId}_hover_color_input_field`} />
          </CollapsibleContainer>
        </div>
        <AnalyticsFooter />
      </div>
    </CollapsibleContainer>
  );
};

export default ImageLinkEditableItem;
