import { API_BASE } from "../../../api_links";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadTrackingCodes = async (tracking) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/tracking`;

  const updateObj = {
    google: tracking?.google,
    facebook: tracking?.facebook,
    linkedIn: tracking?.linkedIn,
    pinterest: tracking?.pinterest,
    tiktok: tracking?.tiktok,
    seo: tracking?.seo,
  };

  console.log("Update data", updateObj);

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Tracking Codes", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading Tracking Codes settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Tracking Codes Settings uploaded",
      });
    }
  });
};
