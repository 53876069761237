import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

const CalendlySkeleton = () => {
  return (
    <div className={styles.calendly_skeleton}>
      <Skeleton width={"100%"} className={styles.main_bg} />

      <Skeleton
        width={"100%"}
        className={styles.text}
        containerClassName={styles.header_text}
      />
      <Skeleton className={styles.cta_main_icon} />
      <Skeleton
        className={styles.calendar}
        containerClassName={styles.calendar_container}
      />
    </div>
  );
};

export default CalendlySkeleton;
