import React, { useState } from "react";
import styles from "./social.module.scss";
import SocialBarChart from "./barChart";
import SocialPieChart from "./pieChart";
import SocialListChart from "./list";

const SocialAnalytics = () => {
  const [listSelected, setListSelected] = useState(true);
  const [barSelected, setBarSelected] = useState(false);
  const [pieSelected, setPieSelected] = useState(false);

  const [numberSelected, setNumberSelected] = useState(true);
  const [percentageSelected, setPercentageSelected] = useState(false);

  const [viewSelected, setViewSelected] = useState(true);
  const [clickSelected, setClickSelected] = useState(false);
  const [ctrSelected, setCtrSelected] = useState(false);

  const [countrySelected, setCountrySelected] = useState(true);
  const [stateSelected, setStateSelected] = useState(false);
  const [citySelected, setCitySelected] = useState(false);

  const handleChangeChart = (select) => {
    setListSelected(false);
    setPieSelected(false);
    setBarSelected(false);

    select(true);
  };

  const handleChangeNoPerc = (select) => {
    setNumberSelected(false);
    setPercentageSelected(false);

    select(true);
  };

  const handleChangeViewClick = (select) => {
    setViewSelected(false);
    setClickSelected(false);
    setCtrSelected(false);

    select(true);
  };

  const handleChangeCountryState = (select) => {
    setCountrySelected(false);
    setStateSelected(false);
    setCitySelected(false);

    select(true);
  };

  return (
    <div className={styles.analytic_bar}>
      <div className={styles.section_top}>
        <div className={styles.section_options}>
          <p
            onClick={() => handleChangeChart(setListSelected)}
            className={listSelected && styles.selected_option}
          >
            List
          </p>
          <p
            onClick={() => handleChangeChart(setBarSelected)}
            className={barSelected && styles.selected_option}
          >
            Bar
          </p>
          <p
            onClick={() => handleChangeChart(setPieSelected)}
            className={pieSelected && styles.selected_option}
          >
            Chart
          </p>
        </div>
        <div className={styles.section_options}>
          <p
            onClick={() => handleChangeNoPerc(setNumberSelected)}
            className={numberSelected && styles.selected_option}
          >
            # Number
          </p>
          <p
            onClick={() => handleChangeNoPerc(setPercentageSelected)}
            className={percentageSelected && styles.selected_option}
          >
            % Percentage
          </p>
        </div>
      </div>

      {listSelected && <SocialListChart />}
      {barSelected && <SocialBarChart />}
      {pieSelected && <SocialPieChart />}

      <div className={styles.section_top}>
        <div className={styles.section_options}>
          <p
            onClick={() => handleChangeViewClick(setViewSelected)}
            className={viewSelected && styles.selected_option}
          >
            Views
          </p>
          <p
            onClick={() => handleChangeViewClick(setClickSelected)}
            className={clickSelected && styles.selected_option}
          >
            Click
          </p>
          <p
            onClick={() => handleChangeViewClick(setCtrSelected)}
            className={ctrSelected && styles.selected_option}
          >
            CTR
          </p>
        </div>
        <div className={styles.section_options}>
          <p
            onClick={() => handleChangeCountryState(setCountrySelected)}
            className={countrySelected && styles.selected_option}
          >
            Country
          </p>
          <p
            onClick={() => handleChangeCountryState(setStateSelected)}
            className={stateSelected && styles.selected_option}
          >
            State
          </p>
          <p
            onClick={() => handleChangeCountryState(setCitySelected)}
            className={citySelected && styles.selected_option}
          >
            City
          </p>
        </div>
      </div>
    </div>
  );
};

export default SocialAnalytics;
