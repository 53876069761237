import React from "react";
import AnalyticsChart from "../../common/charts/chart";

export const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const options = {
  legend: "none",
  colors: ["#81F2F9", "#1ba8f0", "#75D89D", "#FCC97B", "#E97D85", "#FF81DC"],
  backgroundColor: "transparent",
  pieHole: 0.5,
  pieSliceText: "none",
};

const SocialPieChart = () => {
  return (
    <AnalyticsChart
      chartType="PieChart"
      data={data}
      options={options}
    />
  );
};

export default SocialPieChart;
