import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  return {
    loaded: true,
    profile_picture_image_preview_file: data?.profile_pic, // Existing profile pic
    profile_picture_adjustments: data?.profile_picture_adjustments ?? {
      size: 0,
      top: 0,
      left: 0,
      rotate: 0,
    },
    border_style: data?.border_style ?? {
      version: {
        rounded_border: true,
        square_border: false,
        circle_border: false,
      },
    },
    border_stroke: data?.border_stroke ?? {
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
      width: 0,
    },
    background: data?.background ?? {
      enable: false,
      color: {
        hex: "#222f4f",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    share_leads: data?.share_leads,
    leads_form_fields: {
      name: data?.name,
      company_business_name: data?.company_business_name,
      job_title: data?.job_title,
      email: data?.email,
      mobile: data?.mobile,
      phone: data?.phone,
      extension: data?.extension,
    },
    custom_username: data?.custom_username ?? "",
  };
};

export const getPersonalDetailsFromDB = (
  personalDetails,
  setPersonalDetails,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.personalDetails);
    if (!parsedData) {
      setPersonalDetails({ ...personalDetails, loaded: true });
      return;
    }
    setPersonalDetails(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/personal_details`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (!data || data?.code || data?.message) return;
            setPersonalDetails(parseDataForContext(data));
            // buildNotification({
            //   type: "success",
            //   message: "Personal Details Settings Loaded",
            // });
          }
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/personal_details/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (!data || data?.code || data?.message) return;
            setPersonalDetails(parseDataForContext(data));
          }
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const getUsernameFromDB = (
  setUsername,
  username = null,
  data = null
) => {
  if (data?.username?.username) {
    setUsername(data?.username?.username);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/username`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const username = data?.username;
            setUsername(username);
          }
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    setUsername(username);
  }
};
