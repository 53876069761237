import Cookies from "js-cookie";

export const checkLoginCookie = () => {
  const authToken = Cookies.get("authToken");
  return authToken;
};

export const removeLoginCookie = () => {
  Cookies.remove("authToken");
};
