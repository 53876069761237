import React, { useState } from "react";

import Button from "../../../common/button/button";

import SocialShareModal from "../../../livePreview/shareProfile/socialShareModal/socialShareModal";

const ReferBody = ({ styles }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.inner}>
      <h3 className={styles.tab_heading}>
        Click button below to share Umicard with friends
      </h3>
      <Button
        title={"Share with friends"}
        callback={() => {
          setOpen(!open);
        }}
        callbackData={"none"}
      />
      <SocialShareModal
        open={open}
        closer={setOpen}
        url={`https://umicard.com`}
      />
    </div>
  );
};

export default ReferBody;
