import React from "react";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import ImageLinkStyles from "./imageLinkStyles/imageLinkStyles";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import AddMoreEdirableItemButton from "../../../common/addMoreEditableItemButton/addMoreEdirableItemButton";
import ImageLinkEditableItem from "../../../common/imageLinksEditableItem/imageLinksEditableItem";

const ImageLinks = ({ styles }) => {
  return (
    <div className="image_links">
      <SectionTopBar
        title={"Image Links"}
        id={"image-links-section-designer"}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Icons Style"
          inputId="enable_icons_style_image_links_section"
        >
          <div className={styles.editable_section}>
            <ImageLinkStyles />
          </div>
        </CollapsibleContainer>
      </div>

      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Links' Background Color"
          inputId="enable_image_links_section_background_color"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="image_links_section_background_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Links' Border Color"
          inputId="enable_image_links_section_border_color"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="image_links_section_border_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Links' Hover Color"
          inputId="enable_image_links_section_hover_color"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="image_links_section_hover_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <ImageLinkEditableItem
          styles={styles}
          title={"Image Link 1"}
          inputId={"image_link_item_1"}
        />
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <ImageLinkEditableItem
          styles={styles}
          title={"Image Link 2"}
          inputId={"image_link_item_2"}
        />
      </div>
      <AddMoreEdirableItemButton title={"Add More Links"} />
    </div>
  );
};

export default ImageLinks;
