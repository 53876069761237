import React, { useEffect, useState, useContext } from "react";
import { CRMURL } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";

import { CRMContext } from "../../../contexts/settingsContexts/crm.context";

import {
  update_crm_activecampaign_api_key,
  update_crm_activecampaign_api_url,
  update_crm_activecampaign_tag_id,
} from "../updateFunctions";

import { uploadCRM } from "../uploadFunction";

import SelectInputField from "../../../common/selectInputField/selectInputField";
import InputField from "../../../common/inputField/inputField";
import Button from "../../../common/button/button";

const ActiveCampaign = () => {
  const [crm, setCrm] = useContext(CRMContext);
  const [tagIDs, setTagIDs] = useState([]);

  const { API_Key, API_URL, tag_id } =
    crm.use_form.crm_details.crm_options.activecampaign;

  const fetchActiveCampaignTagIDs = () => {
    const authToken = checkLoginCookie();
    if (!authToken) return;
    fetch(`${CRMURL}/activecampaign/tag_ids`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: authToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        const tags = data?.tags?.tags;
        if (tags && tags?.length) {
          const tempTags = [];
          tags.forEach(({ id, tag }) => {
            tempTags.push({ value: id, text: tag });
          });
          setTagIDs(tempTags);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchActiveCampaignTagIDs();
  }, []);

  return (
    <React.Fragment>
      <InputField
        label={"API_URL"}
        defaultInputValue={API_URL}
        type={"password"}
        callback={update_crm_activecampaign_api_url}
        context={crm}
        updateContext={setCrm}
      />
      <InputField
        label={"API_Key"}
        defaultInputValue={API_Key}
        type={"password"}
        callback={update_crm_activecampaign_api_key}
        context={crm}
        updateContext={setCrm}
      />
      <Button
        title={"Fetch Tags"}
        callback={() => {
          fetchActiveCampaignTagIDs();
        }}
        callbackData={"none"}
      />
      <SelectInputField
        inputFieldId={
          "settings_privacy_contact_detail_form_submission_endpoint_tag"
        }
        options={tagIDs}
        label={"Select Tag"}
        defaultInputValue={tag_id}
        valuesObjectType={true}
        callback={update_crm_activecampaign_tag_id}
        context={crm}
        updateContext={setCrm}
      />
      <Button
        title={"Save ActiveCampaign Settings"}
        callback={uploadCRM}
        callbackData={crm}
      />
    </React.Fragment>
  );
};

export default ActiveCampaign;
