const destroyNotification = (notification) => {
  notification.classList.add("destroy");
  setTimeout(() => {
    notification.remove();
  }, 150);
};

const showNotification = (notification) => {
  setTimeout(() => {
    notification.classList.add("show");
    notification.querySelector(".close").onclick = () => notification.remove();
    setTimeout(() => {
      destroyNotification(notification);
    }, 3500);
  }, 10);
};

const buildNotificationHTML = ({ container, type, message }) => {
  const notification = document.createElement("div");
  notification.className = `notification ${type}`;
  notification.innerHTML = `<div class="sign"></div>
  <div class="message">
    <p class="heading">${type}</p>
    <p class="details">${message}</p>
  </div>
  <div class="close"></div>`;
  container.appendChild(notification);

  showNotification(notification);
};

export const buildNotification = (details) => {
  // if (true) return false;
  const container = document.getElementById("notifications");
  if (!container) return;
  buildNotificationHTML({ container, ...details });
};
