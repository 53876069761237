import { checkLoginCookie } from "../auth/checkLoginCookie";
import { buildNotification } from "../components/notification/buildNotification";
import {
  buildLoader,
  destroyLoader,
} from "../components/notification/buildLoader";

// Secure means we need to send auth token with it
export const uploadStaticData = (secure = false, url, update, callback) => {
  const loader = buildLoader();

  const headers = {
    "Content-Type": "application/json",
  };

  if (secure) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    headers["Authorization"] = `Bearer ${authToken}`;
  }

  try {
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(update),
    })
      .then((response) => response.json())
      .then((data) => {
        destroyLoader(loader);
        callback(data);
      })
      .catch((error) => {
        destroyLoader(loader);
        buildNotification({
          type: "error",
          message: "Unable to upload. Network error",
        });
        console.error("Error:", error);
      });
  } catch (error) {
    console.error(error);
  }
};
