import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";

const parseDataForContext = (data) => {
  if (!data) return;

  return {
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    phone: data?.phone,
    job_title: data?.job_title,
  };
};

export const getProfileInformationFromDB = (setProfileInfo) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  const url = `${API_BASE}:LiqXQBP3/get_profile_info`;

  try {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (!data || data?.code || data?.message) return;
          setProfileInfo(parseDataForContext(data));
        }
      });
  } catch (error) {
    console.error(error);
  }
};
