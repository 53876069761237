import { imageUploadURL, S3UploadImageURL } from "../api_links";

export const uploadImage = (file) => {
  if (!file) return;

  const formData = new FormData();
  formData.append("content", file);
  const options = {
    method: "POST",
    body: formData,
  };

  return fetch(imageUploadURL, options);
};

// S3 Image uploader
export const uploadImageS3 = (file, signedRequest) => {
  return fetch(signedRequest, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    },
    body: file,
  });
};

export const signImageS3 = (file, customPath) => {
  console.log(file);
  if (!file) return;
  const name = file.name;
  const type = file.type;
  return fetch(`${S3UploadImageURL}/signedS3`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ customPath, name, type }),
  });
};

export const deleteImageS3 = (filePath, callback = null) => {
  console.log("Delete this file", filePath);
  if (!filePath) return;

  fetch(`${S3UploadImageURL}/deleteS3`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filePath }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(filePath, " Delete Status ", data);
      if (callback) callback();
    });
};
