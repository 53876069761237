import React, { useState } from "react";

import styles from "./passwordProtectedContent.module.scss";

import LockedOverlay from "../../lockedOverlay/lockedOverlay";
import PasswordProtected from "../passwordProtected";

// type : image_gallery, video_gallery
const PasswordProtectedContent = ({
  overlayTitle,
  popupTitle,
  infoType = null,
  password,
  updateCallback = null,
  children,
}) => {
  const [openPasswordForm, setOpenPasswordForm] = useState(false);
  const [hideOverlay, setHideOverlay] = useState(false);

  return (
    <React.Fragment>
      <LockedOverlay
        title={overlayTitle}
        callback={() => {
          setOpenPasswordForm(!openPasswordForm);
        }}
        hide={hideOverlay}
        thick_hide={true}
      />
      <div
        className={`${styles.password_input_layer} ${
          openPasswordForm && styles.show
        }`}
      >
        <PasswordProtected
          type={"slider"}
          title={popupTitle}
          infoType={infoType}
          password={password}
          closer={setOpenPasswordForm}
          callback={() => {
            setHideOverlay(true);
            setOpenPasswordForm(false);
            if (updateCallback) updateCallback();
          }}
        />
      </div>
      {children}
    </React.Fragment>
  );
};

export default PasswordProtectedContent;
