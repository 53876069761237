import React, { useState } from "react";
import { DateRange } from "react-date-range";
import styles from "./date.module.scss";
import cross_svg from "../../../assets/global/icon_calendar.svg";
import collapse_svg from "../../../assets/global/icon_collapse.svg";
import { ReactSVG } from "react-svg";

const DateAnalytics = () => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <>
      <div className={styles.analytic_section}>
        <div className={styles.date_selection} onClick={() => setOpen(!open)}>
          <span>
            <ReactSVG src={cross_svg} /> Sep 1st, 2022 to Sep 7th, 2022
          </span>
          <ReactSVG
            style={{ transform: open ? "rotate(0deg)" : "rotate(180deg)" }}
            src={collapse_svg}
          />
        </div>
        {open && (
          <div className={styles.calendar_container}>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              showDateDisplay={false}
              style={{ width: "100%" }}
              className="minhal"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateAnalytics;
