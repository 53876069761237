import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import InputField from "../inputField/inputField";
import ConfrimationPopup from "../confirmationPopup/confirmationPopup";

import styles from "./analyticsFooter.module.scss";

import lock_svg from "../../../assets/sections/analytics/icon_lock.svg";
import graphs_svg from "../../../assets/sections/analytics/icon_graphs.svg";
import calendar_svg from "../../../assets/sections/analytics/icon_calendar.svg";
import delete_svg from "../../../assets/global/icon_delete.svg";

const AnalyticsFooter = ({
  context = null,
  updateContext = null,
  index = null,
  deleteCallback = null,
  type = "unknown",
}) => {
  const [showPasswordEditor, setShowPasswordEditor] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [scheduleValue, setScheduleValue] = useState([new Date(), new Date()]);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

  const update_password = (updatedValue, context, updateContext, index) => {
    if (index === null) return;
    const libraryCopy = { ...context };
    const targetItem = libraryCopy.gallery_data[index];
    if (!targetItem) return;
    targetItem["password"] = updatedValue;
    updateContext(libraryCopy);
  };

  const update_schedule = (newScheule) => {
    if (index === null) return;
    const libraryCopy = { ...context };
    const targetItem = libraryCopy.gallery_data[index];
    if (!targetItem) return;

    // Clearing
    if (!newScheule) {
      setScheduleValue(["", ""]);
      targetItem["schedule"] = ["", ""];
      updateContext(libraryCopy);
      return;
    }

    // Setting new schedule
    const [start, end] = newScheule;
    if (new Date(start) > new Date(end)) return;
    const [startParsed, endParsed] = [
      new Date(start).toISOString(),
      new Date(end).toISOString(),
    ];
    setScheduleValue([start, end]);
    targetItem["schedule"] = [startParsed, endParsed];
    updateContext(libraryCopy);
  };

  useEffect(() => {
    if (context && context?.gallery_data[index]?.schedule) {
      setScheduleValue(context.gallery_data[index].schedule);
    }
  }, [context]);

  return (
    <div className={styles.analytics_footer_container}>
      <div className={styles.analytics_footer_left}>
        <span className={styles.analytics_icon}>
          <ReactSVG
            src={lock_svg}
            wrapper={"span"}
            onClick={() => {
              setShowPasswordEditor(!showPasswordEditor);
              setShowDatePicker(false);
            }}
          />
        </span>
        {/* <span className={styles.analytics_icon}>
          <ReactSVG src={graphs_svg} wrapper={"span"} />{" "}
        </span> */}
        <span
          className={styles.analytics_icon}
          onClick={() => {
            setShowDatePicker(!showDatePicker);
            setShowPasswordEditor(false);
          }}
        >
          <ReactSVG src={calendar_svg} wrapper={"span"} />{" "}
        </span>
      </div>
      <div className={styles.analytics_footer_right}>
        <button
          className={styles.delete_btn}
          onClick={() => {
            // context && deleteCallback(context, updateContext, index);
            setDeleteConfirmationShow(true);
          }}
        >
          <ReactSVG src={delete_svg} wrapper={"span"} />{" "}
        </button>
      </div>
      <div
        className={styles.date_range_picker}
        style={{ display: showPasswordEditor ? "flex" : "none" }}
      >
        <InputField
          inputFieldId={`${type}_gallery_item_${index}_pw`}
          label={"Enter password to enable lock"}
          defaultInputValue={
            context && context.gallery_data.length > 0
              ? context.gallery_data[index].password
              : ""
          }
          callback={update_password}
          context={context}
          updateContext={updateContext}
          index={index}
        />
      </div>
      <div
        className={styles.date_range_picker}
        style={{ display: showDatePicker ? "flex" : "none" }}
      >
        <DateRangePicker
          className={styles.range_picker_body}
          value={scheduleValue}
          onChange={update_schedule}
        />
      </div>
      <ConfrimationPopup
        type="warning"
        privacyContactName="Hayder"
        heading={"Wait, Are you sure?"}
        subHeading={`If you remove this ${
          context?.gallery_data[index]?.title ?? "gallery item"
        }, it cannot be recovered.`}
        noBtnText={"Cancel"}
        yesBtnText={"Yes, delete it!"}
        show={deleteConfirmationShow}
        setShow={setDeleteConfirmationShow}
        yesCallback={() => {
          context && deleteCallback(context, updateContext, index);
        }}
      />
    </div>
  );
};

export default AnalyticsFooter;
