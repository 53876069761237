import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import styles from "./collapsibleHeader.module.scss";
import collapse_svg from "../../../../assets/global/icon_collapse.svg";
import help_svg from "../../../../assets/global/icon_help.svg";

const CollapsibleHeader = ({
  smaller = false,
  title,
  subtitle = "",
  inputId,
  draggableItem = false,
  showSwitch = true,
  showIconLeft = null,
  helpIcon = "",
  enabled = false,
  callback = null,
  context = null,
  updateContext = null,
  index = null,
  bodyRef,
}) => {
  const [open, setOpen] = useState(false);

  const enable_disable_section = (state) => {
    // Color Picker auto close
    const colorPickerBody = bodyRef.current.querySelector(
      ".color_picker_body_custom"
    );
    if (state) {
      bodyRef.current.style.opacity = 1;
      bodyRef.current.style.pointerEvents = "unset";

      if (colorPickerBody) colorPickerBody.style.opacity = 1;
      if (colorPickerBody) colorPickerBody.style.pointerEvents = "unset";
    } else {
      bodyRef.current.style.opacity = 0.5;
      bodyRef.current.style.pointerEvents = "none";

      if (colorPickerBody) colorPickerBody.style.opacity = 0;
      if (colorPickerBody) colorPickerBody.style.pointerEvents = "none";
    }
    if (callback) callback(state, context, updateContext, index);
  };

  const open_close_body = (state) => {
    if (state) {
      bodyRef.current.style.overflow = "hidden";
      bodyRef.current.style.height = `${bodyRef.current.scrollHeight}px`;
      setTimeout(() => {
        if (!bodyRef.current) return;
        bodyRef.current.style.height = "0px";
      }, 1);
    } else {
      bodyRef.current.style.height = `${bodyRef.current.scrollHeight}px`;
      setTimeout(() => {
        if (!bodyRef.current) return;
        bodyRef.current.style.overflow = "unset";
        bodyRef.current.style.height = "unset";
      }, 150);
    }
  };

  useEffect(() => {
    if (showSwitch) enable_disable_section(enabled);
    else {
      bodyRef.current.style.opacity = 1;
      bodyRef.current.style.pointerEvents = "unset";
    }
  }, [enabled]);

  return (
    <div
      className={`${styles.header_container} ${
        smaller && styles.container_smaller_header
      }`}
    >
      <div className={styles.header_left}>
        {draggableItem && <span className="ellipses"></span>}
        {showSwitch && (
          <label className={styles.switch}>
            <input
              type="checkbox"
              name={inputId}
              onChange={(e) => enable_disable_section(e.target.checked)}
              checked={enabled}
            />
            <span className={`${styles.slider} ${styles.round}`}></span>
          </label>
        )}
        {showIconLeft && (
          <span className={styles.collapsible_header_icon}>
            <ReactSVG src={showIconLeft} wrapper={"span"} />
          </span>
        )}
        <p className={styles.collapsible_heading}>
          {title}{" "}
          {subtitle && (
            <span className={styles.collapsible_subtitle}>{subtitle}</span>
          )}
        </p>
        {helpIcon && <ReactSVG src={help_svg} wrapper={"span"} />}
      </div>
      <div
        className={styles.header_right}
        style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        onClick={() => {
          open_close_body(!open);
          setOpen(!open);
        }}
      >
        <ReactSVG src={collapse_svg} wrapper={"span"} />
      </div>
    </div>
  );
};

export default CollapsibleHeader;
