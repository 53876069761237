import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

const PersonlDetailsSkeleton = () => {
  return (
    <div className={styles.personal_details_skeleton}>
      <Skeleton width={"90%"} height={60} className={styles.business_logo} />
      <Skeleton width={"50%"} className={styles.text} />
      <Skeleton className={styles.main_bg} />
      <Skeleton className={styles.profile_picture} circle />
      <Skeleton width={"70%"} className={`${styles.text} ${styles.name}`} />
      <Skeleton width={"50%"} className={`${styles.text} ${styles.job}`} />
      <div className={styles.contact_div}>
        <Skeleton className={styles.contact_div_inner} />
        <div className={styles.contact_flex}>
          <Skeleton
            className={styles.contact_icon}
            containerClassName={styles.contact_icon_container}
            circle
          />
          <Skeleton
            className={styles.contact_icon}
            containerClassName={styles.contact_icon_container}
            circle
          />
          <Skeleton
            className={styles.contact_icon}
            containerClassName={styles.contact_icon_container}
            circle
          />
          <Skeleton
            className={styles.contact_icon}
            containerClassName={styles.contact_icon_container}
            circle
          />
        </div>
      </div>
    </div>
  );
};

export default PersonlDetailsSkeleton;
