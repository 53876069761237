export const updateSidebarEnable = (
  value,
  id,
  sidebarOrder,
  setSidebarOrder
) => {
  const sidebarOrderCopy = { ...sidebarOrder };
  for (let i = 0; i < sidebarOrderCopy.order.length; i++) {
    const targetValue = sidebarOrderCopy.order[i];
    if (targetValue.title === id) {
      targetValue.enable = value;
      setSidebarOrder(sidebarOrderCopy);
      break;
    }
  }
};
