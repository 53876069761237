import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import styles from "./selectDropdown.module.scss";
import lock_svg from "../../../assets/global/icon_lock.svg";
import { ReactSVG } from "react-svg";

const { Option } = components;
const IconOption = (props) => (
  <Option {...props} className={styles.option}>
    <p className={styles.select_option}>
      <span>{props.data.label}</span> {props.data.locked && <ReactSVG src={lock_svg} wrapper={"span"} />}
    </p>
  </Option>
);

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div style={{ display: "flex" }}>
    <p>Duration: {label}</p>
  </div>
);

const SelectDropdown = ({
  inputFieldId,
  options,
  showLabel = true,
  label = "",
  defaultInputValue = "",
  valuesObjectType = false,
  callback = null,
  context = null,
  updateContext = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultInputValue);

  useEffect(() => {
    setSelectedValue(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <div className={styles.select_container}>
      {showLabel && <label htmlFor={inputFieldId}>{label}</label>}
      <Select
        defaultValue={options[0]}
        options={options}
        components={{ Option: IconOption, IndicatorSeparator: () => null }}
        isSearchable={false}
        className={styles.hello}
        onChange={(e) => console.log(e)}
        styles={{
          option: (baseStyles, state) => ({
            ...baseStyles,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: state.isSelected && "#F5F5F5",
            color: state.isSelected && "#505050",
            cursor: "pointer",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            width: "200px",
            cursor: "pointer",
            color: "#505050",
            backgroundColor: "#EEEFF0",
            fontSize: "15px",
            fontWeight: 500,
          }),
        }}
        isOptionDisabled={(option) => option.locked}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

export default SelectDropdown;
