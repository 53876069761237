import React, { useState, useEffect } from "react";

import CounterInput from "../counterInput/counterInput";

import styles from "./imageEditor.module.scss";

const ImageEditor = ({
  inputFieldId,
  defaultValues = null,
  sizeCallback = null,
  leftCallback = null,
  topCallback = null,
  rotateCallback = null,
  context = null,
  updateContext = null,
  sizeOnly = false,
  sizeMin = 0,
  sizeMax = 50,
  index = null,
}) => {
  const [size, setSize] = useState(0);
  const [left, setleft] = useState(0);
  const [top, setTop] = useState(0);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    if (!defaultValues) return;

    if (parseInt(defaultValues.size)) setSize(defaultValues.size);
    if (parseInt(defaultValues.left)) setleft(defaultValues.left);
    if (parseInt(defaultValues.top)) setTop(defaultValues.top);
    if (parseInt(defaultValues.rotate)) setRotate(defaultValues.rotate);
  }, [defaultValues]);

  return (
    <div className={styles.image_editor}>
      <CounterInput
        inputFieldId={inputFieldId}
        label={"Change Image Size"}
        step={1}
        min={sizeMin}
        max={sizeMax}
        reset={true}
        resetValue={0}
        defaultValue={size}
        callback={sizeCallback}
        index={index}
        context={context}
        updateContext={updateContext}
      />
      {!sizeOnly && (
        <>
          <CounterInput
            inputFieldId={inputFieldId}
            label={"Set Image Left Position"}
            step={1}
            min={-1000}
            max={1000}
            reset={true}
            resetValue={0}
            defaultValue={left}
            callback={leftCallback}
            index={index}
            context={context}
            updateContext={updateContext}
          />
          <CounterInput
            inputFieldId={inputFieldId}
            label={"Set Image Top Position"}
            step={1}
            min={-1000}
            max={1000}
            reset={true}
            resetValue={0}
            defaultValue={top}
            callback={topCallback}
            index={index}
            context={context}
            updateContext={updateContext}
          />
          <CounterInput
            inputFieldId={inputFieldId}
            label={"Set Image Rotation in degrees"}
            step={1}
            min={-360}
            max={360}
            reset={true}
            resetValue={0}
            defaultValue={rotate}
            callback={rotateCallback}
            index={index}
            context={context}
            updateContext={updateContext}
          />
        </>
      )}
    </div>
  );
};

export default ImageEditor;
