import React, { useState } from "react";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
} from "react-share";
import { ReactSVG } from "react-svg";

import { copyToClipboard } from "../../../helpers/utilityFunctions";

import styles from "./socialShareModal.module.scss";

import Modal from "../../../common/modal/modal";

import linkedIn_png from "../../../../assets/sections/socialLinks/linkedin.png";
import twitter_png from "../../../../assets/sections/socialLinks/twitter.png";
import facebook_png from "../../../../assets/sections/socialLinks/facebook.png";
import facebook_messenger_png from "../../../../assets/sections/socialLinks/fb_messenger.png";
import whatsapp_png from "../../../../assets/sections/socialLinks/whatsapp.png";
import copy_svg from "../../../../assets/global/icon_copy_line.svg";

const SocialShareModal = ({ open, closer, url, shareButtons }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Modal open={open} closer={closer} maxWidth={330}>
      <h2 className={styles.share_heading}>Share to</h2>
      <h5 className={styles.share_subheading}>
        Share Your UmiCard with your connections to let them know about your it.
      </h5>
      <div className={styles.popup_share_btns}>{shareButtons}</div>
      <div className={styles.share_btns}>
        <LinkedinShareButton
          url={url}
          title={"Checkout my Umicard"}
          summary={"Some summary here"}
        >
          <img src={linkedIn_png} alt="Share to linkedin" />
          <span className={styles.share_text}>Share on LinkedIn</span>
        </LinkedinShareButton>
        <TwitterShareButton
          url={url}
          title={"Checkout my umicard"}
          hashtags={["umicard"]}
        >
          <img src={twitter_png} alt="Share to Twitter" />
          <span className={styles.share_text}>Share on Twitter</span>
        </TwitterShareButton>
        <FacebookShareButton
          url={url}
          quote={"Checkout my Umicard"}
          hashtag={"#umicard"}
        >
          <img src={facebook_png} alt="Share to Facebook" />
          <span className={styles.share_text}>Share on Facebook</span>
        </FacebookShareButton>
        <FacebookMessengerShareButton
          url={url}
          title={"Checkout my Umicard"}
          appId={"745394683159911"}
        >
          <img src={facebook_messenger_png} alt="Share to Facebook Messenger" />
          <span className={styles.share_text}>
            Share via Facebook Messenger
          </span>
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={url} title={"Checkout my Umicard"}>
          <img src={whatsapp_png} alt="Share to WhatsApp" />
          <span className={styles.share_text}>Share on WhatsApp</span>
        </WhatsappShareButton>
      </div>
      <h5 className={styles.share_subheading}>
        Copy Your UmiCard Link to Clipboard
      </h5>
      <div
        className={`${styles.copy_url} ${copied && styles.copied}`}
        onClick={() => {
          copyToClipboard(url);
          setCopied(true);
        }}
      >
        <span className={styles.copy_text}>{url}</span>
        <ReactSVG
          src={copy_svg}
          wrapper={"span"}
          className={styles.copy_icon}
        />
      </div>
    </Modal>
  );
};

export default SocialShareModal;
