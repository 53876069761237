export const update_enable_calendly = (value, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.enable = value;
  setCalendly(calendlyCopy);
};
export const update_calendly_url = (value, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.url = value;
  setCalendly(calendlyCopy);
};
export const update_calendly_title = (value, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.title = value;
  setCalendly(calendlyCopy);
};
export const update_enable_calendly_section_background_color = (
  value,
  calendly,
  setCalendly
) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.background.enable = value;
  setCalendly(calendlyCopy);
};
export const update_calendly_section_background_color = (
  value,
  calendly,
  setCalendly
) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.background.color = value;
  setCalendly(calendlyCopy);
};
export const update_enable_calendly_section_icon = (
  value,
  calendly,
  setCalendly
) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.icon.enable = value;
  setCalendly(calendlyCopy);
};
export const update_calendly_icon = (file, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };

  if (!file) {
    calendlyCopy.icon.uploadable_file = "";
    calendlyCopy.icon.upload = "";
  } else {
    calendlyCopy.icon.uploadable_file = file;
    calendlyCopy.icon.upload = URL.createObjectURL(file);
  }
  setCalendly(calendlyCopy);
};

export const select_icon_type_calendly = (option, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.icon.type = option;
  setCalendly(calendlyCopy);
};

export const update_calendly_fa_icon_color = (value, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.icon.color = value;
  setCalendly(calendlyCopy);
};

export const update_fa_icon_name_calendly = (value, calendly, setCalendly) => {
  const calendlyCopy = { ...calendly };
  calendlyCopy.icon.fa_icon = value;
  setCalendly(calendlyCopy);
};
