import React, { useEffect, useContext } from "react";
import { InlineWidget } from "react-calendly";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  parseRGBA,
  buildCTAIconColorClass,
} from "../../helpers/stylesheetGeneration";

import { DataLoadContext } from "../../contexts/dataLoad.context";
import { CalendlyContext } from "../../contexts/sectionsContexts/calendly.context";
import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import styles from "./calendly.module.scss";

import CalendlySkeleton from "../../skeletons/calendly/calendly.skeleton";

import defaultCTAIcon from "../../../assets/umicardViewer/videoGalleryViewer/ctaDefaultIcon.svg";

const Calendly = () => {
  const [dataLoad, setDataLoad] = useContext(DataLoadContext);
  const [calendly] = useContext(CalendlyContext);
  const [fontsAndColors] = useContext(FontsAndColorsContext);

  useEffect(() => {
    if (calendly.loaded) {
      setDataLoad({ ...dataLoad, calendly: true });
    }
  }, [calendly]);

  const { enable, url, title, icon, background } = calendly;

  let parsedCalendlyURL = url;
  if (url.substring(0, 21).toLowerCase() !== "https://calendly.com/")
    parsedCalendlyURL = "https://calendly.com/" + url;

  const backgroundColorSection = parseRGBA(background.color.rgb);

  const {
    enable: enable_calendly_section_icon,
    preview_file: calendly_icon_existing_url,
    upload: calendly_icon_preview_url,
    type,
    fa_icon,
    color,
  } = icon;

  const iconColorClass = buildCTAIconColorClass("calendly_fa", color);

  const { sections_border_radius } = fontsAndColors;

  return (
    <>
      {!dataLoad.calendly ? (
        <CalendlySkeleton />
      ) : (
        <>
          {enable && (
            <div
              id="calendly-viewer"
              className={styles.calendly}
              style={{
                backgroundColor: background.enable && backgroundColorSection,
                borderRadius: !sections_border_radius && 0,
              }}
            >
              <div className={styles.calendly_header}>
                <p className={styles.calendly_heading}>{title}</p>
                {enable_calendly_section_icon && (
                  <div className={`${styles.calendly_icon} ${iconColorClass}`}>
                    {type === "img" && (
                      <img
                        src={
                          calendly_icon_preview_url
                            ? calendly_icon_preview_url
                            : calendly_icon_existing_url
                            ? calendly_icon_existing_url
                            : defaultCTAIcon
                        }
                        alt="Calendly CTA"
                      />
                    )}
                    {type === "fa" && <FontAwesomeIcon icon={fa_icon} />}
                  </div>
                )}
              </div>
              {url && (
                <div
                  className={styles.widget}
                  style={{ borderRadius: !sections_border_radius && 0 }}
                >
                  <InlineWidget
                    url={parsedCalendlyURL}
                    styles={{ width: "100%", height: "550px" }}
                    pageSettings={{
                      hideGdprBanner: true,
                      backgroundColor: "222f4f",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Calendly;
