import React, { useState } from "react";

const Vimeo = ({ vimeo_id, title, thumbnail = false }) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <React.Fragment>
      {!showVideo && (
        <img
          src={`https://vumbnail.com/${vimeo_id}.jpg`}
          alt={title}
          onClick={() => {
            if (thumbnail) return;
            setShowVideo(true);
          }}
        />
      )}

      {showVideo && (
        <iframe
          title={title}
          src={`https://player.vimeo.com/video/${vimeo_id}`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </React.Fragment>
  );
};

export default Vimeo;
