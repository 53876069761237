import { checkLoginCookie } from "../../../auth/checkLoginCookie";
import { API_BASE } from "../../../api_links";

export const markIntroAsComplete = () => {
  const authToken = checkLoginCookie();
  if (!authToken) {
    return;
  }

  const MARK_INTRO_COMPLETE_ENDPOINT =
    API_BASE + ":LiqXQBP3/markIntroAsComplete";

  fetch(MARK_INTRO_COMPLETE_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.message) {
        console.log(data.message);
      } else {
        // Success
        console.log("Success in updating mark intro as complete", data);
      }
    });
};
