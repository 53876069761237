import { API_BASE } from "../../../api_links";
import {
  signImageS3,
  uploadImageS3,
  deleteImageS3,
} from "../../../uploadServices/imageUploader";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadPersonalDetails = async (personalDetails) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/personal_details`;

  const {
    profile_picture_image_preview_file,
    profile_picture_image_uploadable_file,
    profile_picture_adjustments,
    border_style,
    border_stroke,
    background,
    leads_form_fields,
    share_leads,
    custom_username,
  } = personalDetails;

  const {
    name,
    company_business_name,
    job_title,
    email,
    mobile,
    phone,
    extension,
  } = leads_form_fields;

  const updateObj = {
    profile_pic: profile_picture_image_preview_file,
    profile_picture_adjustments,
    border_style,
    border_stroke,
    background,
    share_leads,
    name,
    company_business_name,
    job_title,
    email,
    mobile,
    phone,
    extension,
    custom_username,
  };

  // Upload Profile picture to S3
  if (profile_picture_image_uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        profile_picture_image_uploadable_file,
        "personal/pictures"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            profile_picture_image_uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            deleteImageS3(profile_picture_image_preview_file?.url);
            updateObj.profile_pic = { url };
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Personal Details", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading Personal Details",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Profile Picture Uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Personal Details", result);
    if (result?.message) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading personal details settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Personal Details Settings Uploaded",
      });
    }
  });
};

export const uploadUsername = (username) => {
  if (!username || username === "username") return;
  const dataUploadURL = `${API_BASE}:LiqXQBP3/updateusername`;
  const updateObj = { username };

  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result username update", result);
    if (result?.message) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Username failed to update",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Username Updated!",
      });
    }
  });
};
