export const update_enable_sections_and_titles_headlines = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.sections_and_titles_headlines.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_sections_border_radius = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.sections_border_radius = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_sections_and_titles_headlines_font = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font =
    value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_sections_and_titles_headlines_font_styles = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_bold =
    bold;
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_italic =
    italic;
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_underline =
    underline;
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_align_left =
    left_align;
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_align_right =
    right_align;
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_font_align_center =
    center_align;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_sections_and_titles_headlines_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.sections_and_titles_headlines.sections_and_titles_headlines_color =
    value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_body_text = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.body_text.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_body_text_font = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.body_text.body_text_font = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_body_text_font_styles = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.body_text.body_text_font_bold = bold;
  fontsAndColorsCopy.body_text.body_text_font_italic = italic;
  fontsAndColorsCopy.body_text.body_text_font_underline = underline;
  fontsAndColorsCopy.body_text.body_text_font_align_left = left_align;
  fontsAndColorsCopy.body_text.body_text_font_align_right = right_align;
  fontsAndColorsCopy.body_text.body_text_font_align_center = center_align;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_body_text_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.body_text.body_text_color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_contact_icons_primary_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.contact_icons_primary_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_contact_icons_primary_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.contact_icons_primary_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_contact_icons_secondary_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.contact_icons_secondary_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_contact_icons_secondary_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.contact_icons_secondary_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_social_media_icons_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.social_media_icons_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_social_media_icons_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.social_media_icons_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_social_media_icons_background_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.social_media_icons_background_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_social_media_icons_background_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.social_media_icons_background_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_share_my_card_background_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.share_my_card_background_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_share_my_card_background_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.share_my_card_background_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_enable_seconds_icon_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.seconds_icon_color.enable = value;
  setFontsAndColors(fontsAndColorsCopy);
};

export const update_seconds_icon_color = (
  value,
  fontsAndColors,
  setFontsAndColors
) => {
  const fontsAndColorsCopy = { ...fontsAndColors };
  fontsAndColorsCopy.seconds_icon_color.color = value;
  setFontsAndColors(fontsAndColorsCopy);
};
