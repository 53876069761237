import React from "react";

import { update_social_icon_style_version } from "../updateFunctions";

import Checkbox from "../../../../common/checkbox/checkbox";

import styles from "./socialIconsStyle.module.scss";

const SocialIconsStyle = ({ version, context, updateContext }) => {
  const ids = Object.keys(version);

  return (
    <div className={styles.social_icons_style_selectors_container}>
      {ids.map((id) => {
        return (
          <Checkbox
            key={id}
            inputFieldId={id}
            checkboxStyle={"social_icons_style_checkbox"}
            sendIDInUpdate={true}
            defaultValue={version[id]}
            callback={update_social_icon_style_version}
            context={context}
            updateContext={updateContext}
          />
        );
      })}
    </div>
  );
};

export default SocialIconsStyle;
