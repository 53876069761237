import React from "react";
import "../iubenda";

const CookiePolicy = () => {
  return (
    <>
      <a
        href="https://www.iubenda.com/privacy-policy/48266867/cookie-policy"
        className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
        title="Privacy Policy"
      >
        Cookie Policy
      </a>
    </>
  );
};

export default CookiePolicy;
