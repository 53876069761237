import React from "react";

import styles from "./modal.module.scss";

const Modal = ({ children, open, closer, maxWidth = 0, overflow = false }) => {
  const closeModal = (e) => {
    if (!e.target.dataset.popbg) return;
    closer(!open);
  };

  return (
    <div
      className={styles.popup_bg_layer}
      onClick={(e) => closeModal(e)}
      style={{
        display: open ? "flex" : "none",
      }}
      data-popbg="true"
    >
      <div
        className={`${styles.popup_container}`}
        style={{
          maxWidth: maxWidth > 0 ? `${maxWidth}px` : "",
          overflowY: overflow ? "visible" : "",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
