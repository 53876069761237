import React, { useState, createContext } from "react";

export const ProfileInfoContext = createContext();

export const ProfileInfoProvider = ({ children }) => {
  const [profileInfo, setProfileInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    job_title: "",
  });

  return (
    <ProfileInfoContext.Provider value={[profileInfo, setProfileInfo]}>
      {children}
    </ProfileInfoContext.Provider>
  );
};
