import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import styles from "./lifetime.module.scss";
import cross_svg from "../../../assets/global/icon_cross.svg";
import AnalyticsBarChart from "./barChart";
import LifetimeMapChart from "./mapChart";

const LifetimeAnalytics = () => {
  const [mapSelected, setMapSelected] = useState(true);
  const [barSelected, setBarSelected] = useState(false);
  const [pieSelected, setPieSelected] = useState(false);

  const [numberSelected, setNumberSelected] = useState(true);
  const [percentageSelected, setPercentageSelected] = useState(false);

  const [viewSelected, setViewSelected] = useState(true);
  const [clickSelected, setClickSelected] = useState(false);
  const [ctrSelected, setCtrSelected] = useState(false);

  const handleChangeChart = (select) => {
    setMapSelected(false);
    setPieSelected(false);
    setBarSelected(false);

    select(true);
  };

  const handleChangeNoPerc = (select) => {
    setNumberSelected(false);
    setPercentageSelected(false);

    select(true);
  };

  const handleChangeViewClick = (select) => {
    setViewSelected(false);
    setClickSelected(false);
    setCtrSelected(false);

    select(true);
  };

  return (
    <>
      <div className={styles.analytic_section}>
        <div className={styles.content_section}>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.views}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Views:</p>
            </div>
            <p className={styles.value}>256</p>
          </div>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.clicks}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Clicks:</p>
            </div>
            <p className={styles.value}>185</p>
          </div>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.unique}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Unique Clicks:</p>
            </div>
            <p className={styles.value}>122</p>
          </div>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.ctr}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Avg CTR:</p>
            </div>
            <p className={styles.value}>4.6%</p>
          </div>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.time}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Avg Time to Click:</p>
            </div>
            <p className={styles.value}>5.7s</p>
          </div>
          <div className={styles.single_item}>
            <div className={`${styles.title_section} ${styles.earning}`}>
              <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
              <p className={styles.title}>Earning:</p>
            </div>
            <p className={styles.value}>$ 2,350</p>
          </div>
        </div>
      </div>
      <AnalyticsBarChart />

      <div className={styles.analytic_bar}>
        <div className={styles.section_top}>
          <div className={styles.section_options}>
            <p
              onClick={() => handleChangeChart(setMapSelected)}
              className={mapSelected && styles.selected_option}
            >
              Map
            </p>
            <p
              onClick={() => handleChangeChart(setBarSelected)}
              className={barSelected && styles.selected_option}
            >
              Bar
            </p>
            <p
              onClick={() => handleChangeChart(setPieSelected)}
              className={pieSelected && styles.selected_option}
            >
              Chart
            </p>
          </div>
          <div className={styles.section_options}>
            <p
              onClick={() => handleChangeNoPerc(setNumberSelected)}
              className={numberSelected && styles.selected_option}
            >
              # Number
            </p>
            <p
              onClick={() => handleChangeNoPerc(setPercentageSelected)}
              className={percentageSelected && styles.selected_option}
            >
              % Percentage
            </p>
          </div>
        </div>

        <LifetimeMapChart />

        <div className={styles.section_top}>
          <div className={styles.section_options}>
            <p
              onClick={() => handleChangeViewClick(setViewSelected)}
              className={viewSelected && styles.selected_option}
            >
              Views
            </p>
            <p
              onClick={() => handleChangeViewClick(setClickSelected)}
              className={clickSelected && styles.selected_option}
            >
              Click
            </p>
            <p
              onClick={() => handleChangeViewClick(setCtrSelected)}
              className={ctrSelected && styles.selected_option}
            >
              CTR
            </p>
          </div>
        </div>
      </div>
      <div className={styles.analytic_bar}>
        <div className={styles.info_section}>
          For more interesting analytics go to Analytics tab in dashboard.
        </div>
      </div>
    </>
  );
};

export default LifetimeAnalytics;
