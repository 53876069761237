import React from "react";
import AnalyticsChart from "../../common/charts/chart";

const LinkBarChart = () => {
  const data = [
    ["City", "2010 Population"],
    ["New York City, NY", 8175000],
    ["Los Angeles, CA", 3792000],
    ["Chicago, IL", 2695000],
    ["Houston, TX", 2099000],
    ["Philadelphia, PA", 1526000],
  ];

  const options = {
    legend: "none",
    backgroundColor: "transparent",
    colors: ["#75D89D"],
  };

  return (
    <AnalyticsChart
      chartType="BarChart"
      data={data}
      options={options}
    />
  );
};

export default LinkBarChart;
