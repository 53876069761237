// const a = [];
// document.querySelectorAll(".icon-name").forEach((i) => {
//   a.push(i.innerText);
// });
// console.log(a);

export const FA_ICONS = [
  "address-book",
  "address-card",
  "bell",
  "bell-slash",
  "bookmark",
  "building",
  "calendar",
  "calendar-check",
  "calendar-days",
  "calendar-minus",
  "calendar-plus",
  "calendar-xmark",
  "chart-bar",
  "chess-bishop",
  "chess-king",
  "chess-knight",
  "chess-pawn",
  "chess-queen",
  "chess-rook",
  "circle",
  "circle-check",
  "circle-dot",
  "circle-down",
  "circle-left",
  "circle-pause",
  "circle-play",
  "circle-question",
  "circle-right",
  "circle-stop",
  "circle-up",
  "circle-user",
  "circle-xmark",
  "clipboard",
  "clock",
  "clone",
  "closed-captioning",
  "comment",
  "comment-dots",
  "comments",
  "compass",
  "copy",
  "copyright",
  "credit-card",
  "envelope",
  "envelope-open",
  "eye",
  "eye-slash",
  "face-angry",
  "face-dizzy",
  "face-flushed",
  "face-frown",
  "face-frown-open",
  "face-grimace",
  "face-grin",
  "face-grin-beam",
  "face-grin-beam-sweat",
  "face-grin-hearts",
  "face-grin-squint",
  "face-grin-squint-tears",
  "face-grin-stars",
  "face-grin-tears",
  "face-grin-tongue",
  "face-grin-tongue-squint",
  "face-grin-tongue-wink",
  "face-grin-wide",
  "face-grin-wink",
  "face-kiss",
  "face-kiss-beam",
  "face-kiss-wink-heart",
  "face-laugh",
  "face-laugh-beam",
  "face-laugh-squint",
  "face-laugh-wink",
  "face-meh",
  "face-meh-blank",
  "face-rolling-eyes",
  "face-sad-cry",
  "face-sad-tear",
  "face-smile",
  "face-smile-beam",
  "face-smile-wink",
  "face-surprise",
  "face-tired",
  "file",
  "file-audio",
  "file-code",
  "file-excel",
  "file-image",
  "file-lines",
  "file-pdf",
  "file-powerpoint",
  "file-video",
  "file-word",
  "file-zipper",
  "flag",
  "floppy-disk",
  "folder",
  "folder-closed",
  "folder-open",
  "font-awesome",
  "futbol",
  "gem",
  "hand",
  "hand-back-fist",
  "hand-lizard",
  "hand-peace",
  "hand-point-down",
  "hand-point-left",
  "hand-point-right",
  "hand-point-up",
  "hand-pointer",
  "hand-scissors",
  "hand-spock",
  "handshake",
  "hard-drive",
  "heart",
  "hospital",
  "hourglass",
  "id-badge",
  "id-card",
  "image",
  "images",
  "keyboard",
  "lemon",
  "life-ring",
  "lightbulb",
  "map",
  "message",
  "money-bill-1",
  "moon",
  "newspaper",
  "note-sticky",
  "object-group",
  "object-ungroup",
  "paper-plane",
  "paste",
  "pen-to-square",
  "rectangle-list",
  "rectangle-xmark",
  "registered",
  "share-from-square",
  "snowflake",
  "square",
  "square-caret-down",
  "square-caret-left",
  "square-caret-right",
  "square-caret-up",
  "square-check",
  "square-full",
  "square-minus",
  "square-plus",
  "star",
  "star-half",
  "star-half-stroke",
  "sun",
  "thumbs-down",
  "thumbs-up",
  "trash-can",
  "user",
  "window-maximize",
  "window-minimize",
  "window-restore",
];

// [
//   "0",
//   "1",
//   "2",
//   "3",
//   "4",
//   "42-group",
//   "5",
//   "500px",
//   "6",
//   "7",
//   "8",
//   "9",
//   "a",
//   "accessible-icon",
//   "accusoft",
//   "address-book",
//   "address-book",
//   "address-card",
//   "address-card",
//   "adn",
//   "adversal",
//   "affiliatetheme",
//   "airbnb",
//   "algolia",
//   "align-center",
//   "align-justify",
//   "align-left",
//   "align-right",
//   "alipay",
//   "amazon",
//   "amazon-pay",
//   "amilia",
//   "anchor",
//   "anchor-circle-check",
//   "anchor-circle-exclamation",
//   "anchor-circle-xmark",
//   "anchor-lock",
//   "android",
//   "angellist",
//   "angle-down",
//   "angle-left",
//   "angle-right",
//   "angle-up",
//   "angles-down",
//   "angles-left",
//   "angles-right",
//   "angles-up",
//   "angrycreative",
//   "angular",
//   "ankh",
//   "app-store",
//   "app-store-ios",
//   "apper",
//   "apple",
//   "apple-pay",
//   "apple-whole",
//   "archway",
//   "arrow-down",
//   "arrow-down-1-9",
//   "arrow-down-9-1",
//   "arrow-down-a-z",
//   "arrow-down-long",
//   "arrow-down-short-wide",
//   "arrow-down-up-across-line",
//   "arrow-down-up-lock",
//   "arrow-down-wide-short",
//   "arrow-down-z-a",
//   "arrow-left",
//   "arrow-left-long",
//   "arrow-pointer",
//   "arrow-right",
//   "arrow-right-arrow-left",
//   "arrow-right-from-bracket",
//   "arrow-right-long",
//   "arrow-right-to-bracket",
//   "arrow-right-to-city",
//   "arrow-rotate-left",
//   "arrow-rotate-right",
//   "arrow-trend-down",
//   "arrow-trend-up",
//   "arrow-turn-down",
//   "arrow-turn-up",
//   "arrow-up",
//   "arrow-up-1-9",
//   "arrow-up-9-1",
//   "arrow-up-a-z",
//   "arrow-up-from-bracket",
//   "arrow-up-from-ground-water",
//   "arrow-up-from-water-pump",
//   "arrow-up-long",
//   "arrow-up-right-dots",
//   "arrow-up-right-from-square",
//   "arrow-up-short-wide",
//   "arrow-up-wide-short",
//   "arrow-up-z-a",
//   "arrows-down-to-line",
//   "arrows-down-to-people",
//   "arrows-left-right",
//   "arrows-left-right-to-line",
//   "arrows-rotate",
//   "arrows-spin",
//   "arrows-split-up-and-left",
//   "arrows-to-circle",
//   "arrows-to-dot",
//   "arrows-to-eye",
//   "arrows-turn-right",
//   "arrows-turn-to-dots",
//   "arrows-up-down",
//   "arrows-up-down-left-right",
//   "arrows-up-to-line",
//   "artstation",
//   "asterisk",
//   "asymmetrik",
//   "at",
//   "atlassian",
//   "atom",
//   "audible",
//   "audio-description",
//   "austral-sign",
//   "autoprefixer",
//   "avianex",
//   "aviato",
//   "award",
//   "aws",
//   "b",
//   "baby",
//   "baby-carriage",
//   "backward",
//   "backward-fast",
//   "backward-step",
//   "bacon",
//   "bacteria",
//   "bacterium",
//   "bag-shopping",
//   "bahai",
//   "baht-sign",
//   "ban",
//   "ban-smoking",
//   "bandage",
//   "bandcamp",
//   "barcode",
//   "bars",
//   "bars-progress",
//   "bars-staggered",
//   "baseball",
//   "baseball-bat-ball",
//   "basket-shopping",
//   "basketball",
//   "bath",
//   "battery-empty",
//   "battery-full",
//   "battery-half",
//   "battery-quarter",
//   "battery-three-quarters",
//   "battle-net",
//   "bed",
//   "bed-pulse",
//   "beer-mug-empty",
//   "behance",
//   "behance-square",
//   "bell",
//   "bell",
//   "bell-concierge",
//   "bell-slash",
//   "bell-slash",
//   "bezier-curve",
//   "bicycle",
//   "bilibili",
//   "bimobject",
//   "binoculars",
//   "biohazard",
//   "bitbucket",
//   "bitcoin",
//   "bitcoin-sign",
//   "bity",
//   "black-tie",
//   "blackberry",
//   "blender",
//   "blender-phone",
//   "blog",
//   "blogger",
//   "blogger-b",
//   "bluetooth",
//   "bluetooth-b",
//   "bold",
//   "bolt",
//   "bolt-lightning",
//   "bomb",
//   "bone",
//   "bong",
//   "book",
//   "book-atlas",
//   "book-bible",
//   "book-bookmark",
//   "book-journal-whills",
//   "book-medical",
//   "book-open",
//   "book-open-reader",
//   "book-quran",
//   "book-skull",
//   "bookmark",
//   "bookmark",
//   "bootstrap",
//   "border-all",
//   "border-none",
//   "border-top-left",
//   "bore-hole",
//   "bots",
//   "bottle-droplet",
//   "bottle-water",
//   "bowl-food",
//   "bowl-rice",
//   "bowling-ball",
//   "box",
//   "box-archive",
//   "box-open",
//   "box-tissue",
//   "boxes-packing",
//   "boxes-stacked",
//   "braille",
//   "brain",
//   "brazilian-real-sign",
//   "bread-slice",
//   "bridge",
//   "bridge-circle-check",
//   "bridge-circle-exclamation",
//   "bridge-circle-xmark",
//   "bridge-lock",
//   "bridge-water",
//   "briefcase",
//   "briefcase-medical",
//   "broom",
//   "broom-ball",
//   "brush",
//   "btc",
//   "bucket",
//   "buffer",
//   "bug",
//   "bug-slash",
//   "bugs",
//   "building",
//   "building",
//   "building-circle-arrow-right",
//   "building-circle-check",
//   "building-circle-exclamation",
//   "building-circle-xmark",
//   "building-columns",
//   "building-flag",
//   "building-lock",
//   "building-ngo",
//   "building-shield",
//   "building-un",
//   "building-user",
//   "building-wheat",
//   "bullhorn",
//   "bullseye",
//   "burger",
//   "buromobelexperte",
//   "burst",
//   "bus",
//   "bus-simple",
//   "business-time",
//   "buy-n-large",
//   "buysellads",
//   "c",
//   "cake-candles",
//   "calculator",
//   "calendar",
//   "calendar",
//   "calendar-check",
//   "calendar-check",
//   "calendar-day",
//   "calendar-days",
//   "calendar-days",
//   "calendar-minus",
//   "calendar-minus",
//   "calendar-plus",
//   "calendar-plus",
//   "calendar-week",
//   "calendar-xmark",
//   "calendar-xmark",
//   "camera",
//   "camera-retro",
//   "camera-rotate",
//   "campground",
//   "canadian-maple-leaf",
//   "candy-cane",
//   "cannabis",
//   "capsules",
//   "car",
//   "car-battery",
//   "car-burst",
//   "car-on",
//   "car-rear",
//   "car-side",
//   "car-tunnel",
//   "caravan",
//   "caret-down",
//   "caret-left",
//   "caret-right",
//   "caret-up",
//   "carrot",
//   "cart-arrow-down",
//   "cart-flatbed",
//   "cart-flatbed-suitcase",
//   "cart-plus",
//   "cart-shopping",
//   "cash-register",
//   "cat",
//   "cc-amazon-pay",
//   "cc-amex",
//   "cc-apple-pay",
//   "cc-diners-club",
//   "cc-discover",
//   "cc-jcb",
//   "cc-mastercard",
//   "cc-paypal",
//   "cc-stripe",
//   "cc-visa",
//   "cedi-sign",
//   "cent-sign",
//   "centercode",
//   "centos",
//   "certificate",
//   "chair",
//   "chalkboard",
//   "chalkboard-user",
//   "champagne-glasses",
//   "charging-station",
//   "chart-area",
//   "chart-bar",
//   "chart-bar",
//   "chart-column",
//   "chart-gantt",
//   "chart-line",
//   "chart-pie",
//   "chart-simple",
//   "check",
//   "check-double",
//   "check-to-slot",
//   "cheese",
//   "chess",
//   "chess-bishop",
//   "chess-bishop",
//   "chess-board",
//   "chess-king",
//   "chess-king",
//   "chess-knight",
//   "chess-knight",
//   "chess-pawn",
//   "chess-pawn",
//   "chess-queen",
//   "chess-queen",
//   "chess-rook",
//   "chess-rook",
//   "chevron-down",
//   "chevron-left",
//   "chevron-right",
//   "chevron-up",
//   "child",
//   "child-dress",
//   "child-reaching",
//   "child-rifle",
//   "children",
//   "chrome",
//   "chromecast",
//   "church",
//   "circle",
//   "circle",
//   "circle-arrow-down",
//   "circle-arrow-left",
//   "circle-arrow-right",
//   "circle-arrow-up",
//   "circle-check",
//   "circle-check",
//   "circle-chevron-down",
//   "circle-chevron-left",
//   "circle-chevron-right",
//   "circle-chevron-up",
//   "circle-dollar-to-slot",
//   "circle-dot",
//   "circle-dot",
//   "circle-down",
//   "circle-down",
//   "circle-exclamation",
//   "circle-h",
//   "circle-half-stroke",
//   "circle-info",
//   "circle-left",
//   "circle-left",
//   "circle-minus",
//   "circle-nodes",
//   "circle-notch",
//   "circle-pause",
//   "circle-pause",
//   "circle-play",
//   "circle-play",
//   "circle-plus",
//   "circle-question",
//   "circle-question",
//   "circle-radiation",
//   "circle-right",
//   "circle-right",
//   "circle-stop",
//   "circle-stop",
//   "circle-up",
//   "circle-up",
//   "circle-user",
//   "circle-user",
//   "circle-xmark",
//   "circle-xmark",
//   "city",
//   "clapperboard",
//   "clipboard",
//   "clipboard",
//   "clipboard-check",
//   "clipboard-list",
//   "clipboard-question",
//   "clipboard-user",
//   "clock",
//   "clock",
//   "clock-rotate-left",
//   "clone",
//   "clone",
//   "closed-captioning",
//   "closed-captioning",
//   "cloud",
//   "cloud-arrow-down",
//   "cloud-arrow-up",
//   "cloud-bolt",
//   "cloud-meatball",
//   "cloud-moon",
//   "cloud-moon-rain",
//   "cloud-rain",
//   "cloud-showers-heavy",
//   "cloud-showers-water",
//   "cloud-sun",
//   "cloud-sun-rain",
//   "cloudflare",
//   "cloudscale",
//   "cloudsmith",
//   "cloudversify",
//   "clover",
//   "cmplid",
//   "code",
//   "code-branch",
//   "code-commit",
//   "code-compare",
//   "code-fork",
//   "code-merge",
//   "code-pull-request",
//   "codepen",
//   "codiepie",
//   "coins",
//   "colon-sign",
//   "comment",
//   "comment",
//   "comment-dollar",
//   "comment-dots",
//   "comment-dots",
//   "comment-medical",
//   "comment-slash",
//   "comment-sms",
//   "comments",
//   "comments",
//   "comments-dollar",
//   "compact-disc",
//   "compass",
//   "compass",
//   "compass-drafting",
//   "compress",
//   "computer",
//   "computer-mouse",
//   "confluence",
//   "connectdevelop",
//   "contao",
//   "cookie",
//   "cookie-bite",
//   "copy",
//   "copy",
//   "copyright",
//   "copyright",
//   "cotton-bureau",
//   "couch",
//   "cow",
//   "cpanel",
//   "creative-commons",
//   "creative-commons-by",
//   "creative-commons-nc",
//   "creative-commons-nc-eu",
//   "creative-commons-nc-jp",
//   "creative-commons-nd",
//   "creative-commons-pd",
//   "creative-commons-pd-alt",
//   "creative-commons-remix",
//   "creative-commons-sa",
//   "creative-commons-sampling",
//   "creative-commons-sampling-plus",
//   "creative-commons-share",
//   "creative-commons-zero",
//   "credit-card",
//   "credit-card",
//   "critical-role",
//   "crop",
//   "crop-simple",
//   "cross",
//   "crosshairs",
//   "crow",
//   "crown",
//   "crutch",
//   "cruzeiro-sign",
//   "css3",
//   "css3-alt",
//   "cube",
//   "cubes",
//   "cubes-stacked",
//   "cuttlefish",
//   "d",
//   "d-and-d",
//   "d-and-d-beyond",
//   "dailymotion",
//   "dashcube",
//   "database",
//   "deezer",
//   "delete-left",
//   "delicious",
//   "democrat",
//   "deploydog",
//   "deskpro",
//   "desktop",
//   "dev",
//   "deviantart",
//   "dharmachakra",
//   "dhl",
//   "diagram-next",
//   "diagram-predecessor",
//   "diagram-project",
//   "diagram-successor",
//   "diamond",
//   "diamond-turn-right",
// ];
