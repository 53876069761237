import { API_BASE } from "../../../api_links";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadSectionsOrder = (order) => {
  if (!order) return;
  const dataUploadURL = `${API_BASE}:LiqXQBP3/sections_order`;
  const updateObj = { order: JSON.stringify(order.order) };

  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Sections Order update", result);
    if (result?.message || result?.code) {
      console.log(result.message, "Error sections order upload");
      buildNotification({
        type: "error",
        message: result?.message ?? "Error updating sidebar order",
      });
    } else {
      console.log("Data uploaded sections order successful");
    }
  });
};
