import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  parseRGBA,
  buildTextClass,
  buildContactIconsClass,
  buildCTAIconColorClass,
  buildZeroBorderRadiusClass,
} from "../../helpers/stylesheetGeneration";

import { checkSchedule } from "../../helpers/utilityFunctions";

import { checkYoutubeURL, checkVimeoURL } from "../../helpers/utilityFunctions";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { DataLoadContext } from "../../contexts/dataLoad.context";
import { VideoGalleryContext } from "../../contexts/sectionsContexts/videoGallery.context";
import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import styles from "./videoGallery.module.scss";

import VideoGallerySkeleton from "../../skeletons/videoGallery/videoGallery.skeleton";

import PasswordProtectedContent from "../../common/passwordProtected/passwordProtectedContent/passwordProtectedContent";
import LockedOverlay from "../../common/lockedOverlay/lockedOverlay";
import YouTube from "./videoComponents/youTube";
import Vimeo from "./videoComponents/vimeo";

import defaultGalleryIcon from "../../../assets/umicardViewer/videoGalleryViewer/defaultGalleryIcon.svg";
import defaultGalleryImage from "../../../assets/umicardViewer/videoGalleryViewer/defaultGalleryItem.svg";
import defaultCTAIcon from "../../../assets/umicardViewer/videoGalleryViewer/ctaDefaultIcon.svg";

const VideoGalleryViewer = () => {
  const [dataLoad, setDataLoad] = useContext(DataLoadContext);
  const [videoGallery] = useContext(VideoGalleryContext);
  const [fontsAndColors] = useContext(FontsAndColorsContext);

  const [topSlider, setTopSlider] = useState(null);
  const [bottomSlider, setBottomSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [galleryDataState, setGalleryDataState] = useState([]);

  useEffect(() => {
    if (videoGallery.loaded) {
      setDataLoad({ ...dataLoad, videoGallery: true });
    }
  }, [videoGallery]);

  const topSliderSettings = {
    asNavFor: bottomSlider,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };
  const bottomSliderSettings = {
    asNavFor: topSlider,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  const {
    video_gallery_section_title,
    video_gallery_section_icon,
    video_gallery_section_background,
    video_link_gallery_section_background,
    video_link_font_and_icon_color,
    gallery_data,
  } = videoGallery;

  const { sections_border_radius } = fontsAndColors;

  // parse gallery data
  let gallery_data_parsed = gallery_data.filter((gd) => {
    if (gd.enable) return gd;
  });

  useEffect(() => {
    // parse gallery data
    let gallery_data_parsed = gallery_data.filter((gd) => {
      if (gd.enable) {
        // Check dates first
        const { schedule } = gd;
        if (checkSchedule(schedule)) return gd;
      }
    });

    gallery_data_parsed = gallery_data_parsed.map((gd) => {
      if (gd?.password) {
        gd["show"] = false;
      } else {
        gd["show"] = true;
      }
      return gd;
    });

    if (gallery_data_parsed.length < 4)
      // make copies
      gallery_data_parsed = [
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
      ];

    setGalleryDataState(gallery_data_parsed);
  }, [videoGallery]);

  const { enable: enable_gallery_section_title, title } =
    video_gallery_section_title;

  const {
    enable: enable_gallery_section_icon,
    type: video_gallery_icon_type,
    fa_icon: video_gallery_icon_name,
    color: video_gallery_icon_color,
    preview_file: gallery_icon_existing_url,
    upload: gallery_icon_preview_url,
  } = video_gallery_section_icon;

  const videoGalleryMainIconColor = buildCTAIconColorClass(
    "video_gallery_main_cta_icon",
    video_gallery_icon_color
  );

  const {
    enable: enable_gallery_section_background,
    color: video_gallery_section_background_color,
  } = video_gallery_section_background;

  const {
    enable: enable_video_link_gallery_section_background,
    color: video_link_gallery_background_color,
  } = video_link_gallery_section_background;

  const {
    enable: enable_video_link_font_color,
    color: video_link_color,
    font: video_link_font,
    font_bold: video_link_font_bold,
    font_italic: video_link_font_italic,
    font_underline: video_link_font_underline,
    font_align_left: video_link_font_align_left,
    font_align_right: video_link_font_align_right,
    font_align_center: video_link_font_align_center,
  } = video_link_font_and_icon_color;

  // Custom Classes
  const customStyles = StyleSheet.create({
    videoGalleryBackground: {
      backgroundColor:
        enable_gallery_section_background &&
        parseRGBA(video_gallery_section_background_color.rgb),
    },
    videoLinkBackground: {
      backgroundColor:
        enable_video_link_gallery_section_background &&
        parseRGBA(video_link_gallery_background_color.rgb),
    },
  });

  const customCTAIconsBG = buildContactIconsClass(
    video_link_font_and_icon_color
  );

  const video_cta_link_custom_class = buildTextClass(
    enable_video_link_font_color,
    video_link_font,
    video_link_font_bold,
    video_link_font_italic,
    video_link_font_underline,
    video_link_font_align_left,
    video_link_font_align_right,
    video_link_font_align_center,
    video_link_color
  );

  return (
    <>
      {!dataLoad.videoGallery ? (
        <VideoGallerySkeleton />
      ) : (
        <div
          id="video-gallery-viewer"
          className={`${styles.video_gallery_viewer} gallery_preview ${css(
            customStyles.videoGalleryBackground
          )}`}
          style={{ borderRadius: !sections_border_radius && 0 }}
        >
          <div className={styles.video_gallery_header}>
            <p
              className={styles.video_gallery_heading}
              style={{ display: enable_gallery_section_title ? "" : "none" }}
            >
              {title}
            </p>
            <div
              className={`${styles.video_gallery_icon} ${videoGalleryMainIconColor}`}
              style={{ display: enable_gallery_section_icon ? "" : "none" }}
            >
              {video_gallery_icon_type === "img" && (
                <img
                  src={
                    gallery_icon_preview_url
                      ? gallery_icon_preview_url
                      : gallery_icon_existing_url
                      ? gallery_icon_existing_url
                      : defaultGalleryIcon
                  }
                  alt="Gallery Icon"
                />
              )}
              {video_gallery_icon_type === "fa" && (
                <FontAwesomeIcon icon={video_gallery_icon_name} />
              )}
            </div>
          </div>
          <p className={styles.video_gallery_tagline}>
            {galleryDataState[currentSlide]?.title ?? "Video Title"}
          </p>
          <div className={styles.sliders_container} id="video_top_slider">
            {galleryDataState.length > 0 && (
              <Slider
                {...topSliderSettings}
                ref={(slider) => setTopSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { enable, title, video_url, password, show } =
                    gallery_item;
                  if (!enable) return;
                  let parsed_video_url = video_url;
                  if (video_url.substring(0, 8).toLowerCase() !== "https://")
                    parsed_video_url = "https://" + video_url;
                  return (
                    <div
                      key={index}
                      className={`${styles.gallery_slide} ${
                        !sections_border_radius && buildZeroBorderRadiusClass()
                      }`}
                    >
                      {show && (
                        <React.Fragment>
                          {checkYoutubeURL(parsed_video_url) ? (
                            <YouTube
                              yt_id={checkYoutubeURL(parsed_video_url)}
                              title={title}
                            />
                          ) : checkVimeoURL(parsed_video_url) ? (
                            <Vimeo
                              vimeo_id={checkVimeoURL(parsed_video_url)}
                              title={title}
                            />
                          ) : (
                            !video_url && (
                              <img src={defaultGalleryImage} alt={title} />
                            )
                          )}
                        </React.Fragment>
                      )}
                      {!show && password && (
                        <PasswordProtectedContent
                          overlayTitle={"Click to Unlock Video"}
                          popupTitle={"Unlock Locked Video"}
                          infoType={"video_gallery"}
                          password={password}
                          updateCallback={() => {
                            const copyGalleryData = [...galleryDataState];
                            galleryDataState[index]["show"] = true;
                            setGalleryDataState(copyGalleryData);
                          }}
                        >
                          {show && (
                            <React.Fragment>
                              {checkYoutubeURL(parsed_video_url) ? (
                                <YouTube
                                  yt_id={checkYoutubeURL(parsed_video_url)}
                                  title={title}
                                />
                              ) : checkVimeoURL(parsed_video_url) ? (
                                <Vimeo
                                  vimeo_id={checkVimeoURL(parsed_video_url)}
                                  title={title}
                                />
                              ) : (
                                !video_url && (
                                  <img src={defaultGalleryImage} alt={title} />
                                )
                              )}
                            </React.Fragment>
                          )}
                        </PasswordProtectedContent>
                      )}
                      {!password && (
                        <React.Fragment>
                          {checkYoutubeURL(parsed_video_url) ? (
                            <YouTube
                              yt_id={checkYoutubeURL(parsed_video_url)}
                              title={title}
                            />
                          ) : checkVimeoURL(parsed_video_url) ? (
                            <Vimeo
                              vimeo_id={checkVimeoURL(parsed_video_url)}
                              title={title}
                            />
                          ) : (
                            !video_url && (
                              <img src={defaultGalleryImage} alt={title} />
                            )
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  );
                })}
              </Slider>
            )}
            <div
              className={`${styles.gallery_cta} ${css(
                customStyles.videoLinkBackground
              )}`}
              style={{ borderRadius: !sections_border_radius && 0 }}
            >
              <div
                className={`${styles.cta_icon} ${
                  galleryDataState[currentSlide]?.CTAIcon?.color &&
                  buildCTAIconColorClass(
                    `video_gallery_item_${currentSlide}`,
                    galleryDataState[currentSlide].CTAIcon.color
                  )
                }`}
              >
                {galleryDataState[currentSlide] &&
                galleryDataState[currentSlide].CTAIcon.type === "img" ? (
                  <img
                    src={
                      galleryDataState[currentSlide].CTAIcon.upload
                        ? galleryDataState[currentSlide].CTAIcon.upload
                        : galleryDataState[currentSlide].CTAIcon.preview_file
                        ? galleryDataState[currentSlide].CTAIcon.preview_file
                        : defaultCTAIcon
                    }
                    alt="CTA Icon"
                  />
                ) : (
                  galleryDataState[currentSlide] && (
                    <FontAwesomeIcon
                      icon={
                        galleryDataState[currentSlide].CTAIcon.fa_icon ?? "bell"
                      }
                    />
                  )
                )}
              </div>
              <div className={styles.cta_text_url}>
                {galleryDataState[currentSlide] && (
                  <a
                    href={"https://" + galleryDataState[currentSlide].cta_url}
                    target="_blank"
                    className={video_cta_link_custom_class}
                  >
                    {galleryDataState[currentSlide].cta_text}
                  </a>
                )}
              </div>
            </div>
            <span className={styles.sliders_divider}></span>
            {galleryDataState.length > 0 && (
              <Slider
                {...bottomSliderSettings}
                ref={(slider) => setBottomSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { enable, title, video_url, show, password } =
                    gallery_item;
                  if (!enable) return;
                  let parsed_video_url = video_url;
                  if (video_url.substring(0, 8).toLowerCase() !== "https://")
                    parsed_video_url = "https://" + video_url;
                  return (
                    <div key={index} className={styles.gallery_slide_bottom}>
                      <div
                        className={styles.thumbnail}
                        style={{ borderRadius: !sections_border_radius && 0 }}
                      >
                        {show && (
                          <React.Fragment>
                            {checkYoutubeURL(parsed_video_url) ? (
                              <YouTube
                                yt_id={checkYoutubeURL(parsed_video_url)}
                                title={title}
                                thumbnail={true}
                              />
                            ) : checkVimeoURL(parsed_video_url) ? (
                              <Vimeo
                                vimeo_id={checkVimeoURL(parsed_video_url)}
                                title={title}
                                thumbnail={true}
                              />
                            ) : (
                              !video_url && (
                                <img src={defaultGalleryImage} alt={title} />
                              )
                            )}
                          </React.Fragment>
                        )}
                        {!show && password && (
                          // <img src={defaultGalleryImage} alt={title} />
                          <LockedOverlay />
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoGalleryViewer;
