import React, { useContext } from "react";

import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import { SocialLinksContext } from "../../contexts/sectionsContexts/socialLinks.context";

import {
  update_enable_sections_and_titles_headlines,
  update_sections_and_titles_headlines_font,
  update_sections_and_titles_headlines_font_styles,
  update_sections_and_titles_headlines_color,
  update_sections_border_radius,
  update_enable_body_text,
  update_body_text_font,
  update_body_text_font_styles,
  update_body_text_color,
  update_enable_contact_icons_primary_color,
  update_contact_icons_primary_color,
  update_enable_contact_icons_secondary_color,
  update_contact_icons_secondary_color,
  update_enable_social_media_icons_color,
  update_social_media_icons_color,
  update_enable_social_media_icons_background_color,
  update_social_media_icons_background_color,
  update_enable_share_my_card_background_color,
  update_share_my_card_background_color,
  update_enable_seconds_icon_color,
  update_seconds_icon_color,
} from "./updateFunctions";

import {
  update_enable_social_icons_color,
  update_social_icons_color,
} from "../sections/socialLinks/updateFunctions";

import { uploadFontsAndColors } from "./uploadFunction";

import { uploadSocialLinks } from "../sections/socialLinks/uploadFunction";

import CollapsibleContainer from "../../common/collapsibleContainer/collapsibleContainer";
import ColorPicker from "../../common/colorPicker/colorPicker";
import FontPicker from "../../common/fontPicker/fontPicker";
import SectionTopBar from "../../common/sectionTopBar/sectionTopBar";
import Checkbox from "../../common/checkbox/checkbox";

const FontsAndColors = ({ styles }) => {
  const [fontsAndColors, setFontsAndColors] = useContext(FontsAndColorsContext);
  const [socialLinks, setSocialLinks] = useContext(SocialLinksContext);

  const {
    sections_and_titles_headlines,
    sections_border_radius,
    body_text,
    contact_icons_primary_color,
    contact_icons_secondary_color,
    social_media_icons_color,
    social_media_icons_background_color,
    share_my_card_background_color,
    seconds_icon_color,
  } = fontsAndColors;

  const { icons_color } = socialLinks;

  const {
    enable: enable_sections_and_titles_headlines,
    sections_and_titles_headlines_font,
    sections_and_titles_headlines_font_bold,
    sections_and_titles_headlines_font_italic,
    sections_and_titles_headlines_font_underline,
    sections_and_titles_headlines_font_align_left,
    sections_and_titles_headlines_font_align_right,
    sections_and_titles_headlines_font_align_center,
    sections_and_titles_headlines_color,
  } = sections_and_titles_headlines;

  const {
    enable: enable_body_text,
    body_text_font,
    body_text_font_bold,
    body_text_font_italic,
    body_text_font_underline,
    body_text_font_align_left,
    body_text_font_align_right,
    body_text_font_align_center,
    body_text_color,
  } = body_text;

  return (
    <div className="fonts_and_colors">
      <SectionTopBar
        title={"Fonts & Colors"}
        id={"fonts-and-colors-section-designer"}
        updateCallback={uploadFontsAndColors}
        updateData={fontsAndColors}
        extraUpdates={[
          { extraCallback: uploadSocialLinks, extraData: socialLinks },
        ]}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Sections Titles and Headlines"
          inputId="enable_sections_and_titles_headlines"
          enabled={enable_sections_and_titles_headlines}
          callback={update_enable_sections_and_titles_headlines}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <FontPicker
              inputFieldId={"sections_and_titles_headlines_font"}
              defaultInputValue={sections_and_titles_headlines_font}
              savedFontStyles={{
                bold: sections_and_titles_headlines_font_bold,
                italic: sections_and_titles_headlines_font_italic,
                underline: sections_and_titles_headlines_font_underline,
                left_align: sections_and_titles_headlines_font_align_left,
                right_align: sections_and_titles_headlines_font_align_right,
                center_align: sections_and_titles_headlines_font_align_center,
              }}
              callback_font_name={update_sections_and_titles_headlines_font}
              callback_font_styles={
                update_sections_and_titles_headlines_font_styles
              }
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="sections_and_titles_headlines_color"
              savedColor={sections_and_titles_headlines_color}
              callback={update_sections_and_titles_headlines_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Sections Border Radius"
          inputId="enable_sections_border_radius"
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <Checkbox
              inputFieldId={"enable_sections_border_radius"}
              label={"Toggle between rounded and sharp sections borders"}
              callback={update_sections_border_radius}
              defaultValue={sections_border_radius}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Body Text"
          inputId="enable_body_text_headlines"
          enabled={enable_body_text}
          callback={update_enable_body_text}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <FontPicker
              inputFieldId={"body_text_headlines_font"}
              defaultInputValue={body_text_font}
              savedFontStyles={{
                bold: body_text_font_bold,
                italic: body_text_font_italic,
                underline: body_text_font_underline,
                left_align: body_text_font_align_left,
                right_align: body_text_font_align_right,
                center_align: body_text_font_align_center,
              }}
              callback_font_name={update_body_text_font}
              callback_font_styles={update_body_text_font_styles}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="body_text_headlines_color"
              savedColor={body_text_color}
              callback={update_body_text_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Contact Icons Primary Color"
          subtitle="The four icons under your name (mobile, Text, Call, Email)"
          inputId="enable_contact_icons_primary_color"
          enabled={contact_icons_primary_color.enable}
          callback={update_enable_contact_icons_primary_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="contact_icons_primary_color"
              savedColor={contact_icons_primary_color.color}
              callback={update_contact_icons_primary_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Contact Icons Secondary Color"
          subtitle="The four icons symbol under your name (mobile, Text, Call, Email)"
          inputId="enable_contact_icons_secondary_color"
          enabled={contact_icons_secondary_color.enable}
          callback={update_enable_contact_icons_secondary_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="contact_icons_secondary_color"
              savedColor={contact_icons_secondary_color.color}
              callback={update_contact_icons_secondary_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Icons"
          subtitle="The section icons showing on top right corner of each section"
          inputId="enable_social_icons"
          enabled={icons_color.enable}
          callback={update_enable_social_icons_color}
          context={socialLinks}
          updateContext={setSocialLinks}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_icons_color"
              savedColor={icons_color.color}
              callback={update_social_icons_color}
              context={socialLinks}
              updateContext={setSocialLinks}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Media Icons’ Border Color"
          subtitle="Select the color of your social media icons"
          inputId="enable_social_media_icons_color"
          enabled={social_media_icons_color.enable}
          callback={update_enable_social_media_icons_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_media_icons_color"
              savedColor={social_media_icons_color.color}
              callback={update_social_media_icons_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Media Icons’ Background Color"
          subtitle="Select the background color of the save my card button."
          inputId="enable_social_media_icons_background_color"
          enabled={social_media_icons_background_color.enable}
          callback={update_enable_social_media_icons_background_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_media_icons_background_color"
              savedColor={social_media_icons_background_color.color}
              callback={update_social_media_icons_background_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Share My Card's Background Color"
          subtitle="Select the background color of the share my card button"
          inputId="enable_share_my_card_background_color"
          enabled={share_my_card_background_color.enable}
          callback={update_enable_share_my_card_background_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="share_my_card_background_color"
              savedColor={share_my_card_background_color.color}
              callback={update_share_my_card_background_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Share My Card's Text Color"
          subtitle="The icon showing on top right corner of each section."
          inputId="enable_seconds_icon_color"
          enabled={seconds_icon_color.enable}
          callback={update_enable_seconds_icon_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="seconds_icon_color"
              savedColor={seconds_icon_color.color}
              callback={update_seconds_icon_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
};

export default FontsAndColors;
