import { API_BASE } from "../../../api_links";
import {
  uploadImage,
  signImageS3,
  uploadImageS3,
  deleteImageS3,
} from "../../../uploadServices/imageUploader";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadBrandingDetails = async (brandingDetails) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/branding_details`;

  const {
    background,
    background_overlay,
    business_brand_name,
    business_brand_slogan,
    business_brand_logo,
    brand_logo_adjustments,
  } = brandingDetails;

  const {
    enable: enable_background_color,
    use_background_color,
    branding_background_color,
    use_background_image,
    background_image_preview_file,
    background_image_uploadable_file,
  } = background;

  const { enable: enable_background_overlay, branding_background_overlay } =
    background_overlay;

  const {
    enable: enable_brand_business_name,
    brand_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color,
  } = business_brand_name;

  const {
    enable: enable_business_brand_slogan,
    brand_slogan,
    brand_slogan_font,
    brand_slogan_font_bold,
    brand_slogan_font_italic,
    brand_slogan_font_underline,
    brand_slogan_font_align_left,
    brand_slogan_font_align_right,
    brand_slogan_font_align_center,
    brand_slogan_color,
  } = business_brand_slogan;

  const {
    enable: enable_business_brand_logo,
    business_brand_logo_uploadable_file,
    business_brand_logo_preview_file,
  } = business_brand_logo;

  // let background_image_obj;
  // if (background_image_uploadable_file) {
  //   background_image_obj = await uploadImage(background_image_uploadable_file)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       return data;
  //     });
  // }

  // let business_brand_logo_obj;
  // if (business_brand_logo_uploadable_file) {
  //   business_brand_logo_obj = await uploadImage(
  //     business_brand_logo_uploadable_file
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       return data;
  //     });
  // }

  const updateObj = {
    background_image: background_image_preview_file,
    business_brand_logo: business_brand_logo_preview_file,
    enable_background_color,
    use_background_color,
    branding_background_color,
    use_background_image,
    enable_background_overlay,
    branding_background_overlay,
    enable_brand_business_name,
    brand_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color,
    enable_business_brand_slogan,
    brand_slogan,
    brand_slogan_font,
    brand_slogan_font_bold,
    brand_slogan_font_italic,
    brand_slogan_font_underline,
    brand_slogan_font_align_left,
    brand_slogan_font_align_right,
    brand_slogan_font_align_center,
    brand_slogan_color,
    enable_business_brand_logo,
    brand_logo_adjustments,
  };

  // Background image
  if (background_image_uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        background_image_uploadable_file,
        "branding/background"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            background_image_uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            deleteImageS3(background_image_preview_file?.url);
            updateObj.background_image = { url };
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Branding Details", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading Branding Details",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Branding Background Image Uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // Brand Logo
  if (business_brand_logo_uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        business_brand_logo_uploadable_file,
        "branding/logo"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            business_brand_logo_uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            deleteImageS3(business_brand_logo_preview_file?.url);
            updateObj.business_brand_logo = { url };
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Branding Details", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading Branding Details",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Branding Logo Uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // if (business_brand_logo_obj)
  //   updateObj["business_brand_logo"] = business_brand_logo_obj;
  // else updateObj["business_brand_logo"] = business_brand_logo_preview_file;

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Branding Details", result);
    if (result?.message) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading branding settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Branding Settings uploaded",
      });
    }
  });
};
