import React, { useState, createContext } from "react";

export const YoutubeContext = createContext();

export const YoutubeProvider = ({ children }) => {
  const [youtube, setYoutube] = useState({
    channel_id: "",
    videos_list_type: "dynamic", // dynamic || custom -- Here dynamic means whatever order is returned from youtube api
    number_of_dynamic_videos: 4,
    dynamic_list_type: "recent", // recent || popular
    default_videos_list: [],
    custom_videos_list: [],
    customization: {
      enable: false,
      icon: {
        enable: true,
        type: "fa", // img || fa
        preview_file: "", // Existing profile pic
        uploadable_file: "", // file object if new pic selected
        upload: "",
        fa_icon: "bell",
        color: {
          hex: "#ffffff",
          rgb: {
            r: 255,
            g: 255,
            b: 255,
            a: 1,
          },
        },
      },
      background: {
        enable: true,
        color: {
          hex: "#222f4f",
          rgb: {
            r: 34,
            g: 47,
            b: 79,
            a: 1,
          },
        },
      },
      headline: "My Youtube Videos",
      font: {
        name: "verdana",
        bold: true,
        italic: false,
        underline: false,
        align_left: false,
        align_right: false,
        align_center: true,
      },
      featured_color: {
        hex: "#ffffff",
        rgb: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
    },
  });

  return (
    <YoutubeContext.Provider value={[youtube, setYoutube]}>
      {children}
    </YoutubeContext.Provider>
  );
};
