import React from "react";

const LandingPagesWrapper = ({ children }) => {
  return (
    <div className="page-wrapper">
      <header className="navbar-wrapper">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="page-padding">
            <div className="navbar-container w-container">
              <div className="navbar-logo-wrapper">
                <div className="navbar-menu-button w-nav-button">
                  <div className="navbar-menu-icon w-icon-nav-menu"></div>
                </div>
                <a href="/" className="navbar-logo w-nav-brand">
                  <img
                    src="images/logo.webp"
                    width="171"
                    alt=""
                    className="image"
                  />
                </a>
              </div>
              <nav role="navigation" className="navbar-menu w-nav-menu">
                <div
                  data-hover="false"
                  data-delay="300"
                  className="navbar-dropdown w-dropdown"
                >
                  <div className="navbar-link w-dropdown-toggle">
                    <div className="navbar-link-icon w-icon-dropdown-toggle"></div>
                    <div className="navbar-link-text">Features</div>
                  </div>
                  <nav className="navbar-dropdown-menu w-dropdown-list">
                    <a
                      href="/features/customization"
                      className="dropdown-link w-dropdown-link"
                    >
                      Customization
                    </a>
                    <a
                      href="/features/leadgen"
                      className="dropdown-link w-dropdown-link"
                    >
                      Lead Gen
                    </a>
                    <a
                      href="/features/imagegallery"
                      className="dropdown-link w-dropdown-link"
                    >
                      Image Gallery
                    </a>
                    <a
                      href="/features/videogallery"
                      className="dropdown-link w-dropdown-link"
                    >
                      Video Gallery
                    </a>
                    <a
                      href="/features/sociallinks"
                      className="dropdown-link w-dropdown-link"
                    >
                      Social Links
                    </a>
                    <a
                      href="/features/calendly"
                      className="dropdown-link w-dropdown-link"
                    >
                      Claendly
                    </a>
                    <a
                      href="/features/youtube"
                      className="dropdown-link w-dropdown-link"
                    >
                      YouTube
                    </a>
                    <a
                      href="/features/abutyou"
                      className="dropdown-link w-dropdown-link"
                    >
                      About You
                    </a>
                    <a
                      href="/features/fansupport"
                      className="dropdown-link w-dropdown-link"
                    >
                      Fan Support
                    </a>
                  </nav>
                </div>
                <div
                  data-hover="false"
                  data-delay="300"
                  className="navbar-dropdown w-dropdown"
                >
                  <div className="navbar-link w-dropdown-toggle">
                    <div className="navbar-link-icon w-icon-dropdown-toggle"></div>
                    <div className="navbar-link-text">Use Cases</div>
                  </div>
                  <nav className="navbar-dropdown-menu w-dropdown-list">
                    <a
                      href="/usecases/business"
                      className="dropdown-link w-dropdown-link"
                    >
                      Business
                    </a>
                    <a
                      href="/usecases/influencers"
                      className="dropdown-link w-dropdown-link"
                    >
                      Influencers
                    </a>
                    <a
                      href="/usecases/consultants"
                      className="dropdown-link w-dropdown-link"
                    >
                      Consultants
                    </a>
                    <a href="youtube" className="dropdown-link w-dropdown-link">
                      YouTubers
                    </a>
                    <a
                      href="/usecases/comingsoon"
                      className="dropdown-link w-dropdown-link"
                    >
                      Coming soon
                    </a>
                  </nav>
                </div>
                <a href="/umicard-pricing" className="navbar-link w-nav-link">
                  Pricing
                </a>
                <a href="#" className="navbar-link w-nav-link">
                  Learn
                </a>
                <a href="/makeamatter" className="navbar-link w-nav-link">
                  Make a Matter
                </a>
              </nav>
              <div className="navbar-buttons-wrapper">
                <div className="margin-right-1 hide-mobile-landscape">
                  <div
                    data-hover="false"
                    data-delay="300"
                    className="navbar-dropdown w-dropdown"
                  >
                    <div className="button-icon-2 button-circle w-dropdown-toggle">
                      <div className="icon-1x1-small w-embed">
                        <svg
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.412 9.512H3.932V9.336C3.932 8.84533 4.06 8.41867 4.316 8.056C4.58267 7.68267 4.892 7.32533 5.244 6.984C5.54267 6.68533 5.836 6.38667 6.124 6.088C6.412 5.78933 6.652 5.45867 6.844 5.096C7.036 4.73333 7.132 4.32267 7.132 3.864C7.132 3.34133 6.99333 2.856 6.716 2.408C6.43867 1.96 6.044 1.59733 5.532 1.32C5.03067 1.032 4.43333 0.888 3.74 0.888C3.12133 0.888 2.556 1.01067 2.044 1.256C1.54267 1.50133 1.12667 1.848 0.796 2.296C0.476 2.73333 0.268 3.24533 0.172 3.832L1.58 4.152C1.68667 3.58667 1.932 3.13333 2.316 2.792C2.71067 2.45067 3.18 2.28 3.724 2.28C4.31067 2.28 4.75867 2.44533 5.068 2.776C5.388 3.096 5.548 3.464 5.548 3.88C5.548 4.36 5.39333 4.776 5.084 5.128C4.78533 5.48 4.44933 5.84267 4.076 6.216C3.80933 6.472 3.548 6.74933 3.292 7.048C3.036 7.336 2.82267 7.66667 2.652 8.04C2.492 8.40267 2.412 8.824 2.412 9.304V9.512ZM2.428 13H3.98V11.08H2.428V13Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <nav className="navbar-dropdown-menu alt w-dropdown-list">
                      <a
                        href="https://support.umicard.com/getstarted"
                        className="dropdown-link w-dropdown-link"
                      >
                        Get Started
                      </a>
                      <a
                        href="https://support.umicard.com"
                        className="dropdown-link w-dropdown-link"
                      >
                        Help Topics
                      </a>
                      <a
                        href="https://support.umicard.com"
                        className="dropdown-link w-dropdown-link"
                      >
                        Get help
                      </a>
                      <a
                        href="/vaiolationreport"
                        className="dropdown-link w-dropdown-link"
                      >
                        Report a Violation
                      </a>
                    </nav>
                  </div>
                </div>
                <div className="margin-right-1">
                  <a
                    href="/login"
                    className="button-text is-navbar hide-mobile-portrait w-button"
                  >
                    Login
                  </a>
                </div>
                <a href="/signup" className="button is-navbar w-button">
                  Sign Up Free
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {children}
      <footer className="footer">
        <div className="footer-main">
          <div className="page-padding">
            <div className="container-large w-container">
              <div className="footer-logo-wrapper">
                <a href="/" className="w-inline-block">
                  <img
                    src="images/logo.webp"
                    loading="lazy"
                    width="171"
                    alt=""
                  />
                </a>
              </div>
              <div className="footer-links">
                <div className="footer-links-column">
                  <h5 className="footer-links-column-heading">Company</h5>
                  <a href="#" className="footer-link">
                    Our story
                  </a>
                  <a href="#" className="footer-link">
                    Global Impact
                  </a>
                  <a href="https://blog.umicard.com" className="footer-link">
                    Blog
                  </a>
                  <a href="/contact" className="footer-link">
                    Contact
                  </a>
                </div>
                <div className="footer-links-column">
                  <h5 className="footer-links-column-heading">Support</h5>
                  <a href="/getstarted" className="footer-link">
                    Get Started
                  </a>
                  <a href="https://support.umicard.com" className="footer-link">
                    Help Topics
                  </a>
                  <a href="/faq" className="footer-link">
                    FAQs
                  </a>
                  <a href="https://support.umicard.com" className="footer-link">
                    Get help
                  </a>
                  <a href="/vaiolationreport" className="footer-link">
                    Report a Violation
                  </a>
                </div>
                <div className="footer-links-column">
                  <h5 className="footer-links-column-heading">Integrations</h5>
                  <a href="#" className="footer-link">
                    List of Integrations
                  </a>
                  <a href="#" className="footer-link">
                    Suggest an  Integration
                  </a>
                </div>
                <div className="footer-links-column">
                  <h5 className="footer-links-column-heading">Legal</h5>
                  <a href="/Privacy-Policy" className="footer-link">
                    Privacy Policy
                  </a>
                  <a href="/Terms-and-Conditions" className="footer-link">
                    Terms &amp; Conditions
                  </a>
                  <a href="/Cookie-Policy" className="footer-link">
                    Cookie Policy
                  </a>
                  <a href="/legalcontact" className="footer-link">
                    Contact Legal Team
                  </a>
                </div>
                <div className="footer-links-column">
                  <h5 className="footer-links-column-heading">
                    Let&#x27;s Connect on
                  </h5>
                  <div className="social-links">
                    <a
                      href="https://LinkedIn.com/company/umicard"
                      target="_blank"
                      className="social-link w-inline-block"
                    >
                      <img src="images/Linkedin.svg" loading="lazy" alt="" />
                    </a>
                    <a
                      href="https://twitter.com/the_umicard"
                      target="_blank"
                      className="social-link w-inline-block"
                    >
                      <img src="images/Twitter.svg" loading="lazy" alt="" />
                    </a>
                    <a
                      href="https://facebook.com/the_umicard"
                      target="_blank"
                      className="social-link w-inline-block"
                    >
                      <img src="images/Facebook.svg" loading="lazy" alt="" />
                    </a>
                    <a
                      href="https://instagram.com/umicard"
                      target="_blank"
                      className="social-link w-inline-block"
                    >
                      <img src="images/Instagram.svg" loading="lazy" alt="" />
                    </a>
                    <a
                      href="/umicard"
                      target="_blank"
                      className="social-link w-inline-block"
                    >
                      <img src="images/UmiCard.svg" loading="lazy" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyrights">
          <div className="page-padding">
            <div className="container-large w-container">
              <div className="text-align-center">
                Copyright © 2021 UmiCard™ Pty Ltd. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPagesWrapper;
