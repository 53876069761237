import { API_BASE } from "../../../../api_links";
import { uploadStaticData } from "../../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../../notification/buildNotification";
import {
  signImageS3,
  uploadImageS3,
  deleteImageS3,
} from "../../../../uploadServices/imageUploader";

export const uploadYoutubeCreator = async (youtube) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/youtube_creator`;

  const {
    channel_id,
    videos_list_type,
    number_of_dynamic_videos,
    dynamic_list_type,
    custom_videos_list,
    customization,
  } = youtube;

  const updateObj = {
    channel_id,
    videos_list_type,
    number_of_dynamic_videos,
    dynamic_list_type,
    custom_videos_list: JSON.stringify(custom_videos_list),
    customization,
  };

  const { icon } = customization;

  icon.upload = "";

  console.log("Update data", updateObj);

  // Uploading icon
  console.log("Upload icon", icon.uploadable_file);
  if (icon.uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        icon.uploadable_file,
        "youtube_creator/Icons"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            icon.uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            deleteImageS3(icon?.preview_file);
            updateObj.customization.icon.preview_file = url;
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Youtube Creator", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading Youtube Creator icon",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Youtube Creator icon uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Youtube Creator", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading Youtube Creator settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Youtube Creator Settings uploaded",
      });
    }
  });
};
