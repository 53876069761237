import React from "react";

import AuthFlowBasicContainer from "../authFlowBasicContainer/authFlowBasicContainer";
import InputField from "../../common/inputField/inputField";
import RadioInputField from "../../common/radioInputField/radioInputField";

import { markIntroAsComplete } from "./intro.service";

import styles from "../formStyling.module.scss";

import cover from "../../../assets/authFlow/cover_signup.png";
import business_svg from "../../../assets/authFlow/intro/business.svg";
import personal_branding_svg from "../../../assets/authFlow/intro/personal_branding.svg";
import content_creator_svg from "../../../assets/authFlow/intro/content_creator.svg";
import creative_art_svg from "../../../assets/authFlow/intro/creative_art.svg";
import e_commerce_svg from "../../../assets/authFlow/intro/e_commerce.svg";
import education_svg from "../../../assets/authFlow/intro/education.svg";
import entertainment_svg from "../../../assets/authFlow/intro/entertainment.svg";
import fashion_svg from "../../../assets/authFlow/intro/fashion.svg";
import food_svg from "../../../assets/authFlow/intro/food.svg";
import health_svg from "../../../assets/authFlow/intro/health.svg";
import non_profit_svg from "../../../assets/authFlow/intro/non_profit.svg";
import politics_svg from "../../../assets/authFlow/intro/politics.svg";
import services_svg from "../../../assets/authFlow/intro/services.svg";
import technology_svg from "../../../assets/authFlow/intro/technology.svg";
import tourism_svg from "../../../assets/authFlow/intro/tourism.svg";

const Intro = () => {
  markIntroAsComplete(); // Do it whether user fills info or skips it

  const submitIntroDetails = (e) => {
    e.preventDefault();

    // Submit data and go to next page
    window.location.href = "/pricing";
  };

  return (
    <AuthFlowBasicContainer
      title="Would you please answer following 3 questions?"
      help="So we can create the best experience for you"
      cover={cover}
      wide={true}
    >
      <form className={styles.user_form} onSubmit={submitIntroDetails}>
        <InputField
          label={"What's your name?"}
          inputFieldId={"first_name"}
          placeholder={"First Name"}
          extraClass={"fifty"}
        />
        <InputField
          placeholder={"Last Name"}
          inputFieldId={"last_name"}
          extraClass={"fifty"}
        />
        <InputField
          placeholder="I want to generate more leads"
          label="What can we help you to achieve?"
          inputFieldId={"reason_to_join"}
        />
        <div className={styles.new_section}>
          <p className={styles.small_heading}>
            What category describes your UmiCard the best?
          </p>
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_business"
            inputStyle={"left_icon_w_background"}
            icon={business_svg}
            text={"Business"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_personal_branding"
            inputStyle={"left_icon_w_background"}
            icon={personal_branding_svg}
            text={"Personal Branding"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_content_creator"
            inputStyle={"left_icon_w_background"}
            icon={content_creator_svg}
            text={"Content Creator"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_creative_art"
            inputStyle={"left_icon_w_background"}
            icon={creative_art_svg}
            text={"Creative Art"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_e_commerce"
            inputStyle={"left_icon_w_background"}
            icon={e_commerce_svg}
            text={"E-Commerce"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_education"
            inputStyle={"left_icon_w_background"}
            icon={education_svg}
            text={"Education"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_entertainment"
            inputStyle={"left_icon_w_background"}
            icon={entertainment_svg}
            text={"Entertainment"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_fashion"
            inputStyle={"left_icon_w_background"}
            icon={fashion_svg}
            text={"Fashion & Beauty"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_food"
            inputStyle={"left_icon_w_background"}
            icon={food_svg}
            text={"Food & Beverage"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_health"
            inputStyle={"left_icon_w_background"}
            icon={health_svg}
            text={"Health & Well-Being"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_non_profit"
            inputStyle={"left_icon_w_background"}
            icon={non_profit_svg}
            text={"Non-Profit"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_politics"
            inputStyle={"left_icon_w_background"}
            icon={politics_svg}
            text={"Government & Politics"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_services"
            inputStyle={"left_icon_w_background"}
            icon={services_svg}
            text={"Services"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_technology"
            inputStyle={"left_icon_w_background"}
            icon={technology_svg}
            text={"IT & Technology"}
          />
          <RadioInputField
            forGroup={"umicard_category"}
            inputFieldId="umicard_category_tourism"
            inputStyle={"left_icon_w_background"}
            icon={tourism_svg}
            text={"Tourism & Travel Industry"}
          />
        </div>
        <div className={styles.new_section}>
          <p className={styles.small_heading}>
            Select your buisness category (optional):
          </p>
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_agency"
            inputStyle={"pill_radio"}
            text={"Agency"}
          />
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_consulting"
            inputStyle={"pill_radio"}
            text={"Consulting"}
          />
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_financial_services"
            inputStyle={"pill_radio"}
            text={"Financial Services"}
          />
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_shop_retail"
            inputStyle={"pill_radio"}
            text={"Shop & Retail"}
          />
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_real_estate"
            inputStyle={"pill_radio"}
            text={"Real Estate"}
          />
          <RadioInputField
            forGroup={"umicard_category_optional"}
            inputFieldId="umicard_category_optional_amazon_seller"
            inputStyle={"pill_radio"}
            text={"Amazon Seller"}
          />
        </div>
        <button type="submit">Continue</button>
      </form>
    </AuthFlowBasicContainer>
  );
};

export default Intro;
