import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
// import { buildNotification } from "../../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const {
    enable_video_gallery_section_title,
    video_gallery_section_title,
    enable_video_gallery_section_icon,
    video_gallery_icon_type,
    video_gallery_icon_name,
    video_gallery_icon_color,
    video_gallery_section_icon_url,
    enable_video_gallery_section_background,
    video_gallery_section_background_color,
    enable_video_link_gallery_section_background,
    video_link_gallery_section_background_color,
    enable_video_link_font_and_icon_color,
    video_link_color,
    video_link_font,
    video_link_font_bold,
    video_link_font_italic,
    video_link_font_underline,
    video_link_font_align_left,
    video_link_font_align_right,
    video_link_font_align_center,
    gallery_data,
  } = data;

  return {
    loaded: true,
    video_gallery_section_title: {
      enable: enable_video_gallery_section_title,
      title: video_gallery_section_title,
    },
    video_gallery_section_icon: {
      enable: enable_video_gallery_section_icon,
      type: video_gallery_icon_type,
      fa_icon: video_gallery_icon_name,
      color: video_gallery_icon_color,
      preview_file: video_gallery_section_icon_url, // Existing pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
    },
    video_gallery_section_background: {
      enable: enable_video_gallery_section_background,
      color: video_gallery_section_background_color,
    },
    video_link_gallery_section_background: {
      enable: enable_video_link_gallery_section_background,
      color: video_link_gallery_section_background_color,
    },
    video_link_font_and_icon_color: {
      enable: enable_video_link_font_and_icon_color,
      color: video_link_color,
      font: video_link_font,
      font_bold: video_link_font_bold,
      font_italic: video_link_font_italic,
      font_underline: video_link_font_underline,
      font_align_left: video_link_font_align_left,
      font_align_right: video_link_font_align_right,
      font_align_center: video_link_font_align_center,
    },
    gallery_data,
  };
};

export const getVideoGalleryFromDB = (
  videoGallery,
  setVideoGallery,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.videoGallery);
    if (!parsedData) {
      setVideoGallery({ ...videoGallery, loaded: true });
      return;
    }
    setVideoGallery(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/video_gallery`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setVideoGallery(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Video Gallery Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/video_gallery/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setVideoGallery(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
