import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
// import { buildNotification } from "../../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const {
    enable_image_gallery_section_title,
    image_gallery_section_title,
    enable_image_gallery_section_icon,
    image_gallery_icon_type,
    image_gallery_icon_name,
    image_gallery_icon_color,
    image_gallery_section_icon_url,
    enable_image_gallery_section_background,
    image_gallery_section_background_color,
    enable_image_link_gallery_section_background,
    image_link_gallery_section_background_color,
    enable_image_link_font_and_icon_color,
    image_link_color,
    image_link_font,
    image_link_font_bold,
    image_link_font_italic,
    image_link_font_underline,
    image_link_font_align_left,
    image_link_font_align_right,
    image_link_font_align_center,
    gallery_data,
  } = data;

  return {
    loaded: true,
    image_gallery_section_title: {
      enable: enable_image_gallery_section_title,
      title: image_gallery_section_title,
    },
    image_gallery_section_icon: {
      enable: enable_image_gallery_section_icon,
      type: image_gallery_icon_type,
      fa_icon: image_gallery_icon_name,
      color: image_gallery_icon_color,
      preview_file: image_gallery_section_icon_url, // Existing pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
    },
    image_gallery_section_background: {
      enable: enable_image_gallery_section_background,
      color: image_gallery_section_background_color,
    },
    image_link_gallery_section_background: {
      enable: enable_image_link_gallery_section_background,
      color: image_link_gallery_section_background_color,
    },
    image_link_font_and_icon_color: {
      enable: enable_image_link_font_and_icon_color,
      color: image_link_color,
      font: image_link_font,
      font_bold: image_link_font_bold,
      font_italic: image_link_font_italic,
      font_underline: image_link_font_underline,
      font_align_left: image_link_font_align_left,
      font_align_right: image_link_font_align_right,
      font_align_center: image_link_font_align_center,
    },
    gallery_data,
  };
};

export const getImageGalleryFromDB = (
  imageGallery,
  setImageGallery,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.imageGallery);
    if (!parsedData) {
      setImageGallery({ ...imageGallery, loaded: true });
      return;
    }
    setImageGallery(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/image_gallery`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setImageGallery(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Image Gallery Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/image_gallery/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setImageGallery(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
