import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  return { order: data.order };
};

export const getSectionsOrderFromDB = (
  setSidebarOrder,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.sectionsOrder);
    if (!parsedData) return;
    setSidebarOrder(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/sections_order`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setSidebarOrder(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Sidebar Order Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/sections_order/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setSidebarOrder(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
