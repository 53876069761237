import React from "react";

import styles from "./tocFooter.module.scss";

const TOCFooter = () => {
  return (
    <div className={styles.toc_footer}>
      <div className={styles.left}>
        <p className={styles.copy}>&copy; UmiCard 2022</p>
      </div>
      <div className={styles.right}>
        <a
          className={styles.link}
          href="/Terms-and-Conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        <a
          className={styles.link}
          href="/Privacy-Policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className={styles.link}
          href="/Cookie-Policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
      </div>
    </div>
  );
};

export default TOCFooter;
