import React from "react";

import Checkbox from "../../../../common/checkbox/checkbox";

import styles from "./imageLinkStyles.module.scss";

const ImageLinkStyles = () => {
  return (
    <div className={styles.image_link_style_selectors_container}>
      <Checkbox
        inputFieldId={"image_link_icon_style_1"}
        checkboxStyle={"image_link_style_checkbox"}
      />
      <Checkbox
        inputFieldId={"image_link_icon_style_2"}
        checkboxStyle={"image_link_style_checkbox"}
      />
      <Checkbox
        inputFieldId={"image_link_icon_style_3"}
        checkboxStyle={"image_link_style_checkbox"}
      />
      <Checkbox
        inputFieldId={"image_link_icon_style_4"}
        checkboxStyle={"image_link_style_checkbox"}
      />
    </div>
  );
};

export default ImageLinkStyles;
