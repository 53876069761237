import React, { useEffect, createRef } from "react";

const YouTubeSubscribe = ({ theme, layout, count, channelName, channelid }) => {
  const youtubeSubscribeNode = createRef();

  useEffect(() => {
    const youtubescript = document.createElement("script");
    youtubescript.src = "https://apis.google.com/js/platform.js";
    window.document.body.appendChild(youtubescript);
  }, []);

  return (
    <section className="youtubeSubscribe">
      <div
        ref={youtubeSubscribeNode}
        className="g-ytsubscribe"
        data-theme={theme}
        data-layout={layout}
        data-count={count}
        data-channel={channelName}
        data-channelid={channelid}
      />
    </section>
  );
};

export default YouTubeSubscribe;
