import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
// import { buildNotification } from "../../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) {
    return;
  }

  const { style, icons_color, social_links, order } = data;

  const returnObject = {
    loaded: true,
    style,
    icons_color,
    social_links,
  };
  if (order.length > 0) returnObject["order"] = order;

  return returnObject;
};

export const getSocialLinksFromDB = (
  socialLinks,
  setSocialLinks,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.socialLinks);
    if (!parsedData) {
      setSocialLinks({ ...socialLinks, loaded: true });
      return;
    }
    setSocialLinks(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/social_links`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setSocialLinks(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Social Links Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/social_links/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setSocialLinks(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
