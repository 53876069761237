import React from "react";

import styles from "./addMoreEdirableItemButton.module.scss";

const AddMoreEdirableItemButton = ({
  title,
  callback,
  context,
  updateContext,
}) => {
  return (
    <button
      className={styles.addMoreButton}
      onClick={() => {
        callback && callback(context, updateContext);
      }}
    >
      + {title}
    </button>
  );
};

export default AddMoreEdirableItemButton;
