import { API_BASE } from "../../../api_links";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadLayout = (layout) => {
  if (!layout) return;
  const dataUploadURL = `${API_BASE}:LiqXQBP3/card_design`;
  const updateObj = { layout };

  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Layout update", result);
    if (result?.message) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading card design settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Card Design Settings uploaded",
      });
    }
  });
};
