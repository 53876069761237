import React, { useEffect, createRef, useState } from "react";
import { ReactSVG } from "react-svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LivePreview from "../livePreview/livePreview";
import AnalyticsSection from "../common/analyticsSection/container";

import styles from "./analytics.module.scss";
import privacy_svg from "../../assets/settings/icon_privacy.svg";
import support_cause_svg from "../../assets/settings_sidebar/icon_support_cause.svg";
import tracking_pixels_svg from "../../assets/settings_sidebar/icon_tracking_pixels.svg";
import floating_button_svg from "../../assets/sections_sidebar/floating_button.svg";
import seo_svg from "../../assets/settings_sidebar/icon_seo.svg";
import commerce_integration_svg from "../../assets/settings_sidebar/icon_commerce_integration.svg";
import age_restricted_svg from "../../assets/settings_sidebar/icon_age_restricted.svg";
import toc_pp_svg from "../../assets/settings_sidebar/icon_toc_pp.svg";
import SectionTopBar from "../common/sectionTopBar/sectionTopBar";
import LifetimeAnalytics from "./lifetimeAnalytics/lifetime";
import DateAnalytics from "./dateAnalytics/date";
import SocialAnalytics from "./socialAnalytics/social";
import LinkAnalytics from "./linksAnalytics/link";
import ReferrerAnalytics from "./referrerAnalytics/referrer";
import DeviceAnalytics from "./deviceAnalytics/device";
import GeoAnalytics from "./geoAnalytics/geo";

const Analytics = () => {
  const [openMobileDesigner, setOpenMobileDesigner] = useState(false);
  const [lifetimeSelected, setLifetimeSelected] = useState(true);
  const [dateSelected, setDateSelected] = useState(false);
  const [socialSelected, setSocialSelected] = useState(false);
  const [linksSelected, setLinksSelected] = useState(false);
  const [geoSelected, setGeoSelected] = useState(false);
  const [referrersSelected, setReferrersSelected] = useState(false);
  const [deviceSelected, setDeviceSelected] = useState(false);

  const sidebarRef = createRef();
  const lifetimeRef = createRef();
  const dateRef = createRef();
  const socialRef = createRef();
  const linksRef = createRef();
  const geoRef = createRef();
  const referrerRef = createRef();
  const deviceRef = createRef();

  const makeSidebarSectionActive = (activate) => {
    setLifetimeSelected(false);
    setDateSelected(false);
    setSocialSelected(false);
    setLinksSelected(false);
    setGeoSelected(false);
    setReferrersSelected(false);
    setDeviceSelected(false);
    setOpenMobileDesigner(false);

    activate(true);
  };

  const scrollToSection = (targetElement, top = false) => {
    if (top) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    const topOffset = targetElement.offsetTop;
    if (topOffset)
      window.scrollTo({
        top: topOffset - 110,
        left: 0,
        behavior: "smooth",
      });
  };


  // const stickySidebar = createRef();

  useEffect(() => {
    if (sidebarRef.current.clientHeight < window.innerHeight - 100) {
      sidebarRef.current.style.width = `${sidebarRef.current.clientWidth}px`;
      sidebarRef.current.style.position = "fixed";
    }
  });

  return (
    <div className={styles.analytics_container}>
      <div
        className={`${styles.section_selector} ${styles.floating_selection} ${
          openMobileDesigner ? styles.open : ""
        }`}
      >
        <div ref={sidebarRef} className={styles.allow_scroll_mobile}>
          <div className={styles.analytics_white_box}>
            <p className={styles.heading}>Links</p>
            <div className={styles.customize_options_container}>
              <button
                className={`${styles.customize_option} ${
                  lifetimeSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setLifetimeSelected);
                  scrollToSection(0, true);
                }}
              >
                <ReactSVG src={privacy_svg} wrapper={"span"} />
                Lifetime Analytics
              </button>
              <button
                className={`${styles.customize_option} ${
                  dateSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setDateSelected);
                  scrollToSection(dateRef.current);
                }}
              >
                <ReactSVG src={toc_pp_svg} wrapper={"span"} />
                Custom Search
              </button>
              <button
                className={`${styles.customize_option} ${
                  socialSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setSocialSelected);
                  scrollToSection(socialRef.current);
                }}
              >
                <ReactSVG src={support_cause_svg} wrapper={"span"} />
                Social Links
              </button>
              <button
                className={`${styles.customize_option} ${
                  linksSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setLinksSelected);
                  scrollToSection(linksRef.current);
                }}
              >
                <ReactSVG src={tracking_pixels_svg} wrapper={"span"} />
                Links
              </button>
              <button
                className={`${styles.customize_option} ${
                  geoSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setGeoSelected);
                  scrollToSection(geoRef.current);
                }}
              >
                <ReactSVG src={seo_svg} wrapper={"span"} />
                Geo Analytics
              </button>
              <button
                className={`${styles.customize_option} ${
                  referrersSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setReferrersSelected);
                  scrollToSection(referrerRef.current);
                }}
              >
                <ReactSVG src={commerce_integration_svg} wrapper={"span"} />
                Top Referrers
              </button>
              <button
                className={`${styles.customize_option} ${
                  deviceSelected && styles.active
                }`}
                onClick={() => {
                  makeSidebarSectionActive(setDeviceSelected);
                  scrollToSection(deviceRef.current);
                }}
              >
                <ReactSVG src={age_restricted_svg} wrapper={"span"} />
                Device Analytics
              </button>
            </div>
          </div>
        </div>
        <button
          className={styles.floating_button_analytics}
          onClick={() => setOpenMobileDesigner(!openMobileDesigner)}
        >
          <ReactSVG src={floating_button_svg} wrapper="span" />
        </button>
      </div>
      <div className={styles.section_details_editor}>
        <SectionTopBar
          title={"Analytics"}
          id={"branding-section-designer"}
          showHelp={false}
          showUpdateBtn={false}
          clearBtnText={"Clear All"}
        />
        <div
          ref={lifetimeRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Lifetime Analytics"}
            inputId={`settings_custom_toc_pp_enable`}
            showDropdown={true}
            showUnlockBtn={false}
          >
            <LifetimeAnalytics />
          </AnalyticsSection>
        </div>
        
        <div
          ref={dateRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Select the date below to show"}
            inputId={`settings_support_cause_enable`}
            subtitle={"Free account can only selec t up to 30 days, Upgrade your account to unlock all analytics."}
          >
            <DateAnalytics />
          </AnalyticsSection>
        </div>
        
        <div
          ref={socialRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Best Performing Social Links"}
            inputId={`settings_commerce_integration_enable`}
            subtitle={"Please note: the data is sample data. Unlock the feature to see your link data."}
          >
            <SocialAnalytics />
          </AnalyticsSection>
        </div>

        <div
          ref={linksRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Best Performing Links"}
            inputId={`settings_commerce_integration_enable`}
            subtitle={"Please note: the data is sample data. Unlock the feature to see your link data."}
          >
            <LinkAnalytics />
          </AnalyticsSection>
        </div>

        <div
          ref={geoRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Geo Analytics"}
            inputId={`settings_age_restricted_material_enable`}
            subtitle={"Please note: the data is sample data. Unlock the feature to see your link data."}
          >
            <GeoAnalytics />
          </AnalyticsSection>
        </div>

        <div
          ref={referrerRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Top Referrers"}
            inputId={`settings_age_restricted_material_enable`}
            subtitle={"Please note: the data is sample data. Unlock the feature to see your link data."}
          >
            <ReferrerAnalytics />
          </AnalyticsSection>
        </div>

        <div
          ref={deviceRef}
          className={`${styles.analytics_white_box} ${styles.details_editor}`}
        >
          <AnalyticsSection
            title={"Device Analytics"}
            inputId={`settings_age_restricted_material_enable`}
            subtitle={"Please note: the data is sample data. Unlock the feature to see your link data."}
          >
            <DeviceAnalytics />
          </AnalyticsSection>
        </div>
      </div>
      <LivePreview />
    </div>
  );
};

export default Analytics;
