import React, { useEffect } from "react";

import LandingPagesWrapper from "../../common/landingPagesWrapper/landingPagesWrapper";

const PricingLanding = () => {
  useEffect(() => {
    window.document.documentElement.dataset["wfPage"] =
      "640a35946f00441f8a66a138";
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
    window.document.body.classList.add("webflow");
  });

  return (
    <LandingPagesWrapper>
      <main className="main-wrapper">
        <section
          data-w-id="16ed6074-db2a-e44e-166f-fff4a0cb6267"
          className="section_basicpricing"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="w-layout-grid grid-halves">
                  <div id="w-node-_71e3b27e-c565-77f8-821c-26bfb01f0c5f-8a66a138">
                    <h2 className="heading">
                      Join the <span className="text-span-3">Basic</span>{" "}
                      Subscription
                    </h2>
                    <p className="text-size-medium">
                      and get access to all the current and upcoming features
                    </p>
                    <div>
                      <div>
                        <img
                          src="images/Group-70.png"
                          loading="lazy"
                          alt=""
                          className="image-2"
                        />
                      </div>
                      <a href="/signup" className="button-4 w-button">
                        <span className="text-span">$</span>
                        <strong className="bold-text">2.95</strong>/month{" "}
                      </a>
                      <div className="text-block">
                        Hurry, this offer won&#x27;t last forever.
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_60e49964-9e7b-e868-1028-2991dfe23aba-8a66a138">
                    <div
                      id="w-node-_16ed6074-db2a-e44e-166f-fff4a0cb6275-8a66a138"
                      className="feature-images-wrapper"
                    >
                      <div
                        id="w-node-_16ed6074-db2a-e44e-166f-fff4a0cb6276-8a66a138"
                        style={{ opacity: 1 }}
                        className="feature-image-wrapper _1"
                      ></div>
                      <img
                        src="images/HC-Baseic-Pricing-image.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 93vw, (max-width: 686px) 94vw, (max-width: 991px) 645px, (max-width: 1439px) 47vw, (max-width: 1919px) 574px, 100vw"
                        srcSet="images/HC-Baseic-Pricing-image-p-500.png 500w, images/HC-Baseic-Pricing-image.png 645w"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="section-3">
          <div className="w-container">
            <img
              src="images/horizontal-separator-dash.png"
              loading="lazy"
              alt=""
              className="image-3"
            />
          </div>
        </div>
        <div className="section-2">
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980244-8a66a138"
                    className="feature-images-wrapper"
                  >
                    <div
                      id="w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980245-8a66a138"
                      className="feature-image-wrapper _1"
                    >
                      <img
                        src="images/Premium.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, (max-width: 1439px) 47vw, 559.984375px"
                        alt="Premium"
                      />
                    </div>
                  </div>
                  <div
                    id="w-node-_3d79ffa0-d5bc-a14e-994c-f6352f980234-8a66a138"
                    className="feature-content"
                  >
                    <h2 className="heading">
                      Join the <span className="text-span-2">Premium </span>
                      Subscription
                    </h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          and get access to all the basic and Premium Features.
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src="images/Group-70.png"
                        loading="lazy"
                        alt=""
                        className="image-2"
                      />
                    </div>
                    <a href="/signup" className="button-4 w-button">
                      <span className="text-span">$</span>
                      <strong className="bold-text">5.95</strong>/month{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="section-10">
        <div className="columns f2wf-columns-3">
          <div className="column-7">
            <div className="intro">
              <div className="pricing-left">
                <div className="name">
                  <div className="text-12">Basic</div>
                </div>
                <div className="pricing">
                  <div className="_599-mo">$2.95/mo</div>
                </div>
              </div>
              <div className="image-wrapper-3">
                <img
                  src="images/pricing-star.png"
                  loading="lazy"
                  alt=""
                  className="pricing-1"
                />
              </div>

              <a href="/signup" className="button-5">
                <div className="text-13">Get Your UmiCard Basic NOW</div>
              </a>
            </div>
            <div className="features">
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">Access all Basic Features</div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">Premium Card Designs</div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">Customizable Contact Section</div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Customizable Social Media Icons
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Access to all Font and Color Customizations
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Card Branding and Customization
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">About me with Call to Action</div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Image Gallery with Customizable Call to Actions
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Video Gallery with Customizable Call to Action
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">Calendy Integration</div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  YouTube integration with Call to Action
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Access to content/item scheduler{" "}
                </div>
              </div>
              <div className="pricing-feature">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper"
                  />
                </div>
                <div className="feature-item">
                  Item locker, to protect certain content with password or
                  secret code
                </div>
              </div>
            </div>
          </div>
          <div className="column-8">
            <div className="intro">
              <div className="pricing-left">
                <div className="name">
                  <div className="text-12">Premium</div>
                </div>
                <div className="pricing">
                  <div className="_599-mo">$5.95/mo</div>
                </div>
              </div>
              <div className="image-wrapper-3">
                <img
                  src="images/pricing-diamond.png"
                  loading="lazy"
                  alt=""
                  className="pricing-1"
                />
              </div>

              <a href="/signup" className="button-6">
                <div className="text-14">Get Your UmiCard Premium NOW</div>
              </a>
            </div>
            <div className="description">
              <div className="text-15">
                Everything in Basic Plan Plus the followings:
              </div>
            </div>
            <div className="features-2">
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Absolute Privacy through Contact Lock
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Lead Generation (collect leads detail, email and phone number)
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Email Marketing Integration (ConvertKit, ActiveCampaign,
                  Hubspot, Salesforce, more coming soon)
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Integration with your Marketing Pixels.
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Receive Payments inside the card
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Access to detailed Analytics (coming soon)
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Early Access to future releases{" "}
                </div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">Custom URL</div>
              </div>
              <div className="pricing-feature-2">
                <div className="icon">
                  <img
                    src="images/Icon-Wrapper.svg"
                    loading="lazy"
                    alt=""
                    className="icon-wrapper-2"
                  />
                </div>
                <div className="feature-item">
                  Etiam sodales ac felis id interdum
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="faq-section">
        <div className="container-2 faq-container">
          <h2 className="pricing-h1 faq-h2">Frequently Asked Questions</h2>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">
                Seriously, what is Actual Price?{" "}
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                you saw it above, the
                <strong> Basic Plan is ONLY $2.95/month</strong>, and
                <strong> Premium Plan is ONLY $5.95/month</strong>.{" "}
                <strong>No hidden fees, no additional fees</strong>. Simplicity
                is the Key.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">
                Why UmiCard is so affordable? Is there a catch??
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                This is our Very Limited Time Launch Offer and we increase the
                subscription Prices very soon.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">
                Will I be impacted by Price increase in the future?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                <strong>No. if You Join Today.</strong> When you join today,
                you&#x27;ll Secure your special launch pricing and regardless of
                what the future prices maybe, you will continue to pay Only
                $2.95/month if you get the Basic Subscription and $5.95/month if
                you get the Premium subscription. That&#x27;s pour Commitment to
                you.
                <br />
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">
                Can I upgrade my plan in the future?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Absolutely. You can upgrade or downgrade your plan anytime.
                However, remember if you want to upgrade after prices are
                increased, you&#x27;ll be paying the new subscription fees.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">
                Will you renew my subscription automatically?
              </div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Yes, your subscription will be automatically renewed every month
                until you decide to cancel it.
              </p>
            </div>
          </div>
          <div className="faq-wrap">
            <div className="faq-question">
              <div className="faq-plus-wrap">
                <div className="faq-plus-l"></div>
                <div className="faq-plus"></div>
              </div>
              <div className="faq-q-text">Can I get a refund?</div>
            </div>
            <div className="faq-answer">
              <p className="faq-answer-text">
                Of course. If you didn&#x27;t like UmiCard for any reason, just
                send an email to refund@umicard.com with 14 days of your
                purchase and we&#x27;ll refund your money, no questions asked,
                no string attached.
              </p>
            </div>
          </div>
        </div>
        <div className="full-texture"></div>
      </div> */}
    </LandingPagesWrapper>
  );
};

export default PricingLanding;
