import React, { useState, createContext } from "react";

export const TrackingCodesContext = createContext();

export const TrackingCodesProvider = ({ children }) => {
  const [trackingCodes, setTrackingCodes] = useState({
    google: {
      analyticsId: "",
      tagManagerId: "",
    },
    facebook: {
      pixelId: "",
      conversionsAPIToken: "",
    },
    linkedIn: {
      insightTagId: "",
    },
    pinterest: {
      tagId: "",
    },
    tiktok: {
      pixelId: "",
    },
    seo: {
      metaTitle: "",
      metaDescription: "",
    },
  });

  return (
    <TrackingCodesContext.Provider value={[trackingCodes, setTrackingCodes]}>
      {children}
    </TrackingCodesContext.Provider>
  );
};
