import { API_BASE } from "../../../../api_links";
import { uploadStaticData } from "../../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../../notification/buildNotification";
import {
  signImageS3,
  uploadImageS3,
  deleteImageS3,
} from "../../../../uploadServices/imageUploader";

export const uploadVideoGallery = async (videoGallery) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/video_gallery`;

  const {
    video_gallery_section_title,
    video_gallery_section_icon,
    video_gallery_section_background,
    video_link_gallery_section_background,
    video_link_font_and_icon_color,
    gallery_data: gd,
  } = videoGallery;

  gd.map((item) => {
    const { CTAIcon } = item;
    CTAIcon.upload = "";
  });

  const { enable: enable_video_gallery_section_title, title } =
    video_gallery_section_title;

  const {
    enable: enable_video_gallery_section_icon,
    type: video_gallery_icon_type,
    fa_icon: video_gallery_icon_name,
    color: video_gallery_icon_color,
    preview_file: video_gallery_icon_existing_url,
    uploadable_file: video_gallery_icon_uploadable_file,
  } = video_gallery_section_icon;

  const {
    enable: enable_gallery_section_background,
    color: video_gallery_section_background_color,
  } = video_gallery_section_background;

  const {
    enable: enable_video_link_gallery_section_background,
    color: video_link_gallery_background_color,
  } = video_link_gallery_section_background;

  const {
    enable: enable_video_link_font_and_icon_color,
    color: video_link_color,
    font: video_link_font,
    font_bold: video_link_font_bold,
    font_italic: video_link_font_italic,
    font_underline: video_link_font_underline,
    font_align_left: video_link_font_align_left,
    font_align_right: video_link_font_align_right,
    font_align_center: video_link_font_align_center,
  } = video_link_font_and_icon_color;

  const updateObj = {
    enable_video_gallery_section_title,
    video_gallery_section_title: title,
    enable_video_gallery_section_icon,
    video_gallery_icon_type,
    video_gallery_icon_name,
    video_gallery_icon_color,
    video_gallery_section_icon_url: video_gallery_icon_existing_url,
    enable_video_gallery_section_background: enable_gallery_section_background,
    video_gallery_section_background_color,
    enable_video_link_gallery_section_background,
    video_link_gallery_section_background_color:
      video_link_gallery_background_color,
    enable_video_link_font_and_icon_color,
    video_link_color,
    video_link_font,
    video_link_font_bold,
    video_link_font_italic,
    video_link_font_underline,
    video_link_font_align_left,
    video_link_font_align_right,
    video_link_font_align_center,
    gallery_data: JSON.stringify(gd),
  };

  if (video_gallery_icon_uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        video_gallery_icon_uploadable_file,
        "videoGallery/Icons"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            video_gallery_icon_uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            deleteImageS3(video_gallery_icon_existing_url);
            updateObj["video_gallery_section_icon_url"] = url;
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Video Gallery", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading Video gallery icon",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Video Gallery icon uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  for (let i = 0; i < gd.length; i++) {
    const gallery_item = gd[i];

    const { video_url, CTAIcon } = gallery_item;

    if (video_url.uploadable_file) {
      let tempUpdateObjGD = JSON.parse(updateObj.gallery_data);
      tempUpdateObjGD[i].video_url.preview_file = video_url.uploadable_file;
      tempUpdateObjGD[i].video_url.uploadable_file = "";
      updateObj.gallery_data = JSON.stringify(tempUpdateObjGD);

      // Upload to xano
      uploadStaticData(true, dataUploadURL, updateObj, (result) => {
        console.log("Static Upload Result Video Gallery", result);
        if (result?.code) {
          buildNotification({
            type: "error",
            message:
              result?.message ?? "Error uploading Video gallery Video URL",
          });
        } else {
          buildNotification({
            type: "success",
            message: "Video Gallery Video URL uploaded",
          });
        }
      });
    }

    if (CTAIcon.uploadable_file) {
      try {
        const signedRequestObject = await signImageS3(
          CTAIcon.uploadable_file,
          "videoGallery/ctaIconImages"
        );
        signedRequestObject.json().then(async (data) => {
          const { signedRequest, url } = data;
          if (!signedRequest || !url) return;
          try {
            const uploadResult = await uploadImageS3(
              CTAIcon.uploadable_file,
              signedRequest
            );
            console.log("upload s3 result", uploadResult, url);
            if (uploadResult.status === 200) {
              deleteImageS3(CTAIcon?.preview_file);
              let tempUpdateObjGD = JSON.parse(updateObj.gallery_data);
              tempUpdateObjGD[i].CTAIcon.preview_file = url;
              tempUpdateObjGD[i].CTAIcon.uploadable_file = "";
              tempUpdateObjGD[i].CTAIcon.upload = "";
              updateObj.gallery_data = JSON.stringify(tempUpdateObjGD);
              // Upload to xano
              uploadStaticData(true, dataUploadURL, updateObj, (result) => {
                console.log("Static Upload Result Video Gallery", result);
                if (result?.code) {
                  buildNotification({
                    type: "error",
                    message:
                      result?.message ??
                      "Error uploading Video gallery CTA Icon",
                  });
                } else {
                  buildNotification({
                    type: "success",
                    message: "Video Gallery CTA Icon uploaded",
                  });
                }
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  console.log("Update data", updateObj);

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Video Gallery", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading Video gallery settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Video Gallery Settings uploaded",
      });
    }
  });
};
