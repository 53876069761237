import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { StripeNode } from "../../../api_links";

import { checkLoginWithExistingCookie } from "../../authFlow/login/login.service";
import { removeLoginCookie } from "../../../auth/checkLoginCookie";

import demoUserImage3 from "../../../assets/demo-content/user_image_3.jpg";
import dropdown_svg from "../../../assets/header/icon_down.svg";
import profile_svg from "../../../assets/header/dropdownMenu/profile.svg";
import company_svg from "../../../assets/header/dropdownMenu/company.svg";
import billing_svg from "../../../assets/header/dropdownMenu/billing.svg";
import refer_svg from "../../../assets/header/dropdownMenu/refer.svg";
import logout_svg from "../../../assets/header/dropdownMenu/logout.svg";

import { PersonalDetailsContext } from "../../contexts/designerContexts/personalDetails.context";

const UserDetailsDropDown = ({ styles, customPortalSetMobile }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [personalDetails] = useContext(PersonalDetailsContext);
  const [customerPortalURL, setCustomerPortalURL] = useState("");

  const {
    profile_picture_image_preview_file,
    profile_picture_image_upload,
    leads_form_fields,
  } = personalDetails;

  const { name } = leads_form_fields;

  useEffect(() => {
    checkLoginWithExistingCookie((data) => {
      const striptCustomerID = data?.stripe_customer_id;
      if (!striptCustomerID) return;
      fetch(`${StripeNode}/customer-portal/${striptCustomerID}`)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status && data?.url) {
            setCustomerPortalURL(data?.url);
            customPortalSetMobile(data?.url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    // For closing
    document.body.addEventListener("click", (e) => {
      if (!e.target.closest("#profile-dropdown")) setDropDownOpen(false);
    });
  }, []);

  return (
    <div
      id="profile-dropdown"
      className={`${styles.user_drop_down} vertical_align_center`}
    >
      <div className={styles.user_image}>
        <img
          className={styles.user_image_tag}
          src={
            profile_picture_image_upload
              ? profile_picture_image_upload
              : profile_picture_image_preview_file?.url
              ? profile_picture_image_preview_file?.url
              : demoUserImage3
          }
          alt={name}
        />
      </div>
      <p className={styles.user_name}>{name.split(" ")[0]}</p>
      <button
        className={styles.user_dropdown_btn}
        onClick={() => {
          setDropDownOpen(!dropDownOpen);
        }}
      >
        <ReactSVG src={dropdown_svg} wrapper={"span"} />
      </button>
      <div
        className={styles.dropdown_menu}
        style={{ display: dropDownOpen ? "block" : "none" }}
      >
        <Link to={"/profile"}>
          <span className={styles.icon}>
            <ReactSVG src={profile_svg} wrapper={"span"} />{" "}
          </span>
          <span className={styles.url_text}>Profile</span>
        </Link>
        <Link to={"/profile/company"}>
          <span className={styles.icon}>
            <ReactSVG src={company_svg} wrapper={"span"} />{" "}
          </span>
          <span className={styles.url_text}>Company</span>
        </Link>
        {/* {customerPortalURL && ( */}
        <Link to={"/profile/billing"}>
          <span className={styles.icon}>
            <ReactSVG src={billing_svg} wrapper={"span"} />{" "}
          </span>
          <span className={styles.url_text}>Billing</span>
        </Link>
        {/* )} */}
        <Link to={"/profile/refer"}>
          <span className={styles.icon}>
            <ReactSVG src={refer_svg} wrapper={"span"} />{" "}
          </span>
          <span className={styles.url_text}>Refer a friend</span>
        </Link>
        <a
          href={"#"}
          onClick={(e) => {
            e.preventDefault();

            removeLoginCookie();
            window.location.href = "/login";
          }}
        >
          <span className={styles.icon}>
            <ReactSVG src={logout_svg} wrapper={"span"} />{" "}
          </span>
          <span className={styles.url_text}>Logout</span>
        </a>
      </div>
    </div>
  );
};

export default UserDetailsDropDown;
