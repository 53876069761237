export const update_enable_background_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background.enable = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_use_background_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background.use_background_color = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_branding_background_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background.branding_background_color = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_use_background_image = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background.use_background_image = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_branding_background_image = (
  file,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  if (!file) {
    brandingDetailsCopy.background.background_image_upload = "";
    brandingDetailsCopy.background.background_image_uploadable_file = "";
  } else {
    brandingDetailsCopy.background.background_image_upload =
      URL.createObjectURL(file);
    brandingDetailsCopy.background.background_image_uploadable_file = file;
  }
  setBrandingDetails(brandingDetailsCopy);
};

export const remove_uploaded_background_image = (
  brandingDetails,
  setBrandingDetails,
  callback
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background.background_image_preview_file = { url: "" };
  setBrandingDetails(brandingDetailsCopy);
  callback(brandingDetailsCopy);
};

export const update_enable_background_overlay = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background_overlay.enable = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_branding_background_overlay_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.background_overlay.branding_background_overlay = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_enable_business_brand_name = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_name.enable = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_name = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_name.brand_name = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_name_font = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_name.brand_name_font = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_name_font_styles = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_name.brand_name_font_bold = bold;
  brandingDetailsCopy.business_brand_name.brand_name_font_italic = italic;
  brandingDetailsCopy.business_brand_name.brand_name_font_underline = underline;
  brandingDetailsCopy.business_brand_name.brand_name_font_align_left =
    left_align;
  brandingDetailsCopy.business_brand_name.brand_name_font_align_right =
    right_align;
  brandingDetailsCopy.business_brand_name.brand_name_font_align_center =
    center_align;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_name_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_name.brand_name_color = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_enable_business_brand_slogan = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_slogan.enable = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_slogan = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_slogan.brand_slogan = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_slogan_font = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_slogan_font_styles = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_bold = bold;
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_italic = italic;
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_underline =
    underline;
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_align_left =
    left_align;
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_align_right =
    right_align;
  brandingDetailsCopy.business_brand_slogan.brand_slogan_font_align_center =
    center_align;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_brand_slogan_color = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_slogan.brand_slogan_color = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_enable_business_brand_logo = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_logo.enable = value;
  setBrandingDetails(brandingDetailsCopy);
};

export const update_business_brand_logo = (
  file,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  if (!file) {
    brandingDetailsCopy.business_brand_logo.business_brand_logo_upload = "";
    brandingDetailsCopy.business_brand_logo.business_brand_logo_uploadable_file =
      "";
  } else {
    brandingDetailsCopy.business_brand_logo.business_brand_logo_upload =
      URL.createObjectURL(file);
    brandingDetailsCopy.business_brand_logo.business_brand_logo_uploadable_file =
      file;
  }
  setBrandingDetails(brandingDetailsCopy);
};

export const remove_uploaded_brand_logo = (
  brandingDetails,
  setBrandingDetails,
  callback
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  brandingDetailsCopy.business_brand_logo.business_brand_logo_preview_file = {
    url: "",
  };
  setBrandingDetails(brandingDetailsCopy);
  callback(brandingDetailsCopy);
};

export const update_brand_logo_size = (
  value,
  brandingDetails,
  setBrandingDetails
) => {
  const brandingDetailsCopy = { ...brandingDetails };
  if (value >= 10 && value <= 100) {
    brandingDetailsCopy.brand_logo_adjustments.size = value;
    setBrandingDetails(brandingDetailsCopy);
  }
};
