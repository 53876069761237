import React, { useContext } from "react";

import { CRMContext } from "../../../contexts/settingsContexts/crm.context";

import { update_crm_hubspot_api_key } from "../updateFunctions";

import { uploadCRM } from "../uploadFunction";

import InputField from "../../../common/inputField/inputField";
import Button from "../../../common/button/button";

const Hubspot = () => {
  const [crm, setCrm] = useContext(CRMContext);

  const { API_Key } = crm.use_form.crm_details.crm_options.hubspot;

  return (
    <React.Fragment>
      <InputField
        label={"API_Key"}
        defaultInputValue={API_Key}
        type={"password"}
        callback={update_crm_hubspot_api_key}
        context={crm}
        updateContext={setCrm}
      />
      <Button
        title={"Save Hubspot Settings"}
        callback={uploadCRM}
        callbackData={crm}
      />
    </React.Fragment>
  );
};

export default Hubspot;
