import React from "react";

import styles from "./supportCauseSlide.module.scss";

const SupportCauseSlide = ({ causeName, icon, text, link }) => {
  return (
    <div className={styles.support_cause_slide_container}>
      <div className={styles.icon_wrapper}>
        <img src={icon} alt={causeName} className={styles.cause_icon} />
      </div>
      <div className={styles.text_wrapper}>
        <h3 className={styles.main_heading}>{causeName}</h3>
        <p className={styles.info_text}>{text}</p>
        <a href={link} className={styles.info_link}>
          Learn more about the cause
        </a>
      </div>
    </div>
  );
};

export default SupportCauseSlide;
