import React from "react";
import { ReactSVG } from "react-svg";
import AnalyticsChart from "../../common/charts/chart";
import styles from "./device.module.scss";
import cross_svg from "../../../assets/global/icon_cross.svg";

export const data = [
  ["Task", "Hours per Day"],
  ["Work", 10],
  ["Eat", 20],
  ["Commute", 15],
];

export const options = {
  legend: "none",
  colors: ["#75D89D", "#FCC97B", "#FF81DC", "#FCC97B"],
  backgroundColor: "transparent",
  pieHole: 0.5,
  pieSliceText: "none",
};

const DevicePieChart = () => {
  return (
    <div className={styles.chart_section}>
      <div className={styles.content_section}>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.views}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Views <span className={styles.value}>213</span></p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.clicks}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Clicks <span className={styles.value}>323</span></p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.unique}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Unique Clicks <span className={styles.value}>22</span></p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.ctr}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Avg CTR <span className={styles.value}>323</span></p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.time}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Avg Time to Click <span className={styles.value}>76</span></p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.earning}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p>Earning <span className={styles.value}>345</span></p>
          </div>
        </div>
      </div>
      <AnalyticsChart chartType="PieChart" data={data} options={options} width="300px" />
    </div>
  );
};

export default DevicePieChart;
