import { checkLoginWithExistingCookie } from "../components/authFlow/login/login.service";
import { StripeNode } from "../api_links";

export const openStripeDashboard = () => {
  checkLoginWithExistingCookie((data) => {
    const striptCustomerID = data?.stripe_customer_id;
    if (!striptCustomerID) return;
    fetch(`${StripeNode}/customer-portal/${striptCustomerID}`)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status && data?.url) {
          console.log(data.url);
          window.location.href = data.url;
        } else window.location.href = "/pricing";
      })
      .catch((error) => {
        console.log(error);
        window.location.href = "/pricing";
      });
  });
};
