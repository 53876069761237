import React, { useState, createContext } from "react";

export const FontsAndColorsContext = createContext();

export const FontsAndColorsProvider = ({ children }) => {
  const [fontsAndColors, setFontsAndColors] = useState({
    sections_and_titles_headlines: {
      enable: false,
      sections_and_titles_headlines_font: "verdana",
      sections_and_titles_headlines_font_bold: false,
      sections_and_titles_headlines_font_italic: false,
      sections_and_titles_headlines_font_underline: false,
      sections_and_titles_headlines_font_align_left: false,
      sections_and_titles_headlines_font_align_right: false,
      sections_and_titles_headlines_font_align_center: true,
      sections_and_titles_headlines_color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    sections_border_radius: true,
    body_text: {
      enable: false,
      body_text_font: "verdana",
      body_text_font_bold: false,
      body_text_font_italic: false,
      body_text_font_underline: false,
      body_text_font_align_left: false,
      body_text_font_align_right: false,
      body_text_font_align_center: true,
      body_text_color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    contact_icons_primary_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    contact_icons_secondary_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    social_media_icons_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    social_media_icons_background_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    share_my_card_background_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    seconds_icon_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
  });

  return (
    <FontsAndColorsContext.Provider value={[fontsAndColors, setFontsAndColors]}>
      {children}
    </FontsAndColorsContext.Provider>
  );
};
