export const update_youtube_channel_id = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.channel_id = value;
  setYoutube(youtubeCopy);
};

export const select_videos_list_type = (value, youtube, setYoutube, index) => {
  const youtubeCopy = { ...youtube };

  if (["dynamic", "custom"].includes(index) && value)
    youtubeCopy.videos_list_type = index;
  setYoutube(youtubeCopy);
};

export const update_dynamic_videos_list_number = (
  value,
  youtube,
  setYoutube
) => {
  const youtubeCopy = { ...youtube };
  if (value >= 1 && value <= 10) {
    youtubeCopy.number_of_dynamic_videos = value;
    setYoutube(youtubeCopy);
  }
};

export const update_dynamic_list_type = (value, id, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  if (["recent", "popular"].includes(id) && value) {
    youtubeCopy.dynamic_list_type = id;
    setYoutube(youtubeCopy);
  }
};

export const update_existing_id = (value, youtube, setYoutube, index) => {
  const youtubeCopy = { ...youtube };
  const targetVideoID = youtubeCopy.custom_videos_list[index];
  if (targetVideoID !== undefined) {
    youtubeCopy.custom_videos_list[index] = value;
    setYoutube(youtubeCopy);
  }
};

export const delete_existing_id = (index, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  const targetVideoID = youtubeCopy.custom_videos_list[index];

  if (targetVideoID !== undefined) {
    youtubeCopy.custom_videos_list.splice(index, 1);
    setYoutube(youtubeCopy);
  }
};

export const add_new_youtube_id = (youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.custom_videos_list.push("");
  setYoutube(youtubeCopy);
};

export const update_enable_youtube_customization = (
  value,
  youtube,
  setYoutube
) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.enable = value;
  setYoutube(youtubeCopy);
};

export const select_icon_type_youtube = (option, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.icon.type = option;
  setYoutube(youtubeCopy);
};

export const update_youtube_icon = (file, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };

  if (!file) {
    youtubeCopy.customization.icon.uploadable_file = "";
    youtubeCopy.customization.icon.upload = "";
  } else {
    youtubeCopy.customization.icon.uploadable_file = file;
    youtubeCopy.customization.icon.upload = URL.createObjectURL(file);
  }
  setYoutube(youtubeCopy);
};

export const update_fa_icon_name_youtube = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.icon.fa_icon = value;
  setYoutube(youtubeCopy);
};

export const update_youtube_fa_icon_color = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.icon.color = value;
  setYoutube(youtubeCopy);
};

export const update_youtube_section_background_color = (
  value,
  youtube,
  setYoutube
) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.background.color = value;
  setYoutube(youtubeCopy);
};

export const update_youtube_headline = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.headline = value;
  setYoutube(youtubeCopy);
};

export const update_youtube_font = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.font.name = value;
  setYoutube(youtubeCopy);
};

export const update_youtube_font_styles = (value, youtube, setYoutube) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.font.bold = bold;
  youtubeCopy.customization.font.italic = italic;
  youtubeCopy.customization.font.underline = underline;
  youtubeCopy.customization.font.align_left = left_align;
  youtubeCopy.customization.font.align_right = right_align;
  youtubeCopy.customization.font.align_center = center_align;
  setYoutube(youtubeCopy);
};

export const update_youtube_featured_color = (value, youtube, setYoutube) => {
  const youtubeCopy = { ...youtube };
  youtubeCopy.customization.featured_color = value;
  setYoutube(youtubeCopy);
};
