import React, { useContext } from "react";

import { CardDesignContext } from "../../contexts/designerContexts/cardDesign.context";

import { updateLayout } from "./updateFunctions";
import { uploadLayout } from "./uploadFunction";

import { layouts } from "./availableLayouts";

import SectionTopBar from "../../common/sectionTopBar/sectionTopBar";
import RadioInputField from "../../common/radioInputField/radioInputField";

const CardDesign = ({ styles }) => {
  const [cardDesign, setCardDesign] = useContext(CardDesignContext);

  return (
    <div className="personal_details">
      <SectionTopBar
        title={"Card Design"}
        id={"card-design-section-designer"}
        updateCallback={uploadLayout}
        updateData={cardDesign}
      />

      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>Choose Card Design</h4>
          <p className={styles.section_info_text}>
            We can add some more text here if needed...
          </p>
          <div className={styles.cardDesign_options}>
            {layouts.map(({ id, img }) => {
              return (
                <RadioInputField
                  key={id}
                  forGroup={"carddesign_layout"}
                  inputFieldId={id}
                  inputStyle={"carddesign_radio"}
                  selected={cardDesign === id}
                  callback={updateLayout}
                  context={cardDesign}
                  updateContext={setCardDesign}
                >
                  <img src={img} alt={id} />
                </RadioInputField>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDesign;
