import React from "react";

import styles from "./textArea.module.scss";

const TextArea = ({
  inputFieldId,
  label = "",
  extraClass = "", // no_label
  defaultInputValue = "",
  placeholder = "",
  children,
}) => {
  return (
    <div
      className={`${styles.input_field_container} ${styles[extraClass] ?? ""}`}
    >
      <label htmlFor={inputFieldId}>
        <textarea
          name={inputFieldId}
          id={inputFieldId}
          placeholder={
            placeholder ?? "Write something about yourself or business here..."
          }
          defaultValue={defaultInputValue}
        ></textarea>
        <span className={styles.label_text}>{label}</span>
        {children}
      </label>
    </div>
  );
};

export default TextArea;
