import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const Tab = ({ styles, to = "", show, setShow, allTabsShow, icon, text }) => {
  return (
    <Link
      to={`/profile/${to}`}
      className={`${styles.tab} ${show && styles.active}`}
      // onClick={() => {
      //   allTabsShow.forEach((hideShow) => {
      //     hideShow(false);
      //   });
      //   setShow(true);
      // }}
    >
      <span className={styles.icon}>
        <ReactSVG src={icon} wrapper={"span"} />
      </span>
      <span className={styles.text}>{text}</span>
    </Link>
  );
};

export default Tab;
