import React, { useState, createRef, useContext } from "react";
import { ReactSVG } from "react-svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { SidebarOrderContext } from "../contexts/designerContexts/sectionsOrder.context";

import { uploadSectionsOrder } from "./sectionsOrder/uploadFunction";

import { updateSidebarEnable } from "./sectionsOrder/updateFunctions";

import LivePreview from "../livePreview/livePreview";
import CardDesign from "./cardDesign/cardDesign";
import PersonalDetail from "./personalDetail/personalDetail";
import Branding from "./branding/branding";
import FontsAndColors from "./fontsAndColors/fontsAndColors";
import SocialLinks from "./sections/socialLinks/socialLinks";
import ImageGallery from "./sections/imageGallery/imageGallery";
import VideoGallery from "./sections/videoGallery/videoGallery";
import ImageLinks from "./sections/imageLinks/imageLinks";
import Calendly from "./sections/calendly/calendly";
import About from "./sections/about/about";
import YoutubeCreator from "./sections/youtubeCreator/youtubeCreator";
import Switch from "../common/switch/switch";

import styles from "./designer.module.scss";
import cardDesign_svg from "../../assets/customizer_sidebar/icon_card_design.svg";
import personalDetail_svg from "../../assets/customizer_sidebar/icon_personal_details.svg";
import branding_svg from "../../assets/customizer_sidebar/icon_branding.svg";
import fonts_colors_svg from "../../assets/customizer_sidebar/icon_fonts_colors.svg";
import image_gallery_svg from "../../assets/sections_sidebar/icon_image_gallery.svg";
import video_gallery_svg from "../../assets/sections_sidebar/icon_video_gallery.svg";
import about_svg from "../../assets/sections_sidebar/icon_about.svg";
import news_svg from "../../assets/sections_sidebar/icon_news.svg";
import youtube_creator_svg from "../../assets/sections_sidebar/icon_youtube_creator.svg";
import location_svg from "../../assets/sections_sidebar/icon_location.svg";
import social_links_svg from "../../assets/sections_sidebar/icon_social_links.svg";
import image_links_svg from "../../assets/sections_sidebar/icon_image_links.svg";
import calendly_svg from "../../assets/sections_sidebar/icon_calendly.svg";
import floating_button_svg from "../../assets/sections_sidebar/floating_button.svg";
import { useEffect } from "react";

const Designer = () => {
  const [sidebarOrder, setSidebarOrder] = useContext(SidebarOrderContext);
  const [openMobileDesigner, setOpenMobileDesigner] = useState(false);
  const [scrollSectionID, setScrollSectionID] = useState("");

  // Sidebar logic
  const allSidebarViewFuncs = [];
  const [cardDesignShow, setCardDesignShow] = useState(true);
  allSidebarViewFuncs.push(setCardDesignShow);
  const [personalDetailsShow, setPersonalDetailsShow] = useState(false);
  allSidebarViewFuncs.push(setPersonalDetailsShow);
  const [brandingShow, setBrandingShow] = useState(false);
  allSidebarViewFuncs.push(setBrandingShow);
  const [fontsAndColorsShow, setFontsAndColorsShow] = useState(false);
  allSidebarViewFuncs.push(setFontsAndColorsShow);
  const [imageGalleryShow, setImageGalleryShow] = useState(false);
  allSidebarViewFuncs.push(setImageGalleryShow);
  const [videoGalleryShow, setVideoGalleryShow] = useState(false);
  allSidebarViewFuncs.push(setVideoGalleryShow);
  const [aboutShow, setAboutShow] = useState(false);
  allSidebarViewFuncs.push(setAboutShow);
  const [socialLinksShow, setSocialLinksShow] = useState(false);
  allSidebarViewFuncs.push(setSocialLinksShow);
  const [imageLinksShow, setImageLinksShow] = useState(false);
  allSidebarViewFuncs.push(setImageLinksShow);
  const [calendlyShow, setCalendlyShow] = useState(false);
  allSidebarViewFuncs.push(setCalendlyShow);
  const [youtubeCreatorShow, setYoutubeCreatorShow] = useState(false);
  allSidebarViewFuncs.push(setYoutubeCreatorShow);

  const makeAllSidebarViewsHidden = () => {
    allSidebarViewFuncs.map((sidebarViewFun) => sidebarViewFun(false));
  };

  const extractSidebarEnableValue = (id) => {
    if (!sidebarOrder || !sidebarOrder?.order || !sidebarOrder?.order?.length) {
      return true;
    }

    for (let i = 0; i < sidebarOrder.order.length; i++) {
      const { title, enable } = sidebarOrder.order[i];
      if (title === id) {
        return enable;
      }
    }
  };

  // Sidebar elements map
  const sideBarMap = {
    imageGallery_order: (
      <button
        className={`${styles.add_the_section} ${
          imageGalleryShow && styles.active
        } vertical_align_center`}
        onClick={() => {
          setOpenMobileDesigner(false);
          makeAllSidebarViewsHidden();
          setImageGalleryShow(true);
          setTimeout(() => {
            setScrollSectionID("image-gallery-viewer");
          }, 10);
          window.scrollTo(0, 0);
        }}
      >
        <span className={styles.ellipses}></span>
        <ReactSVG src={image_gallery_svg} wrapper={"span"} /> Image Gallery{" "}
        <Switch
          inputFieldId={"imageGallery_order"}
          defaultValue={extractSidebarEnableValue("imageGallery_order")}
          sendIDInUpdate={true}
          callback={updateSidebarEnable}
          context={sidebarOrder}
          updateContext={setSidebarOrder}
          uploadData={uploadSectionsOrder}
        />
      </button>
    ),
    videoGallery_order: (
      <button
        className={`${styles.add_the_section} ${
          videoGalleryShow && styles.active
        } vertical_align_center`}
        onClick={() => {
          setOpenMobileDesigner(false);
          makeAllSidebarViewsHidden();
          setVideoGalleryShow(true);
          setTimeout(() => {
            setScrollSectionID("video-gallery-viewer");
          }, 10);
          window.scrollTo(0, 0);
        }}
      >
        <span className={styles.ellipses}></span>
        <ReactSVG src={video_gallery_svg} wrapper={"span"} /> Video Gallery{" "}
        <Switch
          inputFieldId={"videoGallery_order"}
          defaultValue={extractSidebarEnableValue("videoGallery_order")}
          sendIDInUpdate={true}
          callback={updateSidebarEnable}
          context={sidebarOrder}
          updateContext={setSidebarOrder}
          uploadData={uploadSectionsOrder}
        />
      </button>
    ),
    // about_order: (
    //   <button
    //     className={`${styles.add_the_section} ${
    //       aboutShow && styles.active
    //     } vertical_align_center`}
    //     onClick={() => {
    //       setOpenMobileDesigner(false);
    //       makeAllSidebarViewsHidden();
    //       setAboutShow(true);
    //     }}
    //   >
    //     <span className={styles.ellipses}></span>
    //     <ReactSVG src={about_svg} wrapper={"span"} /> About{" "}
    //     <Switch
    //       inputFieldId={"about_order"}
    //       defaultValue={extractSidebarEnableValue("about_order")}
    //       sendIDInUpdate={true}
    //       callback={updateSidebarEnable}
    //       context={sidebarOrder}
    //       updateContext={setSidebarOrder}
    //       uploadData={uploadSectionsOrder}
    //     />
    //   </button>
    // ),
    youtubeCreator_order: (
      <button
        className={`${styles.add_the_section} ${
          youtubeCreatorShow && styles.active
        } vertical_align_center`}
        onClick={() => {
          setOpenMobileDesigner(false);
          makeAllSidebarViewsHidden();
          setYoutubeCreatorShow(true);
          setTimeout(() => {
            setScrollSectionID("youtube-creator-viewer");
          }, 10);
          window.scrollTo(0, 0);
        }}
      >
        <span className={styles.ellipses}></span>
        <ReactSVG src={youtube_creator_svg} wrapper={"span"} /> Youtube Creator{" "}
        <Switch
          inputFieldId={"youtubeCreator_order"}
          defaultValue={extractSidebarEnableValue("youtubeCreator_order")}
          sendIDInUpdate={true}
          callback={updateSidebarEnable}
          context={sidebarOrder}
          updateContext={setSidebarOrder}
          uploadData={uploadSectionsOrder}
        />
      </button>
    ),
    // imageLinks_order: (
    //   <button
    //     className={`${styles.add_the_section} ${
    //       imageLinksShow && styles.active
    //     } vertical_align_center`}
    //     onClick={() => {
    //       setOpenMobileDesigner(false);
    //       makeAllSidebarViewsHidden();
    //       setImageLinksShow(true);
    //     }}
    //   >
    //     <span className={styles.ellipses}></span>
    //     <ReactSVG src={image_links_svg} wrapper={"span"} /> Image Links{" "}
    //     <Switch
    //       inputFieldId={"imageLinks_order"}
    //       defaultValue={extractSidebarEnableValue("imageLinks_order")}
    //       sendIDInUpdate={true}
    //       callback={updateSidebarEnable}
    //       context={sidebarOrder}
    //       updateContext={setSidebarOrder}
    //       uploadData={uploadSectionsOrder}
    //     />
    //   </button>
    // ),
    socialLinks_order: (
      <button
        className={`${styles.add_the_section} ${
          socialLinksShow && styles.active
        } vertical_align_center`}
        onClick={() => {
          setOpenMobileDesigner(false);
          makeAllSidebarViewsHidden();
          setSocialLinksShow(true);
          setTimeout(() => {
            setScrollSectionID("personal-details-viewer");
          }, 10);
          window.scrollTo(0, 0);
        }}
      >
        <span className={styles.ellipses}></span>
        <ReactSVG src={social_links_svg} wrapper={"span"} /> Social Links{" "}
        <Switch
          inputFieldId={"socialLinks_order"}
          defaultValue={extractSidebarEnableValue("socialLinks_order")}
          sendIDInUpdate={true}
          callback={updateSidebarEnable}
          context={sidebarOrder}
          updateContext={setSidebarOrder}
          uploadData={uploadSectionsOrder}
        />
      </button>
    ),
    calendly_order: (
      <button
        className={`${styles.add_the_section} ${
          calendlyShow && styles.active
        } vertical_align_center`}
        onClick={() => {
          setOpenMobileDesigner(false);
          makeAllSidebarViewsHidden();
          setCalendlyShow(true);
          setTimeout(() => {
            setScrollSectionID("calendly-viewer");
          }, 10);
          window.scrollTo(0, 0);
        }}
      >
        <span className={styles.ellipses}></span>
        <ReactSVG src={calendly_svg} wrapper={"span"} />
        Calendly{" "}
        <Switch
          inputFieldId={"calendly_order"}
          defaultValue={extractSidebarEnableValue("calendly_order")}
          sendIDInUpdate={true}
          callback={updateSidebarEnable}
          context={sidebarOrder}
          updateContext={setSidebarOrder}
          uploadData={uploadSectionsOrder}
        />
      </button>
    ),
  };

  // Re-order sidebar elements
  const reOrderSections = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const tempOrder = [...sidebarOrder.order];
    const [reOrderedItem] = tempOrder.splice(source.index, 1);
    tempOrder.splice(destination.index, 0, reOrderedItem);
    const sideBarOrderCopy = { ...sidebarOrder, order: tempOrder };
    setSidebarOrder(sideBarOrderCopy);
    uploadSectionsOrder(sideBarOrderCopy);
  };

  // Sticky sidebar
  const stickySidebar = createRef();

  useEffect(() => {
    if (stickySidebar.current.clientHeight < window.innerHeight - 100) {
      stickySidebar.current.style.width = `${stickySidebar.current.clientWidth}px`;
      stickySidebar.current.style.position = "fixed";
    }
  });

  return (
    <div className={styles.designer_container}>
      <div
        className={`${styles.section_selector} ${styles.floating_selection} ${
          openMobileDesigner ? styles.open : ""
        }`}
      >
        <div className={styles.allow_scroll_mobile} ref={stickySidebar}>
          <div className={styles.designer_white_box}>
            <p className={styles.heading}>Customize</p>
            <div className={styles.customize_options_container}>
              <button
                className={`${styles.customize_option} ${
                  cardDesignShow && styles.active
                }`}
                onClick={() => {
                  makeAllSidebarViewsHidden();
                  setCardDesignShow(true);
                  setTimeout(() => {
                    setScrollSectionID("personal-details-viewer");
                  }, 10);
                  window.scrollTo(0, 0);
                }}
              >
                <ReactSVG src={cardDesign_svg} wrapper={"span"} />
                Card Design
              </button>
              <button
                className={`${styles.customize_option} ${
                  personalDetailsShow && styles.active
                }`}
                onClick={() => {
                  makeAllSidebarViewsHidden();
                  setPersonalDetailsShow(true);
                  setTimeout(() => {
                    setScrollSectionID("personal-details-viewer");
                  }, 10);
                  window.scrollTo(0, 0);
                }}
              >
                <ReactSVG src={personalDetail_svg} wrapper={"span"} />
                Personal Details
              </button>
              <button
                className={`${styles.customize_option} ${
                  brandingShow && styles.active
                }`}
                onClick={() => {
                  makeAllSidebarViewsHidden();
                  setBrandingShow(true);
                  setTimeout(() => {
                    setScrollSectionID("personal-details-viewer");
                  }, 10);
                  window.scrollTo(0, 0);
                }}
              >
                <ReactSVG src={branding_svg} wrapper={"span"} />
                Branding
              </button>
              <button
                className={`${styles.customize_option} ${
                  fontsAndColorsShow && styles.active
                }`}
                onClick={() => {
                  makeAllSidebarViewsHidden();
                  setFontsAndColorsShow(true);
                  setTimeout(() => {
                    setScrollSectionID("personal-details-viewer");
                  }, 10);
                  window.scrollTo(0, 0);
                }}
              >
                <ReactSVG src={fonts_colors_svg} wrapper={"span"} />
                Fonts and Colors
              </button>
            </div>
          </div>
          <div className={styles.designer_white_box}>
            <p className={styles.heading}>Sections</p>
            <div className={styles.customize_options_container}>
              <button className={styles.add_new_section}>
                + Add New Section
              </button>
              <DragDropContext onDragEnd={reOrderSections}>
                <Droppable droppableId="designer-section-order">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {sidebarOrder.order.map(({ title, enable }, index) => {
                        return (
                          <Draggable
                            key={`${title}_${index}`}
                            draggableId={title}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {sideBarMap[title]}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        <button
          className={styles.floating_button_designer}
          onClick={() => setOpenMobileDesigner(!openMobileDesigner)}
        >
          <ReactSVG src={floating_button_svg} wrapper="span" />
        </button>
      </div>
      <div className={styles.section_details_editor}>
        <span style={{ display: cardDesignShow ? "block" : "none" }}>
          <CardDesign styles={styles} />
        </span>
        <span style={{ display: personalDetailsShow ? "block" : "none" }}>
          <PersonalDetail styles={styles} />
        </span>
        <span style={{ display: brandingShow ? "block" : "none" }}>
          <Branding styles={styles} />
        </span>
        <span style={{ display: fontsAndColorsShow ? "block" : "none" }}>
          <FontsAndColors styles={styles} />
        </span>
        <span style={{ display: socialLinksShow ? "block" : "none" }}>
          <SocialLinks styles={styles} />
        </span>
        <span style={{ display: imageGalleryShow ? "block" : "none" }}>
          <ImageGallery styles={styles} />
        </span>
        <span style={{ display: videoGalleryShow ? "block" : "none" }}>
          <VideoGallery styles={styles} />
        </span>
        <span style={{ display: imageLinksShow ? "block" : "none" }}>
          <ImageLinks styles={styles} />
        </span>
        <span style={{ display: calendlyShow ? "block" : "none" }}>
          <Calendly styles={styles} />
        </span>
        <span style={{ display: aboutShow ? "block" : "none" }}>
          <About styles={styles} />
        </span>
        <span style={{ display: youtubeCreatorShow ? "block" : "none" }}>
          <YoutubeCreator styles={styles} />
        </span>
      </div>
      <LivePreview scroll={scrollSectionID} />
    </div>
  );
};

export default Designer;
