import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PasswordProtectedContent from "../../common/passwordProtected/passwordProtectedContent/passwordProtectedContent";
import LockedOverlay from "../../common/lockedOverlay/lockedOverlay";

import {
  parseRGBA,
  buildTextClass,
  buildContactIconsClass,
  buildCTAIconColorClass,
  buildImageEditedClass,
  buildZeroBorderRadiusClass,
} from "../../helpers/stylesheetGeneration";

import { checkSchedule } from "../../helpers/utilityFunctions";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { DataLoadContext } from "../../contexts/dataLoad.context";
import { ImageGalleryContext } from "../../contexts/sectionsContexts/imageGallery.context";
import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import styles from "./imageGallery.module.scss";

import ImageGallerySkeleton from "../../skeletons/imageGallery/imageGallery.skeleton";

import defaultGalleryIcon from "../../../assets/umicardViewer/imageGalleryViewer/defaultGalleryIcon.svg";
import defaultGalleryImage from "../../../assets/umicardViewer/imageGalleryViewer/defaultGalleryItem.svg";
import defaultCTAIcon from "../../../assets/umicardViewer/imageGalleryViewer/ctaDefaultIcon.svg";

const ImageGalleryViewer = () => {
  const [dataLoad, setDataLoad] = useContext(DataLoadContext);
  const [imageGallery] = useContext(ImageGalleryContext);
  const [fontsAndColors] = useContext(FontsAndColorsContext);

  const [topSlider, setTopSlider] = useState(null);
  const [bottomSlider, setBottomSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [galleryDataState, setGalleryDataState] = useState([]);

  useEffect(() => {
    if (imageGallery.loaded) {
      setDataLoad({ ...dataLoad, imageGallery: true });
    }
  }, [imageGallery]);

  const topSliderSettings = {
    asNavFor: bottomSlider,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };
  const bottomSliderSettings = {
    asNavFor: topSlider,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  const {
    image_gallery_section_title,
    image_gallery_section_icon,
    image_gallery_section_background,
    image_link_gallery_section_background,
    image_link_font_and_icon_color,
    gallery_data,
  } = imageGallery;

  const { sections_border_radius } = fontsAndColors;

  useEffect(() => {
    // parse gallery data
    let gallery_data_parsed = gallery_data.filter((gd) => {
      if (gd.enable) {
        // Check dates first
        const { schedule } = gd;
        if (checkSchedule(schedule)) return gd;
      }
    });

    gallery_data_parsed = gallery_data_parsed.map((gd) => {
      if (gd?.password) {
        gd["show"] = false;
      } else {
        gd["show"] = true;
      }
      return gd;
    });

    if (gallery_data_parsed.length < 4)
      // make copies
      gallery_data_parsed = [
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
      ];

    setGalleryDataState(gallery_data_parsed);
  }, [imageGallery]);

  const { enable: enable_gallery_section_title, title } =
    image_gallery_section_title;

  const {
    enable: enable_gallery_section_icon,
    type: image_gallery_icon_type,
    fa_icon: image_gallery_icon_name,
    color: image_gallery_icon_color,
    preview_file: gallery_icon_existing_url,
    upload: gallery_icon_preview_url,
  } = image_gallery_section_icon;

  const imageGalleryMainIconColor = buildCTAIconColorClass(
    "image_gallery_main_cta_icon",
    image_gallery_icon_color
  );

  const {
    enable: enable_gallery_section_background,
    color: image_gallery_section_background_color,
  } = image_gallery_section_background;

  const {
    enable: enable_image_link_gallery_section_background,
    color: image_link_gallery_background_color,
  } = image_link_gallery_section_background;

  const {
    enable: enable_image_link_font_color,
    color: image_link_color,
    font: image_link_font,
    font_bold: image_link_font_bold,
    font_italic: image_link_font_italic,
    font_underline: image_link_font_underline,
    font_align_left: image_link_font_align_left,
    font_align_right: image_link_font_align_right,
    font_align_center: image_link_font_align_center,
  } = image_link_font_and_icon_color;

  // Custom Classes
  const customStyles = StyleSheet.create({
    imageGalleryBackground: {
      backgroundColor:
        enable_gallery_section_background &&
        parseRGBA(image_gallery_section_background_color.rgb),
    },
    imageLinkBackground: {
      backgroundColor:
        enable_image_link_gallery_section_background &&
        parseRGBA(image_link_gallery_background_color.rgb),
    },
  });

  const customCTAIconsBG = buildContactIconsClass(
    image_link_font_and_icon_color
  );

  const image_cta_link_custom_class = buildTextClass(
    enable_image_link_font_color,
    image_link_font,
    image_link_font_bold,
    image_link_font_italic,
    image_link_font_underline,
    image_link_font_align_left,
    image_link_font_align_right,
    image_link_font_align_center,
    image_link_color
  );

  return (
    <>
      {!dataLoad.imageGallery ? (
        <ImageGallerySkeleton />
      ) : (
        <div
          id="image-gallery-viewer"
          className={`${styles.image_gallery_viewer} gallery_preview ${css(
            customStyles.imageGalleryBackground
          )}`}
          style={{ borderRadius: !sections_border_radius && 0 }}
        >
          <div className={styles.image_gallery_header}>
            <p
              className={styles.image_gallery_heading}
              style={{ display: enable_gallery_section_title ? "" : "none" }}
            >
              {title}
            </p>
            <div
              className={`${styles.image_gallery_icon} ${imageGalleryMainIconColor}`}
              style={{ display: enable_gallery_section_icon ? "" : "none" }}
            >
              {image_gallery_icon_type === "img" && (
                <img
                  src={
                    gallery_icon_preview_url
                      ? gallery_icon_preview_url
                      : gallery_icon_existing_url
                      ? gallery_icon_existing_url
                      : defaultGalleryIcon
                  }
                  alt="Gallery Icon"
                />
              )}
              {image_gallery_icon_type === "fa" && (
                <FontAwesomeIcon icon={image_gallery_icon_name} />
              )}
            </div>
          </div>
          <p className={styles.image_gallery_tagline}>
            {galleryDataState[currentSlide]?.title ?? "Image Title"}
          </p>
          <div className={styles.sliders_container}>
            {galleryDataState.length > 0 && (
              <Slider
                {...topSliderSettings}
                ref={(slider) => setTopSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { enable, title, mainImage, password, show } =
                    gallery_item;
                  if (!enable) return;
                  return (
                    <div
                      key={index}
                      className={`${styles.gallery_slide} ${
                        !sections_border_radius && buildZeroBorderRadiusClass()
                      }`}
                    >
                      {password && (
                        <PasswordProtectedContent
                          overlayTitle={"Click to Unlock Image"}
                          popupTitle={"Unlock Locked Image"}
                          infoType={"image_gallery"}
                          password={password}
                          updateCallback={() => {
                            const copyGalleryData = [...galleryDataState];
                            galleryDataState[index]["show"] = true;
                            setGalleryDataState(copyGalleryData);
                          }}
                        >
                          {show && (
                            <img
                              className={buildImageEditedClass(
                                mainImage.adjustments
                              )}
                              src={
                                mainImage.upload
                                  ? mainImage.upload
                                  : mainImage.preview_file
                                  ? mainImage.preview_file
                                  : defaultGalleryImage
                              }
                              alt={title}
                            />
                          )}
                        </PasswordProtectedContent>
                      )}
                      {!password && (
                        <img
                          className={buildImageEditedClass(
                            mainImage.adjustments
                          )}
                          src={
                            mainImage.upload
                              ? mainImage.upload
                              : mainImage.preview_file
                              ? mainImage.preview_file
                              : defaultGalleryImage
                          }
                          alt={title}
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            )}
            <div
              className={`${styles.gallery_cta} ${css(
                customStyles.imageLinkBackground
              )}`}
              style={{ borderRadius: !sections_border_radius && 0 }}
            >
              <div
                className={`${styles.cta_icon} 
            ${
              galleryDataState[currentSlide]?.CTAIcon?.color &&
              buildCTAIconColorClass(
                `image_gallery_item_${currentSlide}`,
                galleryDataState[currentSlide].CTAIcon.color
              )
            }
            `}
              >
                {galleryDataState[currentSlide] &&
                galleryDataState[currentSlide].CTAIcon.type === "img" ? (
                  <img
                    src={
                      galleryDataState[currentSlide].CTAIcon.upload
                        ? galleryDataState[currentSlide].CTAIcon.upload
                        : galleryDataState[currentSlide].CTAIcon.preview_file
                        ? galleryDataState[currentSlide].CTAIcon.preview_file
                        : defaultCTAIcon
                    }
                    alt="CTA Icon"
                  />
                ) : (
                  galleryDataState[currentSlide] && (
                    <FontAwesomeIcon
                      icon={
                        galleryDataState[currentSlide].CTAIcon.fa_icon ?? "bell"
                      }
                    />
                  )
                )}
              </div>
              <div className={styles.cta_text_url}>
                {galleryDataState[currentSlide] && (
                  <a
                    href={"https://" + galleryDataState[currentSlide].cta_url}
                    target="_blank"
                    className={image_cta_link_custom_class}
                  >
                    {galleryDataState[currentSlide].cta_text}
                  </a>
                )}
              </div>
            </div>
            <span className={styles.sliders_divider}></span>
            {galleryDataState.length > 0 && (
              <Slider
                {...bottomSliderSettings}
                ref={(slider) => setBottomSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { enable, title, mainImage, show, password } =
                    gallery_item;
                  if (!enable) return;

                  return (
                    <div key={index} className={styles.gallery_slide_bottom}>
                      <div
                        className={styles.thumbnail}
                        style={{ borderRadius: !sections_border_radius && 0 }}
                      >
                        {show && (
                          <img
                            src={
                              mainImage.upload
                                ? mainImage.upload
                                : mainImage.preview_file
                                ? mainImage.preview_file
                                : defaultGalleryImage
                            }
                            alt={title}
                          />
                        )}
                        {!show && password && (
                          // <img src={defaultGalleryImage} alt={title} />
                          <LockedOverlay />
                        )}
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageGalleryViewer;
