import React, { useState } from "react";
import { Link } from "react-router-dom";

import { signUpService } from "./signup.service";

import AuthFlowBasicContainer from "../authFlowBasicContainer/authFlowBasicContainer";
import InputField from "../../common/inputField/inputField";
import Checkbox from "../../common/checkbox/checkbox";

import styles from "../formStyling.module.scss";

import cover from "../../../assets/authFlow/cover_signup.png";

const Signup = () => {
  const [signupError, setSignupError] = useState("");

  return (
    <AuthFlowBasicContainer
      title="Create Your UmiCard for Free "
      help="UmiCard is FREE Forever. No Payment or Credit Card is required."
      cover={cover}
    >
      <form
        autoComplete="false"
        // noValidate
        className={styles.user_form}
        onSubmit={(e) => {
          signUpService(e, setSignupError);
        }}
      >
        <InputField
          label={"Invite Code"}
          inputFieldId={"invite_code"}
          required={true}
          type={"text"}
          hideError={setSignupError}
        />
        <InputField
          label={"Email or Phone Number"}
          inputFieldId={"signup_email_phone"}
          required={true}
          type={"email"}
          hideError={setSignupError}
        />
        {/* <InputField
          label={"Umicard.com/username"}
          inputFieldId={"signup_username"}
          required={true}
          hideError={setSignupError}
        /> */}
        <InputField
          label={"Password"}
          inputFieldId={"signup_password"}
          type={"password"}
          required={true}
          hideError={setSignupError}
        />
        <Checkbox
          inputFieldId={"signup_agree_terms_conditions"}
          checkboxStyle={"signup_form"}
          required={true}
          hideError={setSignupError}
        />
        <p className={`${styles.error_message} ${signupError && styles.show}`}>
          {signupError}
        </p>
        <button type="submit">Sign Up</button>
      </form>

      <p className={styles.second_option_text}>
        Already have an account? <Link to={"/login"}>Log in</Link>
      </p>
    </AuthFlowBasicContainer>
  );
};

export default Signup;
