import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { PersonalDetailsContext } from "../contexts/designerContexts/personalDetails.context";

import { getPersonalDetailsFromDB } from "../designer/personalDetail/useDBData";

import Tab from "./tab/tab";
import TabBody from "./tabBody/tabBody";
import ProfileBody from "./tabBody/profileBody/profileBody";
import BillingBody from "./tabBody/billingBody/billdingBody";
import ReferBody from "./tabBody/referBody/referBody";

import styles from "./profile.module.scss";

import profile_svg from "../../assets/header/dropdownMenu/profile.svg";
import company_svg from "../../assets/header/dropdownMenu/company.svg";
import billing_svg from "../../assets/header/dropdownMenu/billing.svg";
import refer_svg from "../../assets/header/dropdownMenu/refer.svg";

const Profile = () => {
  const { tab } = useParams();
  const [, setPersonalDetails] = useContext(PersonalDetailsContext);
  const [profile, showProfile] = useState(true);
  const [company, showCompany] = useState(false);
  const [billing, showBilling] = useState(false);
  const [refer, showRefer] = useState(false);
  const AllTabsShow = [showProfile, showCompany, showBilling, showRefer];
  const tabsMap = {
    profile: showProfile,
    company: showCompany,
    billing: showBilling,
    refer: showRefer,
  };

  useEffect(() => {
    getPersonalDetailsFromDB(null, setPersonalDetails);
    AllTabsShow.forEach((hideShow) => {
      hideShow(false);
    });
    console.log("Open tab", tab);
    const tabToShowParam = tabsMap[tab];
    if (!tabToShowParam) {
      showProfile(true);
      return;
    }
    tabsMap[tab](true);
  }, [tab]);

  return (
    <div className={styles.profile}>
      <div className={styles.tabs}>
        <Tab
          styles={styles}
          show={profile}
          setShow={showProfile}
          allTabsShow={AllTabsShow}
          icon={profile_svg}
          text={"Profile"}
        />
        <Tab
          styles={styles}
          to={"company"}
          show={company}
          setShow={showCompany}
          allTabsShow={AllTabsShow}
          icon={company_svg}
          text={"Company"}
        />
        <Tab
          styles={styles}
          to={"billing"}
          show={billing}
          setShow={showBilling}
          allTabsShow={AllTabsShow}
          icon={billing_svg}
          text={"Billing"}
        />
        <Tab
          styles={styles}
          to={"refer"}
          show={refer}
          setShow={showRefer}
          allTabsShow={AllTabsShow}
          icon={refer_svg}
          text={"Refer a friend"}
        />
      </div>
      <TabBody styles={styles} show={profile}>
        <ProfileBody styles={styles} />
      </TabBody>
      <TabBody styles={styles} show={company}>
        <h3>Company Dashboard</h3>
      </TabBody>
      <TabBody styles={styles} show={billing}>
        <BillingBody styles={styles} />
      </TabBody>
      <TabBody styles={styles} show={refer}>
        <ReferBody styles={styles} />
      </TabBody>
    </div>
  );
};

export default Profile;
