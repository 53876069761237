import React, { useState, useEffect, createRef } from "react";
import { ReactSVG } from "react-svg";
import QRCode from "react-qr-code";
import {
  copyToClipboard,
  popupAlert,
  getMobileOperatingSystem,
} from "../../helpers/utilityFunctions";

import ShareButtons from "./shareButtons/shareButtons";

import share_svg from "../../../assets/global/icon_share.svg";
import copy_svg from "../../../assets/global/icon_copy_line.svg";
import SocialShareModal from "./socialShareModal/socialShareModal";

const ShareProfile = ({ username, styles }) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [openSocialShareModal, setOpenSocialShareModal] = useState(false);
  const [smsURL, setSMSURL] = useState("");
  const copiedRef = createRef();

  useEffect(() => {
    const OS = getMobileOperatingSystem().toLowerCase();
    if (OS === "ios")
      setSMSURL(
        `sms:;body=Checkout my umicard https://umicard.com/${username}`
      );
    else
      setSMSURL(
        `sms:?body=Checkout my umicard https://umicard.com/${username}`
      );
  }, [username]);

  return (
    <>
      <p className={styles.profile_link_heading}>My card link</p>
      <div className={`${styles.profile_link_share} vertical_align_center`}>
        <a href={`/${username}`} target="_blank">
          umicard.com/{username}
        </a>
        <span className={styles.share_profile_link}>
          <p ref={copiedRef} className={styles.copied}>
            Copied!
          </p>
          <button
            className={styles.share_btns}
            onClick={() => {
              copyToClipboard(`https://umicard.com/${username}`);
              popupAlert(copiedRef.current, "0", "-28px");
            }}
          >
            <ReactSVG src={copy_svg} wrapper={"span"} />
          </button>
          <button
            className={styles.share_btns}
            onClick={() => {
              setOpenSocialShareModal(!openSocialShareModal);
            }}
          >
            <ReactSVG src={share_svg} wrapper={"span"} />
          </button>
        </span>
      </div>
      <ShareButtons
        styles={styles}
        qrCodeShow={showQRCode}
        setQrCodeShow={setShowQRCode}
        smsURL={smsURL}
      />
      <div
        className={`${styles.qr_code} ${showQRCode && styles.show}`}
        onClick={() => {
          setShowQRCode(!showQRCode);
        }}
      >
        {username !== "username" && (
          <QRCode value={`https://umicard.com/${username}`} />
        )}
      </div>
      <SocialShareModal
        open={openSocialShareModal}
        closer={setOpenSocialShareModal}
        url={`https://umicard.com/${username}`}
        shareButtons={
          <ShareButtons
            styles={styles}
            qrCodeShow={showQRCode}
            setQrCodeShow={setShowQRCode}
            smsURL={smsURL}
          />
        }
      />
    </>
  );
};

export default ShareProfile;
