import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import ReactPinterestTag from "react-pinterest-tag";
import TiktokPixel from "tiktok-pixel";

import { SidebarOrderContext } from "../contexts/designerContexts/sectionsOrder.context";
import { CardDesignContext } from "../contexts/designerContexts/cardDesign.context";
import { PersonalDetailsContext } from "../contexts/designerContexts/personalDetails.context";
import { BrandingContext } from "../contexts/designerContexts/branding.context";
import { UsernameContext } from "../contexts/designerContexts/username.context";
import { FontsAndColorsContext } from "../contexts/designerContexts/fontsAndColors.context";
import { ImageGalleryContext } from "../contexts/sectionsContexts/imageGallery.context";
import { VideoGalleryContext } from "../contexts/sectionsContexts/videoGallery.context";
import { CalendlyContext } from "../contexts/sectionsContexts/calendly.context";
import { CRMContext } from "../contexts/settingsContexts/crm.context";
import { TrackingCodesContext } from "../contexts/settingsContexts/trackingCodes.context";
import { SocialLinksContext } from "../contexts/sectionsContexts/socialLinks.context";
import { YoutubeContext } from "../contexts/sectionsContexts/youtube.context";

import { fetchAllDataFromDB } from "./downloadUserData.service";
import { getSectionsOrderFromDB } from "../designer/sectionsOrder/useDBData";
import {
  getPersonalDetailsFromDB,
  getUsernameFromDB,
} from "../designer/personalDetail/useDBData";
import { getBrandingDetailsFromDB } from "../designer/branding/useDBData";
import { getFontsAndColorsFromDB } from "../designer/fontsAndColors/useDBData";
import { getLayoutFromDB } from "../designer/cardDesign/useDBData";
import { getImageGalleryFromDB } from "../designer/sections/imageGallery/useDBData";
import { getVideoGalleryFromDB } from "../designer/sections/videoGallery/useDBData";
import { getCalendlyFromDB } from "../designer/sections/calendly/useDBData";
import { getCRMFromDB } from "../settings/privacy/useDBData";
import { getTrackingDataFromDB } from "../settings/trackingCodes/useDBData";
import { getSocialLinksFromDB } from "../designer/sections/socialLinks/useDBData";
import { getYoutubeFromDB } from "../designer/sections/youtubeCreator/useDBData";

import PersonalDetailsViewer from "./personalDetailsViewer/personalDetailsViewer";
import ImageGalleryViewer from "./imageGalleryViewer/imageGallery";
import VideoGalleryViewer from "./videoGalleryViewer/videoGallery";
import Calendly from "./calendlyViewer/calendly";
import YoutubeCreatorViewer from "./youtubeCreatorViewer/youtube";
import StickyFooter from "./stickyFooter/stickyFooter";
import Footer from "./footer/footer";

import { parseRGBA, chooseBGImage } from "../helpers/stylesheetGeneration";

import styles from "./umicardViewer.module.scss";

// Elements map
const SectionsMap = {
  imageGallery_order: <ImageGalleryViewer />,
  videoGallery_order: <VideoGalleryViewer />,
  about_order: null,
  youtubeCreator_order: <YoutubeCreatorViewer />,
  imageLinks_order: null,
  calendly_order: <Calendly />,
};

const UmicardViewer = () => {
  const { username } = useParams();
  const [sidebarOrder, setSidebarOrder] = useContext(SidebarOrderContext);
  const [, setCardDesign] = useContext(CardDesignContext);
  const [, setUsername] = useContext(UsernameContext);
  const [personalDetails, setPersonalDetails] = useContext(
    PersonalDetailsContext
  );
  const [brandingDetails, setBrandingDetails] = useContext(BrandingContext);
  const [, setFontsAndColors] = useContext(FontsAndColorsContext);
  const [imageGallery, setImageGallery] = useContext(ImageGalleryContext);
  const [videoGallery, setVideoGallery] = useContext(VideoGalleryContext);
  const [calendly, setCalendly] = useContext(CalendlyContext);
  const [, setCrm] = useContext(CRMContext);
  const [trackingCodes, setTrackingCodes] = useContext(TrackingCodesContext);
  const [socialLinks, setSocialLinks] = useContext(SocialLinksContext);
  const [youtube, setYoutube] = useContext(YoutubeContext);

  const { google, facebook, linkedIn, pinterest, tiktok, seo } = trackingCodes;

  const { background, background_overlay } = brandingDetails;

  const {
    enable: enable_background_color,
    use_background_color,
    branding_background_color,
    use_background_image,
    background_image_preview_file,
    background_image_upload,
  } = background;

  const { enable: enable_background_overlay, branding_background_overlay } =
    background_overlay;

  // Create stylesheet
  const customStyles = StyleSheet.create({
    brandBackground: {
      backgroundColor:
        enable_background_color &&
        use_background_color &&
        parseRGBA(branding_background_color.rgb),

      backgroundImage:
        enable_background_color &&
        use_background_image &&
        chooseBGImage(
          background_image_upload,
          background_image_preview_file?.url
        ),
    },

    brandBackgroundOverlay: {
      backgroundColor:
        enable_background_overlay && parseRGBA(branding_background_overlay.rgb),
    },
  });

  useEffect(() => {
    fetchAllDataFromDB((data) => {
      getSectionsOrderFromDB(setSidebarOrder, username, data);
      getLayoutFromDB(setCardDesign, username, data);
      getPersonalDetailsFromDB(
        personalDetails,
        setPersonalDetails,
        username,
        data
      );
      getBrandingDetailsFromDB(setBrandingDetails, username, data);
      getUsernameFromDB(setUsername, username, data);
      getFontsAndColorsFromDB(setFontsAndColors, username, data);
      getImageGalleryFromDB(imageGallery, setImageGallery, username, data);
      getVideoGalleryFromDB(videoGallery, setVideoGallery, username, data);
      getCalendlyFromDB(calendly, setCalendly, username, data);
      getCRMFromDB(setCrm, username, data);
      getTrackingDataFromDB(setTrackingCodes, username, data);
      getSocialLinksFromDB(socialLinks, setSocialLinks, username, data);
      getYoutubeFromDB(youtube, setYoutube, username, data);
    }, username);
  }, [username]);

  useEffect(() => {
    if (seo) {
      if (seo?.metaTitle) document.title = seo.metaTitle;

      if (seo?.metaDescription)
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", seo.metaDescription);

      // document
      //   .querySelector('meta[name="keywords"]')
      //   .setAttribute("content", seo.keywords);
    }
  }, [seo]);

  useEffect(() => {
    if (google?.analyticsId) {
      ReactGA.initialize(google.analyticsId);
      ReactGA.send("pageview");
    }

    if (google?.tagManagerId) {
      TagManager.initialize({ gtmId: google.tagManagerId });
    }
  }, [google]);

  useEffect(() => {
    if (facebook?.pixelId) {
      ReactPixel.init(facebook.pixelId);
      ReactPixel.pageView();
    }
  }, [facebook]);

  useEffect(() => {
    if (linkedIn?.insightTagId) {
      LinkedInTag.init(linkedIn.insightTagId);
    }
  }, [linkedIn]);

  useEffect(() => {
    if (pinterest?.tagId) {
      ReactPinterestTag.init(pinterest.tagId);
      ReactPinterestTag.pageView();
    }
  }, [pinterest]);

  useEffect(() => {
    if (tiktok?.pixelId) {
      TiktokPixel.init(tiktok.pixelId);
      TiktokPixel.pageView();
    }
  }, [tiktok]);

  return (
    <div
      className={`${styles.umicard_viewer} ${styles.layout_1} ${css(
        customStyles.brandBackground
      )}`}
    >
      <div className={styles.main_content}>
        <div
          className={`${styles.branding_background_overlay} ${css(
            customStyles.brandBackgroundOverlay
          )}`}
          style={{ position: username ? "fixed" : "absolute" }}
        ></div>
        <PersonalDetailsViewer />
        {sidebarOrder.order.map(({ title, enable }) => {
          if (!enable || !SectionsMap[title]) return;
          return (
            <React.Fragment key={title}>{SectionsMap[title]}</React.Fragment>
          );
        })}

        <Footer />
        {/* <div style={{ height: "1000px" }}></div> */}
        {username && <StickyFooter />}
      </div>
    </div>
  );
};

export default UmicardViewer;
