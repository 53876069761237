import React from "react";
import { ReactSVG } from "react-svg";
import styles from "./lifetime.module.scss";
import cross_svg from "../../../assets/global/icon_cross.svg";
import download from "../../../assets/global/icon_download.svg";
import AnalyticsChart from "../../common/charts/chart";

const AnalyticsBarChart = () => {
  const data = [
    [
      "Month",
      "Bolivia",
      "Ecuador",
      "Madagascar",
      "Papua New Guinea",
      "Rwanda",
      "Average",
    ],
    ["2004/05", 165, 938, 522, 998, 450, 614.6],
    ["2005/06", 135, 1120, 599, 1268, 288, 682],
    ["2006/07", 157, 1167, 587, 807, 397, 623],
    ["2007/08", 139, 1110, 615, 968, 215, 609.4],
    ["2008/09", 136, 691, 629, 1026, 366, 569.6],
  ];

  const options = {
    seriesType: "bars",
    series: { 5: { type: "line" } },
    colors: ["#81F2F9", "#1ba8f0", "#75D89D", "#FCC97B", "#E97D85", "#FF81DC"],
    backgroundColor: "transparent",
    legend: "none",
  };

  return (
    <div className={styles.analytic_bar}>
      <div className={styles.bar_top_section}>
        <p className={styles.date_section}>1/01/2022 - 4/05/2022</p>
        <button
          className={`${styles.floating_save_button} ${styles.show} vertical_align_center`}
          onClick={() => 1}
        >
          Download <ReactSVG src={download} wrapper={"span"} />
        </button>
      </div>
      <AnalyticsChart chartType="ComboChart" data={data} options={options} />
      <div className={styles.content_section}>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.views}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Views</p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.clicks}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Clicks</p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.unique}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Unique Clicks</p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.ctr}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Avg CTR</p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.time}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Avg Time to Click</p>
          </div>
        </div>
        <div className={styles.single_item}>
          <div className={`${styles.title_section} ${styles.earning}`}>
            <ReactSVG src={cross_svg} wrapper={"span"} />{" "}
            <p className={styles.title}>Earning</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsBarChart;
