import React, { useState, createContext } from "react";

export const CardDesignContext = createContext();

export const CardDesignProvider = ({ children }) => {
  const [cardDesign, setCardDesign] = useState("layout_1");
  // Options: layout_1 - 4

  return (
    <CardDesignContext.Provider value={[cardDesign, setCardDesign]}>
      {children}
    </CardDesignContext.Provider>
  );
};
