export const buildLoader = () => {
  const loader = document.createElement("div");
  loader.className = "loader";
  loader.innerHTML = `<span class="loader_icon"></span>`;
  document.body.appendChild(loader);
  return loader;
};

export const destroyLoader = (loader) => {
  loader.classList.add("destroy");
  setTimeout(() => {
    loader.remove();
  }, 150);
};
