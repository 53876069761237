export const update_google_analytics_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.google.analyticsId = value;
  setTracking(trackingCopy);
};

export const update_google_tag_manager_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.google.tagManagerId = value;
  setTracking(trackingCopy);
};

export const update_facebook_pixel_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.facebook.pixelId = value;
  setTracking(trackingCopy);
};

export const update_facebook_conversions_api_token = (
  value,
  tracking,
  setTracking
) => {
  const trackingCopy = { ...tracking };
  trackingCopy.facebook.conversionsAPIToken = value;
  setTracking(trackingCopy);
};

export const update_linkedin_insight_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.linkedIn.insightTagId = value;
  setTracking(trackingCopy);
};

export const update_pinterest_tag_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.pinterest.tagId = value;
  setTracking(trackingCopy);
};

export const update_tiktok_pixel_id = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.tiktok.pixelId = value;
  setTracking(trackingCopy);
};

export const update_seo_meta_title = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.seo.metaTitle = value;
  setTracking(trackingCopy);
};

export const update_seo_meta_description = (value, tracking, setTracking) => {
  const trackingCopy = { ...tracking };
  trackingCopy.seo.metaDescription = value;
  setTracking(trackingCopy);
};
