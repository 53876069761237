import React, { useState, useEffect } from "react";

import styles from "./switch.module.scss";

const Switch = ({
  inputFieldId,
  defaultValue,
  sendIDInUpdate = false,
  callback = null,
  context = null,
  updateContext = null,
  uploadData = null,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        name={inputFieldId}
        onChange={(e) => {
          const updatedValue = !value;
          setValue(updatedValue);
          if (!context || !updateContext) return;
          if (callback && sendIDInUpdate)
            callback(updatedValue, inputFieldId, context, updateContext);
          if (callback && !sendIDInUpdate)
            callback(updatedValue, context, updateContext);
          if (uploadData && context) {
            uploadData(context);
          }
        }}
        checked={value}
      />
      <span className={`${styles.slider} ${styles.round}`}></span>
    </label>
  );
};

export default Switch;
