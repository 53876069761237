import React, { useContext } from "react";

import { ImageGalleryContext } from "../../../contexts/sectionsContexts/imageGallery.context";

import { uploadImageGallery } from "./uploadFunction";

import {
  update_enable_image_gallery_section_title,
  update_image_gallery_section_title,
  update_enable_image_gallery_section_icon,
  select_main_icon_type_image_gallery,
  update_image_gallery_main_fa_icon_color,
  update_main_fa_icon_name_image_gallery,
  update_enable_image_gallery_section_background,
  update_image_gallery_section_background_color,
  update_enable_image_link_gallery_section_background,
  update_image_link_gallery_section_background_color,
  update_enable_image_link_font_and_icon_color,
  update_image_link_font_and_icon_color_color,
  image_link_font_and_icon_color_font,
  update_image_link_font_and_icon_color_font_styles,
  update_gallery_icon,
  addGalleryItem,
  update_order_image_gallery,
} from "./updateFunctions";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../../common/inputField/inputField";
import CTAIconSelector from "../../../common/ctaIconSelector/ctaIconSelector";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import FontPicker from "../../../common/fontPicker/fontPicker";
import ImageGalleryEditableItem from "../../../common/imageVideoGalleryEditableItem/imageGalleryEditableItem";
import AddMoreEdirableItemButton from "../../../common/addMoreEditableItemButton/addMoreEdirableItemButton";

import defaultGalleryImage from "../../../../assets/umicardViewer/imageGalleryViewer/defaultGalleryItem.svg";

const ImageGallery = ({ styles }) => {
  const [imageGallery, setImageGallery] = useContext(ImageGalleryContext);

  const {
    image_gallery_section_title,
    image_gallery_section_icon,
    image_gallery_section_background,
    image_link_gallery_section_background,
    image_link_font_and_icon_color,
    gallery_data,
  } = imageGallery;

  const { enable: enable_gallery_section_title, title } =
    image_gallery_section_title;

  const { enable: enable_gallery_section_icon } = image_gallery_section_icon;

  const {
    enable: enable_gallery_section_background,
    color: image_gallery_section_background_color,
  } = image_gallery_section_background;

  const {
    enable: enable_image_link_gallery_section_background,
    color: image_link_gallery_background_color,
  } = image_link_gallery_section_background;

  const {
    enable: enable_image_link_font_color,
    color: image_link_color,
    font: image_link_font,
    font_bold: image_link_font_bold,
    font_italic: image_link_font_italic,
    font_underline: image_link_font_underline,
    font_align_left: image_link_font_align_left,
    font_align_right: image_link_font_align_right,
    font_align_center: image_link_font_align_center,
  } = image_link_font_and_icon_color;

  return (
    <div className="image_gallery">
      <SectionTopBar
        title={"Image Gallery"}
        id={"image-gallery-section-designer"}
        updateCallback={uploadImageGallery}
        updateData={imageGallery}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Sections Title"
          inputId="enable_image_gallery_section_title"
          enabled={enable_gallery_section_title}
          callback={update_enable_image_gallery_section_title}
          context={imageGallery}
          updateContext={setImageGallery}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"image_gallery_section_title"}
              label={"Image Gallery Section Title"}
              extraClass={"no_label"}
              defaultInputValue={title}
              callback={update_image_gallery_section_title}
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Icon"
          inputId="enable_image_gallery_section_icon"
          enabled={enable_gallery_section_icon}
          callback={update_enable_image_gallery_section_icon}
          context={imageGallery}
          updateContext={setImageGallery}
        >
          <div className={styles.editable_section}>
            <p className={styles.section_info_text}>Change icon</p>
            <CTAIconSelector
              inputFieldId={"image_gallery_main_icon"}
              selected={image_gallery_section_icon?.type}
              selectedIcon={image_gallery_section_icon?.fa_icon}
              iconColor={image_gallery_section_icon?.color}
              selectorCallback={select_main_icon_type_image_gallery}
              imageCallback={update_gallery_icon}
              iconSelectorCallback={update_main_fa_icon_name_image_gallery}
              iconColorCallback={update_image_gallery_main_fa_icon_color}
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Background"
          inputId="enable_image_gallery_section_background"
          enabled={enable_gallery_section_background}
          callback={update_enable_image_gallery_section_background}
          context={imageGallery}
          updateContext={setImageGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="image_gallery_section_background_color"
              savedColor={image_gallery_section_background_color}
              callback={update_image_gallery_section_background_color}
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Image Link - Background Color"
          inputId="enable_image_link_gallery_section_background"
          enabled={enable_image_link_gallery_section_background}
          callback={update_enable_image_link_gallery_section_background}
          context={imageGallery}
          updateContext={setImageGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="image_link_gallery_section_background_color"
              savedColor={image_link_gallery_background_color}
              callback={update_image_link_gallery_section_background_color}
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Image Link – Font &amp; Color Settings"
          inputId="enable_image_link_font_and_icon_color_gallery_section"
          enabled={enable_image_link_font_color}
          callback={update_enable_image_link_font_and_icon_color}
          context={imageGallery}
          updateContext={setImageGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="image_link_font_and_icon_color_gallery_section_color"
              savedColor={image_link_color}
              callback={update_image_link_font_and_icon_color_color}
              context={imageGallery}
              updateContext={setImageGallery}
            />
            <FontPicker
              inputFieldId="image_link_font_and_icon_color_gallery_section_font"
              defaultInputValue={image_link_font}
              savedFontStyles={{
                bold: image_link_font_bold,
                italic: image_link_font_italic,
                underline: image_link_font_underline,
                left_align: image_link_font_align_left,
                right_align: image_link_font_align_right,
                center_align: image_link_font_align_center,
              }}
              callback_font_name={image_link_font_and_icon_color_font}
              callback_font_styles={
                update_image_link_font_and_icon_color_font_styles
              }
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>Re-Order Gallery Items</h4>
          <p className={styles.section_info_text}>
            Drag and Drop gallery images to re-order them in carousel
          </p>
          <div className={styles.arrange_gallery_items}>
            {gallery_data.map((image, index) => {
              return (
                <div
                  key={index}
                  className={styles.arrange_item_div}
                  draggable
                  onDragStart={(e) => {
                    e.stopPropagation();
                    e.dataTransfer.setData("index", index);
                  }}
                  onDragEnter={(e) => {
                    e.stopPropagation();
                    e.target.style.opacity = 0.5;
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    e.target.style.opacity = 1;
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.target.style.opacity = 1;
                  }}
                  onDrop={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const dropperID = e.dataTransfer.getData("index");
                    update_order_image_gallery(
                      dropperID,
                      index,
                      imageGallery,
                      setImageGallery
                    );
                  }}
                >
                  <img
                    src={
                      image.mainImage.upload
                        ? image.mainImage.upload
                        : image.mainImage.preview_file
                        ? image.mainImage.preview_file
                        : defaultGalleryImage
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {gallery_data.map((image, index) => {
        return (
          <div
            key={index}
            className={`${styles.designer_white_box} ${styles.details_editor}`}
          >
            <ImageGalleryEditableItem
              styles={styles}
              title={`Image ${index + 1}`}
              inputId={"image_gallery_image_" + index}
              index={index}
              context={imageGallery}
              updateContext={setImageGallery}
            />
          </div>
        );
      })}

      <AddMoreEdirableItemButton
        title={"Add More Images"}
        callback={addGalleryItem}
        context={imageGallery}
        updateContext={setImageGallery}
      />
    </div>
  );
};

export default ImageGallery;
