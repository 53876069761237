import React from "react";

import AuthFlowBasicContainer from "../authFlowBasicContainer/authFlowBasicContainer";
import FeatureList from "./featureList/featureList";

import styles from "./pricing.module.scss";

import cover from "../../../assets/authFlow/cover_pricing.png";

const Pricing = () => {
  const goToDesigner = (plan = "") => {
    if (!plan) window.location.href = "/designer";
    if (plan === "pro")
      window.location.href = "https://buy.stripe.com/test_9AQ3e2aH0d6UapG3cc";
    if (plan === "platinum")
      window.location.href = "https://buy.stripe.com/test_fZe8ym4iCeaYbtKcMN";
  };

  return (
    <AuthFlowBasicContainer
      title="Pick Your Plan"
      help="You can change your plan later"
      cover={cover}
      wide={true}
    >
      <div className={styles.pricing_tables}>
        <div className={styles.pricing_table}>
          <h3 className={styles.main_heading}>Free $0</h3>
          <p className={styles.info_text}>Free Forever</p>
          <p className={styles.plan_description_text}>
            Here we add the three or four line short description of the plan, so
            the users now what they'll get with this plan.
          </p>
          <h4 className={styles.small_heading}>Free Plan Includes:</h4>
          <div className={styles.features_list}>
            <FeatureList styles={styles} limit={5} />
          </div>
          <button
            className={styles.get_started_btn}
            onClick={() => {
              goToDesigner();
            }}
          >
            Get Started
          </button>
        </div>
        <div className={`${styles.pricing_table} ${styles.best}`}>
          <h3 className={styles.main_heading}>Pro $7</h3>
          <p className={styles.info_text}>USD per month</p>
          <p className={styles.plan_description_text}>
            Here we add the three or four line short description of the plan, so
            the users now what they'll get with this plan.
          </p>
          <h4 className={styles.small_heading}>Pro Plan Includes:</h4>
          <div className={styles.features_list}>
            <FeatureList styles={styles} limit={7} />
          </div>
          <button
            className={styles.get_started_btn}
            onClick={() => {
              goToDesigner("pro");
            }}
          >
            Get Started
          </button>
        </div>
        <div className={styles.pricing_table}>
          <h3 className={styles.main_heading}>Platinum $10</h3>
          <p className={styles.info_text}>USD per month</p>
          <p className={styles.plan_description_text}>
            Here we add the three or four line short description of the plan, so
            the users now what they'll get with this plan.
          </p>
          <h4 className={styles.small_heading}>Platinum Plan Includes:</h4>
          <div className={styles.features_list}>
            <FeatureList styles={styles} limit={99} />
          </div>
          <button
            className={styles.get_started_btn}
            onClick={() => {
              goToDesigner("platinum");
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    </AuthFlowBasicContainer>
  );
};

export default Pricing;
