import React, { useState, useEffect, createRef, useContext } from "react";

import { UsernameContext } from "../contexts/designerContexts/username.context";

import ShareProfile from "./shareProfile/shareProfile";
import UmicardViewer from "../umicardViewer/umicardViewer";
import StickyFooter from "../umicardViewer/stickyFooter/stickyFooter";

import styles from "./livePreview.module.scss";

import signal_info_svg from "../../assets/previewer/signal_info.svg";

const LivePreview = ({ scroll, children }) => {
  const [username] = useContext(UsernameContext);
  const [openMobilePreview, setOpenMobilePreview] = useState(false);

  const livePreviewRef = createRef();
  const wrapperRef = createRef();

  useEffect(() => {
    const screenWidth = window?.innerWidth;
    if (!screenWidth) return;
    if (!livePreviewRef?.current) return;
    const livePreviewDOMObj = livePreviewRef.current;
    if (screenWidth > 767) {
      // Make previewer sticky
      livePreviewDOMObj.style.position = "sticky";
      livePreviewDOMObj.style.top = `${
        livePreviewDOMObj.offsetTop < 150 ? livePreviewDOMObj.offsetTop : 110
      }px`;
      livePreviewDOMObj.style.right = "0px";
    } else {
      // Make it normal
      livePreviewDOMObj.style.position = "";
      livePreviewDOMObj.style.top = "";
      livePreviewDOMObj.style.right = "";
    }
  });

  useEffect(() => {
    const elementToScrollTo = document.getElementById(scroll);
    if (!elementToScrollTo) return;
    const scrollAmount = elementToScrollTo.offsetTop;
    wrapperRef.current.scrollTo(0, scrollAmount - 50);
  }, [scroll]);

  return (
    <div
      className={`${styles.live_preview} ${
        openMobilePreview ? styles.open : ""
      }`}
    >
      <button
        className={styles.mobile_preview_button_float}
        onClick={() => setOpenMobilePreview(!openMobilePreview)}
      >
        {openMobilePreview ? "Close Preview" : "Preview"}
      </button>
      <div className={`${styles.live_preview_inner}`} ref={livePreviewRef}>
        <ShareProfile styles={styles} username={username} />
        <div className={styles.live_preview_phone}>
          <div className={styles.phone_top_bar}>
            <div className={styles.time_block}>9:41</div>
            <div className={styles.notch}></div>
            {/* <ReactSVG src={iphone_notch_svg} wrapper={"span"} /> */}
            <div className={styles.time_info_block}>
              <img src={signal_info_svg} alt="Signal Info Phone" />
            </div>
          </div>
          <div ref={wrapperRef} className={styles.preview_wrapper}>
            <UmicardViewer />
            {children}
          </div>
          <StickyFooter preview={true} />
        </div>
      </div>
    </div>
  );
};

export default LivePreview;
