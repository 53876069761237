import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

const SocialLinksSkeleton = () => {
  return (
    <div className={styles.social_links_skeleton}>
      <Skeleton
        width={"100%"}
        height={110}
        className={styles.social_container}
      />
      <div className={styles.icons_flex}>
        <Skeleton
          className={styles.social_icon}
          containerClassName={styles.icon}
        />
        <Skeleton
          className={styles.social_icon}
          containerClassName={styles.icon}
        />
        <Skeleton
          className={styles.social_icon}
          containerClassName={styles.icon}
        />
        <Skeleton
          className={styles.social_icon}
          containerClassName={styles.icon}
        />
      </div>
    </div>
  );
};

export default SocialLinksSkeleton;
