import Cookies from "js-cookie";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
import { buildNotification } from "../../notification/buildNotification";
import { buildLoader, destroyLoader } from "../../notification/buildLoader";

import { API_BASE } from "../../../api_links";

export const checkLoginWithExistingCookie = (callback) => {
  const loader = buildLoader();
  const authToken = checkLoginCookie();
  if (!authToken) {
    destroyLoader(loader);
    return;
  }

  const AUTH_ME_ENDPOINT = API_BASE + ":LiqXQBP3/auth/me";

  fetch(AUTH_ME_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
      } else {
        // Success
        callback(data);
      }
    });
};

export const loginWithEmailAndPassword = (e, set_signIn_U_PW_error) => {
  e.preventDefault();

  const email = e.target.email.value;
  const password = e.target.password.value;

  if (!email || !password) {
    buildNotification({
      type: "warning",
      message: "You must enter all fields first",
    });
    set_signIn_U_PW_error("You must enter all fields first");
    return;
  }

  const SIGN_IN_EMAIL_PASSWORD_ENDPOINT = API_BASE + ":LiqXQBP3/auth/login";

  const submitData = { email, password };

  console.log(submitData);

  const loader = buildLoader();

  fetch(SIGN_IN_EMAIL_PASSWORD_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(submitData),
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
        buildNotification({
          type: "error",
          message: "Wrong email or password",
        });
        set_signIn_U_PW_error(
          "Wrong email or password. You may use the reset password option"
        );
      } else {
        // Success
        console.log(data);
        buildNotification({
          type: "success",
          message: "Logged in!",
        });
        set_signIn_U_PW_error("");
        if (data?.authToken) {
          Cookies.set("authToken", data.authToken, { expires: 1 });
          window.location.href = "/login";
        }
      }
    });
};

export const getMagicLinkViaEmail = (
  e,
  set_signIn_magic_link_error,
  set_signIn_magic_link_info
) => {
  e.preventDefault();

  const email = e.target.magic_link_email.value;
  if (!email) {
    buildNotification({
      type: "warning",
      message: "You must enter email first",
    });
    set_signIn_magic_link_error("You must enter email first");
    return;
  }

  const REQUEST_MAGIC_LINK_API_ENDPOINT =
    API_BASE + ":x4ocgg3f/auth/magic-link";

  fetch(REQUEST_MAGIC_LINK_API_ENDPOINT + `?email=${email}`)
    .then((response) => response.json())
    .then((data) => {
      if (data?.message) {
        console.log(data);
        if (data.message?.success) {
          buildNotification({
            type: "success",
            message: "Magic link sent! Please check your email inbox",
          });
          set_signIn_magic_link_info(
            "Magic link sent! Please check your email inbox"
          );
        } else {
          buildNotification({
            type: "error",
            message: data.message ?? "Magic link not sent",
          });
          set_signIn_magic_link_error(
            "Magic link not sent. You must create an account first"
          );
        }
      }
    });
};

export const loginWithMagicToken = (
  token,
  showThisForm,
  show_magic_link_form,
  set_signIn_magic_link_error
) => {
  const MAGIC_LOGIN_ENDPOINT = API_BASE + ":x4ocgg3f/auth/magic-login";

  if (!token) return;

  const loader = buildLoader();

  fetch(MAGIC_LOGIN_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ magic_token: token }),
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
        showThisForm(show_magic_link_form);
        set_signIn_magic_link_error(
          "Your Magic Link is expired. Please request a new one"
        );
      } else {
        console.log("Magic link login result", data);
        Cookies.set("authToken", data, { expires: 1 });
        window.location.href = "/login";
      }
    })
    .catch(() => {
      console.log("Magic catch error");
      showThisForm(show_magic_link_form);
      set_signIn_magic_link_error(
        "Your Magic Link is expired. Please requrest a new one"
      );
      window.location.href = "/login";
    });
};

export const forgotPassword = (
  email,
  set_forgot_password_error,
  set_forgot_password_info,
  callback
) => {
  console.log("Forgot password for ", email);

  const GET_FORGOT_PASSWORD_CODE = API_BASE + ":LiqXQBP3/forgotpassword";

  fetch(GET_FORGOT_PASSWORD_CODE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.message) {
        console.log(data.message);
        set_forgot_password_error(data.message);
      } else {
        // Success
        buildNotification({
          type: "success",
          message: "Reset password email sent to you! Please check inbox",
        });
        set_forgot_password_info(
          "Reset password email sent to you! Please check inbox"
        );
        console.log("Reset Code sent");
        callback();
      }
    });
};

export const resetPassword = (
  e,
  set_reset_password_error,
  set_reset_password_info
) => {
  e.preventDefault();

  const code = e.target.forgot_password_code.value;
  const password_one = e.target.forgot_password_one.value;
  const password_two = e.target.forgot_password_two.value;

  if (!code || !password_one || !password_two) {
    buildNotification({
      type: "warning",
      message: "You must enter all fields first",
    });
    set_reset_password_error("You must enter all fields first");
    return;
  }

  if (password_one !== password_two) {
    console.log("Passowrds don't match");
    buildNotification({
      type: "error",
      message: "Your passwords don't match",
    });
    set_reset_password_error("Your passwords don't match");
    return;
  }

  const RESET_PASSWORD_URL = API_BASE + ":LiqXQBP3/resetpassword";

  const loader = buildLoader();

  fetch(RESET_PASSWORD_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, password_one, password_two }),
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
        buildNotification({
          type: "error",
          message: data.message,
        });
        set_reset_password_error(data?.message ?? "Invalid Reset Code");
      } else {
        // Success
        buildNotification({
          type: "success",
          message: "Password Rest! Login again with new password",
        });
        set_reset_password_info("Password Rest! Login again with new password");
        console.log("Password reset!");
        window.location.reload();
      }
    });
};
