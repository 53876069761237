import React, { useContext } from "react";

import { BrandingContext } from "../../contexts/designerContexts/branding.context";

import {
  update_enable_background_color,
  update_use_background_color,
  update_branding_background_color,
  update_use_background_image,
  update_branding_background_image,
  remove_uploaded_background_image,
  update_enable_background_overlay,
  update_branding_background_overlay_color,
  update_enable_business_brand_name,
  update_brand_name,
  update_brand_name_font,
  update_brand_name_font_styles,
  update_brand_name_color,
  update_enable_business_brand_slogan,
  update_brand_slogan,
  update_brand_slogan_font,
  update_brand_slogan_font_styles,
  update_brand_slogan_color,
  update_enable_business_brand_logo,
  update_business_brand_logo,
  remove_uploaded_brand_logo,
  update_brand_logo_size,
} from "./updateFunctions";

import { uploadBrandingDetails } from "./uploadFunction";

import CollapsibleContainer from "../../common/collapsibleContainer/collapsibleContainer";
import ColorPicker from "../../common/colorPicker/colorPicker";
import InputField from "../../common/inputField/inputField";
import Checkbox from "../../common/checkbox/checkbox";
import FontPicker from "../../common/fontPicker/fontPicker";
import ImageUploader from "../../common/imageUploader/imageUploader";
import ImageEditor from "../../common/imageEditor/imageEditor";
import SectionTopBar from "../../common/sectionTopBar/sectionTopBar";

const Branding = ({ styles }) => {
  const [brandingDetails, setBrandingDetails] = useContext(BrandingContext);

  if (!brandingDetails) return;

  const {
    background,
    background_overlay,
    business_brand_name,
    business_brand_slogan,
    business_brand_logo,
    brand_logo_adjustments,
  } = brandingDetails;

  const {
    enable: enable_background_color,
    background_image_preview_file,
    use_background_color,
    branding_background_color,
    use_background_image,
  } = background;

  const { enable: enable_background_overlay, branding_background_overlay } =
    background_overlay;

  const {
    enable: enable_brand_business_name,
    brand_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color,
  } = business_brand_name;

  const {
    enable: enable_business_brand_slogan,
    brand_slogan,
    brand_slogan_font,
    brand_slogan_font_bold,
    brand_slogan_font_italic,
    brand_slogan_font_underline,
    brand_slogan_font_align_left,
    brand_slogan_font_align_right,
    brand_slogan_font_align_center,
    brand_slogan_color,
  } = business_brand_slogan;

  const {
    enable: enable_business_brand_logo,
    business_brand_logo_preview_file,
  } = business_brand_logo;

  return (
    <div className="branding">
      <SectionTopBar
        title={"Branding"}
        id={"branding-section-designer"}
        updateCallback={uploadBrandingDetails}
        updateData={brandingDetails}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Background"
          inputId="enable_background_color"
          enabled={enable_background_color}
          callback={update_enable_background_color}
          context={brandingDetails}
          updateContext={setBrandingDetails}
        >
          <div className={styles.editable_section}>
            <Checkbox
              inputFieldId={"use_background_color"}
              label={"Use background color"}
              defaultValue={use_background_color}
              callback={update_use_background_color}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
            <ColorPicker
              inputFieldId="branding_background_color"
              savedColor={branding_background_color}
              callback={update_branding_background_color}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
          <div className={styles.editable_section}>
            <Checkbox
              inputFieldId={"use_background_image"}
              label={"Use background image"}
              defaultValue={use_background_image}
              callback={update_use_background_image}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            >
              <span className={styles.label_sub_info}>
                The image will overlap the solid background
              </span>
            </Checkbox>
            <ImageUploader
              inputFieldId={"branding_background_image"}
              callback={update_branding_background_image}
              uploadedPicture={background_image_preview_file?.url}
              removeUploadedPicture={remove_uploaded_background_image}
              afterUpdateCallback={uploadBrandingDetails}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Background overlay"
          inputId="enable_background_overlay"
          enabled={enable_background_overlay}
          callback={update_enable_background_overlay}
          context={brandingDetails}
          updateContext={setBrandingDetails}
        >
          <div className={styles.editable_section}>
            <h4 className={styles.section_heading}>Overlay color</h4>
            <ColorPicker
              inputFieldId="branding_background_overlay"
              savedColor={branding_background_overlay}
              callback={update_branding_background_overlay_color}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Business / brand name"
          inputId="enable_business_brand_name"
          enabled={enable_brand_business_name}
          callback={update_enable_business_brand_name}
          context={brandingDetails}
          updateContext={setBrandingDetails}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"brand_name"}
              label={"Brand name"}
              defaultInputValue={brand_name}
              callback={update_brand_name}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
            <FontPicker
              inputFieldId={"brand_name_font"}
              defaultInputValue={brand_name_font}
              savedFontStyles={{
                bold: brand_name_font_bold,
                italic: brand_name_font_italic,
                underline: brand_name_font_underline,
                left_align: brand_name_font_align_left,
                right_align: brand_name_font_align_right,
                center_align: brand_name_font_align_center,
              }}
              callback_font_name={update_brand_name_font}
              callback_font_styles={update_brand_name_font_styles}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
          <div className={styles.editable_section}>
            <h4 className={styles.section_heading}>Brand name color</h4>
            <ColorPicker
              inputFieldId="brand_name_color"
              savedColor={brand_name_color}
              callback={update_brand_name_color}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Business / brand slogan"
          inputId="enable_business_brand_slogan"
          enabled={enable_business_brand_slogan}
          callback={update_enable_business_brand_slogan}
          context={brandingDetails}
          updateContext={setBrandingDetails}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"brand_slogan"}
              label={"Brand slogan"}
              defaultInputValue={brand_slogan}
              callback={update_brand_slogan}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
            <FontPicker
              inputFieldId={"brand_slogan_font"}
              defaultInputValue={brand_slogan_font}
              savedFontStyles={{
                bold: brand_slogan_font_bold,
                italic: brand_slogan_font_italic,
                underline: brand_slogan_font_underline,
                left_align: brand_slogan_font_align_left,
                right_align: brand_slogan_font_align_right,
                center_align: brand_slogan_font_align_center,
              }}
              callback_font_name={update_brand_slogan_font}
              callback_font_styles={update_brand_slogan_font_styles}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
          <div className={styles.editable_section}>
            <h4 className={styles.section_heading}>Brand slogan color</h4>
            <ColorPicker
              inputFieldId="brand_slogan_color"
              savedColor={brand_slogan_color}
              callback={update_brand_slogan_color}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Business / brand logo"
          inputId="enable_business_brand_logo"
          enabled={enable_business_brand_logo}
          callback={update_enable_business_brand_logo}
          context={brandingDetails}
          updateContext={setBrandingDetails}
        >
          <div className={styles.editable_section}>
            <h4 className={styles.section_heading}>Pick the logo file</h4>
            <p className={styles.section_info_text}>
              Logo will be shown on loading screen, messages, and menus. The
              image must have a minimum of 512 pixels wide
            </p>
            <ImageUploader
              inputFieldId={"branding_logo_image"}
              callback={update_business_brand_logo}
              uploadedPicture={business_brand_logo_preview_file?.url}
              removeUploadedPicture={remove_uploaded_brand_logo}
              afterUpdateCallback={uploadBrandingDetails}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
          <div className={styles.editable_section}>
            <h4 className={styles.section_heading}>
              Change display settings of Brand Logo
            </h4>
            <ImageEditor
              inputFieldId={"brand_logo_image_upload"}
              defaultValues={brand_logo_adjustments}
              sizeCallback={update_brand_logo_size}
              sizeOnly={true}
              sizeMin={10}
              sizeMax={100}
              context={brandingDetails}
              updateContext={setBrandingDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
};

export default Branding;
