import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

import styles from "./colorPicker.module.scss";
import colorPickerEllipse from "../../../assets/global/color_picker/color_picker_ellipse.png";

const ColorPicker = ({
  inputFieldId,
  savedColor = null,
  callback = null,
  context = null,
  updateContext = null,
  index = null,
}) => {
  const [showColoPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState({
    hex: "#ffffff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  });

  const changeColor = (color, event) => {
    setCurrentColor(color);

    update_color_in_context({ hex: color.hex, rgb: color.rgb });
  };

  const parseRGBA = (rgba) => {
    const { r, g, b, a } = rgba;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  const updateHexCode = (e) => {
    const hex2rgba = (hex) => {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      const rgba = {
        r: r,
        g: g,
        b: b,
        a: currentColor.rgb.a,
      };
      return rgba;
    };
    const currentColorCopy = { ...currentColor };
    const currentInput = e.target.value;

    currentColorCopy.hex = currentInput;

    if (currentInput.length === 7) {
      // If full hex code inserted, then updated the color
      const splitInput = currentInput.split("#");
      const parsedInputHex =
        splitInput.length === 1 ? splitInput[0] : splitInput[1];
      currentColorCopy.rgb = hex2rgba(parsedInputHex);
    }

    setCurrentColor(currentColorCopy);
    update_color_in_context(currentColorCopy);
  };

  const updateAlpha = (e) => {
    const currentColorCopy = { ...currentColor };
    const currentInput = parseFloat(e.target.value) / 100;
    currentColorCopy.rgb.a = currentInput;
    if (currentInput < 0 || currentInput > 1) return;
    if (isNaN(currentInput)) currentColorCopy.rgb.a = 0;
    setCurrentColor(currentColorCopy);
    update_color_in_context(currentColorCopy);
  };

  const updateRGB = (color, value) => {
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };
    const rgbToHex = (r, g, b) =>
      "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);

    if (isNaN(value) || value < 0 || value > 255) return;
    const currentColorCopy = { ...currentColor };
    currentColorCopy.rgb[color] = parseInt(value);
    currentColorCopy.hex = rgbToHex(
      currentColorCopy.rgb.r,
      currentColorCopy.rgb.g,
      currentColorCopy.rgb.b
    );
    setCurrentColor(currentColorCopy);
    update_color_in_context(currentColorCopy);
  };

  const update_color_in_context = (color) => {
    if (callback) callback(color, context, updateContext, index);
  };

  useEffect(() => {
    if (savedColor) {
      setCurrentColor(savedColor);
    }
  }, [savedColor]);

  return (
    <div className={styles.color_picker_container}>
      <div
        className={styles.color_picker_bg_overlay}
        style={{ display: showColoPicker ? "block" : "none" }}
        onClick={() => setShowColorPicker(!showColoPicker)}
      ></div>
      <input
        type="text"
        name={inputFieldId}
        onChange={update_color_in_context}
        value={JSON.stringify(currentColor)}
        hidden
      />
      <div className={styles.picker_inner}>
        <div className={`${styles.picker_item} ${styles.hex}`}>
          <p className={styles.picker_heading}>Hex Code</p>
          <div className={styles.picker_inputs}>
            <input
              type="text"
              className={styles.picker_input}
              value={currentColor.hex.toUpperCase()}
              onChange={updateHexCode}
            />
          </div>
        </div>
        <div className={`${styles.picker_item} ${styles.rgb}`}>
          <p className={styles.picker_heading}>RGB</p>
          <div className={styles.picker_inputs}>
            <input
              type="number"
              min={0}
              max={255}
              step={1}
              className={`${styles.picker_input} ${styles.three_divisons}`}
              value={currentColor.rgb.r}
              onChange={(e) => updateRGB("r", e.target.value)}
            />
            <input
              type="number"
              min={0}
              max={255}
              step={1}
              className={`${styles.picker_input} ${styles.three_divisons}`}
              value={currentColor.rgb.g}
              onChange={(e) => updateRGB("g", e.target.value)}
            />
            <input
              type="number"
              min={0}
              max={255}
              step={1}
              className={`${styles.picker_input} ${styles.three_divisons}`}
              value={currentColor.rgb.b}
              onChange={(e) => updateRGB("b", e.target.value)}
            />
          </div>
        </div>
        <div className={`${styles.picker_item} ${styles.picker_tool}`}>
          <img
            src={colorPickerEllipse}
            alt="Color Picker Icon"
            onClick={() => setShowColorPicker(!showColoPicker)}
          />
          <div
            className={`${styles.color_picker_body_popup} color_picker_body_custom`}
            style={{ display: showColoPicker ? "block" : "none" }}
          >
            <SketchPicker color={currentColor.rgb} onChange={changeColor} />
          </div>
        </div>
        <div className={`${styles.picker_item} ${styles.opacity}`}>
          <p className={styles.picker_heading}>Opacity</p>
          <div className={styles.picker_inputs}>
            <input
              type="number"
              className={styles.picker_input}
              value={`${currentColor.rgb.a * 100}`}
              onChange={updateAlpha}
              min={0}
              max={100}
              step={1}
            />
          </div>
        </div>
        <div className={`${styles.picker_item} ${styles.color_output}`}>
          <div className={styles.picker_inputs}>
            <input
              type="text"
              className={`${styles.picker_input} ${styles.color_output_show}`}
              style={{ backgroundColor: parseRGBA(currentColor.rgb) }}
              disabled
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
