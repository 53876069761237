import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import styles from "./radioInputField.module.scss";

const RadioInputField = ({
  forGroup,
  inputFieldId,
  selected = false,
  inputStyle = "", // left_icon_w_background, pill_radio, carddesign_radio
  icon = "",
  text = "",
  callback = null,
  context = null,
  updateContext = null,
  simpleOnChange = null,
  option = null,
  index = null,
  children,
}) => {
  const [value, setValue] = useState(selected);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  return (
    <div
      className={`${styles.radio_field_container} ${styles[inputStyle] ?? ""}`}
    >
      <label htmlFor={inputFieldId} className={styles.radio_labal}>
        <input
          type="radio"
          name={forGroup}
          id={inputFieldId}
          checked={value}
          onChange={(e) => {
            const updatedValue = e.target.checked;
            setValue(updatedValue);
            if (callback && option) {
              callback(option, context, updateContext, index);
              return;
            }
            if (callback) {
              callback(inputFieldId, context, updateContext);
              return;
            }
            if (simpleOnChange) simpleOnChange();
          }}
        />
        <div className={styles.radio_body}>
          {children}
          {inputStyle === "left_icon_w_background" && (
            <div className={styles.left_icon_body}>
              <ReactSVG src={icon} wrapper={"span"} />
              <span className={styles.left_icon_text}>{text}</span>
            </div>
          )}
          {inputStyle === "pill_radio" && (
            <div className={styles.pill_body}>
              <span className={styles.pill_body_text}>{text}</span>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default RadioInputField;
