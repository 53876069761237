import React, { useState, useEffect, useContext } from "react";

import { CRMContext } from "../../contexts/settingsContexts/crm.context";

import ConvertKit from "./crmSettings/convertkit";
import ActiveCampaign from "./crmSettings/activeCampaign";
import Hubspot from "./crmSettings/hubspot";

import {
  updateContactPrivacyFormSetting,
  update_contact_privacy_password,
  update_crm_field_first_name,
  update_crm_field_last_name,
  update_crm_field_phone,
  update_crm_field_email,
  update_crm_field_company,
  update_crm_field_note,
  update_crm_selection,
} from "./updateFunctions";

import { uploadCRM } from "./uploadFunction";

import CollapsibleContainer from "../../common/collapsibleContainer/collapsibleContainer";
import RadioInputField from "../../common/radioInputField/radioInputField";
import InlineButton from "../../common/inlineButton/inlineButton";
import InputField from "../../common/inputField/inputField";
import Modal from "../../common/modal/modal";
import Checkbox from "../../common/checkbox/checkbox";
import SelectInputField from "../../common/selectInputField/selectInputField";
import Button from "../../common/button/button";

import privacy_svg from "../../../assets/settings/icon_privacy.svg";

const Privacy = ({ styles }) => {
  const [crm, setCrm] = useContext(CRMContext);
  const [showPrivacyContactFormModal, setShowPrivacyContactFormModal] =
    useState(false);
  const [crmOptions, setCrmOptions] = useState(["CRM Options"]);

  const { use_form, use_password, no_one, everyone } = crm;

  const { crm_details, crm_fields } = use_form;

  const { crmID, crm_options } = crm_details;

  const { first_name, last_name, phone, email, company, note } = crm_fields;

  const { password } = use_password;

  useEffect(() => {
    const tempCrmOptions = [];
    Object.keys(crm_options).forEach((key) => {
      tempCrmOptions.push(key);
    });
    setCrmOptions(tempCrmOptions);
  }, []);

  return (
    <React.Fragment>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title={"Privacy"}
          inputId={`settings_privacy_enable`}
          showSwitch={false}
          showIconLeft={privacy_svg}
          helpIcon={"Some Help Text"}
        >
          <div className={styles.editable_section}>
            <h4 className={`${styles.section_heading}`}>
              Who can see your contact detail?
            </h4>
            <RadioInputField
              forGroup={"settings_privacy_contact_detail"}
              inputFieldId={"use_form"}
              selected={use_form?.enable}
              callback={updateContactPrivacyFormSetting}
              context={crm}
              updateContext={setCrm}
            >
              <p>
                Visitor should fill in the form and I’ll contact them.{" "}
                <InlineButton
                  title={"Setup the form"}
                  onClickFunction={() =>
                    setShowPrivacyContactFormModal(!showPrivacyContactFormModal)
                  }
                />
              </p>
            </RadioInputField>
            <RadioInputField
              forGroup={"settings_privacy_contact_detail"}
              inputFieldId={"use_password"}
              selected={use_password?.enable}
              callback={updateContactPrivacyFormSetting}
              context={crm}
              updateContext={setCrm}
            >
              <p>Only visitiors with the following password</p>
              <InputField
                extraClass={"no_label"}
                helperClasses={"mtop_10"}
                placeholder={"Enter password here"}
                type={"text"}
                inputFieldId={
                  "settings_privacy_contact_detail_visitors_password_entry_1"
                }
                defaultInputValue={password}
                callback={update_contact_privacy_password}
                context={crm}
                updateContext={setCrm}
              />
              {/* <InputField
                extraClass={"no_label"}
                helperClasses={"mtop_10"}
                placeholder={"Re-enter password here"}
                type={"text"}
                inputFieldId={
                  "settings_privacy_contact_detail_visitors_password_entry_2"
                }
              /> */}
            </RadioInputField>
            <RadioInputField
              forGroup={"settings_privacy_contact_detail"}
              inputFieldId={"no_one"}
              selected={no_one?.enable}
              callback={updateContactPrivacyFormSetting}
              context={crm}
              updateContext={setCrm}
            >
              <p>No one. Don’t show my contact detail to visitors</p>
            </RadioInputField>
            <RadioInputField
              forGroup={"settings_privacy_contact_detail"}
              inputFieldId={"everyone"}
              selected={everyone?.enable}
              callback={updateContactPrivacyFormSetting}
              context={crm}
              updateContext={setCrm}
            >
              <p>Show my contact information to all of my card visitors</p>
            </RadioInputField>
          </div>
          {/* Use callback and callbackdata here for saving the settings */}
          <Button
            title={"Save Privacy Settings"}
            callback={uploadCRM}
            callbackData={crm}
          />
        </CollapsibleContainer>
      </div>
      {/* Privacy Contact form modal */}
      <Modal
        open={showPrivacyContactFormModal}
        closer={setShowPrivacyContactFormModal}
      >
        <h3 className={"popup_main_heading"}>Privacy Form Setup</h3>
        <h4 className={"popup_sub_heading"}>Select the required fields:</h4>
        <p className={"popup_info_text"}>
          The visitors will be provided with a form with the required fields you
          select, and their information will be sent to you.
        </p>
        <span className="vertical_space"></span>
        <Checkbox
          label={"First Name"}
          inputFieldId={
            "settings_privacy_contact_detail_form_first_name_enable"
          }
          defaultValue={first_name}
          callback={update_crm_field_first_name}
          context={crm}
          updateContext={setCrm}
        />
        <span className="vertical_space"></span>
        <Checkbox
          label={"Last Name"}
          inputFieldId={"settings_privacy_contact_detail_form_last_name_enable"}
          defaultValue={last_name}
          callback={update_crm_field_last_name}
          context={crm}
          updateContext={setCrm}
        />
        <span className="vertical_space"></span>
        <Checkbox
          label={"Email Address"}
          inputFieldId={"settings_privacy_contact_detail_form_email_enable"}
          defaultValue={email}
          callback={update_crm_field_email}
          context={crm}
          updateContext={setCrm}
        />
        <span className="vertical_space"></span>
        <Checkbox
          label={"Phone Number"}
          inputFieldId={"settings_privacy_contact_detail_form_phone_enable"}
          defaultValue={phone}
          callback={update_crm_field_phone}
          context={crm}
          updateContext={setCrm}
        />
        <span className="vertical_space"></span>
        <Checkbox
          label={"Company"}
          inputFieldId={"settings_privacy_contact_detail_form_company_enable"}
          defaultValue={company}
          callback={update_crm_field_company}
          context={crm}
          updateContext={setCrm}
        />
        <span className="vertical_space"></span>
        <Checkbox
          label={"Perseonal Note/Message"}
          inputFieldId={"settings_privacy_contact_detail_form_note_enable"}
          defaultValue={note}
          callback={update_crm_field_note}
          context={crm}
          updateContext={setCrm}
        />
        <h4 className={"popup_sub_heading"}>
          Where should we send the details?
        </h4>
        <span className="vertical_space"></span>
        <SelectInputField
          inputFieldId={
            "settings_privacy_contact_detail_form_submission_endpoint_crm"
          }
          options={crmOptions}
          showLabel={false}
          defaultInputValue={crmID}
          callback={update_crm_selection}
          context={crm}
          updateContext={setCrm}
        />
        {/* CRM Settings modules */}
        {crmID === "convertkit" && <ConvertKit />}
        {crmID === "activecampaign" && <ActiveCampaign />}
        {crmID === "hubspot" && <Hubspot />}
      </Modal>
    </React.Fragment>
  );
};

export default Privacy;
