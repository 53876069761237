import React, { useState, createContext } from "react";

export const DataLoadContext = createContext();

export const DataLoadProvider = ({ children }) => {
  const [dataLoad, setDataLoad] = useState({
    personalDetails: false,
    socialLinks: false,
    imageGallery: false,
    videoGallery: false,
    calendly: false,
  });

  return (
    <DataLoadContext.Provider value={[dataLoad, setDataLoad]}>
      {children}
    </DataLoadContext.Provider>
  );
};
