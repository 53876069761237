import React, { useEffect } from "react";

import LandingPagesWrapper from "../../common/landingPagesWrapper/landingPagesWrapper";

const YoutubeLanding = () => {
  useEffect(() => {
    window.document.documentElement.dataset["wfPage"] =
      "62fac83091fae676316ff138";
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
    window.document.body.classList.add("webflow");
  });

  return (
    <LandingPagesWrapper>
      <main className="main-wrapper">
        <section className="section-hero is-youtube wf-section">
          <div className="section-spacing gap-hero is-youtube">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="hero-inner-wrapper">
                  <div className="hero-content is-youtube">
                    <div className="text-align-center">
                      <div
                        data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f09"
                        style={{ opacity: 0 }}
                        className="text-size-large"
                      >
                        YouTube is full of Competition?
                      </div>
                      <h1
                        data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f10"
                        style={{ opacity: 0 }}
                        className="text-color-white"
                      >
                        Would you like to beat the Competition?
                      </h1>
                      <a
                        data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f19"
                        style={{ opacity: 0 }}
                        href="#"
                        className="button is-white w-button"
                      >
                        Get your
                        <span className="text-color-primary">
                          <strong>Umi</strong>
                        </span>
                        <span className="text-color-secondary">
                          <strong>Card</strong>
                        </span>
                        <sup>TM</sup> Now
                      </a>
                    </div>
                  </div>
                  {/* <div
                    data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f1b"
                    style={{ opacity: 0 }}
                    className="hero-mockup"
                  >
                    <div className="mockup-wrapper is-youtube">
                      <img
                        src="images/iPhone-12-Mockup-1_1iPhone-12-Mockup-1.webp"
                        width="358"
                        sizes="(max-width: 479px) 91vw, 358px"
                        srcSet="
                          images/iPhone-12-Mockup-1_1-p-500.png              500w,
                          images/iPhone-12-Mockup-1_1iPhone-12-Mockup-1.webp 716w
                        "
                        alt=""
                        className="mockup-image"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f1f"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f24-316ff138"
                    className="feature-content"
                  >
                    <h2>Build your audience faster!</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          All focus on you and your videos without noise or
                          distraction
                        </p>
                      </div>
                    </div>
                    <a href="#" className="button w-button">
                      Get your UmiCardTM Now
                    </a>
                  </div>
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f2d-316ff138"
                    className="feature-image-wrapper _12"
                  >
                    <img
                      src="images/Layer-1-1_4Layer-1-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 378.5px"
                      srcSet="
                        images/Layer-1-1_4-p-500.png     500w,
                        images/Layer-1-1_4Layer-1-1.webp 757w
                      "
                      width="378.5"
                      alt=""
                      className="layer-1 _12"
                    />
                    <img
                      src="images/Layer-2-4.png"
                      loading="lazy"
                      width="159"
                      alt=""
                      className="layer-2 _12"
                    />
                    <img
                      src="images/Layer-3_5Layer-3.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 300px"
                      srcSet="
                        images/Layer-3_5-p-500.png   500w,
                        images/Layer-3_5Layer-3.webp 600w
                      "
                      width="300"
                      alt=""
                      className="layer-3 _12"
                    />
                    <img
                      src="images/Layer-4_2.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _12"
                    />
                    <img
                      src="images/12_112.webp"
                      loading="lazy"
                      srcSet="
                        images/12_1-p-500.png   500w,
                        images/12_1-p-800.png   800w,
                        images/12_1-p-1080.png 1080w,
                        images/12_112.webp     1343w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f32"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f37-316ff138"
                    className="feature-image-wrapper is-switched _13"
                  >
                    <img
                      src="images/Layer-1.png"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 255.5px"
                      srcSet="
                        images/Layer-1-p-500.png 500w,
                        images/Layer-1.png       511w
                      "
                      width="255.5"
                      alt=""
                      className="layer-1 _13"
                    />
                    <img
                      src="images/Layer-2_7Layer-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 295px"
                      srcSet="
                        images/Layer-2_7-p-500.png   500w,
                        images/Layer-2_7Layer-2.webp 590w
                      "
                      width="295"
                      alt=""
                      className="layer-2 _13"
                    />
                    <img
                      src="images/Layer-3_4Layer-3.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 410px"
                      srcSet="
                        images/Layer-3_4-p-500.png   500w,
                        images/Layer-3_4-p-800.png   800w,
                        images/Layer-3_4Layer-3.webp 820w
                      "
                      width="410"
                      alt=""
                      className="layer-3 _13"
                    />
                    <img
                      src="images/Layer-4-1_1.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _13"
                    />
                    <img
                      src="images/13_113.webp"
                      loading="lazy"
                      srcSet="
                        images/13_1-p-500.png   500w,
                        images/13_1-p-800.png   800w,
                        images/13_1-p-1080.png 1080w,
                        images/13_113.webp     1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _13"
                    />
                  </div>
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f3c-316ff138"
                    className="feature-content is-switched"
                  >
                    <h2>Guide Your Audience to take Action</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Use the Call to Action for each video to encourage
                          your audience take a specific action.
                        </p>
                      </div>
                    </div>
                    <a href="#" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f47"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f4c-316ff138"
                    className="feature-content"
                  >
                    <h2>Decide what You want to showcase</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Decide whether you want the audience to discover your
                          most recent or most popular videos.
                        </p>
                      </div>
                    </div>
                    <a href="#" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f55-316ff138"
                    className="feature-image-wrapper"
                  >
                    <img
                      src="images/Layer-1-1.png"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 403px"
                      srcSet="
                        images/Layer-1-1-p-500.png 500w,
                        images/Layer-1-1-p-800.png 800w,
                        images/Layer-1-1.png       806w
                      "
                      width="403"
                      alt=""
                      className="layer-1 _1"
                    />
                    <img
                      src="images/Layer-3-1_2Layer-3-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 47vw"
                      srcSet="
                        images/Layer-3-1_2-p-500.png      500w,
                        images/Layer-3-1_2-p-800.png      800w,
                        images/Layer-3-1_2-p-1080.png    1080w,
                        images/Layer-3-1_2Layer-3-1.webp 1202w
                      "
                      width="601"
                      alt=""
                      className="layer-3 _1"
                    />
                    <img
                      src="images/Layer-4-1_1.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _14"
                    />
                    <img
                      src="images/14_114.webp"
                      loading="lazy"
                      srcSet="
                        images/14_1-p-500.png   500w,
                        images/14_1-p-800.png   800w,
                        images/14_1-p-1080.png 1080w,
                        images/14_114.webp     1467w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 767px) 71vw, (max-width: 991px) 72vw, 100vw"
                      width="733.5"
                      alt=""
                      className="layer-full _14"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f5a"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f5f-316ff138"
                    className="feature-image-wrapper is-switched _15"
                  >
                    <img
                      src="images/Layer-1_1Layer-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 341.5px"
                      srcSet="
                        images/Layer-1_1-p-500.png   500w,
                        images/Layer-1_1Layer-1.webp 683w
                      "
                      width="341.5"
                      alt=""
                      className="layer-1 _1"
                    />
                    <img
                      src="images/Layer-2_4Layer-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 358px"
                      srcSet="
                        images/Layer-2_4-p-500.png   500w,
                        images/Layer-2_4Layer-2.webp 716w
                      "
                      width="358"
                      alt=""
                      className="layer-3 _15"
                    />
                    <img
                      src="images/Layer-4_3.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/15_115.webp"
                      loading="lazy"
                      srcSet="
                        images/15_1-p-500.png   500w,
                        images/15_1-p-800.png   800w,
                        images/15_1-p-1080.png 1080w,
                        images/15_115.webp     1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _15"
                    />
                  </div>
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f64-316ff138"
                    className="feature-content is-switched"
                  >
                    <h2>Take Control of Your Traffic</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Decide where you want to send your audience. Without
                          being worry about Ads or noises to distract them.
                        </p>
                      </div>
                    </div>
                    <a href="#" className="button w-button">
                      Get Started NOW
                    </a>
                    <div>
                      <img
                        src="images/Frame-112_1Frame-112.webp"
                        loading="lazy"
                        width="479"
                        srcSet="
                          images/Frame-112_1-p-500.png     500w,
                          images/Frame-112_1-p-800.png     800w,
                          images/Frame-112_1Frame-112.webp 958w
                        "
                        sizes="(max-width: 479px) 93vw, (max-width: 767px) 92vw, 460px"
                        alt=""
                        className="image-platforms is-youtube"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="6d6fce97-2a74-05e7-e759-51c426b70f6f"
          className="section-feature wf-section"
        >
          <div className="section-spacing gap-before-footer">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f74-316ff138"
                    className="feature-content"
                  >
                    <h2>Make Your Marketing Laser Focused.</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          UmiCard? Integrate with top marketing tools to help
                          you reduce your marketing cost and increase your
                          profitability.
                        </p>
                      </div>
                    </div>
                    <a href="#" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_6d6fce97-2a74-05e7-e759-51c426b70f83-316ff138"
                    className="feature-image-wrapper _16"
                  >
                    <img
                      src="images/Layer-1_5Layer-1.webp"
                      loading="lazy"
                      width="242.5"
                      alt=""
                      className="layer-1 _16"
                    />
                    <img
                      src="images/Layer-2_5Layer-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 359px"
                      srcSet="
                        images/Layer-2_5-p-500.png   500w,
                        images/Layer-2_5Layer-2.webp 718w
                      "
                      width="359"
                      alt=""
                      className="layer-2 _16"
                    />
                    <img
                      src="images/Layer-3_6Layer-3.webp"
                      loading="lazy"
                      width="244"
                      alt=""
                      className="layer-3 _16"
                    />
                    <img
                      src="images/Layer-4_3.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _16"
                    />
                    <img
                      src="images/16_116.webp"
                      loading="lazy"
                      srcSet="
                        images/16_1-p-500.png   500w,
                        images/16_1-p-800.png   800w,
                        images/16_1-p-1080.png 1080w,
                        images/16_116.webp     1252w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="626"
                      alt=""
                      className="layer-full _16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </LandingPagesWrapper>
  );
};

export default YoutubeLanding;
