import React, { useEffect, createRef, useState } from "react";
import { ReactSVG } from "react-svg";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Privacy from "./privacy/privacy";
import Tracking from "./trackingCodes/tracking";

import LivePreview from "../livePreview/livePreview";
import CollapsibleContainer from "../common/collapsibleContainer/collapsibleContainer";
import RadioInputField from "../common/radioInputField/radioInputField";
import InputField from "../common/inputField/inputField";
import Checkbox from "../common/checkbox/checkbox";
import AddMoreEdirableItemButton from "../common/addMoreEditableItemButton/addMoreEdirableItemButton";
import SupportCauseSlide from "./supportCauseSlide/supportCauseSlide";

import styles from "../designer/designer.module.scss";
import privacy_svg from "../../assets/settings/icon_privacy.svg";
import support_cause_svg from "../../assets/settings_sidebar/icon_support_cause.svg";
import tracking_pixels_svg from "../../assets/settings_sidebar/icon_tracking_pixels.svg";
import seo_svg from "../../assets/settings_sidebar/icon_seo.svg";
import commerce_integration_svg from "../../assets/settings_sidebar/icon_commerce_integration.svg";
import age_restricted_svg from "../../assets/settings_sidebar/icon_age_restricted.svg";
import toc_pp_svg from "../../assets/settings_sidebar/icon_toc_pp.svg";
import stripe_svg from "../../assets/settings/commerce_integrations/icon_stripe.svg";
import paypal_svg from "../../assets/settings/commerce_integrations/icon_paypal.svg";
import black_lives_matter_svg from "../../assets/settings/causes/icon_black_lives_matter.svg";
import rain_forest_svg from "../../assets/settings/causes/icon_rain_forest.svg";
import recycle_svg from "../../assets/settings/causes/icon_recycle.svg";
import stop_bullying_svg from "../../assets/settings/causes/icon_stop_bullying.svg";
import seas_svg from "../../assets/settings/causes/icon_seas.svg";

const Settings = () => {
  var slick_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [privacySelected, setPrivacySelected] = useState(true);
  const [tocSelected, setTocSelected] = useState(false);
  const [causeSelected, setCauseSelected] = useState(false);
  const [trackingSelected, setTrackingSelected] = useState(false);
  const [seoSelected, setSeoSelected] = useState(false);
  const [commSelected, setCommSelected] = useState(false);
  const [ageSelected, setAgeSelected] = useState(false);

  const sidebarRef = createRef();
  const tocRef = createRef();
  const causeRef = createRef();
  const trackingRef = createRef();
  const seoRef = createRef();
  const commRef = createRef();
  const ageRef = createRef();

  const makeSidebarSectionActive = (activate) => {
    setPrivacySelected(false);
    setTocSelected(false);
    setCauseSelected(false);
    setTrackingSelected(false);
    setSeoSelected(false);
    setCommSelected(false);
    setAgeSelected(false);

    activate(true);
  };

  const scrollToSection = (targetElement, top = false) => {
    if (top) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    const topOffset = targetElement.offsetTop;
    if (topOffset)
      window.scrollTo({
        top: topOffset - 110,
        left: 0,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    const screenWidth = window?.innerWidth;
    if (!screenWidth) return;
    if (!sidebarRef?.current) return;
    const sidebarDOMObj = sidebarRef.current;
    if (screenWidth > 767) {
      // Make previewer sticky
      sidebarDOMObj.style.position = "sticky";
      sidebarDOMObj.style.top = `${
        sidebarDOMObj.offsetTop < 150 ? sidebarDOMObj.offsetTop : 110
      }px`;
      sidebarDOMObj.style.left = "0px";
    } else {
      // Make it normal
      sidebarDOMObj.style.position = "";
      sidebarDOMObj.style.top = "";
      sidebarDOMObj.style.left = "";
    }
  });

  return (
    <div className={styles.designer_container}>
      <div className={styles.section_selector}>
        <div ref={sidebarRef} className={styles.designer_white_box}>
          <p className={styles.heading}>Customize</p>
          <div className={styles.customize_options_container}>
            <button
              className={`${styles.customize_option} ${
                privacySelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setPrivacySelected);
                scrollToSection(0, true);
              }}
            >
              <ReactSVG src={privacy_svg} wrapper={"span"} />
              Privacy
            </button>
            <button
              className={`${styles.customize_option} ${
                tocSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setTocSelected);
                scrollToSection(tocRef.current);
              }}
            >
              <ReactSVG src={toc_pp_svg} wrapper={"span"} />
              Custom Toc and PP
            </button>
            <button
              className={`${styles.customize_option} ${
                causeSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setCauseSelected);
                scrollToSection(causeRef.current);
              }}
            >
              <ReactSVG src={support_cause_svg} wrapper={"span"} />
              Support a Cause
            </button>
            <button
              className={`${styles.customize_option} ${
                trackingSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setTrackingSelected);
                scrollToSection(trackingRef.current);
              }}
            >
              <ReactSVG src={tracking_pixels_svg} wrapper={"span"} />
              Tracking Pixels
            </button>
            <button
              className={`${styles.customize_option} ${
                seoSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setSeoSelected);
                scrollToSection(seoRef.current);
              }}
            >
              <ReactSVG src={seo_svg} wrapper={"span"} />
              SEO
            </button>
            <button
              className={`${styles.customize_option} ${
                commSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setCommSelected);
                scrollToSection(commRef.current);
              }}
            >
              <ReactSVG src={commerce_integration_svg} wrapper={"span"} />
              Commerce Integration
            </button>
            <button
              className={`${styles.customize_option} ${
                ageSelected && styles.active
              }`}
              onClick={() => {
                makeSidebarSectionActive(setAgeSelected);
                scrollToSection(ageRef.current);
              }}
            >
              <ReactSVG src={age_restricted_svg} wrapper={"span"} />
              Age Restricted Material
            </button>
          </div>
        </div>
      </div>
      <div className={styles.section_details_editor}>
        <Privacy styles={styles} />
        <div
          ref={tocRef}
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            title={"Custom ToC and PP"}
            inputId={`settings_custom_toc_pp_enable`}
            showIconLeft={toc_pp_svg}
          >
            <div className={styles.editable_section}>
              <InputField
                inputFieldId={"custom_privacy_policy_link"}
                label={"Link to your Privacy Policy"}
                placeholder={"yourdomain.com/privacy-policy"}
              />
              <InputField
                inputFieldId={"custom_terms_of_service_link"}
                label={"Link to your Terms of Service"}
                placeholder={"yourdomain.com/terms-of-service"}
              />
            </div>
          </CollapsibleContainer>
        </div>
        <div
          ref={causeRef}
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            title={"Support a Cause"}
            inputId={`settings_support_cause_enable`}
            showIconLeft={support_cause_svg}
          >
            <div className={styles.editable_section}>
              <p className={styles.section_info_text}>
                Show your support for an important cause by displaying a banner
                on your profile. You can select up to 3 causes and the banners
                will rotate so increase the chances of your visitors see all
                causes you support.
              </p>
              <div className={styles.editable_section}>
                <Slider {...slick_settings} arrows={true} autoplay={true}>
                  <SupportCauseSlide
                    causeName={"Black Lives Matter"}
                    icon={black_lives_matter_svg}
                    text={
                      "Here we will add the description text about the cause, this text and it’s animated icon will rotate to showcase different causes"
                    }
                    link={"#"}
                  />
                  <SupportCauseSlide
                    causeName={"Recovering Rain Forest"}
                    icon={rain_forest_svg}
                    text={
                      "Here we will add the description text about the cause, this text and it’s animated icon will rotate to showcase different causes"
                    }
                    link={"#"}
                  />
                  <SupportCauseSlide
                    causeName={"Reduce Reuse Recycle"}
                    icon={recycle_svg}
                    text={
                      "Here we will add the description text about the cause, this text and it’s animated icon will rotate to showcase different causes"
                    }
                    link={"#"}
                  />
                  <SupportCauseSlide
                    causeName={"Stop Bullying"}
                    icon={stop_bullying_svg}
                    text={
                      "Here we will add the description text about the cause, this text and it’s animated icon will rotate to showcase different causes"
                    }
                    link={"#"}
                  />
                  <SupportCauseSlide
                    causeName={"Team Seas"}
                    icon={seas_svg}
                    text={
                      "Here we will add the description text about the cause, this text and it’s animated icon will rotate to showcase different causes"
                    }
                    link={"#"}
                  />
                </Slider>
                <hr />
                <p className={styles.section_info_text}>
                  Causes your supported
                </p>
                <Checkbox
                  inputFieldId={"support_black_lives_matter_enable"}
                  label={"Black Lives Matter"}
                  checkboxStyle={"checkbox_with_icon_on_left"}
                  icon={black_lives_matter_svg}
                />
                <Checkbox
                  inputFieldId={"support_recover_rain_forest_enable"}
                  label={"Recovering Rain Forest"}
                  checkboxStyle={"checkbox_with_icon_on_left"}
                  icon={rain_forest_svg}
                />
                <Checkbox
                  inputFieldId={"support_recycle_enable"}
                  label={"Reduce Reuse Recycle"}
                  checkboxStyle={"checkbox_with_icon_on_left"}
                  icon={recycle_svg}
                />
                <Checkbox
                  inputFieldId={"support_stop_bullying_enable"}
                  label={"Stop Bullying"}
                  checkboxStyle={"checkbox_with_icon_on_left"}
                  icon={stop_bullying_svg}
                />
                <Checkbox
                  inputFieldId={"support_team_seas_enable"}
                  label={"Team Seas"}
                  checkboxStyle={"checkbox_with_icon_on_left"}
                  icon={seas_svg}
                />
              </div>
            </div>
          </CollapsibleContainer>
        </div>
        <Tracking styles={styles} codesRef={trackingRef} seoRef={seoRef} />
        <div
          ref={commRef}
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            title={"Commerce Integration"}
            inputId={`settings_commerce_integration_enable`}
            showIconLeft={commerce_integration_svg}
            showSwitch={false}
          >
            <div className={styles.editable_section}>
              <p className={styles.section_info_text}>
                Add integration for the supported payment provider and start to
                sell products or collect tips and payments
              </p>
              <AddMoreEdirableItemButton title={"Add Integration"} />

              <div className={styles.editable_section}>
                <div
                  className={`${styles.designer_white_box} ${styles.details_editor}`}
                >
                  <CollapsibleContainer
                    smaller={true}
                    title={"Stripe"}
                    showIconLeft={stripe_svg}
                    inputId={`commerce_integration_stripe_enable`}
                  >
                    <InputField
                      label={"Stripe Account Description"}
                      inputFieldId={"stripe-description"}
                      placeholder={"Stripe Account Description"}
                    />
                  </CollapsibleContainer>
                </div>
                <div
                  className={`${styles.designer_white_box} ${styles.details_editor}`}
                >
                  <CollapsibleContainer
                    smaller={true}
                    title={"PayPal"}
                    showIconLeft={paypal_svg}
                    inputId={`commerce_integration_paypal_enable`}
                  >
                    <InputField
                      label={"Paypal Account Description"}
                      inputFieldId={"paypal-description"}
                      placeholder={"Paypal Account Description"}
                    />
                  </CollapsibleContainer>
                </div>
              </div>
            </div>
          </CollapsibleContainer>
        </div>
        <div
          ref={ageRef}
          className={`${styles.designer_white_box} ${styles.details_editor}`}
        >
          <CollapsibleContainer
            title={"Age Restricted Material"}
            inputId={`settings_age_restricted_material_enable`}
            showIconLeft={age_restricted_svg}
          >
            <div className={styles.editable_section}>
              <p className={styles.section_info_text}>
                If you age restricted content showing on your UmiCard, have
                content that requires minimum age to view the content, you can
                turn on this feature and select the appropriate option.
              </p>
              <p className={styles.section_info_text}>
                Your card visitors will get an age confirmation page before
                seeing the content of your card.
              </p>
              <span className="vertical_space"></span>
              <h4 className={styles.section_heading}>Select Age Restriction</h4>
              <RadioInputField
                forGroup={"settings_age_restricted_age_range"}
                inputFieldId={"settings_age_restricted_age_range_18_plus"}
              >
                <p>18+</p>
              </RadioInputField>
              <RadioInputField
                forGroup={"settings_age_restricted_age_range"}
                inputFieldId={"settings_age_restricted_age_range_21_plus"}
              >
                <p>21+</p>
              </RadioInputField>
              <RadioInputField
                forGroup={"settings_age_restricted_age_range"}
                inputFieldId={"settings_age_restricted_age_range_25_plus"}
              >
                <p>25+</p>
              </RadioInputField>
              <RadioInputField
                forGroup={"settings_age_restricted_age_range"}
                inputFieldId={"settings_age_restricted_age_range_sensitive"}
              >
                <p>Sensitive Content</p>
              </RadioInputField>
            </div>
          </CollapsibleContainer>
        </div>
      </div>
      <LivePreview />
    </div>
  );
};

export default Settings;
