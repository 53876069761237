import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { YoutubeContext } from "../../../contexts/sectionsContexts/youtube.context";

import {
  update_youtube_channel_id,
  select_videos_list_type,
  update_dynamic_videos_list_number,
  update_dynamic_list_type,
  update_existing_id,
  delete_existing_id,
  // add_new_youtube_id,
  update_enable_youtube_customization,
  select_icon_type_youtube,
  update_youtube_icon,
  update_fa_icon_name_youtube,
  update_youtube_fa_icon_color,
  update_youtube_section_background_color,
  update_youtube_headline,
  update_youtube_font,
  update_youtube_font_styles,
  update_youtube_featured_color,
} from "./updateFunctions";

import { uploadYoutubeCreator } from "./uploadFunction";

import { fetch_yt_channel_details } from "./yt_helpers";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import Button from "../../../common/button/button";
import InputField from "../../../common/inputField/inputField";
import CTAIconSelector from "../../../common/ctaIconSelector/ctaIconSelector";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import FontPicker from "../../../common/fontPicker/fontPicker";
// import AddMoreEdirableItemButton from "../../../common/addMoreEditableItemButton/addMoreEdirableItemButton";
import Checkbox from "../../../common/checkbox/checkbox";
import CounterInput from "../../../common/counterInput/counterInput";
import YTChannelDetails from "./ytChannelDetails/ytChannelDetails";

const YoutubeCreator = ({ styles }) => {
  const [youtube, setYoutube] = useContext(YoutubeContext);

  const {
    channel_id,
    videos_list_type,
    number_of_dynamic_videos,
    dynamic_list_type,
    default_videos_list,
    custom_videos_list,
    customization,
    yt_title,
    yt_description,
    yt_channel_thumbnail,
  } = youtube;

  const { icon, background, headline, font, featured_color } = customization;

  const reOrderCustomYoutubeIDs = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const tempOrder = [...custom_videos_list];
    const [reOrderedItem] = tempOrder.splice(source.index, 1);
    tempOrder.splice(destination.index, 0, reOrderedItem);
    const youtubeCopy = { ...youtube, custom_videos_list: tempOrder };
    setYoutube(youtubeCopy);
  };

  return (
    <div className="youtube_creator">
      <SectionTopBar
        title={"Youtube Creator"}
        id={"youtube-creator-section-designer"}
        updateCallback={uploadYoutubeCreator}
        updateData={youtube}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Add you YouTube Channel Link"
          inputId="enable_youtube_creator_section"
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"youtube_creator_section_channel_url"}
              label={"Enter your Youtube Channel ID here"}
              extraClass={"no_label"}
              placeholder={"UC4bYJWyHtK6rm-41iYLAKrQ"}
              prefix={"https://www.youtube.com/channel/"}
              defaultInputValue={channel_id}
              callback={update_youtube_channel_id}
              context={youtube}
              updateContext={setYoutube}
            />
            <Button
              title={"Save/Update"}
              extraClass={"blue_centered"}
              callback={fetch_yt_channel_details}
              callbackData={{ channel_id, youtube, setYoutube }}
            />
            <YTChannelDetails
              yt_title={yt_title}
              yt_description={yt_description}
              yt_channel_thumbnail={yt_channel_thumbnail}
            />
          </div>
        </CollapsibleContainer>
      </div>{" "}
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Which videos would be shown?"
          inputId="enable_youtube_creator_videos_list_section"
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <div
              className={`${styles.designer_white_box} ${styles.details_editor}`}
            >
              <CollapsibleContainer
                smaller={true}
                title={"Default Order of Videos"}
                inputId={`youtube_creator_dynamic_videos_enable`}
                callback={select_videos_list_type}
                context={youtube}
                updateContext={setYoutube}
                index={"dynamic"}
                enabled={videos_list_type === "dynamic"}
              >
                <CounterInput
                  label={"# of video to show"}
                  step={1}
                  inputFieldId={"youtube_creator_dynamic_videos_quantity"}
                  min={0}
                  max={default_videos_list.length ?? 10}
                  defaultValue={number_of_dynamic_videos}
                  callback={update_dynamic_videos_list_number}
                  context={youtube}
                  updateContext={setYoutube}
                />
                <Checkbox
                  inputFieldId={"popular"}
                  label={
                    "Show my most popular videos (Not available currently)"
                  }
                  defaultValue={dynamic_list_type === "popular"}
                  sendIDInUpdate={true}
                  callback={update_dynamic_list_type}
                  context={youtube}
                  updateContext={setYoutube}
                  disabled={true}
                />
                <span className="vertical_space"></span>
                <Checkbox
                  inputFieldId={"recent"}
                  label={"Show my most recent videos"}
                  defaultValue={dynamic_list_type === "recent"}
                  sendIDInUpdate={true}
                  callback={update_dynamic_list_type}
                  context={youtube}
                  updateContext={setYoutube}
                />
              </CollapsibleContainer>
            </div>
            <div
              className={`${styles.designer_white_box} ${styles.details_editor}`}
            >
              <CollapsibleContainer
                smaller={true}
                title={"Customize Videos List"}
                inputId={`youtube_creator_custom_videos_enable`}
                callback={select_videos_list_type}
                context={youtube}
                updateContext={setYoutube}
                index={"custom"}
                enabled={videos_list_type == "custom"}
              >
                <DragDropContext onDragEnd={reOrderCustomYoutubeIDs}>
                  <Droppable droppableId="social-links-order">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {custom_videos_list.map((video, index) => {
                          return (
                            <Draggable
                              key={`${video.snippet.title}_index`}
                              draggableId={video.snippet.title}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <InputField
                                    key={index}
                                    inputFieldId={`youtube_creator_custom_videos_item_${index}`}
                                    extraClass={"no_label"}
                                    placeholder={
                                      "Your Video ID e.g. ZSjkls9023"
                                    }
                                    draggable={true}
                                    deletable={true}
                                    defaultInputValue={video.snippet.title}
                                    index={index}
                                    callback={update_existing_id}
                                    deleteCallback={delete_existing_id}
                                    context={youtube}
                                    updateContext={setYoutube}
                                    disabled={true}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {/* <AddMoreEdirableItemButton
                  title={"Add More Links"}
                  callback={add_new_youtube_id}
                  context={youtube}
                  updateContext={setYoutube}
                /> */}
              </CollapsibleContainer>
            </div>
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Customization"
          inputId="enable_youtube_creator_section_customization"
          enabled={customization.enable}
          callback={update_enable_youtube_customization}
          context={youtube}
          updateContext={setYoutube}
        >
          <div className={styles.editable_section}>
            <h4 className={`${styles.section_heading} mtop_25`}>Icon</h4>
            <p className={styles.section_info_text}>Change icon</p>
            <CTAIconSelector
              inputFieldId={"youtube_creator_section_icon"}
              selected={icon.type}
              selectedIcon={icon.fa_icon}
              iconColor={icon.color}
              selectorCallback={select_icon_type_youtube}
              imageCallback={update_youtube_icon}
              iconSelectorCallback={update_fa_icon_name_youtube}
              iconColorCallback={update_youtube_fa_icon_color}
              context={youtube}
              updateContext={setYoutube}
            />

            <h4 className={`${styles.section_heading} mtop_25`}>
              Section Background
            </h4>
            <ColorPicker
              inputFieldId={"youtube_creator_background_color"}
              savedColor={background.color}
              callback={update_youtube_section_background_color}
              context={youtube}
              updateContext={setYoutube}
            />
            <InputField
              inputFieldId={"youtube_creator_section_headline"}
              label={"Section Headline"}
              placeholder={"Enter your Headline here"}
              defaultInputValue={headline}
              callback={update_youtube_headline}
              context={youtube}
              updateContext={setYoutube}
            />
            <FontPicker
              inputFieldId={"youtube_creator_section_heading_font"}
              defaultInputValue={font.name}
              savedFontStyles={font}
              callback_font_name={update_youtube_font}
              callback_font_styles={update_youtube_font_styles}
              context={youtube}
              updateContext={setYoutube}
            />
            <h4 className={`${styles.section_heading} mtop_25`}>
              Featured Color
            </h4>
            <ColorPicker
              inputFieldId={"youtube_creator_featured_color"}
              savedColor={featured_color}
              callback={update_youtube_featured_color}
              context={youtube}
              updateContext={setYoutube}
            />
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
};

export default YoutubeCreator;
