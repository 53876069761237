import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import styles from "./inputField.module.scss";

import delete_svg from "../../../assets/global/icon_delete.svg";

const InputField = ({
  inputFieldId,
  label,
  type = "text",
  extraClass = "", // no_label
  helperClasses = "",
  defaultInputValue = "",
  placeholder = "",
  draggable = false,
  deletable = false,
  socialLinkURL = false,
  prefix = "",
  required = false,
  disabled = false,
  callback = null,
  deleteCallback = null,
  context = null,
  updateContext = null,
  index = null,
  sendIDInUpdate = false,
  hideError = null,
  children,
}) => {
  const [value, setValue] = useState(defaultInputValue);

  useEffect(() => {
    setValue(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <div
      className={`${styles.input_field_container} ${
        styles[extraClass] ?? ""
      } ${helperClasses} ${socialLinkURL && styles.social_link_url} ${
        prefix && styles.prefix
      }`}
    >
      <label
        htmlFor={inputFieldId}
        className={`${draggable && styles.draggable_field}`}
      >
        {draggable && (
          <span className={`ellipses ${styles.draggable_icon}`}></span>
        )}
        {deletable && (
          <button
            className={`${styles.delete_icon}`}
            onClick={() => {
              if (deleteCallback) deleteCallback(index, context, updateContext);
            }}
          >
            <ReactSVG src={delete_svg} wrapper={"span"} />
          </button>
        )}
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <input
          type={type}
          id={inputFieldId}
          name={inputFieldId}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => {
            const updatedValue = e.target.value;
            setValue(updatedValue);
            if (callback && !sendIDInUpdate)
              callback(updatedValue, context, updateContext, index);
            if (callback && sendIDInUpdate)
              callback(
                updatedValue,
                inputFieldId,
                context,
                updateContext,
                index
              );
            if (hideError) hideError("");
          }}
        />
        <span className={styles.label_text}>{label}</span>
        {children}
      </label>
    </div>
  );
};

export default InputField;
