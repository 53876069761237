import React, { useState, createContext } from "react";

export const CRMContext = createContext();

export const CRMProvider = ({ children }) => {
  const [crm, setCrm] = useState({
    use_form: {
      enable: false,
      crm_details: {
        crmID: "convertkit", // convertkit || activecampaign || hubspot
        crm_options: {
          convertkit: {
            API_Key: "",
            API_Secret: "",
            form_id: 0,
            tag_id: 0,
          },
          activecampaign: {
            API_URL: "",
            API_Key: "",
            tag_id: 0,
          },
          hubspot: {
            API_Key: "",
          },
        },
      },
      crm_fields: {
        first_name: true,
        last_name: true,
        phone: true,
        email: true,
        company: false,
        note: false,
      },
    },
    use_password: {
      enable: false,
      password: "",
    },
    no_one: {
      enable: false,
    },
    everyone: {
      enable: true,
    },
  });

  return (
    <CRMContext.Provider value={[crm, setCrm]}>{children}</CRMContext.Provider>
  );
};
