import React from "react";
import BarChart from "../../common/charts/barChart";

const SocialBarChart = () => {
  const data = [
    ["New York City, NY", 8175000],
    ["Los Angeles, CA", 3792000],
    ["Chicago, IL", 2695000],
    ["Houston, TX", 2099000],
    ["Philadelphia, PA", 1526000],
  ];

  return (
    <BarChart data={data} />
  );
};

export default SocialBarChart;
