export const update_first_name = (value, profileInfo, setProfileInfo) => {
  const profileInfoCopy = { ...profileInfo };
  profileInfoCopy.first_name = value;
  setProfileInfo(profileInfoCopy);
};
export const update_last_name = (value, profileInfo, setProfileInfo) => {
  const profileInfoCopy = { ...profileInfo };
  profileInfoCopy.last_name = value;
  setProfileInfo(profileInfoCopy);
};
export const update_job_title = (value, profileInfo, setProfileInfo) => {
  const profileInfoCopy = { ...profileInfo };
  profileInfoCopy.job_title = value;
  setProfileInfo(profileInfoCopy);
};
export const update_phone = (value, profileInfo, setProfileInfo) => {
  const profileInfoCopy = { ...profileInfo };
  profileInfoCopy.phone = value;
  setProfileInfo(profileInfoCopy);
};
export const update_email = (value, profileInfo, setProfileInfo) => {
  const profileInfoCopy = { ...profileInfo };
  profileInfoCopy.email = value;
  setProfileInfo(profileInfoCopy);
};
