import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const layout = data?.layout;
  return layout;
};

export const getLayoutFromDB = (setLayout, username = null, data = null) => {
  if (data) {
    const parsedData = parseDataForContext(data.cardDesign);
    if (!parsedData) return;
    setLayout(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/card_design`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setLayout(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Card Design Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/card_design/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setLayout(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
