import { swap } from "../../../helpers/utilityFunctions";

export const update_enable_video_gallery_section_title = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_title.enable = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_video_gallery_section_title = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_title.title = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_enable_video_gallery_section_icon = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_icon.enable = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_enable_video_gallery_section_background = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_background.enable = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_video_gallery_section_background_color = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_background.color = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_enable_video_link_gallery_section_background = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_gallery_section_background.enable = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_video_link_gallery_section_background_color = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_gallery_section_background.color = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_enable_video_link_font_and_icon_color = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_font_and_icon_color.enable = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_video_link_font_and_icon_color_color = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_font_and_icon_color.color = value;
  setVideoGallery(videoGalleryCopy);
};
export const video_link_font_and_icon_color_font = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_font_and_icon_color.font = value;
  setVideoGallery(videoGalleryCopy);
};
export const update_video_link_font_and_icon_color_font_styles = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_link_font_and_icon_color.font_bold = bold;
  videoGalleryCopy.video_link_font_and_icon_color.font_italic = italic;
  videoGalleryCopy.video_link_font_and_icon_color.font_underline = underline;
  videoGalleryCopy.video_link_font_and_icon_color.font_align_left = left_align;
  videoGalleryCopy.video_link_font_and_icon_color.font_align_right =
    right_align;
  videoGalleryCopy.video_link_font_and_icon_color.font_align_center =
    center_align;
  setVideoGallery(videoGalleryCopy);
};

export const update_gallery_icon = (file, videoGallery, setVideoGallery) => {
  const videoGalleryCopy = { ...videoGallery };

  if (!file) {
    videoGalleryCopy.video_gallery_section_icon.uploadable_file = "";
    videoGalleryCopy.video_gallery_section_icon.upload = "";
  } else {
    videoGalleryCopy.video_gallery_section_icon.uploadable_file = file;
    videoGalleryCopy.video_gallery_section_icon.upload =
      URL.createObjectURL(file);
  }
  setVideoGallery(videoGalleryCopy);
};

export const select_main_icon_type_video_gallery = (
  option,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_icon.type = option;
  setVideoGallery(videoGalleryCopy);
};

export const update_video_gallery_main_fa_icon_color = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_icon.color = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_main_fa_icon_name_video_gallery = (
  value,
  videoGallery,
  setVideoGallery
) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.video_gallery_section_icon.fa_icon = value;
  setVideoGallery(videoGalleryCopy);
};

export const addGalleryItem = (videoGallery, setVideoGallery) => {
  const videoGalleryCopy = { ...videoGallery };
  videoGalleryCopy.gallery_data.push({
    enable: true,
    title: "Video Title",
    video_url: "https://youtube.com",
    CTAIcon: {
      type: "fa", // img || fa
      fa_icon: "bell",
      color: {
        hex: "#222f4f",
        rgb: {
          r: 34,
          g: 47,
          b: 79,
          a: 1,
        },
      },
      preview_file: "", // Existing pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
    },
    cta_text: "CTA Text",
    cta_url: "yourdomain.com/landingpage",
    password: "",
    show: true,
    schedule: ["", ""],
  });
  setVideoGallery(videoGalleryCopy);
};

export const update_enable_gallery_video = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  targetVideo.enable = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_video_gallery_video_title = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  targetVideo.title = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_video_gallery_video_cta_text = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  targetVideo.cta_text = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_video_gallery_video_cta_url = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  targetVideo.cta_url = value;
  setVideoGallery(videoGalleryCopy);
};

export const delete_gallery_item = (videoGallery, setVideoGallery, index) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  videoGalleryCopy.gallery_data.splice(index, 1);
  setVideoGallery(videoGalleryCopy);
};

export const update_gallery_item_main_video = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  targetVideo.video_url = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_gallery_item_cta_icon = (
  file,
  videoGallery,
  setVideoGallery,
  index
) => {
  if (index === null) return;
  const videoGalleryCopy = { ...videoGallery };
  const targetVideo = videoGalleryCopy.gallery_data[index];
  if (!targetVideo) return;
  const innerVideoObj = targetVideo.CTAIcon;
  if (!innerVideoObj) return;
  if (!file) {
    innerVideoObj.uploadable_file = "";
    innerVideoObj.upload = "";
  } else {
    innerVideoObj.uploadable_file = file;
    innerVideoObj.upload = URL.createObjectURL(file);
  }
  setVideoGallery(videoGalleryCopy);
};

export const select_icon_type_video_gallery = (
  option,
  videoGallery,
  setVideoGallery,
  index
) => {
  const videoGalleryCopy = { ...videoGallery };
  const targetImage = videoGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.type = option;
  setVideoGallery(videoGalleryCopy);
};

export const update_videoGallery_fa_icon_color = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  const videoGalleryCopy = { ...videoGallery };
  const targetImage = videoGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.color = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_fa_icon_name_videoGallery = (
  value,
  videoGallery,
  setVideoGallery,
  index
) => {
  const videoGalleryCopy = { ...videoGallery };
  const targetImage = videoGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.fa_icon = value;
  setVideoGallery(videoGalleryCopy);
};

export const update_order_video_gallery = (
  swap1,
  swap2,
  videoGallery,
  setVideoGallery
) => {
  if (swap1 === undefined || swap2 === undefined) return;
  if (isNaN(swap1) || isNaN(swap2)) return;
  const videoGalleryCopy = { ...videoGallery };
  const { gallery_data } = videoGalleryCopy;
  if (!gallery_data[swap1] || !gallery_data[swap2]) return;
  swap(gallery_data, swap1, swap2);
  setVideoGallery(videoGalleryCopy);
};
