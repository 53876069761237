import React from "react";

import { DataLoadProvider } from "./dataLoad.context";
import { SidebarOrderProvider } from "./designerContexts/sectionsOrder.context";
import { CardDesignProvider } from "./designerContexts/cardDesign.context";
import { UsernameProvider } from "./designerContexts/username.context";
import { PersonalDetailsProvider } from "./designerContexts/personalDetails.context";
import { BrandingProvider } from "./designerContexts/branding.context";
import { FontsAndColorsProvider } from "./designerContexts/fontsAndColors.context";
import { ImageGalleryProvider } from "./sectionsContexts/imageGallery.context";
import { VideoGalleryProvider } from "./sectionsContexts/videoGallery.context";
import { CalendlyProvider } from "./sectionsContexts/calendly.context";
import { CRMProvider } from "./settingsContexts/crm.context";
import { TrackingCodesProvider } from "./settingsContexts/trackingCodes.context";
import { SocialLinksProvider } from "./sectionsContexts/socialLinks.context";
import { ProfileInfoProvider } from "./profileContexts/profile.context";
import { YoutubeProvider } from "./sectionsContexts/youtube.context";

const AllContexts = ({ children }) => {
  return (
    <DataLoadProvider>
      <SidebarOrderProvider>
        <CardDesignProvider>
          <UsernameProvider>
            <ProfileInfoProvider>
              <PersonalDetailsProvider>
                <BrandingProvider>
                  <FontsAndColorsProvider>
                    <ImageGalleryProvider>
                      <VideoGalleryProvider>
                        <CalendlyProvider>
                          <CRMProvider>
                            <TrackingCodesProvider>
                              <SocialLinksProvider>
                                <YoutubeProvider>{children}</YoutubeProvider>
                              </SocialLinksProvider>
                            </TrackingCodesProvider>
                          </CRMProvider>
                        </CalendlyProvider>
                      </VideoGalleryProvider>
                    </ImageGalleryProvider>
                  </FontsAndColorsProvider>
                </BrandingProvider>
              </PersonalDetailsProvider>
            </ProfileInfoProvider>
          </UsernameProvider>
        </CardDesignProvider>
      </SidebarOrderProvider>
    </DataLoadProvider>
  );
};

export default AllContexts;
