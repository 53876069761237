import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
// import { buildNotification } from "../../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const {
    channel_id,
    videos_list_type,
    number_of_dynamic_videos,
    dynamic_list_type,
    default_videos_list,
    custom_videos_list,
    customization,
    yt_id,
    yt_title,
    yt_description,
    yt_channel_thumbnail,
  } = data;

  return {
    loaded: true,
    channel_id,
    videos_list_type,
    number_of_dynamic_videos,
    dynamic_list_type,
    default_videos_list,
    custom_videos_list,
    customization,
    yt_id,
    yt_title,
    yt_description,
    yt_channel_thumbnail,
  };
};

export const getYoutubeFromDB = (
  youtube,
  setYoutube,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.youtubeCreator);
    if (!parsedData) {
      setYoutube({ ...youtube, loaded: true });
      return;
    }
    setYoutube(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/youtube_creator`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setYoutube(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Calendly Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/youtube_creator/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setYoutube(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
