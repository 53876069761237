import { API_BASE } from "../../../api_links";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadCRM = async (crm) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/crm`;

  const updateObj = {
    use_form: crm.use_form,
    use_password: crm.use_password,
    no_one: crm.no_one,
    everyone: crm.everyone,
  };

  console.log("Update data", updateObj);

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Privacy", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading Privacy settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Privacy Settings uploaded",
      });
    }
  });
};
