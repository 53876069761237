import React from "react";
import ListChart from "../../common/charts/list";

const SocialListChart = () => {
  const data = [
    {
      content: "The five minutes team makeover",
      value: "526",
    },
    {
      content: "Book a meeting with me",
      value: "462",
    },
    {
      content: "Join my webinar",
      value: "200",
    },
    {
      content: "Image Link - 1",
      value: "3920",
    },
    {
      content: "Video Link - 1",
      value: "222",
    },
  ];

  return <ListChart data={data} />;
};

export default SocialListChart;
