import React, { useState, createContext } from "react";

export const SocialLinksContext = createContext();

export const SocialLinksProvider = ({ children }) => {
  const [socialLinks, setSocialLinks] = useState({
    style: {
      enable: true,
      version: {
        // social_icon_style_1: false,
        social_icon_style_2: true,
        // social_icon_style_3: false,
        social_icon_style_4: false,
        // social_icon_style_5: false,
        social_icon_style_6: false,
        // social_icon_style_7: false,
        // social_icon_style_8: false,
      },
    },
    icons_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    social_links: {
      linkedin: {
        enable: true,
        url: "",
      },
      instagram: {
        enable: true,
        url: "",
      },
      twitter: {
        enable: true,
        url: "",
      },
      facebook: {
        enable: true,
        url: "",
      },
      facebook_messenger: {
        enable: false,
        url: "",
      },
      youtube: {
        enable: false,
        url: "",
      },
      podcast: {
        enable: false,
        url: "",
      },
      skype: {
        enable: false,
        url: "",
      },
      whatsapp: {
        enable: false,
        url: "",
      },
      pinterest: {
        enable: false,
        url: "",
      },
      snapchat: {
        enable: false,
        url: "",
      },
      wordpress: {
        enable: false,
        url: "",
      },
      twitch: {
        enable: false,
        url: "",
      },
    },
    order: [
      "linkedin",
      "facebook_messenger",
      "facebook",
      "instagram",
      "pinterest",
      "podcast",
      "skype",
      "snapchat",
      "twitter",
      "twitch",
      "whatsapp",
      "youtube",
      "wordpress",
    ],
  });

  return (
    <SocialLinksContext.Provider value={[socialLinks, setSocialLinks]}>
      {children}
    </SocialLinksContext.Provider>
  );
};
