import React from "react";
import AnalyticsChart from "../../common/charts/chart";

const LifetimeMapChart = () => {
  const data = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
    ["Pakistan", 200],
  ];

  const options = {
    legend: "none",
    colors: ["#81F2F9", "#1ba8f0", "#75D89D", "#FCC97B", "#E97D85", "#FF81DC"],
    backgroundColor: "transparent",
  };
  return (
    <AnalyticsChart
      chartType="GeoChart"
      data={data}
      options={options}
      width="90%"
    />
  );
};

export default LifetimeMapChart;
