export const update_enable_social_icons_style = (
  value,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  socialLinksCopy.style.enable = value;
  setSocialLinks(socialLinksCopy);
};

export const update_social_icon_style_version = (
  value,
  id,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  if (socialLinksCopy.style.version.hasOwnProperty(id)) {
    // Make others false first
    Object.keys(socialLinksCopy.style.version).forEach((key) => {
      socialLinksCopy.style.version[key] = false;
    });

    // Lets update now
    socialLinksCopy.style.version[id] = value;
    setSocialLinks(socialLinksCopy);
  }
};

export const update_enable_social_icons_color = (
  value,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  socialLinksCopy.icons_color.enable = value;
  setSocialLinks(socialLinksCopy);
};

export const update_social_icons_color = (
  value,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  socialLinksCopy.icons_color.color = value;
  setSocialLinks(socialLinksCopy);
};

export const update_enable_single_social_link = (
  value,
  id,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  if (socialLinksCopy.social_links.hasOwnProperty(id)) {
    // Lets update now
    socialLinksCopy.social_links[id].enable = value;
    setSocialLinks(socialLinksCopy);
  }
};

export const update_single_social_link_url = (
  value,
  id,
  socialLinks,
  setSocialLinks
) => {
  const socialLinksCopy = { ...socialLinks };
  if (socialLinksCopy.social_links.hasOwnProperty(id)) {
    // Lets update now
    socialLinksCopy.social_links[id].url = value;
    setSocialLinks(socialLinksCopy);
  }
};
