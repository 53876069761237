import React from "react";

import styles from "./notification.module.scss";
import "./notificationStyles.scss";

const Notifications = () => {
  return <div id="notifications" className={styles.notifications}></div>;
};

export default Notifications;
