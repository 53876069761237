import React, { useState, createContext } from "react";

export const PersonalDetailsContext = createContext();

export const PersonalDetailsProvider = ({ children }) => {
  const [personalDetails, setPersonalDetails] = useState({
    profile_picture_image_preview_file: "", // Existing profile pic
    profile_picture_image_uploadable_file: "", // file object if new pic selected
    profile_picture_image_upload: "", // preview link before uploading
    profile_picture_adjustments: {
      size: 0,
      top: 0,
      left: 0,
      rotate: 0,
    },
    border_style: {
      version: {
        rounded_border: true,
        square_border: false,
        circle_border: false,
      },
    },
    border_stroke: {
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
      width: 0,
    },
    background: {
      enable: false,
      color: {
        hex: "#222f4f",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    share_leads: true,
    leads_form_fields: {
      name: "Your Name",
      company_business_name: "Business Name",
      job_title: "Job Title",
      email: "",
      mobile: "",
      phone: "",
      extension: "",
    },
    custom_username: "",
  });

  return (
    <PersonalDetailsContext.Provider
      value={[personalDetails, setPersonalDetails]}
    >
      {children}
    </PersonalDetailsContext.Provider>
  );
};
