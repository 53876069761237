import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  parseRGBA,
  buildTextClass,
  buildCTAIconColorClass,
  buildZeroBorderRadiusClass,
} from "../../helpers/stylesheetGeneration";

import { checkYoutubeURL } from "../../helpers/utilityFunctions";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { DataLoadContext } from "../../contexts/dataLoad.context";
import { YoutubeContext } from "../../contexts/sectionsContexts/youtube.context";
import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import styles from "./youtubeCreator.module.scss";

import YouTubeCreatorSkeleton from "../../skeletons/youtubeCreatorSekeleton/youtubeCreatorSekeleton";

import YouTube from "../videoGalleryViewer/videoComponents/youTube";
import YouTubeSubscribe from "./ytSubscribe/ytSubscribe";

import defaultGalleryIcon from "../../../assets/umicardViewer/videoGalleryViewer/defaultGalleryIcon.svg";
import defaultGalleryImage from "../../../assets/umicardViewer/videoGalleryViewer/defaultGalleryItem.svg";

const YoutubeCreatorViewer = () => {
  const [dataLoad, setDataLoad] = useContext(DataLoadContext);
  const [youtube] = useContext(YoutubeContext);
  const [fontsAndColors] = useContext(FontsAndColorsContext);

  const [topSlider, setTopSlider] = useState(null);
  const [bottomSlider, setBottomSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [galleryDataState, setGalleryDataState] = useState([]);

  useEffect(() => {
    if (youtube.loaded) {
      setDataLoad({ ...dataLoad, youtube: true });
    }
  }, [youtube]);

  const topSliderSettings = {
    asNavFor: bottomSlider,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
  };
  const bottomSliderSettings = {
    asNavFor: topSlider,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  const {
    videos_list_type,
    number_of_dynamic_videos,
    // dynamic_list_type,
    default_videos_list,
    custom_videos_list,
    customization,
    yt_id,
  } = youtube;

  const { icon, background, headline, font, featured_color } = customization;

  const { sections_border_radius } = fontsAndColors;

  let gallery_data_parsed = [];

  useEffect(() => {
    if (videos_list_type === "dynamic") {
      gallery_data_parsed = [...default_videos_list].slice(
        0,
        number_of_dynamic_videos
      );
    } else {
      gallery_data_parsed = [...custom_videos_list];
    }

    if (custom_videos_list.length < 4)
      // make copies
      gallery_data_parsed = [
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
        ...gallery_data_parsed,
      ];

    setGalleryDataState(gallery_data_parsed);
  }, [youtube]);

  const youtubeCreatorMainIconColor = buildCTAIconColorClass(
    "youtube_creator_main_cta_icon",
    icon.color
  );

  const video_cta_link_custom_class = buildTextClass(
    true,
    font.name,
    font.bold,
    font.italic,
    font.underline,
    font.align_left,
    font.align_right,
    font.align_center,
    featured_color
  );

  return (
    <>
      {!dataLoad.youtube ? (
        <YouTubeCreatorSkeleton />
      ) : (
        <div
          id="youtube-creator-viewer"
          className={`${styles.youtube_creator_viewer} gallery_preview`}
          style={{
            borderRadius: !sections_border_radius && 0,
            backgroundColor: parseRGBA(background.color.rgb),
          }}
        >
          <div className={styles.youtube_creator_header}>
            <p className={styles.youtube_creator_heading}>{headline}</p>
            <div
              className={`${styles.youtube_creator_icon} ${youtubeCreatorMainIconColor}`}
              style={{ display: icon.enable ? "" : "none" }}
            >
              {icon.type === "img" && (
                <img
                  src={
                    icon.upload
                      ? icon.upload
                      : icon.preview_file
                      ? icon.preview_file
                      : defaultGalleryIcon
                  }
                  alt="Gallery Icon"
                />
              )}
              {icon.type === "fa" && <FontAwesomeIcon icon={icon.fa_icon} />}
            </div>
          </div>
          <p
            className={`${styles.youtube_creator_tagline} ${video_cta_link_custom_class}`}
          >
            {galleryDataState[currentSlide]?.snippet?.title ?? "Video Title"}
          </p>
          <div className={styles.sliders_container} id="video_top_slider">
            {galleryDataState.length > 0 && (
              <Slider
                {...topSliderSettings}
                ref={(slider) => setTopSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { snippet } = gallery_item;
                  const { title, resourceId } = snippet;
                  const { videoId } = resourceId;

                  let parsed_video_url = `https://youtube.com/v/${videoId}`;

                  return (
                    <div
                      key={index}
                      className={`${styles.gallery_slide} ${
                        !sections_border_radius && buildZeroBorderRadiusClass()
                      }`}
                    >
                      <>
                        {checkYoutubeURL(parsed_video_url) ? (
                          <YouTube
                            yt_id={checkYoutubeURL(parsed_video_url)}
                            title={title}
                          />
                        ) : (
                          <img src={defaultGalleryImage} alt={title} />
                        )}
                      </>
                    </div>
                  );
                })}
              </Slider>
            )}

            {/* Video description */}
            <div
              className={`${styles.gallery_cta}`}
              style={{ borderRadius: !sections_border_radius && 0 }}
            >
              <div className={styles.cta_text_url}>
                {galleryDataState[currentSlide] && (
                  <p className={video_cta_link_custom_class}>
                    {galleryDataState[
                      currentSlide
                    ]?.snippet?.description.substr(0, 150) ??
                      "Video description"}
                  </p>
                )}
              </div>
            </div>

            <span className={styles.sliders_divider}></span>
            {galleryDataState.length > 0 && (
              <Slider
                {...bottomSliderSettings}
                ref={(slider) => setBottomSlider(slider)}
              >
                {galleryDataState.map((gallery_item, index) => {
                  const { snippet } = gallery_item;
                  const { title, resourceId } = snippet;
                  const { videoId } = resourceId;

                  let parsed_video_url = `https://youtube.com/v/${videoId}`;

                  return (
                    <div key={index} className={styles.gallery_slide_bottom}>
                      <div
                        className={styles.thumbnail}
                        style={{ borderRadius: !sections_border_radius && 0 }}
                      >
                        <React.Fragment>
                          {checkYoutubeURL(parsed_video_url) ? (
                            <YouTube
                              yt_id={checkYoutubeURL(parsed_video_url)}
                              title={title}
                              thumbnail={true}
                            />
                          ) : (
                            <img src={defaultGalleryImage} alt={title} />
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}

            <YouTubeSubscribe
              // channelName={channelName}
              channelid={yt_id}
              theme={"default"}
              layout={"full"}
              count={"default"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default YoutubeCreatorViewer;
