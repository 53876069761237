import React from "react";

import CollapsibleContainer from "../collapsibleContainer/collapsibleContainer";
import InputField from "../inputField/inputField";
import ImageUploader from "../imageUploader/imageUploader";
import CTAIconSelector from "../ctaIconSelector/ctaIconSelector";
import AnalyticsFooter from "../analyticsFooter/analyticsFooter";

import {
  update_enable_gallery_video,
  update_video_gallery_video_title,
  update_gallery_item_main_video,
  update_gallery_item_cta_icon,
  update_video_gallery_video_cta_text,
  update_video_gallery_video_cta_url,
  delete_gallery_item,
  select_icon_type_video_gallery,
  update_videoGallery_fa_icon_color,
  update_fa_icon_name_videoGallery,
} from "../../designer/sections/videoGallery/updateFunctions";

const VideoGalleryEditableItem = ({
  styles,
  title,
  inputId,
  index,
  context,
  updateContext,
}) => {
  let targetGalleryItem = context.gallery_data[index];

  return (
    <CollapsibleContainer
      title={targetGalleryItem?.title ?? title}
      inputId={inputId}
      draggableItem={false}
      enabled={targetGalleryItem?.enable}
      callback={update_enable_gallery_video}
      context={context}
      updateContext={updateContext}
      index={index}
    >
      <div className={styles.editable_section}>
        <InputField
          inputFieldId={`${inputId}_title`}
          label={`Video title`}
          defaultInputValue={targetGalleryItem?.title ?? `Our Video Collection`}
          callback={update_video_gallery_video_title}
          context={context}
          updateContext={updateContext}
          index={index}
        />

        <InputField
          inputFieldId={`${inputId}_url`}
          label={`Video URL`}
          placeholder={`Enter YouTube or Vimeo URL without https:// at start`}
          prefix={"https://"}
          defaultInputValue={targetGalleryItem?.video_url ?? "youtube.com"}
          callback={update_gallery_item_main_video}
          context={context}
          updateContext={updateContext}
          index={index}
        />

        <p className={styles.section_info_text}>Change CTA Icon</p>
        <CTAIconSelector
          inputFieldId={inputId}
          selected={targetGalleryItem.CTAIcon?.type}
          selectedIcon={targetGalleryItem.CTAIcon?.fa_icon}
          iconColor={targetGalleryItem.CTAIcon?.color}
          selectorCallback={select_icon_type_video_gallery}
          imageCallback={update_gallery_item_cta_icon}
          iconSelectorCallback={update_fa_icon_name_videoGallery}
          iconColorCallback={update_videoGallery_fa_icon_color}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <InputField
          inputFieldId={`${inputId}_cta_text`}
          label={"CTA Text"}
          defaultInputValue={targetGalleryItem?.cta_text ?? "CTA Text Here"}
          callback={update_video_gallery_video_cta_text}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <InputField
          inputFieldId={`${inputId}_cta_url`}
          label={"CTA URL"}
          prefix={"https://"}
          defaultInputValue={
            targetGalleryItem?.cta_url ?? "yourdomain.com/landingpage"
          }
          callback={update_video_gallery_video_cta_url}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <AnalyticsFooter
          deleteCallback={delete_gallery_item}
          context={context}
          updateContext={updateContext}
          index={index}
          type={"video"}
        />
      </div>
    </CollapsibleContainer>
  );
};

export default VideoGalleryEditableItem;
