import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data?.use_form) return;

  return {
    use_form: data.use_form,
    use_password: data.use_password,
    no_one: data.no_one,
    everyone: data?.everyone ?? { enable: false },
  };
};

export const getCRMFromDB = (setCrm, username = null, data = null) => {
  if (data) {
    const parsedData = parseDataForContext(data.crm);
    if (!parsedData) return;
    setCrm(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/crm`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setCrm(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "CRM Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/crm/${username}`;
    console.log(url);
    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setCrm(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
