import React, { useState } from "react";
import { FA_ICONS } from "../../helpers/fa_icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ImageUploader from "../imageUploader/imageUploader";
import ColorPicker from "../colorPicker/colorPicker";
import RadioInputField from "../radioInputField/radioInputField";
import Modal from "../modal/modal";

import styles from "./ctaIconSelector.module.scss";

// import cta_icon from "../../../assets/global/icon_cta.svg";

const CTAIconSelector = ({
  inputFieldId,
  selected = "fa",
  selectedIcon = "bell",
  iconColor = {
    hex: "#ffffff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  },
  selectorCallback = null,
  imageCallback = null,
  iconSelectorCallback = null,
  iconColorCallback = null,
  context = null,
  updateContext = null,
  index = null,
}) => {
  const [openCTAIconsList, setOpenCTAIconsList] = useState(false);

  return (
    <>
      <div className={styles.cta_selector}>
        <div className={styles.icon_box}>
          <RadioInputField
            forGroup={inputFieldId}
            inputFieldId={inputFieldId + "_fa"}
            option={"fa"}
            selected={selected === "fa"}
            callback={selectorCallback}
            context={context}
            updateContext={updateContext}
            index={index}
          >
            <p className={styles.info}>Use an existing icon</p>
            <div className={styles.fa_picker}>
              <div className={styles.demo}>
                {/* <img src={cta_icon} alt="CTA Default Icon Demo" /> */}
                <FontAwesomeIcon icon={selectedIcon} />
              </div>
              <div className={styles.selector}>
                <button
                  className={styles.select_btn}
                  onClick={() => {
                    setOpenCTAIconsList(!openCTAIconsList);
                  }}
                >
                  Change Icon
                </button>
              </div>
            </div>
          </RadioInputField>
        </div>
        <div className={styles.icon_box}>
          <RadioInputField
            forGroup={inputFieldId}
            inputFieldId={inputFieldId + "_img"}
            option={"img"}
            selected={selected === "img"}
            callback={selectorCallback}
            context={context}
            updateContext={updateContext}
            index={index}
          >
            <p className={styles.info}>Upload your own icon</p>
            <ImageUploader
              inputFieldId={inputFieldId}
              callback={imageCallback}
              context={context}
              updateContext={updateContext}
              index={index}
            />
          </RadioInputField>
        </div>
      </div>

      <span className="vertical_space"></span>
      <p className={styles.info}>Change color</p>
      <ColorPicker
        inputFieldId={inputFieldId}
        savedColor={iconColor}
        callback={iconColorCallback}
        context={context}
        updateContext={updateContext}
        index={index}
      />
      <Modal open={openCTAIconsList} closer={setOpenCTAIconsList}>
        <h3>Select Icon</h3>
        <div className={styles.cta_selections}>
          {FA_ICONS.map((icon_name) => {
            return (
              <div
                key={icon_name}
                className={styles.cta_selection}
                onClick={() => {
                  iconSelectorCallback(
                    icon_name,
                    context,
                    updateContext,
                    index
                  );
                  setOpenCTAIconsList(!openCTAIconsList);
                }}
              >
                <div className={styles.cta_inner}>
                  <FontAwesomeIcon icon={icon_name} />
                  <p className={styles.title}>{icon_name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default CTAIconSelector;
