import React, { useContext } from "react";

import { VideoGalleryContext } from "../../../contexts/sectionsContexts/videoGallery.context";

import { uploadVideoGallery } from "./uploadFunction";

import {
  update_enable_video_gallery_section_title,
  update_video_gallery_section_title,
  update_enable_video_gallery_section_icon,
  select_main_icon_type_video_gallery,
  update_video_gallery_main_fa_icon_color,
  update_main_fa_icon_name_video_gallery,
  update_enable_video_gallery_section_background,
  update_video_gallery_section_background_color,
  update_enable_video_link_gallery_section_background,
  update_video_link_gallery_section_background_color,
  update_enable_video_link_font_and_icon_color,
  update_video_link_font_and_icon_color_color,
  video_link_font_and_icon_color_font,
  update_video_link_font_and_icon_color_font_styles,
  update_gallery_icon,
  addGalleryItem,
  update_order_video_gallery,
} from "./updateFunctions";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../../common/inputField/inputField";
import CTAIconSelector from "../../../common/ctaIconSelector/ctaIconSelector";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import FontPicker from "../../../common/fontPicker/fontPicker";
import VideoGalleryEditableItem from "../../../common/imageVideoGalleryEditableItem/videoGalleryEditableItem";
import AddMoreEdirableItemButton from "../../../common/addMoreEditableItemButton/addMoreEdirableItemButton";

const VideoGallery = ({ styles }) => {
  const [videoGallery, setVideoGallery] = useContext(VideoGalleryContext);

  const {
    video_gallery_section_title,
    video_gallery_section_icon,
    video_gallery_section_background,
    video_link_gallery_section_background,
    video_link_font_and_icon_color,
    gallery_data,
  } = videoGallery;

  const { enable: enable_gallery_section_title, title } =
    video_gallery_section_title;

  const { enable: enable_gallery_section_icon } = video_gallery_section_icon;

  const {
    enable: enable_gallery_section_background,
    color: video_gallery_section_background_color,
  } = video_gallery_section_background;

  const {
    enable: enable_video_link_gallery_section_background,
    color: video_link_gallery_background_color,
  } = video_link_gallery_section_background;

  const {
    enable: enable_video_link_font_color,
    color: video_link_color,
    font: video_link_font,
    font_bold: video_link_font_bold,
    font_italic: video_link_font_italic,
    font_underline: video_link_font_underline,
    font_align_left: video_link_font_align_left,
    font_align_right: video_link_font_align_right,
    font_align_center: video_link_font_align_center,
  } = video_link_font_and_icon_color;

  return (
    <div className="video_gallery">
      <SectionTopBar
        title={"Video Gallery"}
        id={"video-gallery-section-designer"}
        updateCallback={uploadVideoGallery}
        updateData={videoGallery}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Title"
          inputId="enable_video_gallery_section_title"
          enabled={enable_gallery_section_title}
          callback={update_enable_video_gallery_section_title}
          context={videoGallery}
          updateContext={setVideoGallery}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"video_gallery_section_title"}
              label={"Video Gallery Section Title"}
              extraClass={"no_label"}
              defaultInputValue={title}
              callback={update_video_gallery_section_title}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Icon"
          inputId="enable_video_gallery_section_icon"
          enabled={enable_gallery_section_icon}
          callback={update_enable_video_gallery_section_icon}
          context={videoGallery}
          updateContext={setVideoGallery}
        >
          <div className={styles.editable_section}>
            <p className={styles.section_info_text}>Change icon</p>
            <CTAIconSelector
              inputFieldId={"video_gallery_main_icon"}
              selected={video_gallery_section_icon?.type}
              selectedIcon={video_gallery_section_icon?.fa_icon}
              iconColor={video_gallery_section_icon?.color}
              selectorCallback={select_main_icon_type_video_gallery}
              imageCallback={update_gallery_icon}
              iconSelectorCallback={update_main_fa_icon_name_video_gallery}
              iconColorCallback={update_video_gallery_main_fa_icon_color}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Video Section Background Color"
          inputId="enable_video_gallery_section_background"
          enabled={enable_gallery_section_background}
          callback={update_enable_video_gallery_section_background}
          context={videoGallery}
          updateContext={setVideoGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="video_gallery_section_background_color"
              savedColor={video_gallery_section_background_color}
              callback={update_video_gallery_section_background_color}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Video Link - Background Color"
          inputId="enable_video_link_gallery_section_background"
          enabled={enable_video_link_gallery_section_background}
          callback={update_enable_video_link_gallery_section_background}
          context={videoGallery}
          updateContext={setVideoGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="video_link_gallery_section_background_color"
              savedColor={video_link_gallery_background_color}
              callback={update_video_link_gallery_section_background_color}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Video Link – Font and Color Settings"
          inputId="enable_video_link_font_and_icon_color_gallery_section"
          enabled={enable_video_link_font_color}
          callback={update_enable_video_link_font_and_icon_color}
          context={videoGallery}
          updateContext={setVideoGallery}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="video_link_font_and_icon_color_gallery_section_color"
              savedColor={video_link_color}
              callback={update_video_link_font_and_icon_color_color}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
            <FontPicker
              inputFieldId="video_link_font_and_icon_color_gallery_section_font"
              defaultInputValue={video_link_font}
              savedFontStyles={{
                bold: video_link_font_bold,
                italic: video_link_font_italic,
                underline: video_link_font_underline,
                left_align: video_link_font_align_left,
                right_align: video_link_font_align_right,
                center_align: video_link_font_align_center,
              }}
              callback_font_name={video_link_font_and_icon_color_font}
              callback_font_styles={
                update_video_link_font_and_icon_color_font_styles
              }
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>
            Re-Order Video Gallery Items
          </h4>
          <p className={styles.section_info_text}>
            Drag and Drop video gallery items to re-order them in carousel
          </p>
          <div className={styles.arrange_gallery_items}>
            {gallery_data.map((video, index) => {
              return (
                <div
                  key={index}
                  className={styles.arrange_item_div}
                  draggable
                  onDragStart={(e) => {
                    e.stopPropagation();
                    e.dataTransfer.setData("index", index);
                  }}
                  onDragEnter={(e) => {
                    e.stopPropagation();
                    e.target.style.opacity = 0.5;
                  }}
                  onDragLeave={(e) => {
                    e.stopPropagation();
                    e.target.style.opacity = 1;
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.target.style.opacity = 1;
                  }}
                  onDrop={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const dropperID = e.dataTransfer.getData("index");
                    update_order_video_gallery(
                      dropperID,
                      index,
                      videoGallery,
                      setVideoGallery
                    );
                  }}
                >
                  <div className={styles.arrange_div_block}>
                    <p>{video?.title ?? `Video ${index + 1}`}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {gallery_data.map((video, index) => {
        return (
          <div
            key={index}
            className={`${styles.designer_white_box} ${styles.details_editor}`}
          >
            <VideoGalleryEditableItem
              styles={styles}
              title={`Video ${index + 1}`}
              inputId={"video_gallery_image_" + index}
              index={index}
              context={videoGallery}
              updateContext={setVideoGallery}
            />
          </div>
        );
      })}

      <AddMoreEdirableItemButton
        title={"Add More Videos"}
        callback={addGalleryItem}
        context={videoGallery}
        updateContext={setVideoGallery}
      />
    </div>
  );
};

export default VideoGallery;
