import React from "react";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";

const NavLinkRender = ({ src, styles, text, destination, fullURL = false }) => {
  return (
    <React.Fragment>
      {fullURL ? (
        <a
          href={destination}
          target="_blank"
          className={`${styles.nav_link} vertical_align_center`}
        >
          <span className={styles.nav_link_inner}>
            <span className={styles.nav_link_icon}>
              <ReactSVG src={src} wrapper={"span"} />
            </span>{" "}
            {text}
          </span>
        </a>
      ) : (
        <NavLink
          to={destination}
          className={`${styles.nav_link} vertical_align_center`}
          activeClassName={styles.active}
        >
          <span className={styles.nav_link_inner}>
            <span className={styles.nav_link_icon}>
              <ReactSVG src={src} wrapper={"span"} />
            </span>{" "}
            {text}
          </span>
        </NavLink>
      )}
    </React.Fragment>
  );
};

export default NavLinkRender;
