import { API_BASE } from "../../../../api_links";
import { uploadStaticData } from "../../../../uploadServices/uploadStaticData";

export const uploadProfileInfo = (profileInfo) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/update_profile_info`;
  const updateObj = {
    first_name: profileInfo.first_name,
    last_name: profileInfo.last_name,
    email: profileInfo.email,
    phone: profileInfo.phone,
    job_title: profileInfo.job_title,
  };

  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Profile info update", result);
    if (result?.message) {
      console.log("Failed to update profile info");
    } else {
      console.log("Profile info updated!");
    }
  });
};
