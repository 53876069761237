import React from "react";
import "../iubenda";

const TermsAndConditions = () => {
  return (
    <>
      <a
        href="https://www.iubenda.com/terms-and-conditions/48266867"
        className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
        title="Privacy Policy"
      >
        Terms and Conditions
      </a>
    </>
  );
};

export default TermsAndConditions;
