import React from "react";

import CollapsibleContainer from "../collapsibleContainer/collapsibleContainer";
import InputField from "../inputField/inputField";
import ImageUploader from "../imageUploader/imageUploader";
import AnalyticsFooter from "../analyticsFooter/analyticsFooter";

const AboutLinkEditableItem = ({ styles, title, inputId }) => {
  return (
    <CollapsibleContainer title={title} inputId={inputId} draggableItem={true}>
      <div className={styles.editable_section}>
        <InputField
          inputFieldId={`${inputId}_title`}
          label={`Link title`}
          defaultInputValue={"Link Title"}
        />
        <InputField
          inputFieldId={`${inputId}_url`}
          label={"URL"}
          placeholder={"Enter your YouTube or Vimeo URL here"}
        />
        <p className={styles.section_info_text}>Change Icon</p>
        <ImageUploader inputFieldId={`${inputId}_icon`} />
        <AnalyticsFooter />
      </div>
    </CollapsibleContainer>
  );
};

export default AboutLinkEditableItem;
