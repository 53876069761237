import React from "react";

import CollapsibleHeader from "./collapsibleHeader/collapsibleHeader";

import styles from "./collapsibleContainer.module.scss";

const CollapsibleContainer = ({
  smaller,
  title,
  subtitle = "",
  inputId,
  draggableItem,
  showSwitch,
  showIconLeft,
  helpIcon,
  enabled = false,
  callback = null,
  context = null,
  updateContext = null,
  index = null,
  children,
}) => {
  const collapseBodyRef = React.createRef();

  return (
    <div>
      <div className="header">
        <CollapsibleHeader
          smaller={smaller}
          title={title}
          subtitle={subtitle}
          inputId={inputId}
          draggableItem={draggableItem}
          showSwitch={showSwitch}
          showIconLeft={showIconLeft}
          helpIcon={helpIcon}
          enabled={enabled}
          callback={callback}
          context={context}
          updateContext={updateContext}
          index={index}
          bodyRef={collapseBodyRef}
        />
      </div>
      <div className={styles.collapse_body} ref={collapseBodyRef}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleContainer;
