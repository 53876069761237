import React, { useEffect } from "react";

import LandingPagesWrapper from "../../common/landingPagesWrapper/landingPagesWrapper";

import "../../../assets/styles/webflow/components.css";
import "../../../assets/styles/webflow/main.css";
import "./style.css";

const LandingPage = () => {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
    window.document.body.classList.add("webflow");
  });

  return (
    <LandingPagesWrapper>
      <main className="main-wrapper">
        <section
          data-w-id="3af3ebdd-c9f2-e2cc-58f7-c1047be88e43"
          className="section-hero wf-section"
        >
          <div className="section-spacing gap-hero">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="hero-inner-wrapper">
                  <div className="hero-content">
                    <div className="text-align-center">
                      <div
                        data-w-id="9ab6bd03-8906-2cfa-bc01-a0b1416527fd"
                        style={{ opacity: 0 }}
                        className="text-size-large"
                      >
                        If You Are a
                        <span className="text-color-primary">Professional</span>
                        , You must have an
                        <span className="text-color-primary">UmiCard!</span>
                      </div>
                      <h1
                        data-w-id="51a7d9b6-2c36-eac6-5702-b12821638c72"
                        style={{ opacity: 0 }}
                      >
                        use{" "}
                        <span className="background-color-gradient is-clipped">
                          Umi
                        </span>
                        <span className="text-color-secondary">Card</span> to
                        <br />
                      </h1>
                      <div
                        data-w-id="555818cd-95e8-7c62-49bf-e62711e53a85"
                        style={{ opacity: 0 }}
                        className="typed-wrapper"
                      >
                        <h1 className="typed">
                          Grow your audience
                          <br />
                        </h1>
                      </div>
                      <a
                        data-w-id="c964df97-a07a-57ee-7b3f-8a1a6a27a30f"
                        style={{ opacity: 0 }}
                        href="/signup"
                        className="button is-secondary w-button"
                      >
                        Claim Your UmiCard™  for FREE
                      </a>
                    </div>
                  </div>
                  <div
                    data-w-id="bf7a1504-f48a-f07f-7891-78e7fccfc6e7"
                    style={{ opacity: 0 }}
                    className="hero-mockup"
                  >
                    <img
                      src="images/Mockup-Background.svg"
                      alt=""
                      className="hero-mockup-background"
                    />
                    <div className="mockup-wrapper">
                      <img
                        src="images/Group-105_1Group-105.webp"
                        sizes="(max-width: 479px) 44vw, 150px"
                        width="276"
                        srcSet="
                          images/Group-105_1-p-500.png     500w,
                          images/Group-105_1Group-105.webp 552w
                        "
                        alt=""
                        className="mockup-image"
                      />
                    </div>
                    <div className="hero-floating-icons">
                      <img
                        src="images/Badge-03.svg"
                        loading="lazy"
                        alt=""
                        className="badge _10"
                      />
                      <img
                        src="images/Badge-02.svg"
                        loading="lazy"
                        alt=""
                        className="badge _09"
                      />
                      <img
                        src="images/Badge-08.svg"
                        loading="lazy"
                        alt=""
                        className="badge _08"
                      />
                      <img
                        src="images/Badge-07.svg"
                        loading="lazy"
                        alt=""
                        className="badge _07"
                      />
                      <img
                        src="images/Badge-01.svg"
                        loading="lazy"
                        alt=""
                        className="badge _06"
                      />
                      <img
                        src="images/Badge-09.svg"
                        loading="lazy"
                        alt=""
                        className="badge _05"
                      />
                      <img
                        src="images/Badge-10.svg"
                        loading="lazy"
                        alt=""
                        className="badge _04"
                      />
                      <img
                        src="images/Badge-05.svg"
                        loading="lazy"
                        alt=""
                        className="badge _03"
                      />
                      <img
                        src="images/Badge-04.svg"
                        loading="lazy"
                        alt=""
                        className="badge _01"
                      />
                      <img
                        src="images/Badge-06.svg"
                        loading="lazy"
                        alt=""
                        className="badge _02"
                      />
                      <img
                        src="images/Social-04.svg"
                        loading="lazy"
                        alt=""
                        className="social _12"
                      />
                      <img
                        src="images/Social-09.svg"
                        loading="lazy"
                        alt=""
                        className="social _11"
                      />
                      <img
                        src="images/Social-08.svg"
                        loading="lazy"
                        alt=""
                        className="social _13"
                      />
                      <img
                        src="images/Social-10.svg"
                        loading="lazy"
                        alt=""
                        className="social _10"
                      />
                      <img
                        src="images/Social-13.svg"
                        loading="lazy"
                        alt=""
                        className="social _09"
                      />
                      <img
                        src="images/Social-06.svg"
                        loading="lazy"
                        alt=""
                        className="social _08"
                      />
                      <img
                        src="images/Social-07.svg"
                        loading="lazy"
                        alt=""
                        className="social _07"
                      />
                      <img
                        src="images/Social-05.svg"
                        loading="lazy"
                        alt=""
                        className="social _06"
                      />
                      <img
                        src="images/Social-02.svg"
                        loading="lazy"
                        alt=""
                        className="social _05"
                      />
                      <img
                        src="images/Social-12.svg"
                        loading="lazy"
                        alt=""
                        className="social _04"
                      />
                      <img
                        src="images/Social-11.svg"
                        loading="lazy"
                        alt=""
                        className="social _03"
                      />
                      <img
                        src="images/Social-01.svg"
                        loading="lazy"
                        alt=""
                        className="social _02"
                      />
                      <img
                        src="images/Social-03.svg"
                        loading="lazy"
                        alt=""
                        className="social _01"
                      />
                      <div className="hero-floating-overlay"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="7a69dc09-27ca-4a85-b9a0-de5f831af16e"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_8a383b0e-1243-2f61-454e-370cfaa3f756-156ff136"
                    className="feature-content"
                  >
                    <h2>Fully customizable to match your brand</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          customize every single element to perfectly match it
                          with your brand and business theme.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_6f4e3ed8-3f71-00dd-274c-4ec074fc2b1b-156ff136"
                    className="feature-images-wrapper"
                  >
                    <div
                      id="w-node-_16585bea-bac4-5cc4-631c-9a13981ccbc4-156ff136"
                      style={{ opacity: 1 }}
                      className="feature-image-wrapper _1"
                    >
                      <div className="layer-1 _1">
                        <img
                          src="images/Layer-1-1_1Layer-1-1.webp"
                          loading="lazy"
                          sizes="(max-width: 991px) 100vw, 262.5px"
                          srcSet="
                            images/Layer-1-1_1-p-500.png     500w,
                            images/Layer-1-1_1Layer-1-1.webp 525w
                          "
                          width="262.5"
                          alt=""
                          className="layer-1 _1 image"
                        />
                      </div>
                      <div className="layer-2 _1">
                        <img
                          src="images/Layer-2_6Layer-2.webp"
                          loading="lazy"
                          width="202"
                          alt=""
                          className="layer-2 _1 image"
                        />
                      </div>
                      <div className="layer-3 _1">
                        <img
                          src="images/Layer-3_1Layer-3.webp"
                          loading="lazy"
                          width="246"
                          alt=""
                          className="layer-3 _1 image"
                        />
                      </div>
                      <div className="layer-4 _1">
                        <img
                          src="images/Rectangle-101.svg"
                          loading="lazy"
                          width="402"
                          alt=""
                          className="layer-4 _1 image"
                        />
                      </div>
                      <img
                        src="images/1_11.webp"
                        loading="lazy"
                        srcSet="
                          images/1_1-p-500.png   500w,
                          images/1_1-p-800.png   800w,
                          images/1_1-p-1080.png 1080w,
                          images/1_11.webp      1120w
                        "
                        sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                        width="560"
                        alt=""
                        className="layer-full _1"
                      />
                    </div>
                    <div
                      style={{ opacity: 0 }}
                      className="feature-image-wrapper _1 alt"
                    >
                      <div className="layer-1 _1">
                        <img
                          src="images/Group-66_1Group-66.webp"
                          loading="lazy"
                          sizes="(max-width: 991px) 100vw, 262.5px"
                          srcSet="
                            images/Group-66_1-p-500.png    500w,
                            images/Group-66_1Group-66.webp 505w
                          "
                          width="262.5"
                          alt=""
                          className="layer-1 _1 image"
                        />
                      </div>
                      <div className="layer-2 _1">
                        <img
                          src="images/Group-25_1Group-25.webp"
                          loading="lazy"
                          width="202"
                          alt=""
                          className="layer-2 _1 image"
                        />
                      </div>
                      <div className="layer-3 _1">
                        <img
                          src="images/Group-62_1Group-62.webp"
                          loading="lazy"
                          width="246"
                          alt=""
                          className="layer-3 _1 image"
                        />
                      </div>
                      <div className="layer-4 _1">
                        <img
                          src="images/Rectangle-101.svg"
                          loading="lazy"
                          width="402"
                          alt=""
                          className="layer-4 _1 image"
                        />
                      </div>
                      <img
                        src="images/1_11.webp"
                        loading="lazy"
                        srcSet="
                          images/1_1-p-500.png   500w,
                          images/1_1-p-800.png   800w,
                          images/1_1-p-1080.png 1080w,
                          images/1_11.webp      1120w
                        "
                        sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                        width="560"
                        alt=""
                        className="layer-full _1"
                      />
                    </div>
                    <div
                      style={{ opacity: 0 }}
                      className="feature-image-wrapper _1 alt"
                    >
                      <div className="layer-1 _1">
                        <img
                          src="images/Group-66_1Group-66.webp"
                          loading="lazy"
                          sizes="(max-width: 991px) 100vw, 262.5px"
                          srcSet="
                            images/Group-66_1-p-500.png    500w,
                            images/Group-66_1Group-66.webp 505w
                          "
                          width="262.5"
                          alt=""
                          className="layer-1 _1 image"
                        />
                      </div>
                      <div className="layer-2 _1">
                        <img
                          src="images/Group-25_1Group-25.webp"
                          loading="lazy"
                          width="202"
                          alt=""
                          className="layer-2 _1 image"
                        />
                      </div>
                      <div className="layer-3 _1">
                        <img
                          src="images/Group-62_1Group-62.webp"
                          loading="lazy"
                          width="246"
                          alt=""
                          className="layer-3 _1 image"
                        />
                      </div>
                      <div className="layer-4 _1">
                        <img
                          src="images/Rectangle-101.svg"
                          loading="lazy"
                          width="402"
                          alt=""
                          className="layer-4 _1 image"
                        />
                      </div>
                      <img
                        src="images/1_11.webp"
                        loading="lazy"
                        srcSet="
                          images/1_1-p-500.png   500w,
                          images/1_1-p-800.png   800w,
                          images/1_1-p-1080.png 1080w,
                          images/1_11.webp      1120w
                        "
                        sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                        width="560"
                        alt=""
                        className="layer-full _1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-slider wf-section">
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_976a2096-87e1-b17b-c5c0-b4ca5465b8f8-156ff136"
                    className="feature-image-wrapper is-switched _2"
                  >
                    <div
                      data-delay="4000"
                      data-animation="cross"
                      className="slider w-slider"
                      data-autoplay="true"
                      data-easing="ease"
                      data-hide-arrows="false"
                      data-disable-swipe="false"
                      data-autoplay-limit="0"
                      data-nav-spacing="4"
                      data-duration="500"
                      data-infinite="true"
                    >
                      <div className="w-slider-mask">
                        <div className="slide w-slide">
                          <img
                            src="images/Group-71_1Group-71.webp"
                            loading="lazy"
                            srcSet="
                              images/Group-71_1-p-500.png     500w,
                              images/Group-71_1-p-800.png     800w,
                              images/Group-71_1-p-1080.png   1080w,
                              images/Group-71_1Group-71.webp 1120w
                            "
                            sizes="(max-width: 479px) 93vw, (max-width: 767px) 71vw, (max-width: 991px) 72vw, 47vw"
                            alt=""
                            className="slide-image"
                          />
                        </div>
                        <div className="slide w-slide">
                          <img
                            src="images/Group-70-1_1Group-70-1.webp"
                            loading="lazy"
                            srcSet="
                              images/Group-70-1_1-p-500.png       500w,
                              images/Group-70-1_1-p-800.png       800w,
                              images/Group-70-1_1-p-1080.png     1080w,
                              images/Group-70-1_1Group-70-1.webp 1120w
                            "
                            sizes="(max-width: 479px) 93vw, (max-width: 767px) 71vw, (max-width: 991px) 72vw, 47vw"
                            alt=""
                            className="slide-image"
                          />
                        </div>
                        <div className="slide w-slide">
                          <img
                            src="images/Group-68-1_1Group-68-1.webp"
                            loading="lazy"
                            srcSet="
                              images/Group-68-1_1-p-500.png       500w,
                              images/Group-68-1_1-p-800.png       800w,
                              images/Group-68-1_1-p-1080.png     1080w,
                              images/Group-68-1_1Group-68-1.webp 1120w
                            "
                            sizes="(max-width: 479px) 93vw, (max-width: 767px) 71vw, (max-width: 991px) 72vw, 47vw"
                            alt=""
                            className="slide-image"
                          />
                        </div>
                      </div>
                      <div className="slider-arrow w-slider-arrow-left">
                        <div className="w-icon-slider-left"></div>
                      </div>
                      <div className="slider-arrow w-slider-arrow-right">
                        <div className="w-icon-slider-right"></div>
                      </div>
                      <div className="w-slider-nav w-slider-nav-invert w-round"></div>
                    </div>
                  </div>
                  <div
                    id="w-node-_976a2096-87e1-b17b-c5c0-b4ca5465b8ef-156ff136"
                    className="feature-content is-switched"
                  >
                    <h2>Easily Share Your UmiCard everywhere</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          share your single UmiCard link eveywhere and let
                          <br />
                          your leads and sales to grow.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="44a6d3a9-73a1-e482-3b7e-6a1e8aecd885"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_44a6d3a9-73a1-e482-3b7e-6a1e8aecd88a-156ff136"
                    className="feature-content"
                  >
                    <h2>So Easy to Manage &amp; Customize</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Customizing and managing your UmiCard is so easy,
                          simply select your bradning color and use the
                          editor&#x27;s drag and drop feature to manage, order
                          and customize your UmiCard.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_44a6d3a9-73a1-e482-3b7e-6a1e8aecd893-156ff136"
                    className="feature-image-wrapper _3"
                  >
                    <img
                      src="images/Layer-2-1_2Layer-2-1.webp"
                      loading="lazy"
                      width="509"
                      srcSet="
                        images/Layer-2-1_2-p-500.png      500w,
                        images/Layer-2-1_2-p-800.png      800w,
                        images/Layer-2-1_2Layer-2-1.webp 1018w
                      "
                      sizes="(max-width: 991px) 100vw, 47vw"
                      alt=""
                      className="layer-2 _3"
                    />
                    <img
                      src="images/Layer-4_1Layer-4.webp"
                      loading="lazy"
                      width="502"
                      srcSet="
                        images/Layer-4_1-p-500.png    500w,
                        images/Layer-4_1-p-800.png    800w,
                        images/Layer-4_1Layer-4.webp 1004w
                      "
                      sizes="(max-width: 991px) 100vw, 47vw"
                      alt=""
                      className="layer-4 _3"
                    />
                    <img
                      src="images/3_13.webp"
                      loading="lazy"
                      srcSet="
                        images/3_1-p-500.png   500w,
                        images/3_1-p-800.png   800w,
                        images/3_1-p-1080.png 1080w,
                        images/3_13.webp      1120w
                      "
                      sizes="(max-width: 767px) 75vw, (max-width: 991px) 560px, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="832258a5-3ef6-85d7-7501-c718fcf69903"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_832258a5-3ef6-85d7-7501-c718fcf69911-156ff136"
                    className="feature-image-wrapper is-switched _4"
                  >
                    <img
                      src="images/Layer-2-2_1Layer-2-2.webp"
                      loading="lazy"
                      width="261"
                      alt=""
                      className="layer-2 _4"
                    />
                    <img
                      src="images/Layer-3-1_1Layer-3-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 290px"
                      srcSet="
                        images/Layer-3-1_1-p-500.png     500w,
                        images/Layer-3-1_1Layer-3-1.webp 580w
                      "
                      width="290"
                      alt=""
                      className="layer-3 _4"
                    />
                    <img
                      src="images/Layer-4-1.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/4_14.webp"
                      loading="lazy"
                      srcSet="
                        images/4_1-p-500.png   500w,
                        images/4_1-p-800.png   800w,
                        images/4_1-p-1080.png 1080w,
                        images/4_14.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _4"
                    />
                  </div>
                  <div
                    id="w-node-_832258a5-3ef6-85d7-7501-c718fcf69908-156ff136"
                    className="feature-content is-switched"
                  >
                    <h2>Generate Leads</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Generate Leads directly from inside your card, and
                          <br />
                          not let the social and digital hypes and noises
                          distrac your audience.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="a8ba4b2b-2265-1878-55ad-9d0f866719b6"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-a8ba4b2b-2265-1878-55ad-9d0f866719bb-156ff136"
                    className="feature-content"
                  >
                    <h2>
                      Your CRM and
                      <br />
                      Email Autoresponder
                    </h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Connect UmiCard to your selected CRM to generate
                          <br />
                          leads on autopilot and make more sales.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                    <div>
                      <img
                        src="images/Email-Autoresponder_1Email-Autoresponder.webp"
                        loading="lazy"
                        width="490"
                        srcSet="
                          images/Email-Autoresponder_1-p-500.png               500w,
                          images/Email-Autoresponder_1-p-800.png               800w,
                          images/Email-Autoresponder_1Email-Autoresponder.webp 980w
                        "
                        sizes="(max-width: 479px) 93vw, (max-width: 767px) 92vw, 460px"
                        alt=""
                        className="image-platforms"
                      />
                    </div>
                  </div>
                  <div
                    id="w-node-a8ba4b2b-2265-1878-55ad-9d0f866719c4-156ff136"
                    className="feature-image-wrapper _5"
                  >
                    <img
                      src="images/Layer-1_4Layer-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 422px"
                      srcSet="
                        images/Layer-1_4-p-500.png   500w,
                        images/Layer-1_4-p-800.png   800w,
                        images/Layer-1_4Layer-1.webp 844w
                      "
                      width="422"
                      alt=""
                      className="layer-1 _5"
                    />
                    <img
                      src="images/Layer-2_1Layer-2.webp"
                      loading="lazy"
                      width="215"
                      alt=""
                      className="layer-2 _5"
                    />
                    <img
                      src="images/Layer-3_2Layer-3.webp"
                      loading="lazy"
                      width="240"
                      alt=""
                      className="layer-3 _5"
                    />
                    <img
                      src="images/Layer-4.svg"
                      loading="lazy"
                      width="430"
                      alt=""
                      className="layer-4 _5"
                    />
                    <img
                      src="images/5_15.webp"
                      loading="lazy"
                      srcSet="
                        images/5_1-p-500.png   500w,
                        images/5_1-p-800.png   800w,
                        images/5_1-p-1080.png 1080w,
                        images/5_15.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="498b9957-9f73-e65f-d909-a726122f6802"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_498b9957-9f73-e65f-d909-a726122f6810-156ff136"
                    className="feature-image-wrapper is-switched"
                  >
                    <img
                      src="images/Layer-1-1_2Layer-1-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 350px"
                      srcSet="
                        images/Layer-1-1_2-p-500.png     500w,
                        images/Layer-1-1_2Layer-1-1.webp 700w
                      "
                      width="350"
                      alt=""
                      className="layer-1 _6"
                    />
                    <img
                      src="images/Layer-2-1_1Layer-2-1.webp"
                      loading="lazy"
                      width="202"
                      alt=""
                      className="layer-2 _6"
                    />
                    <img
                      src="images/Layer-3_3Layer-3.webp"
                      loading="lazy"
                      width="240"
                      alt=""
                      className="layer-3 _6"
                    />
                    <img
                      src="images/Layer-4-1_2.svg"
                      loading="lazy"
                      width="430"
                      alt=""
                      className="layer-4 _6"
                    />
                    <img
                      src="images/6_16.webp"
                      loading="lazy"
                      srcSet="
                        images/6_1-p-500.png   500w,
                        images/6_1-p-800.png   800w,
                        images/6_1-p-1080.png 1080w,
                        images/6_16.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _6"
                    />
                  </div>
                  <div
                    id="w-node-_498b9957-9f73-e65f-d909-a726122f6807-156ff136"
                    className="feature-content is-switched"
                  >
                    <h2>Integrate it to your AutoResponder</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Autoamte your lead collection and email marketing,
                          <br />
                          by integrating UmiCard to your email marketing
                          <br />
                          software.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="998b9630-aced-1b56-cdd2-c0a9f299a34a"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_998b9630-aced-1b56-cdd2-c0a9f299a34f-156ff136"
                    className="feature-content"
                  >
                    <h2>Take Control of your Traffic</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Send your c ard visitors everywhere you want,
                          <br />
                          whether it&#x27;s generating lead, booking a c all,
                          buying,
                          <br />c ollec ting leads, your website, your soc ial
                          profiles,
                          <br />
                          your online store, . . .
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_998b9630-aced-1b56-cdd2-c0a9f299a358-156ff136"
                    className="feature-image-wrapper _7"
                  >
                    <img
                      src="images/Layer-1-2_2Layer-1-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 326px"
                      srcSet="
                        images/Layer-1-2_2-p-500.png     500w,
                        images/Layer-1-2_2Layer-1-2.webp 652w
                      "
                      width="326"
                      alt=""
                      className="layer-1 _7"
                    />
                    <img
                      src="images/Layer-2-2_2Layer-2-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 304.5px"
                      srcSet="
                        images/Layer-2-2_2-p-500.png     500w,
                        images/Layer-2-2_2Layer-2-2.webp 609w
                      "
                      width="304.5"
                      alt=""
                      className="layer-2 _7"
                    />
                    <img
                      src="images/Layer-3-2.png"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 299.5px"
                      srcSet="
                        images/Layer-3-2-p-500.png 500w,
                        images/Layer-3-2.png       599w
                      "
                      width="299.5"
                      alt=""
                      className="layer-3 _7"
                    />
                    <img
                      src="images/Layer-4-2.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/6_16.webp"
                      loading="lazy"
                      srcSet="
                        images/6_1-p-500.png   500w,
                        images/6_1-p-800.png   800w,
                        images/6_1-p-1080.png 1080w,
                        images/6_16.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _7"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="1c0c1b20-ca73-27b3-b098-ad7abe2ba571"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba576-156ff136"
                    className="feature-image-wrapper is-switched"
                  >
                    <img
                      src="images/Layer-1_3Layer-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 373px"
                      srcSet="
                        images/Layer-1_3-p-500.png   500w,
                        images/Layer-1_3Layer-1.webp 746w
                      "
                      width="373"
                      alt=""
                      className="layer-1 _1"
                    />
                    <img
                      src="images/Layer-2_2Layer-2.webp"
                      loading="lazy"
                      width="205"
                      alt=""
                      className="layer-2 _1"
                    />
                    <img
                      src="images/Layer-3_3Layer-3.webp"
                      loading="lazy"
                      width="240"
                      alt=""
                      className="layer-3 _1"
                    />
                    <img
                      src="images/Layer-4_1.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/8_18.webp"
                      loading="lazy"
                      srcSet="
                        images/8_1-p-500.png   500w,
                        images/8_1-p-800.png   800w,
                        images/8_1-p-1080.png 1080w,
                        images/8_18.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _8"
                    />
                  </div>
                  <div
                    id="w-node-_1c0c1b20-ca73-27b3-b098-ad7abe2ba57b-156ff136"
                    className="feature-content is-switched"
                  >
                    <h2>Protected, Secure and Compliant</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          We store the data in a secure and protect environment
                          that is certified for PCI DSS, HIPPA &amp; ISO27001
                          compliance requirements.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="f7efd667-51ee-cb60-2644-76485ab3fced"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-f7efd667-51ee-cb60-2644-76485ab3fcf2-156ff136"
                    className="feature-content"
                  >
                    <h2>Integrate to your marketing tools</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Easily integrate with your selected marketing tools
                          for receving analytics, targetting and retargetting
                          marketing campaigns as well as autoamting your lead
                          generation.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-f7efd667-51ee-cb60-2644-76485ab3fd01-156ff136"
                    className="feature-image-wrapper"
                  >
                    <img
                      src="images/Layer-1-1_3Layer-1-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 272px"
                      srcSet="
                        images/Layer-1-1_3-p-500.png     500w,
                        images/Layer-1-1_3Layer-1-1.webp 544w
                      "
                      width="272"
                      alt=""
                      className="layer-1 _9"
                    />
                    <img
                      src="images/Layer-2-1_3Layer-2-1.webp"
                      loading="lazy"
                      width="223"
                      alt=""
                      className="layer-2 _9"
                    />
                    <img
                      src="images/Layer-3-1_4Layer-3-1.webp"
                      loading="lazy"
                      width="245"
                      alt=""
                      className="layer-3 _9"
                    />
                    <img
                      src="images/Rectangle-101.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/9_19.webp"
                      loading="lazy"
                      srcSet="
                        images/9_1-p-500.png   500w,
                        images/9_1-p-800.png   800w,
                        images/9_1-p-1080.png 1080w,
                        images/9_19.webp      1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _9"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="543d9649-a434-2581-1cf0-272ce13f57dc"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_543d9649-a434-2581-1cf0-272ce13f57e1-156ff136"
                    className="feature-image-wrapper is-switched"
                  >
                    <img
                      src="images/Layer-1-2_1Layer-1-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 47vw"
                      srcSet="
                        images/Layer-1-2_1-p-500.png      500w,
                        images/Layer-1-2_1-p-800.png      800w,
                        images/Layer-1-2_1-p-1080.png    1080w,
                        images/Layer-1-2_1Layer-1-2.webp 1110w
                      "
                      width="555"
                      alt=""
                      className="layer-1 _10"
                    />
                    <img
                      src="images/Layer-2-2_3Layer-2-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 290px"
                      srcSet="
                        images/Layer-2-2_3-p-500.png     500w,
                        images/Layer-2-2_3Layer-2-2.webp 580w
                      "
                      width="290"
                      alt=""
                      className="layer-2 _10"
                    />
                    <img
                      src="images/Layer-4-2_1.svg"
                      loading="lazy"
                      width="402"
                      alt=""
                      className="layer-4 _1"
                    />
                    <img
                      src="images/10_110.webp"
                      loading="lazy"
                      srcSet="
                        images/10_1-p-500.png   500w,
                        images/10_1-p-800.png   800w,
                        images/10_1-p-1080.png 1080w,
                        images/10_110.webp     1140w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="570"
                      alt=""
                      className="layer-full _10"
                    />
                  </div>
                  <div
                    id="w-node-_543d9649-a434-2581-1cf0-272ce13f57e6-156ff136"
                    className="feature-content is-switched"
                  >
                    <h2>Get Insighful Analytics (Coming soon)</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Collect meaningful analytics and visitors insight to
                          <br />
                          help you laser focus your marketing.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-w-id="71299335-cd61-60a4-94e0-c2cdbdc61ba2"
          className="section-feature wf-section"
        >
          <div className="section-spacing">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="grid-halves">
                  <div
                    id="w-node-_71299335-cd61-60a4-94e0-c2cdbdc61ba7-156ff136"
                    className="feature-content"
                  >
                    <h2>Protect Your Contact Info</h2>
                    <div className="mb-xlarge">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          Stay in control of who can see your contact info, and
                          only share your contact info with the visitors you
                          want.
                        </p>
                      </div>
                    </div>
                    <a href="/signup" className="button w-button">
                      Get Started NOW
                    </a>
                  </div>
                  <div
                    id="w-node-_71299335-cd61-60a4-94e0-c2cdbdc61bb6-156ff136"
                    className="feature-image-wrapper _11"
                  >
                    <img
                      src="images/Layer-1_2Layer-1.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 326px"
                      srcSet="
                        images/Layer-1_2-p-500.png   500w,
                        images/Layer-1_2Layer-1.webp 652w
                      "
                      width="326"
                      alt=""
                      className="layer-1 _11"
                    />
                    <img
                      src="images/Layer-2_3Layer-2.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 273px"
                      srcSet="
                        images/Layer-2_3-p-500.png   500w,
                        images/Layer-2_3Layer-2.webp 546w
                      "
                      width="273"
                      alt=""
                      className="layer-2 _11"
                    />
                    <img
                      src="images/Layer-4_2Layer-4.webp"
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 47vw"
                      srcSet="
                        images/Layer-4_2-p-500.png    500w,
                        images/Layer-4_2-p-800.png    800w,
                        images/Layer-4_2Layer-4.webp 1020w
                      "
                      width="510"
                      alt=""
                      className="layer-4 _11"
                    />
                    <img
                      src="images/11_111.webp"
                      loading="lazy"
                      srcSet="
                        images/11_1-p-500.png   500w,
                        images/11_1-p-800.png   800w,
                        images/11_1-p-1080.png 1080w,
                        images/11_111.webp     1120w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 71vw, 100vw"
                      width="560"
                      alt=""
                      className="layer-full _11"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="section-community wf-section">
          <div className="section-spacing gap-community">
            <div className="page-padding">
              <div className="container-large w-container">
                <div className="community-content">
                  <div className="text-align-center">
                    <h2>Join the UmiCard community and make a matter</h2>
                    <div className="container-small">
                      <div className="text-weight-medium">
                        <p className="text-size-medium">
                          We actively support the UN&#x27;s sustainable goals to
                          make the world a better a place. Join us on this
                          journey, and make a business while making an impact.
                          <br />
                          <br />
                          We support...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-community">
                  <div
                    id="w-node-ffce7ee7-6783-3c0d-88e9-79a630fa002f-156ff136"
                    className="card"
                  >
                    <div className="card-content">
                      <div>Environment by planting trees</div>
                    </div>
                    <img
                      src="images/Rectangle_1Rectangle.webp"
                      loading="lazy"
                      srcSet="
                        images/Rectangle_1-p-500.png     500w,
                        images/Rectangle_1Rectangle.webp 517w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 767px) 44vw, (max-width: 991px) 46vw, 21vw"
                      alt=""
                      className="card-image"
                    />
                  </div>
                  <div
                    id="w-node-e098b266-7b21-e99b-3c50-34de7c0285d5-156ff136"
                    className="card"
                  >
                    <div className="card-content">
                      <div>Clean water projects</div>
                    </div>
                    <img
                      src="images/Rectangle-1_1Rectangle-1.webp"
                      loading="lazy"
                      width="258.5"
                      srcSet="
                        images/Rectangle-1_1-p-500.png       500w,
                        images/Rectangle-1_1Rectangle-1.webp 517w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 767px) 44vw, (max-width: 991px) 46vw, 21vw"
                      alt=""
                      className="card-image"
                    />
                  </div>
                  <div
                    id="w-node-_6acc3555-16f3-8995-cc43-8b453a1adb97-156ff136"
                    className="card"
                  >
                    <div className="card-content">
                      <div>Cancer research</div>
                    </div>
                    <img
                      src="images/Rectangle-2_1Rectangle-2.webp"
                      loading="lazy"
                      width="258.5"
                      alt=""
                      className="card-image"
                    />
                  </div>
                  <div
                    id="w-node-d486c00e-c718-bc78-d0c6-2ba91ce712ec-156ff136"
                    className="card"
                  >
                    <div className="card-content">
                      <div>Education support</div>
                    </div>
                    <img
                      src="images/Rectangle-3_1Rectangle-3.webp"
                      loading="lazy"
                      width="258.5"
                      srcSet="
                        images/Rectangle-3_1-p-500.png       500w,
                        images/Rectangle-3_1Rectangle-3.webp 517w
                      "
                      sizes="(max-width: 479px) 93vw, (max-width: 767px) 44vw, (max-width: 991px) 46vw, 21vw"
                      alt=""
                      className="card-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LandingPagesWrapper>
  );
};

export default LandingPage;
