import layout_1 from "../../../assets/demo-content/cardDesignLayouts/2.jpeg";
import layout_2 from "../../../assets/demo-content/cardDesignLayouts/1.jpeg";
import layout_3 from "../../../assets/demo-content/cardDesignLayouts/3.jpeg";
import layout_4 from "../../../assets/demo-content/cardDesignLayouts/4.jpeg";

export const layouts = [
  { id: "layout_1", img: layout_1 },
  { id: "layout_2", img: layout_2 },
  { id: "layout_3", img: layout_3 },
  { id: "layout_4", img: layout_4 },
];
