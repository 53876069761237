import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { deleteImageS3 } from "../../../uploadServices/imageUploader";

import ConfrimationPopup from "../confirmationPopup/confirmationPopup";

import styles from "./imageUploader.module.scss";

import upload_svg from "../../../assets/global/icon_upload.svg";
import delete_svg from "../../../assets/global/icon_delete.svg";

const ImageUploader = ({
  inputFieldId,
  callback = null,
  uploadedPicture = null,
  removeUploadedPicture = null,
  afterUpdateCallback = null,
  context = null,
  updateContext = null,
  index = null,
}) => {
  const [imageFile, setImageFile] = useState(null);
  const [info, setInfo] = useState(
    "Drop file here, or click to browse (Max 2MB)"
  );
  const [deletePopup, showDeletePopup] = useState(false);

  const fileInputRef = React.createRef();

  const previewImage = (e) => {
    if (e.target.files.length > 0) {
      const targetFile = e.target.files[0];
      if (!targetFile.type.includes("image")) {
        setImageFile(null);
        setInfo("Invalid file selected. Select an image.");
        return;
      }
      if (targetFile.size > 2000000) {
        setImageFile(null);
        setInfo("Image size larger than 2MB. Select another one");
        return;
      } // 2MB size limit
      setInfo("Selected file:");
      setImageFile(targetFile); // for future: Useful if I want to show image preview in here too
      callback(targetFile, context, updateContext, index);
    }
  };

  return (
    <div
      className={`${styles.drop_upload_file} ${
        uploadedPicture ? styles.with_preview : ""
      }`}
    >
      <div className={styles.dropper}>
        <p onClick={() => fileInputRef.current.click()}>
          {" "}
          <ReactSVG src={upload_svg} wrapper={"span"} /> {info} <br />
          {imageFile && `Selected File: ${imageFile.name}`}
        </p>
      </div>
      <div className={styles.existing_preview}>
        <p>Uploaded Picture:</p>
        <div className={styles.frame}>
          <img src={uploadedPicture} alt="Existing Preview" />
        </div>
      </div>
      <button
        className={styles.delete_icon}
        onClick={() => {
          if (uploadedPicture && !imageFile) showDeletePopup(true);
          else {
            setImageFile(null);
            callback(null, context, updateContext, index);
          }
        }}
      >
        <ReactSVG src={delete_svg} wrapper={"span"} />
      </button>
      <input
        type="file"
        name={inputFieldId}
        id={inputFieldId}
        hidden
        accept="image/*"
        ref={fileInputRef}
        onChange={previewImage}
      />
      <ConfrimationPopup
        heading={"Do you really want to delete this picture permanently?"}
        subHeading={
          "If you click yes, this image will be deleted from our servers and cannot be recovered."
        }
        show={deletePopup}
        setShow={showDeletePopup}
        yesCallback={() => {
          deleteImageS3(uploadedPicture, () => {
            if (removeUploadedPicture && afterUpdateCallback) {
              removeUploadedPicture(context, updateContext, (updatedContext) =>
                afterUpdateCallback(updatedContext)
              );
            }
          });
        }}
      />
    </div>
  );
};

export default ImageUploader;
