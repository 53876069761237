import { API_BASE } from "../../../../api_links";
import { uploadStaticData } from "../../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../../notification/buildNotification";

export const uploadSocialLinks = async (socialLinks) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/social_links`;

  const { style, icons_color, social_links, order } = socialLinks;

  const updateObj = {
    style,
    icons_color,
    social_links,
    order,
  };

  console.log("Update data", updateObj);

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Social Links", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading Social Links settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Social Links Settings uploaded",
      });
    }
  });
};
