import React from "react";
import { ReactSVG } from "react-svg";

import checkmark_svg from "../../../../assets/authFlow/pricing/checkmark.svg";

const features_list = [
  "Lorem ipsum",
  "Dolor sit amet",
  "Consectetur adipiscing",
  "Etiam quis ante",
  "Interdum",
  "Vivamus pulvinar",
  "Lorem ipsum 2",
  "Dolor sit amet 2",
  "Consectetur adipiscing 2",
  "Etiam quis ante 2",
  "Interdum 2",
  "Vivamus pulvinar 2",
];

const FeatureList = ({ styles, limit }) => {
  return features_list.map((feature) => {
    if (features_list.indexOf(feature) <= limit) {
      return (
        <p key={feature} className={`${styles.feature} ${styles.available}`}>
          <ReactSVG src={checkmark_svg} wrapper={"span"} />{" "}
          <span className={styles.feature_text}>{feature}</span>
        </p>
      );
    } else {
      return (
        <p key={feature} className={`${styles.feature}`}>
          <ReactSVG src={checkmark_svg} wrapper={"span"} />{" "}
          <span className={styles.feature_text}>{feature}</span>
        </p>
      );
    }
  });
};

export default FeatureList;
