import React from "react";

import { openStripeDashboard } from "../../../../auth/generateStripeLink";

import Button from "../../../common/button/button";

const BillingBody = ({ styles }) => {
  return (
    <div className={styles.inner}>
      <h3 className={styles.tab_heading}>
        Click on the button below to head to stripe dashboard
      </h3>
      <Button
        title={"Open Billing Dashboard"}
        callback={openStripeDashboard}
        callbackData={"none"}
      />
    </div>
  );
};

export default BillingBody;
