import { swap } from "../../../helpers/utilityFunctions";

export const update_enable_image_gallery_section_title = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_title.enable = value;
  setImageGallery(imageGalleryCopy);
};
export const update_image_gallery_section_title = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_title.title = value;
  setImageGallery(imageGalleryCopy);
};
export const update_enable_image_gallery_section_icon = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_icon.enable = value;
  setImageGallery(imageGalleryCopy);
};
export const update_enable_image_gallery_section_background = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_background.enable = value;
  setImageGallery(imageGalleryCopy);
};
export const update_image_gallery_section_background_color = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_background.color = value;
  setImageGallery(imageGalleryCopy);
};
export const update_enable_image_link_gallery_section_background = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_gallery_section_background.enable = value;
  setImageGallery(imageGalleryCopy);
};
export const update_image_link_gallery_section_background_color = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_gallery_section_background.color = value;
  setImageGallery(imageGalleryCopy);
};
export const update_enable_image_link_font_and_icon_color = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_font_and_icon_color.enable = value;
  setImageGallery(imageGalleryCopy);
};
export const update_image_link_font_and_icon_color_color = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_font_and_icon_color.color = value;
  setImageGallery(imageGalleryCopy);
};
export const image_link_font_and_icon_color_font = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_font_and_icon_color.font = value;
  setImageGallery(imageGalleryCopy);
};
export const update_image_link_font_and_icon_color_font_styles = (
  value,
  imageGallery,
  setImageGallery
) => {
  const { bold, italic, underline, left_align, right_align, center_align } =
    value;
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_link_font_and_icon_color.font_bold = bold;
  imageGalleryCopy.image_link_font_and_icon_color.font_italic = italic;
  imageGalleryCopy.image_link_font_and_icon_color.font_underline = underline;
  imageGalleryCopy.image_link_font_and_icon_color.font_align_left = left_align;
  imageGalleryCopy.image_link_font_and_icon_color.font_align_right =
    right_align;
  imageGalleryCopy.image_link_font_and_icon_color.font_align_center =
    center_align;
  setImageGallery(imageGalleryCopy);
};

export const update_gallery_icon = (file, imageGallery, setImageGallery) => {
  const imageGalleryCopy = { ...imageGallery };
  if (!file) {
    imageGalleryCopy.image_gallery_section_icon.uploadable_file = "";
    imageGalleryCopy.image_gallery_section_icon.upload = "";
  } else {
    imageGalleryCopy.image_gallery_section_icon.uploadable_file = file;
    imageGalleryCopy.image_gallery_section_icon.upload =
      URL.createObjectURL(file);
  }
  setImageGallery(imageGalleryCopy);
};

export const select_main_icon_type_image_gallery = (
  option,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_icon.type = option;
  setImageGallery(imageGalleryCopy);
};

export const update_image_gallery_main_fa_icon_color = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_icon.color = value;
  setImageGallery(imageGalleryCopy);
};

export const update_main_fa_icon_name_image_gallery = (
  value,
  imageGallery,
  setImageGallery
) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.image_gallery_section_icon.fa_icon = value;
  setImageGallery(imageGalleryCopy);
};

export const addGalleryItem = (imageGallery, setImageGallery) => {
  const imageGalleryCopy = { ...imageGallery };
  imageGalleryCopy.gallery_data.push({
    enable: true,
    title: "Image Title",
    mainImage: {
      preview_file: "", // Existing profile pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
      adjustments: {
        size: 0,
        top: 0,
        left: 0,
        rotate: 0,
      },
    },
    CTAIcon: {
      type: "fa", // img || fa
      fa_icon: "bell",
      color: {
        hex: "#222f4f",
        rgb: {
          r: 34,
          g: 47,
          b: 79,
          a: 1,
        },
      },
      preview_file: "", // Existing profile pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
    },
    cta_text: "CTA Text",
    cta_url: "yourdomain.com/landingpage",
    password: "",
    show: true,
    schedule: ["", ""],
  });
  setImageGallery(imageGalleryCopy);
};

export const update_enable_gallery_image = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  targetImage.enable = value;
  setImageGallery(imageGalleryCopy);
};

export const update_image_gallery_image_size = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  console.log(value, index);
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  if (value >= 0 && value <= 50) {
    targetImage.mainImage.adjustments.size = value;
    setImageGallery(imageGalleryCopy);
  }
};

export const update_image_gallery_image_left = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  if (value >= -1000 && value <= 1000) {
    targetImage.mainImage.adjustments.left = value;
    setImageGallery(imageGalleryCopy);
  }
};

export const update_image_gallery_image_top = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  if (value >= -1000 && value <= 1000) {
    targetImage.mainImage.adjustments.top = value;
    setImageGallery(imageGalleryCopy);
  }
};

export const update_image_gallery_image_rotate = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  if (value >= -360 && value <= 360) {
    targetImage.mainImage.adjustments.rotate = value;
    setImageGallery(imageGalleryCopy);
  }
};

export const update_image_gallery_image_title = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  targetImage.title = value;
  setImageGallery(imageGalleryCopy);
};

export const update_image_gallery_image_cta_text = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  targetImage.cta_text = value;
  setImageGallery(imageGalleryCopy);
};

export const update_image_gallery_image_cta_url = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  targetImage.cta_url = value;
  setImageGallery(imageGalleryCopy);
};

export const delete_gallery_item = (imageGallery, setImageGallery, index) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  imageGalleryCopy.gallery_data.splice(index, 1);
  setImageGallery(imageGalleryCopy);
};

export const update_gallery_item_main_image = (
  file,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.mainImage;
  if (!innerImgObj) return;
  if (!file) {
    innerImgObj.uploadable_file = "";
    innerImgObj.upload = "";
  } else {
    innerImgObj.uploadable_file = file;
    innerImgObj.upload = URL.createObjectURL(file);
  }
  setImageGallery(imageGalleryCopy);
};

export const update_gallery_item_cta_icon = (
  file,
  imageGallery,
  setImageGallery,
  index
) => {
  if (index === null) return;
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  if (!file) {
    innerImgObj.uploadable_file = "";
    innerImgObj.upload = "";
  } else {
    innerImgObj.uploadable_file = file;
    innerImgObj.upload = URL.createObjectURL(file);
  }
  setImageGallery(imageGalleryCopy);
};

export const select_icon_type_image_gallery = (
  option,
  imageGallery,
  setImageGallery,
  index
) => {
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.type = option;
  setImageGallery(imageGalleryCopy);
};

export const update_imageGallery_fa_icon_color = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.color = value;
  setImageGallery(imageGalleryCopy);
};

export const update_fa_icon_name_imageGallery = (
  value,
  imageGallery,
  setImageGallery,
  index
) => {
  const imageGalleryCopy = { ...imageGallery };
  const targetImage = imageGalleryCopy.gallery_data[index];
  if (!targetImage) return;
  const innerImgObj = targetImage.CTAIcon;
  if (!innerImgObj) return;
  innerImgObj.fa_icon = value;
  setImageGallery(imageGalleryCopy);
};

export const update_order_image_gallery = (
  swap1,
  swap2,
  imageGallery,
  setImageGallery
) => {
  if (swap1 === undefined || swap2 === undefined) return;
  if (isNaN(swap1) || isNaN(swap2)) return;
  const imageGalleryCopy = { ...imageGallery };
  const { gallery_data } = imageGalleryCopy;
  if (!gallery_data[swap1] || !gallery_data[swap2]) return;
  swap(gallery_data, swap1, swap2);
  setImageGallery(imageGalleryCopy);
};
