import React, { useState } from "react";

const YouTube = ({ yt_id, title, thumbnail = false }) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <React.Fragment>
      {!showVideo && (
        <img
          src={`https://ytimg.googleusercontent.com/vi/${yt_id}/default.jpg`}
          alt={title}
          onClick={() => {
            if (thumbnail) return;
            setShowVideo(true);
          }}
        />
      )}

      {showVideo && (
        <iframe
          title={title}
          src={`https://www.youtube.com/embed/${yt_id}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      )}
    </React.Fragment>
  );
};

export default YouTube;
