import React from "react";

import CollapsibleContainer from "../collapsibleContainer/collapsibleContainer";
import InputField from "../inputField/inputField";
import ImageUploader from "../imageUploader/imageUploader";
import ImageEditor from "../imageEditor/imageEditor";
import CTAIconSelector from "../ctaIconSelector/ctaIconSelector";
import AnalyticsFooter from "../analyticsFooter/analyticsFooter";

import {
  update_enable_gallery_image,
  update_image_gallery_image_size,
  update_image_gallery_image_left,
  update_image_gallery_image_top,
  update_image_gallery_image_rotate,
  update_image_gallery_image_title,
  update_gallery_item_main_image,
  update_gallery_item_cta_icon,
  update_image_gallery_image_cta_text,
  update_image_gallery_image_cta_url,
  delete_gallery_item,
  select_icon_type_image_gallery,
  update_imageGallery_fa_icon_color,
  update_fa_icon_name_imageGallery,
} from "../../designer/sections/imageGallery/updateFunctions";

const ImageGalleryEditableItem = ({
  styles,
  title,
  inputId,
  index,
  context,
  updateContext,
}) => {
  let targetGalleryItem = context.gallery_data[index];

  if (!targetGalleryItem.mainImage?.adjustments)
    targetGalleryItem.mainImage.adjustments = {
      size: 0,
      left: 0,
      top: 0,
      rotate: 0,
    };

  return (
    <CollapsibleContainer
      title={targetGalleryItem?.title ?? title}
      inputId={inputId}
      draggableItem={false}
      enabled={targetGalleryItem?.enable}
      callback={update_enable_gallery_image}
      context={context}
      updateContext={updateContext}
      index={index}
    >
      <div className={styles.editable_section}>
        <InputField
          inputFieldId={`${inputId}_title`}
          label={`Image title`}
          defaultInputValue={targetGalleryItem?.title ?? `Our Image Collection`}
          callback={update_image_gallery_image_title}
          context={context}
          updateContext={updateContext}
          index={index}
        />

        <p className={styles.section_info_text}>Upload your image</p>
        <ImageUploader
          inputFieldId={`${inputId}_image`}
          callback={update_gallery_item_main_image}
          context={context}
          updateContext={updateContext}
          index={index}
          uploadedPicture={targetGalleryItem.mainImage?.preview_file}
        />

        <p className={styles.section_info_text}>
          Change settings of your image
        </p>
        <ImageEditor
          inputFieldId={`${inputId}_image_adjustments`}
          defaultValues={targetGalleryItem?.mainImage?.adjustments}
          sizeCallback={update_image_gallery_image_size}
          leftCallback={update_image_gallery_image_left}
          topCallback={update_image_gallery_image_top}
          rotateCallback={update_image_gallery_image_rotate}
          context={context}
          updateContext={updateContext}
          index={index}
        />

        <p className={styles.section_info_text}>Change CTA Icon</p>
        <CTAIconSelector
          inputFieldId={inputId}
          selected={targetGalleryItem.CTAIcon?.type}
          selectedIcon={targetGalleryItem.CTAIcon?.fa_icon}
          iconColor={targetGalleryItem.CTAIcon?.color}
          selectorCallback={select_icon_type_image_gallery}
          imageCallback={update_gallery_item_cta_icon}
          iconSelectorCallback={update_fa_icon_name_imageGallery}
          iconColorCallback={update_imageGallery_fa_icon_color}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <InputField
          inputFieldId={`${inputId}_cta_text`}
          label={"CTA Text"}
          defaultInputValue={targetGalleryItem?.cta_text ?? "CTA Text Here"}
          callback={update_image_gallery_image_cta_text}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <InputField
          inputFieldId={`${inputId}_cta_url`}
          label={"CTA URL"}
          defaultInputValue={
            targetGalleryItem?.cta_url ?? "yourdomain.com/landingpage"
          }
          prefix={"https://"}
          callback={update_image_gallery_image_cta_url}
          context={context}
          updateContext={updateContext}
          index={index}
        />
        <AnalyticsFooter
          deleteCallback={delete_gallery_item}
          context={context}
          updateContext={updateContext}
          index={index}
          type={"image"}
        />
      </div>
    </CollapsibleContainer>
  );
};

export default ImageGalleryEditableItem;
