import { ReactSVG } from "react-svg";
import styles from "./chart.module.scss";
import cross_svg from "../../../assets/global/icon_cross.svg";

const BarChart = ({ data }) => {
  const colors = ["#007bff", "#00bfff", "#21c55d", "#7fe0f3", "#ff4c4c"];
  const populationData = data.map((row) => row[1]);
  const maxValue = populationData.reduce((partialSum, a) => partialSum + a, 0);

  return (
    <div className={styles.bar_chart}>
      {data.map((row, index) => {
        const percentage = (row[1] / maxValue) * 100;
        return (
          <div key={index} className={styles.bar_container}>
            <div className={styles.label}><ReactSVG src={cross_svg} wrapper={"span"} />{" "} {row[0]}</div>
            <div
              className={styles.bar}
              style={{
                width: `${(row[1] / maxValue) * 100}%`,
                backgroundColor: colors[index % colors.length],
              }}
            >
              {percentage.toFixed(1)}%
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
