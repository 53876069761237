import React, { useContext } from "react";

import { UsernameContext } from "../../contexts/designerContexts/username.context";

import styles from "./passwordProtected.module.scss";

import protected_svg from "../../../assets/umicardViewer/lockedContent/protected.svg";
import close_svg from "../../../assets/umicardViewer/lockedContent/close.svg";

import InputField from "../inputField/inputField";
import Button from "../button/button";

// Always use it inside modal and pass closer function to it
const PasswordProtected = ({
  type = "contact", // slider
  title,
  infoType = null,
  password,
  callback,
  closer,
}) => {
  const [username] = useContext(UsernameContext);

  const submitPassword = (e) => {
    e.preventDefault();
    const form = e.target;
    const enteredPassword = form.lead_password.value;
    if (!enteredPassword) return;
    if (enteredPassword === password) {
      callback();
    }
  };

  return (
    <div className={styles.password_protected}>
      {type === "contact" && (
        <div className={styles.lock_header}>
          <img
            src={protected_svg}
            alt="Protected"
            className={styles.protected_icon}
          />
          <p className={styles.protected_heading}>{title}</p>
          <img
            src={close_svg}
            alt="close"
            className={styles.close_icon}
            onClick={() => {
              closer && closer(false);
            }}
          />
        </div>
      )}
      <form onSubmit={submitPassword} className={styles.password_form}>
        {type === "contact" && (
          <React.Fragment>
            <p className={styles.info}>
              {username} decided to show his/her contact detail only to the
              visitors who have the contact secret key.
            </p>
            <p className={styles.info}>
              If {username} provided you with this secret key, enter it below
              and press submit to see his/her contact.
            </p>
          </React.Fragment>
        )}

        <InputField
          inputFieldId={"lead_password_" + title}
          label={"Password"}
          type={"password"}
          extraClass={`no_label`}
          helperClasses={`mtop_10 ${
            type === "slider" && "inline_50 no_margins"
          }`}
        />
        {!type === "slider" && (
          <Button
            title={"Submit"}
            type={"submit"}
            extraClass={
              type === "slider"
                ? "submit_password_gallery_button"
                : "submit_lead_button"
            }
          />
        )}
      </form>
    </div>
  );
};

export default PasswordProtected;
