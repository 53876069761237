import React, { useState, createContext } from "react";

export const CalendlyContext = createContext();

export const CalendlyProvider = ({ children }) => {
  const [calendly, setCalendly] = useState({
    enable: false,
    url: "https://calendly.com/{your-id}",
    title: "Calendly Title",
    icon: {
      enable: false,
      type: "img", // img || fa
      preview_file: "", // Existing profile pic
      uploadable_file: "", // file object if new pic selected
      upload: "",
      fa_icon: "bell",
      color: {
        hex: "#ffffff",
        rgb: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
    },
    background: {
      enable: false,
      color: {
        hex: "#222f4f",
        rgb: {
          r: 34,
          g: 47,
          b: 79,
          a: 1,
        },
      },
    },
  });

  return (
    <CalendlyContext.Provider value={[calendly, setCalendly]}>
      {children}
    </CalendlyContext.Provider>
  );
};
