import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { SocialLinksContext } from "../../../contexts/sectionsContexts/socialLinks.context";
import { FontsAndColorsContext } from "../../../contexts/designerContexts/fontsAndColors.context";

import { uploadSocialLinks } from "./uploadFunction";

import { uploadFontsAndColors } from "../../fontsAndColors/uploadFunction";

import {
  update_enable_social_icons_style,
  update_enable_social_icons_color,
  update_social_icons_color,
} from "./updateFunctions";

import {
  update_enable_social_media_icons_color,
  update_enable_social_media_icons_background_color,
  update_social_media_icons_color,
  update_social_media_icons_background_color,
} from "../../fontsAndColors/updateFunctions";

import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import SocialIconsStyle from "./socialIconsStyle/socialIconsStyle";
import SingleSocialLink from "./singleSocialLink/singeSocialLink";
import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";

import linkedIn_png from "../../../../assets/sections/socialLinks/linkedin.png";
import twitter_png from "../../../../assets/sections/socialLinks/twitter.png";
import facebook_png from "../../../../assets/sections/socialLinks/facebook.png";
import instagram_png from "../../../../assets/sections/socialLinks/instagram.png";
import facebook_messenger_png from "../../../../assets/sections/socialLinks/fb_messenger.png";
import youtube_png from "../../../../assets/sections/socialLinks/youtube.png";
import podcast_png from "../../../../assets/sections/socialLinks/podcast.png";
import skype_png from "../../../../assets/sections/socialLinks/skype.png";
import whatsapp_png from "../../../../assets/sections/socialLinks/whatsapp.png";
import pinterest_png from "../../../../assets/sections/socialLinks/pinterest.png";
import snapchat_png from "../../../../assets/sections/socialLinks/snapchat.png";
import wordpress_png from "../../../../assets/sections/socialLinks/wordpress.png";
import twitch_png from "../../../../assets/sections/socialLinks/twitch.png";

const SocialLink_PNG_MAP = {
  linkedin: linkedIn_png,
  facebook_messenger: facebook_messenger_png,
  facebook: facebook_png,
  instagram: instagram_png,
  pinterest: pinterest_png,
  podcast: podcast_png,
  skype: skype_png,
  snapchat: snapchat_png,
  twitter: twitter_png,
  twitch: twitch_png,
  whatsapp: whatsapp_png,
  youtube: youtube_png,
  wordpress: wordpress_png,
};

const SocialLinks = ({ styles }) => {
  const [socialLinks, setSocialLinks] = useContext(SocialLinksContext);
  const [fontsAndColors, setFontsAndColors] = useContext(FontsAndColorsContext);

  const { style, icons_color, social_links, order } = socialLinks;

  const { enable, version } = style;

  const { social_media_icons_color, social_media_icons_background_color } =
    fontsAndColors;

  const reOrderSocialLinks = (result) => {
    console.log("Reorder", result);
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const tempOrder = [...order];
    const [reOrderedItem] = tempOrder.splice(source.index, 1);
    tempOrder.splice(destination.index, 0, reOrderedItem);
    const socialLinksCopy = { ...socialLinks, order: tempOrder };
    setSocialLinks(socialLinksCopy);
  };

  return (
    <div className="social_links">
      <SectionTopBar
        title={"Social Links"}
        id={"social-links-section-designer"}
        updateCallback={uploadSocialLinks}
        updateData={socialLinks}
        extraUpdates={[
          { extraCallback: uploadFontsAndColors, extraData: fontsAndColors },
        ]}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Icons Style"
          inputId="enable_social_icons_style"
          enabled={enable}
          callback={update_enable_social_icons_style}
          context={socialLinks}
          updateContext={setSocialLinks}
        >
          <div className={styles.editable_section}>
            <SocialIconsStyle
              version={version}
              context={socialLinks}
              updateContext={setSocialLinks}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Icons"
          subtitle="The section icons showing on top right corner of each section"
          inputId="enable_social_icons"
          enabled={icons_color.enable}
          callback={update_enable_social_icons_color}
          context={socialLinks}
          updateContext={setSocialLinks}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_icons_color"
              savedColor={icons_color.color}
              callback={update_social_icons_color}
              context={socialLinks}
              updateContext={setSocialLinks}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Media Icons’ Border Color"
          subtitle="Select the color of your social media icons"
          inputId="enable_social_media_icons_color"
          enabled={social_media_icons_color.enable}
          callback={update_enable_social_media_icons_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_media_icons_color"
              savedColor={social_media_icons_color.color}
              callback={update_social_media_icons_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Social Media Icons’ Background Color"
          subtitle="Select the background color of the save my card button."
          inputId="enable_social_media_icons_background_color"
          enabled={social_media_icons_background_color.enable}
          callback={update_enable_social_media_icons_background_color}
          context={fontsAndColors}
          updateContext={setFontsAndColors}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="social_media_icons_background_color"
              savedColor={social_media_icons_background_color.color}
              callback={update_social_media_icons_background_color}
              context={fontsAndColors}
              updateContext={setFontsAndColors}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <DragDropContext onDragEnd={reOrderSocialLinks}>
        <Droppable droppableId="social-links-order">
          {(provided) => (
            <div
              className={`${styles.designer_white_box} ${styles.details_editor}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {order.map((social_item, index) => {
                return (
                  <Draggable
                    key={`${social_item}_index`}
                    draggableId={social_item}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={styles.editable_section}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <SingleSocialLink
                          inputFieldId={social_item}
                          title={social_item}
                          socialIconImg={SocialLink_PNG_MAP[social_item]}
                          currentData={social_links[social_item]}
                          context={socialLinks}
                          updateContext={setSocialLinks}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SocialLinks;
