import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";

const parseDataForContext = (data) => {
  if (!data) return;

  return {
    google: data?.google,
    facebook: data?.facebook,
    linkedIn: data?.linkedIn,
    pinterest: data?.pinterest,
    tiktok: data?.tiktok,
    seo: data?.seo,
  };
};

export const getTrackingDataFromDB = (
  setTrackingCodes,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data?.tracking);
    if (!parsedData) return;
    setTrackingCodes(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/tracking`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setTrackingCodes(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/tracking/${username}`;
    console.log(url);
    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setTrackingCodes(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
