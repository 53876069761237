import React from "react";
import { ReactSVG } from "react-svg";

import styles from "./lockedOverlay.module.scss";

import lock_svg from "../../../assets/umicardViewer/lockedContent/lock2.svg";

const LockedOverlay = ({
  title,
  callback = null,
  hide,
  thick_hide = false,
  children,
}) => {
  return (
    <React.Fragment>
      <div
        className={`${styles.hide_overlay} ${thick_hide && styles.thick_hide} ${
          hide && styles.hide
        } `}
        onClick={() => {
          if (callback) callback();
        }}
      >
        <span className={styles.lock}>
          <ReactSVG src={lock_svg} wrapper="span" />
        </span>
        <p className={styles.lock_text}>{title}</p>
      </div>
      {children}
    </React.Fragment>
  );
};

export default LockedOverlay;
