import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import Checkbox from "../checkbox/checkbox";
import SelectInputField from "../selectInputField/selectInputField";

import styles from "./fontPicker.module.scss";

import left_align_svg from "../../../assets/global/font_picker/icon_left_align.svg";
import center_align_svg from "../../../assets/global/font_picker/icon_center_align.svg";
import right_align_svg from "../../../assets/global/font_picker/icon_right_align.svg";
import bold_svg from "../../../assets/global/font_picker/icon_bold.svg";
import italic_svg from "../../../assets/global/font_picker/icon_italic.svg";
import underline_svg from "../../../assets/global/font_picker/icon_underline.svg";

const FontPicker = ({
  inputFieldId,
  defaultInputValue = "",
  callback_font_name = null,
  callback_font_styles = null,
  savedFontStyles = null,
  context = null,
  updateContext = null,
}) => {
  const [fontStyles, setFontStyles] = useState(
    savedFontStyles ?? {
      bold: false,
      italic: false,
      underline: false,
      left_align: false,
      right_align: false,
      center_align: false,
    }
  );

  const updateStylesContext = (update) => {
    if (callback_font_styles)
      callback_font_styles(update, context, updateContext);
  };

  const update_bold = () => {
    const fontStylesCopy = { ...fontStyles };
    fontStylesCopy.bold = !fontStylesCopy.bold;
    setFontStyles(fontStylesCopy);
    updateStylesContext(fontStylesCopy);
  };
  const update_italic = () => {
    const fontStylesCopy = { ...fontStyles };
    fontStylesCopy.italic = !fontStylesCopy.italic;
    setFontStyles(fontStylesCopy);
    updateStylesContext(fontStylesCopy);
  };
  const update_underline = () => {
    const fontStylesCopy = { ...fontStyles };
    fontStylesCopy.underline = !fontStylesCopy.underline;
    setFontStyles(fontStylesCopy);
    updateStylesContext(fontStylesCopy);
  };

  const update_alignment = (selection, selected_alignment) => {
    const fontStylesCopy = { ...fontStyles };
    fontStylesCopy.left_align = false;
    fontStylesCopy.right_align = false;
    fontStylesCopy.center_align = false;
    fontStylesCopy[selected_alignment] = selection;
    setFontStyles(fontStylesCopy);
    updateStylesContext(fontStylesCopy);
  };

  useEffect(() => {
    if (savedFontStyles) setFontStyles(savedFontStyles);
  }, [savedFontStyles]);

  return (
    <div className={styles.font_picker_container}>
      <SelectInputField
        inputFieldId={inputFieldId}
        options={["Lato", "Verdana"]}
        label={"Select Font"}
        defaultInputValue={defaultInputValue}
        callback={callback_font_name}
        context={context}
        updateContext={updateContext}
      />
      <div className={styles.font_styles_container}>
        <Checkbox
          inputFieldId={`${inputFieldId}-bold`}
          defaultValue={fontStyles.bold}
          callback={update_bold}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={bold_svg} wrapper={"span"} />
          </span>
        </Checkbox>
        <Checkbox
          inputFieldId={`${inputFieldId}-italic`}
          defaultValue={fontStyles.italic}
          callback={update_italic}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={italic_svg} wrapper={"span"} />
          </span>
        </Checkbox>
        <Checkbox
          inputFieldId={`${inputFieldId}-underline`}
          defaultValue={fontStyles.underline}
          callback={update_underline}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={underline_svg} wrapper={"span"} />
          </span>
        </Checkbox>
        <Checkbox
          inputFieldId={`${inputFieldId}-left`}
          defaultValue={fontStyles.left_align}
          callback={update_alignment}
          context={"left_align"}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={left_align_svg} wrapper={"span"} />
          </span>
        </Checkbox>
        <Checkbox
          inputFieldId={`${inputFieldId}-center`}
          defaultValue={fontStyles.center_align}
          callback={update_alignment}
          context={"center_align"}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={center_align_svg} wrapper={"span"} />
          </span>
        </Checkbox>
        <Checkbox
          inputFieldId={`${inputFieldId}-right`}
          defaultValue={fontStyles.right_align}
          callback={update_alignment}
          context={"right_align"}
        >
          <span className={styles.font_style_svg}>
            <ReactSVG src={right_align_svg} wrapper={"span"} />
          </span>
        </Checkbox>
      </div>
    </div>
  );
};

export default FontPicker;
