import React from "react";

import {
  update_enable_single_social_link,
  update_single_social_link_url,
} from "../updateFunctions";

import { SOCIAL_PREFIXES } from "../../../../helpers/prefixes";

import styles from "./singeSocialLink.module.scss";

import Checkbox from "../../../../common/checkbox/checkbox";
import InputField from "../../../../common/inputField/inputField";

const SingleSocialLink = ({
  inputFieldId,
  title,
  socialIconImg,
  currentData,
  context,
  updateContext,
}) => {
  return (
    <div className={styles.single_social_link_editor}>
      <div className={`${styles.move_icon} vertical_align_center`}>
        <span className={styles.ellipses}></span>
      </div>
      <div className={styles.social_link_editor_inputs_container}>
        <div className={styles.enable_checkbox_container}>
          <p className={styles.social_link_editor_small_label}>Show</p>
          <Checkbox
            inputFieldId={inputFieldId}
            sendIDInUpdate={true}
            defaultValue={currentData.enable}
            callback={update_enable_single_social_link}
            context={context}
            updateContext={updateContext}
          />
        </div>
        <div className={styles.link_input_field}>
          <p className={styles.social_link_editor_small_label}>{title}</p>
          <div className={styles.link_input_field_inner}>
            <InputField
              inputFieldId={inputFieldId}
              extraClass="extraClass"
              socialLinkURL={true}
              prefix={SOCIAL_PREFIXES[title]}
              sendIDInUpdate={true}
              defaultInputValue={currentData.url}
              callback={update_single_social_link_url}
              context={context}
              updateContext={updateContext}
            >
              <img
                className={styles.social_link_icon}
                src={socialIconImg}
                alt={inputFieldId}
              />
            </InputField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSocialLink;
