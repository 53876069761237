export const update_username = (value, username = null, setUsername) => {
  const sanitizedValue = value.trim().split(" ").join("_");
  setUsername(sanitizedValue.toLowerCase().substr(0, 20));
};

export const update_custom_username = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  const sanitizedValue = value.trim().split(" ").join("_").substr(0, 20);
  personalDetailsCopy.custom_username = sanitizedValue;
  setPersonalDetails(personalDetailsCopy);
};

export const update_share_leads_checkbox = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.share_leads = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_name = (value, personalDetails, setPersonalDetails) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.name = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_company_business_name = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.company_business_name = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_job_title = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.job_title = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_email = (value, personalDetails, setPersonalDetails) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.email = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_mobile = (value, personalDetails, setPersonalDetails) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.mobile = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_phone = (value, personalDetails, setPersonalDetails) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.phone = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_extension = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.leads_form_fields.extension = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_Profile_pic = (
  file,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (!file) {
    personalDetailsCopy.profile_picture_image_upload = "";
    personalDetailsCopy.profile_picture_image_uploadable_file = "";
  } else {
    personalDetailsCopy.profile_picture_image_upload =
      URL.createObjectURL(file);
    personalDetailsCopy.profile_picture_image_uploadable_file = file;
  }
  setPersonalDetails(personalDetailsCopy);
};

export const remove_uploaded_Profile_pic = (
  personalDetails,
  setPersonalDetails,
  callback
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.profile_picture_image_preview_file = { url: "" };
  setPersonalDetails(personalDetailsCopy);
  callback(personalDetailsCopy);
};

export const update_profile_pic_size = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (value >= 0 && value <= 50) {
    personalDetailsCopy.profile_picture_adjustments.size = value;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_left = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (value >= -1000 && value <= 1000) {
    personalDetailsCopy.profile_picture_adjustments.left = value;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_top = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (value >= -1000 && value <= 1000) {
    personalDetailsCopy.profile_picture_adjustments.top = value;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_rotate = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (value >= -360 && value <= 360) {
    personalDetailsCopy.profile_picture_adjustments.rotate = value;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_border_style_version = (
  value,
  id,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (personalDetailsCopy.border_style.version.hasOwnProperty(id)) {
    // Make others false first
    Object.keys(personalDetailsCopy.border_style.version).forEach((key) => {
      personalDetailsCopy.border_style.version[key] = false;
    });

    // Lets update now
    personalDetailsCopy.border_style.version[id] = value;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_border_stroke_width = (
  value,
  id,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  if (id >= 0 && id <= 5) {
    // Lets update now
    personalDetailsCopy.border_stroke.width = id;
    setPersonalDetails(personalDetailsCopy);
  }
};

export const update_profile_pic_border_stroke_color = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.border_stroke.color = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_enable_personal_details_background_color = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.background.enable = value;
  setPersonalDetails(personalDetailsCopy);
};

export const update_personal_details_background_color = (
  value,
  personalDetails,
  setPersonalDetails
) => {
  const personalDetailsCopy = { ...personalDetails };
  personalDetailsCopy.background.color = value;
  setPersonalDetails(personalDetailsCopy);
};
