import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const {
    enable_sections_and_titles_headlines,
    sections_and_titles_headlines_font,
    sections_and_titles_headlines_font_bold,
    sections_and_titles_headlines_font_italic,
    sections_and_titles_headlines_font_underline,
    sections_and_titles_headlines_font_align_left,
    sections_and_titles_headlines_font_align_right,
    sections_and_titles_headlines_font_align_center,
    sections_and_titles_headlines_color,
    sections_border_radius,
    enable_body_text,
    body_text_font,
    body_text_font_bold,
    body_text_font_italic,
    body_text_font_underline,
    body_text_font_align_left,
    body_text_font_align_right,
    body_text_font_align_center,
    body_text_color,
    enable_contact_icons_primary_color,
    contact_icons_primary_color: _contact_icons_primary_color,
    enable_contact_icons_secondary_color,
    contact_icons_secondary_color: _contact_icons_secondary_color,
    enable_social_media_icons_color,
    social_media_icons_color: _social_media_icons_color,
    enable_social_media_icons_background_color,
    social_media_icons_background_color: _social_media_icons_background_color,
    enable_share_my_card_background_color,
    share_my_card_background_color: _share_my_card_background_color,
    enable_seconds_icon_color,
    seconds_icon_color: _seconds_icon_color,
  } = data;

  return {
    sections_and_titles_headlines: {
      enable: enable_sections_and_titles_headlines,
      sections_and_titles_headlines_font,
      sections_and_titles_headlines_font_bold,
      sections_and_titles_headlines_font_italic,
      sections_and_titles_headlines_font_underline,
      sections_and_titles_headlines_font_align_left,
      sections_and_titles_headlines_font_align_right,
      sections_and_titles_headlines_font_align_center,
      sections_and_titles_headlines_color,
    },
    sections_border_radius: sections_border_radius ?? true,
    body_text: {
      enable: enable_body_text,
      body_text_font,
      body_text_font_bold,
      body_text_font_italic,
      body_text_font_underline,
      body_text_font_align_left,
      body_text_font_align_right,
      body_text_font_align_center,
      body_text_color,
    },
    contact_icons_primary_color: {
      enable: enable_contact_icons_primary_color,
      color: _contact_icons_primary_color,
    },
    contact_icons_secondary_color: {
      enable: enable_contact_icons_secondary_color,
      color: _contact_icons_secondary_color,
    },
    social_media_icons_color: {
      enable: enable_social_media_icons_color,
      color: _social_media_icons_color,
    },
    social_media_icons_background_color: {
      enable: enable_social_media_icons_background_color,
      color: _social_media_icons_background_color,
    },
    share_my_card_background_color: {
      enable: enable_share_my_card_background_color,
      color: _share_my_card_background_color,
    },
    seconds_icon_color: {
      enable: enable_seconds_icon_color,
      color: _seconds_icon_color,
    },
  };
};

export const getFontsAndColorsFromDB = (
  setFontsAndColors,
  username = null,
  data = null
) => {
  if (data) {
    const parsedData = parseDataForContext(data.fontsAndColors);
    if (!parsedData) return;
    setFontsAndColors(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/fonts_and_colors`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setFontsAndColors(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Fonts & Colors Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/fonts_and_colors/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setFontsAndColors(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
