import React, { useState, createContext } from "react";

export const ImageGalleryContext = createContext();

export const ImageGalleryProvider = ({ children }) => {
  const [imageGallery, setImageGallery] = useState({
    image_gallery_section_title: {
      enable: true,
      title: "My Gallery",
    },
    image_gallery_section_icon: {
      enable: false,
      type: "fa", // img || fa
      fa_icon: "bell",
      color: {
        hex: "#ffffff",
        rgb: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
      preview_file: "", // Existing profile pic
      uploadable_file: "", // file object if new pic selected
      upload: "", // preview link before uploading
    },
    image_gallery_section_background: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    image_link_gallery_section_background: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    image_link_font_and_icon_color: {
      enable: false,
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
      font: "verdana",
      font_bold: false,
      font_italic: false,
      font_underline: false,
      font_align_left: false,
      font_align_right: false,
      font_align_center: true,
    },
    gallery_data: [
      {
        enable: true,
        title: "image title",
        mainImage: {
          preview_file: "", // Existing profile pic
          uploadable_file: "", // file object if new pic selected
          upload: "", // preview link before uploading
          adjustments: {
            size: 0,
            top: 0,
            left: 0,
            rotate: 0,
          },
        },
        CTAIcon: {
          type: "fa", // img || fa
          fa_icon: "bell",
          color: {
            hex: "#222f4f",
            rgb: {
              r: 34,
              g: 47,
              b: 79,
              a: 1,
            },
          },
          preview_file: "", // Existing profile pic
          uploadable_file: "", // file object if new pic selected
          upload: "", // preview link before uploading
        },
        cta_text: "",
        cta_url: "",
        password: "",
        show: true,
        schedule: ["", ""],
      },
    ],
  });

  return (
    <ImageGalleryContext.Provider value={[imageGallery, setImageGallery]}>
      {children}
    </ImageGalleryContext.Provider>
  );
};
