export const SOCIAL_PREFIXES = {
  linkedin: "https://www.linkedin.com/",
  twitter: "https://twitter.com/",
  snapchat: "https://snapchat.com/add/",
  instagram: "https://instagram.com/",
  facebook: "https://facebook.com/",
  podcast: "https://podcast.co/",
  twitch: "https://twitch.tv/",
  facebook_messenger: "https://facebook.com/",
  pinterest: "https://pinterest.com/",
  skype: "https://skype.com/",
  whatsapp: "https://wa.me/",
  wordpress: "https://wordpress.com/",
  youtube: "https://youtube.com/",
};
