import React from "react";
import { Chart } from "react-google-charts";
import styles from "./chart.module.scss";

const AnalyticsChart = ({
  chartType = "BarChart",
  data = [],
  options = {},
  width="100%",
  height="300px"
}) => {
  return (
    <div className={styles.chart_container}>
      <Chart
        chartType={chartType}
        width={width}
        height={height}
        data={data}
        options={options}
      />
    </div>
  );
};

export default AnalyticsChart;
