import React from "react";
import styles from "./device.module.scss";

const DeviceListChart = () => {
  return (
    <div className={styles.list_container}>
      <div className={styles.list_item}>
        <p>The five minutes team makeover</p>
        <p className={styles.value}>526</p>
      </div>
      <div className={styles.list_item}>
        <p>Book a meeting with me</p>
        <p className={styles.value}>100</p>
      </div>
      <div className={styles.list_item}>
        <p>Join my webinar</p>
        <p className={styles.value}>222</p>
      </div>
      <div className={styles.list_item}>
        <p>Image Link - 1</p>
        <p className={styles.value}>1000</p>
      </div>
      <div className={styles.list_item}>
        <p>Video Link - 1</p>
        <p className={styles.value}>20</p>
      </div>
    </div>
  );
};

export default DeviceListChart;
