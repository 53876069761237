import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import styles from "./counterInput.module.scss";

import reset_icon from "../../../assets/global/icon_reset.svg";

const CounterInput = ({
  label,
  step,
  defaultValue = 0,
  min = 0,
  max = 10,
  reset = false,
  resetValue = 0,
  inputFieldId,
  callback = null,
  index = null,
  context = null,
  updateContext = null,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const updateData = (updatedValue) => {
    if (callback && index === null)
      callback(updatedValue, context, updateContext);

    if (callback && index !== null)
      callback(updatedValue, context, updateContext, index);
  };

  const increment = () => {
    if (currentValue + 1 <= max) {
      const updatedValue = currentValue + 1;
      setCurrentValue(updatedValue);
      updateData(updatedValue);
    }
  };

  const decrement = () => {
    if (currentValue - 1 >= min) {
      const updatedValue = currentValue - 1;
      setCurrentValue(updatedValue);
      updateData(updatedValue);
    }
  };

  return (
    <div className={styles.counter_input_container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.counter_container}>
        <button className={styles.plus_minus_button} onClick={decrement}>
          -
        </button>
        <input
          className={styles.number_input}
          type="number"
          min={min}
          max={max}
          step={step}
          name={inputFieldId}
          id={inputFieldId}
          value={currentValue}
          onChange={(e) => {
            const updatedValue = e.target.value;
            if (updatedValue === "") {
              setCurrentValue("");
              return;
            }

            const parsedValue = parseInt(updatedValue);
            if (isNaN(parsedValue)) return;

            if (parsedValue >= min && parsedValue <= max) {
              setCurrentValue(parsedValue);
              updateData(parsedValue);
            }
          }}
        />
        <button className={styles.plus_minus_button} onClick={increment}>
          +
        </button>
        {reset && (
          <button
            className={styles.reset_btn}
            onClick={() => {
              setCurrentValue(resetValue);
              updateData(resetValue);
            }}
          >
            <ReactSVG src={reset_icon} wrapper={"span"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CounterInput;
