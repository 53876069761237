import React from "react";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../../common/inputField/inputField";
import TextArea from "../../../common/textArea/textArea";
import ImageUploader from "../../../common/imageUploader/imageUploader";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import AboutLinkEditableItem from "../../../common/aboutLinkEditableItem/aboutLinkEditableItem";
import AddMoreEdirableItemButton from "../../../common/addMoreEditableItemButton/addMoreEdirableItemButton";

const About = ({ styles }) => {
  return (
    <div className="about">
      <SectionTopBar title={"About"} id={"about-section-designer"} />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Sections Title"
          inputId="enable_about_section_title"
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"about_section_title"}
              label={"About Section Title"}
              extraClass={"no_label"}
              defaultInputValue={"About"}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer title="Icon" inputId="enable_about_section_icon">
          <div className={styles.editable_section}>
            <p className={styles.section_info_text}>Change icon</p>
            <ImageUploader inputFieldId={"about_section_icon"} />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Background Color"
          inputId="enable_about_section_background"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="about_section_background_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="CTA/Link - Background Color"
          inputId="enable_cta_link_about_section_background"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="cta_link_about_section_background_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="CTA Text and Icon Color"
          inputId="enable_cta_text_and_icon_about_section_color"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="cta_text_and_icon_about_section_color_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="CTA Icon Background"
          inputId="enable_CTA_icon_background"
        >
          <div className={styles.editable_section}>
            <ColorPicker inputFieldId="enable_CTA_icon_background_color" />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer title="About Text" inputId="enable_about_text">
          <div className={styles.editable_section}>
            <TextArea
              inputFieldId={"about_text"}
              label={"About"}
              extraClass={"no_label"}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <AboutLinkEditableItem
          styles={styles}
          title={"Link 1"}
          inputId={"about_link_1"}
        />
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <AboutLinkEditableItem
          styles={styles}
          title={"Link 2"}
          inputId={"about_link_2"}
        />
      </div>
      <AddMoreEdirableItemButton title={"Add More Links"} />
    </div>
  );
};

export default About;
