import { API_BASE } from "../../../api_links";
import { uploadStaticData } from "../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../notification/buildNotification";

export const uploadFontsAndColors = async (fontsAndColors) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/fonts_and_colors`;

  const {
    sections_and_titles_headlines,
    sections_border_radius,
    body_text,
    contact_icons_primary_color,
    contact_icons_secondary_color,
    social_media_icons_color,
    social_media_icons_background_color,
    share_my_card_background_color,
    seconds_icon_color,
  } = fontsAndColors;

  const {
    enable: enable_sections_and_titles_headlines,
    sections_and_titles_headlines_font,
    sections_and_titles_headlines_font_bold,
    sections_and_titles_headlines_font_italic,
    sections_and_titles_headlines_font_underline,
    sections_and_titles_headlines_font_align_left,
    sections_and_titles_headlines_font_align_right,
    sections_and_titles_headlines_font_align_center,
    sections_and_titles_headlines_color,
  } = sections_and_titles_headlines;

  const {
    enable: enable_body_text,
    body_text_font,
    body_text_font_bold,
    body_text_font_italic,
    body_text_font_underline,
    body_text_font_align_left,
    body_text_font_align_right,
    body_text_font_align_center,
    body_text_color,
  } = body_text;

  const updateObj = {
    enable_sections_and_titles_headlines,
    sections_and_titles_headlines_font,
    sections_and_titles_headlines_font_bold,
    sections_and_titles_headlines_font_italic,
    sections_and_titles_headlines_font_underline,
    sections_and_titles_headlines_font_align_left,
    sections_and_titles_headlines_font_align_right,
    sections_and_titles_headlines_font_align_center,
    sections_and_titles_headlines_color,
    sections_border_radius,
    enable_body_text,
    body_text_font,
    body_text_font_bold,
    body_text_font_italic,
    body_text_font_underline,
    body_text_font_align_left,
    body_text_font_align_right,
    body_text_font_align_center,
    body_text_color,
    enable_contact_icons_primary_color: contact_icons_primary_color.enable,
    contact_icons_primary_color: contact_icons_primary_color.color,
    enable_contact_icons_secondary_color: contact_icons_secondary_color.enable,
    contact_icons_secondary_color: contact_icons_secondary_color.color,
    enable_social_media_icons_color: social_media_icons_color.enable,
    social_media_icons_color: social_media_icons_color.color,
    enable_social_media_icons_background_color:
      social_media_icons_background_color.enable,
    social_media_icons_background_color:
      social_media_icons_background_color.color,
    enable_share_my_card_background_color:
      share_my_card_background_color.enable,
    share_my_card_background_color: share_my_card_background_color.color,
    enable_seconds_icon_color: seconds_icon_color.enable,
    seconds_icon_color: seconds_icon_color.color,
  };

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Fonts And Colors", result);
    if (result?.message) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading fonts & colors settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Fonts & Colors Settings uploaded",
      });
    }
  });
};
