import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import QRCode from "react-qr-code";

import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";
import { BrandingContext } from "../../contexts/designerContexts/branding.context";

import { parseRGBA } from "../../helpers/stylesheetGeneration";
import {
  buildContactIconsClass,
  insertClassToSVGPath,
} from "../../helpers/stylesheetGeneration";

import styles from "./stickyFooter.module.scss";
import share_symbol from "../../../assets/umicardViewer/stickyFooter/share.svg";

const StickyFooter = ({ preview = false }) => {
  const [fontsAndColors] = useContext(FontsAndColorsContext);
  const [brandingDetails] = useContext(BrandingContext);
  const [showQRCode, setShowQRCode] = useState(false);

  const {
    seconds_icon_color,
    share_my_card_background_color,
    sections_border_radius,
  } = fontsAndColors;

  const { enable, color } = share_my_card_background_color;

  const { enable: enable_share_card_color, color: share_card_color } =
    seconds_icon_color;

  const shareMyCardColor = buildContactIconsClass(seconds_icon_color);

  const { background } = brandingDetails;

  const {
    enable: enable_background_color,
    use_background_color,
    branding_background_color,
  } = background;

  const { username } = useParams();

  return (
    <React.Fragment>
      <div
        className={`${styles.qr_code} ${showQRCode && styles.show} ${
          preview && styles.preview
        }`}
      >
        {username !== "username" && (
          <QRCode value={window.location.origin + `/${username}`} />
        )}
      </div>
      <div
        className={styles.stickyFooter}
        style={{
          position: preview ? "absolute" : "",
          backgroundColor:
            enable_background_color && use_background_color
              ? parseRGBA(branding_background_color.rgb)
              : "",
        }}
      >
        <button
          className={styles.share_card_btn}
          style={{
            backgroundColor: enable && parseRGBA(color.rgb),
            color: enable_share_card_color
              ? parseRGBA(share_card_color.rgb)
              : "",
            borderRadius: !sections_border_radius && 0,
          }}
          onClick={() => {
            setShowQRCode(!showQRCode);
          }}
        >
          <span className={styles.share_symbol}>
            <ReactSVG
              src={share_symbol}
              wrapper={"span"}
              afterInjection={(err, svg) => {
                insertClassToSVGPath(err, svg, shareMyCardColor);
              }}
            />
          </span>{" "}
          Share My Card
        </button>
      </div>
    </React.Fragment>
  );
};

export default StickyFooter;
