import React from "react";
import AnalyticsChart from "../../common/charts/chart";

export const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 24],
  ["Commute", 8],
  ["Watch TV", 20],
  ["Sleep", 7],
];

export const options = {
  legend: "none",
  colors: ["#75D89D", "#FF81DC", "#81F2F9", "#FCC97B", "#1ba8f0", "#E97D85"],
  backgroundColor: "transparent",
  pieHole: 0.5,
  pieSliceText: "none",
};

const GeoPieChart = () => {
  return <AnalyticsChart chartType="PieChart" data={data} options={options} width="300px" />;
};

export default GeoPieChart;
