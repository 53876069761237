import { API_BASE } from "../../../../api_links";
import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
// import { buildNotification } from "../../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) {
    return;
  }

  const { enable, url, title, icon, background } = data;

  return {
    loaded: true,
    enable,
    url,
    title: title ?? "Calendly Title",
    icon: icon ?? {
      enable: false,
      type: "img", // img || fa
      preview_file: "", // Existing profile pic
      uploadable_file: "", // file object if new pic selected
      upload: "",
      color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    background: background ?? {
      enable: false,
      color: {
        hex: "#222f4f",
        rgb: {
          r: 34,
          g: 47,
          b: 79,
          a: 1,
        },
      },
    },
  };
};

export const getCalendlyFromDB = (
  calendly,
  setCalendly,
  username = null,
  data = null
) => {
  if (data) {
    console.log(data);
    const parsedData = parseDataForContext(data.calendly);
    if (!parsedData) {
      setCalendly({ ...calendly, loaded: true });
      return;
    }
    setCalendly(parsedData);
    return;
  }
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/calendly`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setCalendly(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Calendly Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/calendly/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setCalendly(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
