import React, { useContext } from "react";

import { CalendlyContext } from "../../../contexts/sectionsContexts/calendly.context";

import { uploadCalendly } from "./uploadFunction";

import {
  update_enable_calendly,
  update_calendly_url,
  update_calendly_title,
  update_enable_calendly_section_background_color,
  update_calendly_section_background_color,
  update_enable_calendly_section_icon,
  update_calendly_icon,
  select_icon_type_calendly,
  update_calendly_fa_icon_color,
  update_fa_icon_name_calendly,
} from "./updateFunctions";

import SectionTopBar from "../../../common/sectionTopBar/sectionTopBar";
import CollapsibleContainer from "../../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../../common/inputField/inputField";
import ColorPicker from "../../../common/colorPicker/colorPicker";
import CTAIconSelector from "../../../common/ctaIconSelector/ctaIconSelector";

const Calendly = ({ styles }) => {
  const [calendly, setCalendly] = useContext(CalendlyContext);

  const { enable, url, title, icon, background } = calendly;

  return (
    <div className="calendly">
      <SectionTopBar
        title={"Calendly"}
        id={"calendly-section-designer"}
        updateCallback={uploadCalendly}
        updateData={calendly}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title={"Enable Calendly"}
          inputId={"enable_calendly"}
          enabled={enable}
          callback={update_enable_calendly}
          context={calendly}
          updateContext={setCalendly}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={`calendly_url`}
              label={"Add your calendly"}
              defaultInputValue={url}
              prefix={"https://calendly.com/"}
              callback={update_calendly_url}
              context={calendly}
              updateContext={setCalendly}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Section Title"
          inputId="calendly_title_container"
          showSwitch={false}
        >
          <div className={styles.editable_section}>
            <InputField
              inputFieldId={"calendly_title"}
              label={"Calendly Section Title"}
              extraClass={"no_label"}
              defaultInputValue={title}
              callback={update_calendly_title}
              context={calendly}
              updateContext={setCalendly}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Icon"
          inputId="enable_calendly_section_icon"
          enabled={icon.enable}
          callback={update_enable_calendly_section_icon}
          context={calendly}
          updateContext={setCalendly}
        >
          <div className={styles.editable_section}>
            <CTAIconSelector
              inputFieldId={"calendly_section_icon"}
              selected={icon.type}
              selectedIcon={icon.fa_icon}
              iconColor={icon.color}
              selectorCallback={select_icon_type_calendly}
              imageCallback={update_calendly_icon}
              iconSelectorCallback={update_fa_icon_name_calendly}
              iconColorCallback={update_calendly_fa_icon_color}
              context={calendly}
              updateContext={setCalendly}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Calendly Section Background Color"
          inputId="enable_calendly_section_background"
          enabled={background.enable}
          callback={update_enable_calendly_section_background_color}
          context={calendly}
          updateContext={setCalendly}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="calendly_section_background_color"
              savedColor={background.color}
              callback={update_calendly_section_background_color}
              context={calendly}
              updateContext={setCalendly}
            />
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
};

export default Calendly;
