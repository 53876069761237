import React, { useState, createContext } from "react";

export const SidebarOrderContext = createContext();

export const SidebarOrderProvider = ({ children }) => {
  const [sidebarOrder, setSidebarOrder] = useState({
    order: [
      {
        title: "imageGallery_order",
        enable: true,
      },
      {
        title: "videoGallery_order",
        enable: true,
      },
      {
        title: "about_order",
        enable: true,
      },
      {
        title: "youtubeCreator_order",
        enable: true,
      },
      {
        title: "imageLinks_order",
        enable: true,
      },
      {
        title: "socialLinks_order",
        enable: true,
      },
      {
        title: "calendly_order",
        enable: true,
      },
    ],
  });

  return (
    <SidebarOrderContext.Provider value={[sidebarOrder, setSidebarOrder]}>
      {children}
    </SidebarOrderContext.Provider>
  );
};
