import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { copyToClipboard } from "../../helpers/utilityFunctions";

import { UsernameContext } from "../../contexts/designerContexts/username.context";
import { PersonalDetailsContext } from "../../contexts/designerContexts/personalDetails.context";
import {
  update_username,
  update_custom_username,
  update_Profile_pic,
  remove_uploaded_Profile_pic,
  update_profile_pic_size,
  update_profile_pic_left,
  update_profile_pic_top,
  update_profile_pic_rotate,
  update_share_leads_checkbox,
  update_name,
  update_company_business_name,
  update_job_title,
  update_email,
  update_mobile,
  update_phone,
  update_extension,
  update_profile_pic_border_style_version,
  update_profile_pic_border_stroke_width,
  update_profile_pic_border_stroke_color,
  update_enable_personal_details_background_color,
  update_personal_details_background_color,
} from "./updateFunctions";

import { uploadPersonalDetails, uploadUsername } from "./uploadFunction";

import CollapsibleContainer from "../../common/collapsibleContainer/collapsibleContainer";
import InputField from "../../common/inputField/inputField";
import Checkbox from "../../common/checkbox/checkbox";
import ImageUploader from "../../common/imageUploader/imageUploader";
import ImageEditor from "../../common/imageEditor/imageEditor";
import ColorPicker from "../../common/colorPicker/colorPicker";
import SectionTopBar from "../../common/sectionTopBar/sectionTopBar";
import Button from "../../common/button/button";

import copy_svg from "../../../assets/global/icon_copy.svg";
import help_svg from "../../../assets/global/icon_help.svg";

const PersonalDetail = ({ styles }) => {
  const [username, setUsername] = useContext(UsernameContext);
  const [personalDetails, setPersonalDetails] = useContext(
    PersonalDetailsContext
  );

  const {
    leads_form_fields,
    share_leads,
    profile_picture_adjustments,
    border_style,
    border_stroke,
    background,
    profile_picture_image_preview_file,
    custom_username,
  } = personalDetails;

  const borderStyleIDs = Object.keys(border_style.version);

  const {
    name,
    company_business_name,
    job_title,
    email,
    mobile,
    phone,
    extension,
  } = leads_form_fields;

  return (
    <div className="personal_details">
      <SectionTopBar
        title={"Personal Details"}
        id={"personal-details-section-designer"}
        updateCallback={uploadPersonalDetails}
        updateData={personalDetails}
      />
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer title="Change your username" showSwitch={false}>
          <div className={styles.editable_section}>
            <p className={styles.section_info_text}>
              The username is how visitors find and view your card; it must be
              unique, and you can change it to any available username. <br />
              - Spaces will be replaced with _ <br />- All lowercase letters{" "}
              <br />- Max 20 characters
            </p>
            <InputField
              inputFieldId={"username_update"}
              label={"You can update card link here"}
              placeholder={`Current username: ${username}`}
              callback={update_username}
              context={username}
              updateContext={setUsername}
            />
            <span className="vertical_space"></span>
            <h4 className={styles.section_heading}>Your Card URL</h4>
            {/* <p className={styles.section_info_text}>
              This URL is used for accessing the card via web; must be unique
              and Cannot be changed after the card is published
            </p> */}
            <InputField
              inputFieldId={"card_url"}
              label={"Card URL"}
              extraClass={"no_label"}
              helperClasses={"mtop_0"}
              defaultInputValue={`umicard.com/${username}`}
              disabled={true}
            >
              <button
                className={styles.copy_to_clipboard}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://umicard.com/" + username
                  );
                }}
              >
                <ReactSVG src={copy_svg} wrapper={"span"} /> Copy to clipboard
              </button>
            </InputField>
            <Button
              title={"Submit"}
              callback={uploadUsername}
              callbackData={username}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <CollapsibleContainer
          title="Change Background Color"
          subtitle="Some subtitle here if needed"
          inputId="enable_personal_details_background_color"
          enabled={background.enable}
          callback={update_enable_personal_details_background_color}
          context={personalDetails}
          updateContext={setPersonalDetails}
        >
          <div className={styles.editable_section}>
            <ColorPicker
              inputFieldId="personal_details_background_color"
              savedColor={background.color}
              callback={update_personal_details_background_color}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
          </div>
        </CollapsibleContainer>
      </div>
      <div className={`${styles.designer_white_box} ${styles.details_editor}`}>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>Profile Picture</h4>
          <p className={styles.section_info_text}>
            You photo will be showed on your card when visitors search of visit
            your card. Upload a photo with minimum 512 px width and height
          </p>
          <ImageUploader
            inputFieldId={"profile_picture_image_upload"}
            callback={update_Profile_pic}
            uploadedPicture={profile_picture_image_preview_file?.url}
            removeUploadedPicture={remove_uploaded_Profile_pic}
            afterUpdateCallback={uploadPersonalDetails}
            context={personalDetails}
            updateContext={setPersonalDetails}
          />
        </div>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>
            Change display settings of Profile Picture
          </h4>
          <ImageEditor
            inputFieldId={"profile_picture_image_upload"}
            defaultValues={profile_picture_adjustments}
            sizeCallback={update_profile_pic_size}
            leftCallback={update_profile_pic_left}
            topCallback={update_profile_pic_top}
            rotateCallback={update_profile_pic_rotate}
            context={personalDetails}
            updateContext={setPersonalDetails}
          />
        </div>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>
            Choose Profile Picture Border Type
          </h4>
          <div className={styles.profile_pic_border_styles}>
            {borderStyleIDs.map((id) => {
              return (
                <Checkbox
                  key={id}
                  inputFieldId={id}
                  checkboxStyle={"profile_pic_border_style_checkbox"}
                  sendIDInUpdate={true}
                  defaultValue={border_style.version[id]}
                  callback={update_profile_pic_border_style_version}
                  context={personalDetails}
                  updateContext={setPersonalDetails}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.editable_section}>
          <h4 className={styles.section_heading}>
            Add Stroke to your profile picture
          </h4>
          <div className={styles.profile_pic_border_styles}>
            {[0, 1, 2, 3, 4, 5].map((id) => {
              return (
                <Checkbox
                  key={id}
                  inputFieldId={id}
                  checkboxStyle={"profile_pic_border_style_checkbox"}
                  sendIDInUpdate={true}
                  defaultValue={border_stroke.width === id}
                  callback={update_profile_pic_border_stroke_width}
                  context={personalDetails}
                  updateContext={setPersonalDetails}
                />
              );
            })}
          </div>
          <ColorPicker
            inputFieldId={"profile_pic_border_color"}
            savedColor={border_stroke.color}
            callback={update_profile_pic_border_stroke_color}
            context={personalDetails}
            updateContext={setPersonalDetails}
          />
        </div>
        <div className={styles.editable_section}>
          <Checkbox
            inputFieldId={"share_leads"}
            label={"Share my contact with my leads only"}
            defaultValue={share_leads}
            callback={update_share_leads_checkbox}
            context={personalDetails}
            updateContext={setPersonalDetails}
          />
          <div className={styles.leads_contact_details_form}>
            <InputField
              inputFieldId={"name"}
              label={"Full name"}
              defaultInputValue={name}
              callback={update_name}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"company_business"}
              label={"Company/Business Name"}
              defaultInputValue={company_business_name}
              callback={update_company_business_name}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"job_title"}
              label={"Job Title"}
              defaultInputValue={job_title}
              callback={update_job_title}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"email"}
              label={"Email"}
              type={"email"}
              defaultInputValue={email}
              callback={update_email}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"mobile"}
              label={"Mobile number"}
              defaultInputValue={mobile}
              type={"tel"}
              callback={update_mobile}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"phone"}
              label={"Phone number"}
              defaultInputValue={phone}
              type={"tel"}
              extraClass={"sixty"}
              callback={update_phone}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
            <InputField
              inputFieldId={"extension"}
              label={"Extension"}
              defaultInputValue={extension}
              extraClass={"thirty"}
              callback={update_extension}
              context={personalDetails}
              updateContext={setPersonalDetails}
            />
          </div>
        </div>
        <div className={styles.editable_section}>
          {/* <h4 className={styles.section_heading}>Your Card URL</h4>
          <p className={styles.section_info_text}>
            This URL is used for accessing the card via web; must be unique and
            Cannot be changed after the card is published
          </p>
          <InputField
            inputFieldId={"card_url"}
            label={"Card URL"}
            extraClass={"no_label"}
            defaultInputValue={`umicard.com/${username}`}
            disabled={true}
          >
            <button className={styles.copy_to_clipboard}>
              <ReactSVG src={copy_svg} wrapper={"span"} /> Copy to clipboard
            </button>
          </InputField> */}
          <h4 className={styles.section_heading}>Custom URL</h4>
          <p className={styles.section_info_text}>
            This url can be changed in the future, click on the green question
            mark for more information
          </p>
          <InputField
            inputFieldId={"card_url_custom"}
            label={"Card URL"}
            extraClass={"no_label"}
            defaultInputValue={custom_username}
            callback={update_custom_username}
            context={personalDetails}
            updateContext={setPersonalDetails}
            prefix={"https://umicard.com/"}
          ></InputField>
          <button
            className={styles.copy_to_clipboard}
            onClick={() => {
              copyToClipboard(`https://umicard.com/${custom_username}`);
            }}
          >
            <ReactSVG src={copy_svg} wrapper={"span"} /> Copy to clipboard
          </button>
          <button className={styles.help}>
            <ReactSVG src={help_svg} wrapper={"span"} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetail;
