import { API_BASE } from "../../../../api_links";
import { uploadStaticData } from "../../../../uploadServices/uploadStaticData";
import { buildNotification } from "../../../notification/buildNotification";
import {
  signImageS3,
  uploadImageS3,
  deleteImageS3,
} from "../../../../uploadServices/imageUploader";

export const uploadImageGallery = async (imageGallery) => {
  const dataUploadURL = `${API_BASE}:LiqXQBP3/image_gallery`;

  const {
    image_gallery_section_title,
    image_gallery_section_icon,
    image_gallery_section_background,
    image_link_gallery_section_background,
    image_link_font_and_icon_color,
    gallery_data: gd,
  } = imageGallery;

  gd.map((item) => {
    const { mainImage, CTAIcon } = item;
    mainImage.upload = "";
    CTAIcon.upload = "";
  });

  const { enable: enable_image_gallery_section_title, title } =
    image_gallery_section_title;

  const {
    enable: enable_image_gallery_section_icon,
    type: image_gallery_icon_type,
    fa_icon: image_gallery_icon_name,
    color: image_gallery_icon_color,
    preview_file: image_gallery_icon_existing_url,
    uploadable_file: image_gallery_icon_uploadable_file,
  } = image_gallery_section_icon;

  const {
    enable: enable_gallery_section_background,
    color: image_gallery_section_background_color,
  } = image_gallery_section_background;

  const {
    enable: enable_image_link_gallery_section_background,
    color: image_link_gallery_background_color,
  } = image_link_gallery_section_background;

  const {
    enable: enable_image_link_font_and_icon_color,
    color: image_link_color,
    font: image_link_font,
    font_bold: image_link_font_bold,
    font_italic: image_link_font_italic,
    font_underline: image_link_font_underline,
    font_align_left: image_link_font_align_left,
    font_align_right: image_link_font_align_right,
    font_align_center: image_link_font_align_center,
  } = image_link_font_and_icon_color;

  const updateObj = {
    enable_image_gallery_section_title,
    image_gallery_section_title: title,
    enable_image_gallery_section_icon,
    image_gallery_icon_type,
    image_gallery_icon_name,
    image_gallery_icon_color,
    image_gallery_section_icon_url: image_gallery_icon_existing_url,
    enable_image_gallery_section_background: enable_gallery_section_background,
    image_gallery_section_background_color,
    enable_image_link_gallery_section_background,
    image_link_gallery_section_background_color:
      image_link_gallery_background_color,
    enable_image_link_font_and_icon_color,
    image_link_color,
    image_link_font,
    image_link_font_bold,
    image_link_font_italic,
    image_link_font_underline,
    image_link_font_align_left,
    image_link_font_align_right,
    image_link_font_align_center,
    gallery_data: JSON.stringify(gd),
  };

  if (image_gallery_icon_uploadable_file) {
    try {
      const signedRequestObject = await signImageS3(
        image_gallery_icon_uploadable_file,
        "imageGallery/Icons"
      );
      signedRequestObject.json().then(async (data) => {
        const { signedRequest, url } = data;
        if (!signedRequest || !url) return;
        try {
          const uploadResult = await uploadImageS3(
            image_gallery_icon_uploadable_file,
            signedRequest
          );
          console.log("upload s3 result", uploadResult, url);
          if (uploadResult.status === 200) {
            // Delete previoud image
            deleteImageS3(image_gallery_icon_existing_url);
            // Update new URL
            updateObj["image_gallery_section_icon_url"] = url;
            // Upload to xano
            uploadStaticData(true, dataUploadURL, updateObj, (result) => {
              console.log("Static Upload Result Image Gallery", result);
              if (result?.code) {
                buildNotification({
                  type: "error",
                  message:
                    result?.message ?? "Error uploading image gallery icon",
                });
              } else {
                buildNotification({
                  type: "success",
                  message: "Image Gallery icon uploaded",
                });
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  for (let i = 0; i < gd.length; i++) {
    const gallery_item = gd[i];

    const { mainImage, CTAIcon } = gallery_item;
    if (mainImage.uploadable_file) {
      try {
        const signedRequestObject = await signImageS3(
          mainImage.uploadable_file,
          "imageGallery/mainImages"
        );
        signedRequestObject.json().then(async (data) => {
          const { signedRequest, url } = data;
          if (!signedRequest || !url) return;
          try {
            const uploadResult = await uploadImageS3(
              mainImage.uploadable_file,
              signedRequest
            );
            console.log("upload s3 result", uploadResult, url);
            if (uploadResult.status === 200) {
              deleteImageS3(mainImage?.preview_file);
              let tempUpdateObjGD = JSON.parse(updateObj.gallery_data);
              tempUpdateObjGD[i].mainImage.preview_file = url;
              tempUpdateObjGD[i].mainImage.uploadable_file = "";
              tempUpdateObjGD[i].mainImage.upload = "";
              updateObj.gallery_data = JSON.stringify(tempUpdateObjGD);
              // Upload to xano
              uploadStaticData(true, dataUploadURL, updateObj, (result) => {
                console.log("Static Upload Result Image Gallery", result);
                if (result?.code) {
                  buildNotification({
                    type: "error",
                    message:
                      result?.message ?? "Error uploading image gallery Image",
                  });
                } else {
                  buildNotification({
                    type: "success",
                    message: "Image Gallery Image uploaded",
                  });
                }
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (CTAIcon.uploadable_file) {
      try {
        const signedRequestObject = await signImageS3(
          CTAIcon.uploadable_file,
          "imageGallery/ctaIconImages"
        );
        signedRequestObject.json().then(async (data) => {
          const { signedRequest, url } = data;
          if (!signedRequest || !url) return;
          try {
            const uploadResult = await uploadImageS3(
              CTAIcon.uploadable_file,
              signedRequest
            );
            console.log("upload s3 result", uploadResult, url);
            if (uploadResult.status === 200) {
              deleteImageS3(CTAIcon?.preview_file);
              let tempUpdateObjGD = JSON.parse(updateObj.gallery_data);
              tempUpdateObjGD[i].CTAIcon.preview_file = url;
              tempUpdateObjGD[i].CTAIcon.uploadable_file = "";
              tempUpdateObjGD[i].CTAIcon.upload = "";
              updateObj.gallery_data = JSON.stringify(tempUpdateObjGD);
              // Upload to xano
              uploadStaticData(true, dataUploadURL, updateObj, (result) => {
                console.log("Static Upload Result Image Gallery", result);
                if (result?.code) {
                  buildNotification({
                    type: "error",
                    message:
                      result?.message ??
                      "Error uploading image gallery CTA Icon",
                  });
                } else {
                  buildNotification({
                    type: "success",
                    message: "Image Gallery CTA Icon uploaded",
                  });
                }
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  console.log("Update data", updateObj);

  // This callback would later be used for showing update notifications
  uploadStaticData(true, dataUploadURL, updateObj, (result) => {
    console.log("Static Upload Result Image Gallery", result);
    if (result?.code) {
      buildNotification({
        type: "error",
        message: result?.message ?? "Error uploading image gallery settings",
      });
    } else {
      buildNotification({
        type: "success",
        message: "Image Gallery Settings uploaded",
      });
    }
  });
};
