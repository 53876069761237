import React from "react";

const TabBody = ({ styles, show, children }) => {
  return (
    <div
      className={styles.tabBody}
      style={{ display: show ? "block" : "none" }}
    >
      {children}
    </div>
  );
};

export default TabBody;
