import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

const YouTubeCreatorSkeleton = () => {
  return (
    <div className={styles.video_gallery_skeleton}>
      <Skeleton width={"100%"} className={styles.main_bg} />

      <Skeleton
        width={"100%"}
        className={styles.text}
        containerClassName={styles.header_text}
      />
      <Skeleton className={styles.cta_main_icon} />
      <Skeleton
        width={"100%"}
        className={styles.text_small}
        containerClassName={styles.header_sub_text}
      />
      <Skeleton className={styles.gallery_main_video} />
      <Skeleton className={styles.cta_bg} />
      <Skeleton className={styles.cta_icon} />
      <Skeleton
        className={styles.text_small}
        containerClassName={styles.cta_text}
      />
      <span className={styles.divider}></span>
      <div className={styles.list}>
        <Skeleton className={styles.img} containerClassName={styles.item} />
        <Skeleton className={styles.img} containerClassName={styles.item} />
        <Skeleton className={styles.img} containerClassName={styles.item} />
        <Skeleton className={styles.img} containerClassName={styles.item} />
      </div>
    </div>
  );
};

export default YouTubeCreatorSkeleton;
