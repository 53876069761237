import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import styles from "./checkbox.module.scss";
import linkdInBasic_svg from "../../../assets/sections/socialLinks/linkedIn-basic.svg";
import user_avarar_png from "../../../assets/demo-content/user_image_2.jpg";

const Checkbox = ({
  inputFieldId,
  label = "",
  required = false,
  checkboxStyle = "",
  icon = null,
  defaultValue = false,
  callback = null,
  context = null,
  updateContext = null,
  sendIDInUpdate = false,
  hideError = null,
  disabled = false,
  children,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`${styles.checkbox_container} ${styles[checkboxStyle] ?? ""}`}
    >
      {required && <span className={styles.required}>*</span>}
      <input
        className={styles.checkbox}
        name={inputFieldId}
        id={inputFieldId}
        type="checkbox"
        checked={value}
        onChange={() => {
          const updatedValue = !value;
          setValue(updatedValue);
          if (callback && !sendIDInUpdate)
            callback(updatedValue, context, updateContext);
          if (callback && sendIDInUpdate)
            callback(updatedValue, inputFieldId, context, updateContext);
          if (hideError) hideError("");
        }}
        disabled={disabled}
      />
      <label htmlFor={inputFieldId}>
        {icon && (
          <span className={styles.icon_left}>
            <ReactSVG src={icon} wrapper={"span"} />
          </span>
        )}
        <span className={styles.label_text}>{label}</span>
        {children}
        {checkboxStyle === "social_icons_style_checkbox" && (
          <span
            className={`${styles.social_icon_display_container} ${styles[inputFieldId]}`}
          >
            <ReactSVG src={linkdInBasic_svg} wrapper={"span"} />
          </span>
        )}
        {checkboxStyle === "profile_pic_border_style_checkbox" && (
          <span
            className={`${styles.profile_icon_display_container} ${styles[inputFieldId]}`}
          >
            <span>
              {isNaN(inputFieldId) ? (
                <img src={user_avarar_png} alt={inputFieldId} />
              ) : (
                inputFieldId
              )}
            </span>
          </span>
        )}
        {checkboxStyle === "image_link_style_checkbox" && (
          <span
            className={`${styles.image_link_icon_display_container} ${styles[inputFieldId]}`}
          ></span>
        )}
        {checkboxStyle === "signup_form" && (
          <span className={`${styles[inputFieldId]}`}>
            By creating an account, you are agreeing to our{" "}
            <a href="https://UmiCard.com/Terms-and-Conditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="https://UmiCard.com/Privacy-Policy" target="_blank">
              Privacy Policy
            </a>
            .
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
