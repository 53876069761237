export const updateContactPrivacyFormSetting = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  Object.keys(crmCopy).forEach((type) => {
    const targetType = crmCopy[type];
    if (!targetType) return;
    if (type === value) targetType.enable = true;
    else targetType.enable = false;
  });
  setCrm(crmCopy);
};

export const update_contact_privacy_password = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crmCopy.use_password.password = value;
  setCrm(crmCopy);
};

export const update_crm_field_first_name = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["first_name"] = value;
  setCrm(crmCopy);
};

export const update_crm_field_last_name = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["last_name"] = value;
  setCrm(crmCopy);
};

export const update_crm_field_phone = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["phone"] = value;
  setCrm(crmCopy);
};

export const update_crm_field_email = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["email"] = value;
  setCrm(crmCopy);
};

export const update_crm_field_company = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["company"] = value;
  setCrm(crmCopy);
};

export const update_crm_field_note = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_fields;
  if (!targetField) return;
  targetField["note"] = value;
  setCrm(crmCopy);
};

export const update_crm_selection = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  const targetField = crmCopy.use_form.crm_details;
  if (!targetField) return;
  const tempCrmOptions = [];
  Object.keys(targetField.crm_options).forEach((key) => {
    tempCrmOptions.push(key);
  });
  if (!tempCrmOptions.includes(value)) return;
  targetField.crmID = value;
  setCrm(crmCopy);
};

export const update_crm_convertkit_api_key = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.convertkit.API_Key = value;
  setCrm(crmCopy);
};

export const update_crm_convertkit_api_secret = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.convertkit.API_Secret = value;
  setCrm(crmCopy);
};

export const update_crm_convertkit_form_id = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.convertkit.form_id = value;
  setCrm(crmCopy);
};

export const update_crm_convertkit_tag_id = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.convertkit.tag_id = value;
  setCrm(crmCopy);
};

export const update_crm_activecampaign_api_key = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.activecampaign.API_Key = value;
  setCrm(crmCopy);
};

export const update_crm_activecampaign_api_url = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.activecampaign.API_URL = value;
  setCrm(crmCopy);
};

export const update_crm_activecampaign_tag_id = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.activecampaign.tag_id = value;
  setCrm(crmCopy);
};

export const update_crm_hubspot_api_key = (value, crm, setCrm) => {
  const crmCopy = { ...crm };
  crm.use_form.crm_details.crm_options.hubspot.API_Key = value;
  setCrm(crmCopy);
};
