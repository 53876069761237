import React, { useContext } from "react";

import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";

import styles from "./footer.module.scss";

import logo from "../../../assets/global/umicard_logo2.png";

const Footer = () => {
  const [fontsAndColors] = useContext(FontsAndColorsContext);

  const { sections_border_radius } = fontsAndColors;

  return (
    <div
      className={styles.footer}
      style={{ borderRadius: !sections_border_radius && 0 }}
    >
      <img src={logo} alt="Umicard" className={styles.logo} />
      <p className={styles.text}>
        Powered by the Umicard <br /> 2022 Umicard Pty Ltd. All rights Reserved.
      </p>
      <a
        href="/"
        target={"_blank"}
        className={styles.cta}
        style={{ borderRadius: !sections_border_radius && 0 }}
      >
        Get Your Card
      </a>
      <div className={styles.links}>
        <a
          href="https://UmiCard.com/Privacy-Policy"
          target={"_blank"}
          className={styles.link}
        >
          Privacy Policy
        </a>
        <a
          href="https://UmiCard.com/Terms-and-Conditions"
          target={"_blank"}
          className={styles.link}
        >
          Terms & Conditions
        </a>
      </div>
    </div>
  );
};

export default Footer;
