import Cookies from "js-cookie";
import { buildNotification } from "../../notification/buildNotification";
import { buildLoader, destroyLoader } from "../../notification/buildLoader";

import { API_BASE } from "../../../api_links";

export const signUpService = (e, setSignupError) => {
  e.preventDefault();

  const SIGNUP_ENDPOINT = API_BASE + ":LiqXQBP3/auth/signup";

  const invite_code = e.target.invite_code.value;
  const email = e.target.signup_email_phone.value;
  // const username = e.target.signup_username.value;
  const password = e.target.signup_password.value;
  const tncAgree = e.target.signup_agree_terms_conditions.checked;

  if (!invite_code || !email || !password) {
    buildNotification({
      type: "warning",
      message: "You must fill all required fields",
    });
    setSignupError("You must fill all required fields");
    return;
  }

  if (!tncAgree) {
    buildNotification({
      type: "warning",
      message: "You must agree to TnC first",
    });
    setSignupError(
      "Please make sure you Read and Agree with UmiCard's Privacy Policy and Terms and Conditions."
    );
    return;
  }

  const submitData = { invite_code, email, password };

  console.log(submitData);

  const loader = buildLoader();

  fetch(SIGNUP_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(submitData),
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
        buildNotification({
          type: "error",
          message: data.message,
        });
        setSignupError(data.message);
      } else {
        // Success
        console.log(data);
        buildNotification({
          type: "success",
          message: "Signed up! Please check your email for verification code",
        });
        if (data?.authToken) {
          Cookies.set("authToken", data.authToken, { expires: 1 });
          window.location.href = "/login";
        }
      }
    });
};

export const verifyUserEmail = async (
  e,
  set_verify_email_error,
  set_verify_email_info
) => {
  e.preventDefault();

  const token = e.target.verification_code.value;
  if (!token) return;

  const VERIFY_ENDPOINT = API_BASE + ":LiqXQBP3/verifyuseremail";

  const loader = buildLoader();

  fetch(VERIFY_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  })
    .then((response) => response.json())
    .then((data) => {
      destroyLoader(loader);
      if (data?.message) {
        console.log(data.message);
        set_verify_email_error("Verification Unuccessful!");
      } else {
        set_verify_email_info("Verification Successful!");
        console.log("Verification status", data);
        window.location.href = "/login";
      }
    });
};
