import React, { useState, useEffect, useContext } from "react";

import { ProfileInfoContext } from "../../../contexts/profileContexts/profile.context";

import { getProfileInformationFromDB } from "./useDBData";
import {
  update_first_name,
  update_last_name,
  update_phone,
  update_email,
  update_job_title,
} from "./updateFunctions";
import { uploadProfileInfo } from "./uploadFunction";

import {
  forgotPassword,
  resetPassword,
} from "../../../authFlow/login/login.service";

import InputField from "../../../common/inputField/inputField";
import Button from "../../../common/button/button";

const ProfileBody = ({ styles }) => {
  const [profileInfo, setProfileInfo] = useContext(ProfileInfoContext);
  const [forgotPassword_info, set_forgotPassword_info] = useState(false);
  const [forgotPassword_error, set_forgotPassword_error] = useState(false);
  const [reset_password_error, set_reset_password_error] = useState("");
  const [reset_password_info, set_reset_password_info] = useState("");

  const { first_name, last_name, email, phone, job_title } = profileInfo;

  useEffect(() => {
    getProfileInformationFromDB(setProfileInfo);
  }, []);

  return (
    <div className={styles.inner}>
      <h3 className={styles.tab_heading}>Profile Information</h3>
      <div className={styles.inputs}>
        <div className={styles.input}>
          <InputField
            inputFieldId={"f_name_pi"}
            label={"First Name"}
            defaultInputValue={first_name}
            placeholder={"Sara"}
            callback={update_first_name}
            context={profileInfo}
            updateContext={setProfileInfo}
          />
        </div>
        <div className={styles.input}>
          <InputField
            inputFieldId={"l_name_pi"}
            label={"Last Name"}
            defaultInputValue={last_name}
            placeholder={"Jones"}
            callback={update_last_name}
            context={profileInfo}
            updateContext={setProfileInfo}
          />
        </div>
        <div className={styles.input}>
          <InputField
            inputFieldId={"email_pi"}
            label={"Email"}
            type={"email"}
            defaultInputValue={email}
            placeholder={"sara@example.com"}
            callback={update_email}
            context={profileInfo}
            updateContext={setProfileInfo}
          />
        </div>
        <div className={styles.input}>
          <InputField
            inputFieldId={"job_title_pi"}
            label={"Job Title"}
            defaultInputValue={job_title}
            placeholder={"Founder"}
            callback={update_job_title}
            context={profileInfo}
            updateContext={setProfileInfo}
          />
        </div>
        <div className={styles.input}>
          <InputField
            inputFieldId={"phone_pi"}
            label={"Phone Number"}
            type={"tel"}
            defaultInputValue={phone}
            placeholder={"+44 123 456 7890"}
            callback={update_phone}
            context={profileInfo}
            updateContext={setProfileInfo}
          />
        </div>
        <div className={styles.full_width}>
          <Button
            title={"Update"}
            extraClass={"profile_update_button"}
            callback={uploadProfileInfo}
            callbackData={profileInfo}
          />
        </div>
      </div>
      <span className={styles.divider}></span>
      <h3 className={styles.tab_heading}>Update Password</h3>
      <div className={styles.inputs}>
        <div className={styles.full_width}>
          {!forgotPassword_info && (
            <Button
              title={"Get Password Reset Code"}
              extraClass={"profile_update_button"}
              callback={() => {
                forgotPassword(
                  email,
                  set_forgotPassword_error,
                  set_forgotPassword_info,
                  () => {}
                );
              }}
              callbackData={"test"}
            />
          )}
          {forgotPassword_info && (
            <p className={styles.info}>
              Reset token is sent to your registered email address. Please enter
              it below with new Password.
            </p>
          )}
          {forgotPassword_error && (
            <p className={styles.error}>
              Some error occurred while sending code. Try again.
            </p>
          )}
        </div>
        {forgotPassword_info && (
          <form
            onSubmit={(e) => {
              resetPassword(
                e,
                set_reset_password_error,
                set_reset_password_info
              );
            }}
            autoComplete="false"
          >
            <div className={styles.input}>
              <InputField
                inputFieldId={"forgot_password_code"}
                label={"Password Reset Token"}
                placeholder={"ab-sdc234-sdkccv-llk"}
              />
            </div>
            <div className={styles.input}>
              <InputField
                inputFieldId={"forgot_password_one"}
                label={"New Password"}
                type={"password"}
              />
            </div>
            <div className={styles.input}>
              <InputField
                inputFieldId={"forgot_password_two"}
                label={"Re-Enter New Password"}
                type={"password"}
              />
            </div>
            <div className={styles.full_width}>
              {reset_password_info && (
                <p className={styles.info}>{reset_password_info}</p>
              )}
              {reset_password_error && (
                <p className={styles.error}>{reset_password_error}</p>
              )}
              <Button
                title={"Reset Password"}
                extraClass={"profile_update_button"}
                type={"submit"}
              />{" "}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ProfileBody;
