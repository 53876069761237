import React, { useEffect } from "react";
import { StripeNode } from "../../../api_links";

import { buildLoader } from "../../notification/buildLoader";
import { buildNotification } from "../../notification/buildNotification";
import { findStripeSessionID } from "../../helpers/utilityFunctions";

const SubscriptionConfirm = () => {
  useEffect(() => {
    buildLoader();
    const stripeSessionId = findStripeSessionID();
    if (!stripeSessionId) window.location.href = "/login";
    else {
      fetch(`${StripeNode}/checkout-session/${stripeSessionId}`, {
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data?.status) {
            buildNotification({
              type: "success",
              message: "Payment Done!",
            });
            window.location.href = "/designer";
          } else {
            buildNotification({
              type: "error",
              message: "Payment Not done. Redirectly to free dashboard",
            });
            window.location.href = "/designer";
          }
        });
    }
  }, []);

  return <p>verifying...</p>;
};

export default SubscriptionConfirm;
