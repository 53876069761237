import React, { useEffect, useContext, useState } from "react";
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import { CRMURL } from "../../../api_links";
import { SOCIAL_PREFIXES } from "../../helpers/prefixes";

import { DataLoadContext } from "../../contexts/dataLoad.context";
import { CardDesignContext } from "../../contexts/designerContexts/cardDesign.context";
import { PersonalDetailsContext } from "../../contexts/designerContexts/personalDetails.context";
import { SocialLinksContext } from "../../contexts/sectionsContexts/socialLinks.context";
import { BrandingContext } from "../../contexts/designerContexts/branding.context";
import { FontsAndColorsContext } from "../../contexts/designerContexts/fontsAndColors.context";
import { SidebarOrderContext } from "../../contexts/designerContexts/sectionsOrder.context";
import { CRMContext } from "../../contexts/settingsContexts/crm.context";

import {
  parseRGBA,
  buildTextClass,
  buildImageEditedClass,
  buildContactIconsClass,
  insertClassToSVGCircle,
  insertClassToSVGPath,
} from "../../helpers/stylesheetGeneration";

import styles from "./personalDetailsViewer.module.scss";
import modalStyles from "../../common/modal/modal.module.scss";

import PersonlDetailsSkeleton from "../../skeletons/personalDetails/personalDetails.skeleton";
import SocialLinksSkeleton from "../../skeletons/socialLinks/socialLinks.skeleton";

import Button from "../../common/button/button";
import Modal from "../../common/modal/modal";
import InputField from "../../common/inputField/inputField";
import RadioInputField from "../../common/radioInputField/radioInputField";
import TextArea from "../../common/textArea/textArea";
import LockedOverlay from "../../common/lockedOverlay/lockedOverlay";
import PasswordProtected from "../../common/passwordProtected/passwordProtected";

import business_logo_placeholder_svg from "../../../assets/umicardViewer/personalDetailsViewer/business_logo_placeholder.svg";
import profile_pic_placeholder_svg from "../../../assets/demo-content/user_image_3.jpg";
import mobile_icon_svg from "../../../assets/umicardViewer/personalDetailsViewer/mobile_icon.svg";
import text_icon_svg from "../../../assets/umicardViewer/personalDetailsViewer/text_icon.svg";
import phone_icon_svg from "../../../assets/umicardViewer/personalDetailsViewer/phone_icon.svg";
import email_icon_svg from "../../../assets/umicardViewer/personalDetailsViewer/email_icon.svg";

// Social icons
import linkedin_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/linkedin.svg";
import facebook_messenger_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/facebook_messenger.svg";
import facebook_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/facebook.svg";
import instagram_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/instagram.svg";
import pinterest_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/pinterest.svg";
import podcast_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/podcast.svg";
import skype_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/skype.svg";
import snapchat_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/snapchat.svg";
import twitter_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/twitter.svg";
import twitch_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/twitch.svg";
import whatsapp_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/whatsapp.svg";
import youtube_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/youtube.svg";
import wordpress_svg from "../../../assets/umicardViewer/personalDetailsViewer/socialSVGs/wordpress.svg";
import close_svg from "../../../assets/sections/close.svg";

const SocialLink_SVG_MAP = {
  linkedin: linkedin_svg,
  facebook_messenger: facebook_messenger_svg,
  facebook: facebook_svg,
  instagram: instagram_svg,
  pinterest: pinterest_svg,
  podcast: podcast_svg,
  skype: skype_svg,
  snapchat: snapchat_svg,
  twitter: twitter_svg,
  twitch: twitch_svg,
  whatsapp: whatsapp_svg,
  youtube: youtube_svg,
  wordpress: wordpress_svg,
};

const PersonalDetailsViewer = () => {
  const { username } = useParams();
  const [dataLoad, setDataLoad] = useContext(DataLoadContext);
  const [cardDesign] = useContext(CardDesignContext);
  const [personalDetails] = useContext(PersonalDetailsContext);
  const [socialLinks] = useContext(SocialLinksContext);
  const [brandingDetails] = useContext(BrandingContext);
  const [fontsAndColors] = useContext(FontsAndColorsContext);
  const [sidebarOrder] = useContext(SidebarOrderContext);
  const [crm] = useContext(CRMContext);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showleadsForm, setShowLeadsForm] = useState(false);
  const [showLeadsFormError, setShowLeadsFormError] = useState(false);
  const [showSocialLinks, setShowSocialLinks] = useState(true);

  useEffect(() => {
    if (personalDetails.loaded) {
      setDataLoad({ ...dataLoad, personalDetails: true });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (socialLinks.loaded) {
      setDataLoad({ ...dataLoad, socialLinks: true });
    }
  }, [socialLinks]);

  const {
    profile_picture_image_preview_file,
    profile_picture_adjustments,
    border_style,
    border_stroke,
    background: pd_bg,
    profile_picture_image_upload,
    leads_form_fields,
  } = personalDetails;

  let profile_pic_border_style = "rounded_border";
  Object.keys(border_style.version).forEach((bsv) => {
    if (border_style.version[bsv]) profile_pic_border_style = bsv;
  });
  const backgroundColor_PD = parseRGBA(pd_bg.color.rgb);

  const {
    name,
    company_business_name,
    job_title,
    email,
    mobile,
    phone,
    extension,
  } = leads_form_fields;

  const { style, icons_color, social_links, order } = socialLinks;

  let selectedSocialIconStyle = "";
  if (style?.enable) {
    Object.keys(style.version).forEach((key) => {
      if (style.version[key]) selectedSocialIconStyle = key;
    });
  }

  const customSocialIconsPathColor = buildContactIconsClass(icons_color);

  const { business_brand_name, business_brand_logo, brand_logo_adjustments } =
    brandingDetails;

  const {
    enable: enable_brand_business_name,
    brand_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color,
  } = business_brand_name;

  const brand_name_custom_class = buildTextClass(
    enable_brand_business_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color
  );

  const {
    enable: enable_business_brand_logo,
    business_brand_logo_preview_file,
    business_brand_logo_upload,
  } = business_brand_logo;

  const {
    sections_border_radius,
    contact_icons_primary_color,
    contact_icons_secondary_color,
    social_media_icons_color,
    social_media_icons_background_color,
  } = fontsAndColors;

  const socialIconsBorderColor = parseRGBA(social_media_icons_color.color.rgb);
  const socialIconsBackgroundColor = parseRGBA(
    social_media_icons_background_color.color.rgb
  );

  const customContactIconsBG = buildContactIconsClass(
    contact_icons_primary_color
  );

  const customContactIconsInnerColor = buildContactIconsClass(
    contact_icons_secondary_color
  );

  const { use_form, use_password, no_one, everyone } = crm;

  const {
    first_name: lead_fn,
    last_name: lead_ln,
    email: lead_e,
    phone: lead_p,
    company: lead_c,
    note: lead_n,
  } = use_form?.crm_fields;

  useEffect(() => {
    setShowContactDetails(crm.everyone.enable);
  }, [crm]);

  const submitLead = (e) => {
    e.preventDefault();
    const acknowledgeState = e.target["acknowledge-crm-privacy"].checked;
    if (!acknowledgeState) {
      setShowLeadsFormError(true);
      return;
    } else setShowLeadsFormError(false);
    const submitData = {};
    const form = e.target;
    const allFields = form.querySelectorAll("input, textarea");
    let allInput = true;
    allFields.forEach((field) => {
      if (field.value === "") {
        allInput = false;
        field.placeholder = "Fill this field before submitting...";
      }
      submitData[field.name] = field.value;
    });

    if (!allInput) return;
    // Now submit to server
    if (!username) return;

    fetch(`${CRMURL}/submit_lead/${username}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(submitData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setShowContactDetails(true);
          setShowLeadsForm(false);
        }
      });
  };

  useEffect(() => {
    sidebarOrder.order.forEach((section) => {
      if (section.title === "socialLinks_order") {
        setShowSocialLinks(section.enable);
      }
    });
  }, [sidebarOrder]);

  return (
    <React.Fragment>
      {!dataLoad.personalDetails || !dataLoad.socialLinks ? (
        <>
          <PersonlDetailsSkeleton />
          <SocialLinksSkeleton />
        </>
      ) : (
        <>
          {(cardDesign === "layout_1" || cardDesign === "layout_2") && (
            <div
              id="personal-details-viewer"
              className={`${styles.personal_details_viewer} ${
                styles[cardDesign] ?? styles.layout_1
              }`}
            >
              {enable_business_brand_logo && (
                <img
                  className={styles.business_logo}
                  style={{
                    width: !isNaN(brand_logo_adjustments.size)
                      ? `${brand_logo_adjustments.size}%`
                      : "90%",
                  }}
                  src={
                    business_brand_logo_upload
                      ? business_brand_logo_upload
                      : business_brand_logo_preview_file?.url
                      ? business_brand_logo_preview_file?.url
                      : business_logo_placeholder_svg
                  }
                  alt="Business Logo"
                />
              )}
              <div
                className={styles.details_box}
                style={{
                  backgroundColor: pd_bg.enable && backgroundColor_PD,
                  borderRadius: !sections_border_radius && 0,
                }}
              >
                <div className={styles.profile_main_info}>
                  <p
                    className={`${styles.business_name} ${brand_name_custom_class}`}
                  >
                    {brand_name ?? company_business_name}
                  </p>
                  <div
                    className={`${styles.profile_picture} ${styles[profile_pic_border_style]}`}
                    style={{
                      borderWidth: border_stroke.width,
                      borderColor: parseRGBA(border_stroke.color.rgb),
                      borderStyle: "solid",
                    }}
                  >
                    <img
                      className={buildImageEditedClass(
                        profile_picture_adjustments
                      )}
                      src={
                        profile_picture_image_upload
                          ? profile_picture_image_upload
                          : profile_picture_image_preview_file?.url
                          ? profile_picture_image_preview_file?.url
                          : profile_pic_placeholder_svg
                      }
                      // Check for live preview image first
                      // If not found, check for uploaded image
                      // If not found, use placeholder image
                      alt="Profile Picture"
                    />
                  </div>
                  <p className={styles.user_name}>{name}</p>
                  <p className={styles.business_role}>{job_title}</p>
                </div>
              </div>
              {!no_one.enable && (
                <div
                  className={styles.contact_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={mobile_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Mobile</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={text_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Text</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + extension + phone : ""}
                  >
                    <ReactSVG
                      src={phone_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Phone</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "mailto:" + email : ""}
                  >
                    <ReactSVG
                      src={email_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Email</span>
                  </a>
                  {!showContactDetails && (
                    <LockedOverlay
                      title={"Show Contact Details"}
                      callback={() => {
                        setShowLeadsForm(!showleadsForm);
                      }}
                      hide={showContactDetails}
                    />
                  )}
                </div>
              )}
              {showSocialLinks && (
                <div
                  className={styles.social_links_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  {order.map((key) => {
                    const selectedSocialItem = social_links[key];
                    if (selectedSocialItem) {
                      if (selectedSocialItem.enable)
                        return (
                          <a
                            key={key}
                            href={
                              SOCIAL_PREFIXES[key] + selectedSocialItem?.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              className={`${styles.social_icon_display_container} ${styles[selectedSocialIconStyle]}`}
                              style={{
                                backgroundColor:
                                  social_media_icons_background_color.enable
                                    ? socialIconsBackgroundColor
                                    : "",
                                borderColor: social_media_icons_color.enable
                                  ? socialIconsBorderColor
                                  : "",
                              }}
                            >
                              <ReactSVG
                                src={SocialLink_SVG_MAP[key] ?? ""}
                                wrapper={"span"}
                                afterInjection={(err, svg) => {
                                  insertClassToSVGPath(
                                    err,
                                    svg,
                                    customSocialIconsPathColor
                                  );
                                }}
                              />
                            </span>
                          </a>
                        );
                    }
                  })}
                </div>
              )}
            </div>
          )}
          {cardDesign === "layout_3" && (
            <div
              id="personal-details-viewer"
              className={`${styles.personal_details_viewer} ${
                styles[cardDesign] ?? styles.layout_1
              }`}
            >
              <div
                className={styles.business_logo_container}
                style={{
                  backgroundColor: pd_bg.enable && backgroundColor_PD,
                  borderRadius: !sections_border_radius && 0,
                }}
              >
                {enable_business_brand_logo && (
                  <img
                    className={styles.business_logo}
                    style={{
                      width: !isNaN(brand_logo_adjustments.size)
                        ? `${brand_logo_adjustments.size}%`
                        : "90%",
                    }}
                    src={
                      business_brand_logo_upload
                        ? business_brand_logo_upload
                        : business_brand_logo_preview_file?.url
                        ? business_brand_logo_preview_file?.url
                        : business_logo_placeholder_svg
                    }
                    alt="Business Logo"
                  />
                )}
              </div>
              <div
                className={styles.details_box}
                style={{
                  backgroundColor: pd_bg.enable && backgroundColor_PD,
                  borderRadius: !sections_border_radius && 0,
                }}
              >
                <div className={styles.profile_main_info}>
                  <div
                    className={`${styles.profile_picture} ${styles[profile_pic_border_style]}`}
                    style={{
                      borderWidth: border_stroke.width,
                      borderColor: parseRGBA(border_stroke.color.rgb),
                      borderStyle: "solid",
                    }}
                  >
                    <img
                      className={buildImageEditedClass(
                        profile_picture_adjustments
                      )}
                      src={
                        profile_picture_image_upload
                          ? profile_picture_image_upload
                          : profile_picture_image_preview_file?.url
                          ? profile_picture_image_preview_file?.url
                          : profile_pic_placeholder_svg
                      }
                      // Check for live preview image first
                      // If not found, check for uploaded image
                      // If not found, use placeholder image
                      alt="Profile Picture"
                    />
                  </div>
                  <div className={styles.profile_info}>
                    <p className={styles.user_name}>{name}</p>
                    <p className={styles.business_role}>{job_title}</p>
                    <p
                      className={`${styles.business_name} ${brand_name_custom_class}`}
                    >
                      {brand_name ?? company_business_name}
                    </p>
                  </div>
                </div>
              </div>
              {!no_one.enable && (
                <div
                  className={styles.contact_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={mobile_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Mobile</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={text_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Text</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + extension + phone : ""}
                  >
                    <ReactSVG
                      src={phone_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Phone</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "mailto:" + email : ""}
                  >
                    <ReactSVG
                      src={email_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Email</span>
                  </a>
                  {!showContactDetails && (
                    <LockedOverlay
                      title={"Show Contact Details"}
                      callback={() => {
                        setShowLeadsForm(!showleadsForm);
                      }}
                      hide={showContactDetails}
                    />
                  )}
                </div>
              )}
              {showSocialLinks && (
                <div
                  className={styles.social_links_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  {order.map((key) => {
                    const selectedSocialItem = social_links[key];
                    if (selectedSocialItem) {
                      if (selectedSocialItem.enable)
                        return (
                          <a
                            href={
                              SOCIAL_PREFIXES[key] + selectedSocialItem?.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            key={key}
                          >
                            <span
                              className={`${styles.social_icon_display_container} ${styles[selectedSocialIconStyle]}`}
                              style={{
                                backgroundColor:
                                  social_media_icons_background_color.enable
                                    ? socialIconsBackgroundColor
                                    : "",
                                borderColor: social_media_icons_color.enable
                                  ? socialIconsBorderColor
                                  : "",
                              }}
                            >
                              <ReactSVG
                                src={SocialLink_SVG_MAP[key] ?? ""}
                                wrapper={"span"}
                                afterInjection={(err, svg) => {
                                  insertClassToSVGPath(
                                    err,
                                    svg,
                                    customSocialIconsPathColor
                                  );
                                }}
                              />
                            </span>
                          </a>
                        );
                    }
                  })}
                </div>
              )}
            </div>
          )}
          {cardDesign === "layout_4" && (
            <div
              id="personal-details-viewer"
              className={`${styles.personal_details_viewer} ${
                styles[cardDesign] ?? styles.layout_1
              }`}
            >
              <div
                className={styles.details_box}
                style={{
                  backgroundColor: pd_bg.enable && backgroundColor_PD,
                  borderRadius: !sections_border_radius && 0,
                }}
              >
                <div className={styles.profile_main_info}>
                  <div
                    className={`${styles.profile_picture} ${styles[profile_pic_border_style]}`}
                    style={{
                      borderWidth: border_stroke.width,
                      borderColor: parseRGBA(border_stroke.color.rgb),
                      borderStyle: "solid",
                    }}
                  >
                    <img
                      className={buildImageEditedClass(
                        profile_picture_adjustments
                      )}
                      src={
                        profile_picture_image_upload
                          ? profile_picture_image_upload
                          : profile_picture_image_preview_file?.url
                          ? profile_picture_image_preview_file?.url
                          : profile_pic_placeholder_svg
                      }
                      // Check for live preview image first
                      // If not found, check for uploaded image
                      // If not found, use placeholder image
                      alt="Profile Picture"
                    />
                  </div>
                  {enable_business_brand_logo && (
                    <div className={styles.business_logo_container}>
                      <img
                        className={styles.business_logo}
                        style={{
                          width: !isNaN(brand_logo_adjustments.size)
                            ? `${brand_logo_adjustments.size}%`
                            : "90%",
                        }}
                        src={
                          business_brand_logo_upload
                            ? business_brand_logo_upload
                            : business_brand_logo_preview_file?.url
                            ? business_brand_logo_preview_file?.url
                            : business_logo_placeholder_svg
                        }
                        alt="Business Logo"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className={styles.profile_info}
                style={{
                  backgroundColor: pd_bg.enable && backgroundColor_PD,
                  borderRadius: !sections_border_radius && 0,
                }}
              >
                <p className={styles.user_name}>{name}</p>
                <div className={styles.business_info}>
                  <p className={styles.business_role}>{job_title}</p>
                  <p
                    className={`${styles.business_name} ${brand_name_custom_class}`}
                  >
                    {brand_name ?? company_business_name}
                  </p>
                </div>
              </div>
              {!no_one.enable && (
                <div
                  className={styles.contact_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={mobile_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Mobile</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + mobile : ""}
                  >
                    <ReactSVG
                      src={text_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Text</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "tel:" + extension + phone : ""}
                  >
                    <ReactSVG
                      src={phone_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Phone</span>
                  </a>
                  <a
                    className={styles.contact_pill}
                    href={showContactDetails ? "mailto:" + email : ""}
                  >
                    <ReactSVG
                      src={email_icon_svg}
                      wrapper={"span"}
                      className={styles.contact_pill_icon}
                      afterInjection={(err, svg) => {
                        insertClassToSVGCircle(
                          err,
                          svg,
                          customContactIconsInnerColor
                        );
                        insertClassToSVGPath(err, svg, customContactIconsBG);
                      }}
                    />
                    <span className={styles.contact_pill_text}>Email</span>
                  </a>
                  {!showContactDetails && (
                    <LockedOverlay
                      title={"Show Contact Details"}
                      callback={() => {
                        setShowLeadsForm(!showleadsForm);
                      }}
                      hide={showContactDetails}
                    />
                  )}
                </div>
              )}
              {showSocialLinks && (
                <div
                  className={styles.social_links_div}
                  style={{
                    backgroundColor: pd_bg.enable && backgroundColor_PD,
                    borderRadius: !sections_border_radius && 0,
                  }}
                >
                  {order.map((key) => {
                    const selectedSocialItem = social_links[key];
                    if (selectedSocialItem) {
                      if (selectedSocialItem.enable)
                        return (
                          <a
                            href={
                              SOCIAL_PREFIXES[key] + selectedSocialItem?.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            key={key}
                          >
                            <span
                              className={`${styles.social_icon_display_container} ${styles[selectedSocialIconStyle]}`}
                              style={{
                                backgroundColor:
                                  social_media_icons_background_color.enable
                                    ? socialIconsBackgroundColor
                                    : "",
                                borderColor: social_media_icons_color.enable
                                  ? socialIconsBorderColor
                                  : "",
                              }}
                            >
                              <ReactSVG
                                src={SocialLink_SVG_MAP[key] ?? ""}
                                wrapper={"span"}
                                afterInjection={(err, svg) => {
                                  insertClassToSVGPath(
                                    err,
                                    svg,
                                    customSocialIconsPathColor
                                  );
                                }}
                              />
                            </span>
                          </a>
                        );
                    }
                  })}
                </div>
              )}
            </div>
          )}
          {/* Leads form */}
          <Modal open={showleadsForm} closer={setShowLeadsForm}>
            {use_form.enable && (
              <form onSubmit={submitLead}>
                <div className={modalStyles.lead_form_header}>
                  <h3 className={modalStyles.popup_main_heading}>
                    Personal Message
                  </h3>{" "}
                  <button
                    className={modalStyles.closePopupButton}
                    onClick={() => {
                      setShowLeadsForm(!showleadsForm);
                    }}
                  >
                    <ReactSVG src={close_svg} wrapper="span" />{" "}
                  </button>
                </div>
                {lead_fn && (
                  <InputField
                    inputFieldId={"first_name"}
                    label={"First Name"}
                    extraClass={"fifty"}
                  />
                )}
                {lead_ln && (
                  <InputField
                    inputFieldId={"last_name"}
                    label={"Last Name"}
                    extraClass={"fifty_2"}
                  />
                )}
                {lead_e && (
                  <InputField
                    inputFieldId={"email"}
                    label={"Email"}
                    type={"email"}
                  />
                )}
                {lead_p && (
                  <InputField
                    inputFieldId={"phone"}
                    label={"Phone"}
                    type={"tel"}
                    extraClass={"fifty"}
                  />
                )}
                {lead_c && (
                  <InputField
                    inputFieldId={"company"}
                    label={"Company"}
                    extraClass={"fifty_2"}
                  />
                )}
                {lead_n && (
                  <TextArea
                    inputFieldId={"comments"}
                    label={"Additional Note/Comments"}
                    placeholder={"Any notes to be added here"}
                  />
                )}
                <RadioInputField
                  forGroup={"acknowledge-crm-privacy"}
                  inputFieldId={"acknowledge-crm-privacy"}
                  selected={false}
                  simpleOnChange={() => {
                    setShowLeadsFormError(false);
                  }}
                >
                  <p className={`no_margins ${modalStyles.lead_radio_text}`}>
                    Acknowledge for Privacy Policy
                  </p>
                </RadioInputField>
                {showLeadsFormError && (
                  <p className={modalStyles.popup_error_text}>
                    You must acknowledge privacy policy first.
                  </p>
                )}
                <Button
                  title={"Send"}
                  type={"submit"}
                  extraClass={"submit_lead_button"}
                />
              </form>
            )}
            {use_password.enable && (
              <PasswordProtected
                title={"Protected Contact Information"}
                password={use_password.password}
                callback={() => {
                  setShowContactDetails(true);
                  setShowLeadsForm(false);
                }}
                closer={setShowLeadsForm}
              />
            )}
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default PersonalDetailsViewer;
