export const swap = (array, i, j) =>
  ([array[i], array[j]] = [array[j], array[i]]);

export const checkYoutubeURL = (url) => {
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
};

export const checkVimeoURL = (url) => {
  let vimeoID = 0;
  const findVimeoID = url.match(/(\d+)/);
  if (findVimeoID && findVimeoID[0]) vimeoID = findVimeoID[0];
  return vimeoID;
};

export const findStripeSessionID = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("session_id");
};

export const checkSchedule = (schedule) => {
  if (!schedule || !schedule[0] || !schedule[1]) return true;
  else {
    const [start, end] = schedule;

    if (new Date(start) && new Date(end)) {
      // compare with now
      const currentTime = new Date();
      if (currentTime >= new Date(start) && currentTime <= new Date(end))
        return true;
    } else return false;
    if (new Date(start) === new Date(end)) return true;
  }
};

export const copyToClipboard = (content) => {
  const el = document.createElement("textarea");
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const popupAlert = (elem, x, y) => {
  if (elem && document.body.contains(elem)) {
    elem.style.opacity = 1;
    elem.style.transform = `translate(${x}, ${y})`;
    setTimeout(() => {
      elem.style.opacity = 0;
      elem.style.transform = `translate(0, 0)`;
    }, 1000);
  }
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};
