import React, { useState, createContext } from "react";

export const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {
  const [brandingDetails, setBrandingDetails] = useState({
    background: {
      enable: false,
      use_background_color: false,
      branding_background_color: {
        hex: "#ffffff",
        rgb: {
          r: 235,
          g: 255,
          b: 255,
          a: 1,
        },
      },
      use_background_image: false,
      background_image_preview_file: "", // Existing background pic
      background_image_uploadable_file: "", // file object if new pic selected
      background_image_upload: "", // preview link before uploading
    },
    background_overlay: {
      enable: false,
      branding_background_overlay: {
        hex: "#ffffff",
        rgb: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
    },
    business_brand_name: {
      enable: false,
      brand_name: "Your Brand Name",
      brand_name_font: "verdana",
      brand_name_font_bold: false,
      brand_name_font_italic: false,
      brand_name_font_underline: false,
      brand_name_font_align_left: false,
      brand_name_font_align_right: false,
      brand_name_font_align_center: true,
      brand_name_color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    business_brand_slogan: {
      enable: false,
      brand_slogan: "Your Brand Slogan",
      brand_slogan_font: "lato",
      brand_slogan_font_bold: false,
      brand_slogan_font_italic: false,
      brand_slogan_font_underline: false,
      brand_slogan_font_align_left: false,
      brand_slogan_font_align_right: false,
      brand_slogan_font_align_center: true,
      brand_slogan_color: {
        hex: "#000000",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    },
    business_brand_logo: {
      enable: false,
      business_brand_logo_preview_file: "", // Existing background pic
      business_brand_logo_uploadable_file: "", // file object if new pic selected
      business_brand_logo_upload: "", // preview link before uploading
    },
    brand_logo_adjustments: {
      size: 90,
    },
  });

  return (
    <BrandingContext.Provider value={[brandingDetails, setBrandingDetails]}>
      {children}
    </BrandingContext.Provider>
  );
};
