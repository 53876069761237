import React, { useEffect } from "react";

import styles from "./ytChannelDetails.module.scss";

const YTChannelDetails = ({
  yt_title,
  yt_description,
  yt_channel_thumbnail,
}) => {
  return (
    <div className={styles.yt_channel_details}>
      {yt_channel_thumbnail && (
        <>
          <div className={styles.header}>
            <div className={styles.thumbnail}>
              <img src={yt_channel_thumbnail} alt={yt_title} />
            </div>
            <div className={styles.title}>
              <h3>{yt_title}</h3>
            </div>
          </div>
          <>
            {yt_description.split("\n").map((line, index) => {
              return (
                <p key={index} className={styles.description}>
                  {line}
                </p>
              );
            })}
          </>
        </>
      )}
    </div>
  );
};

export default YTChannelDetails;
