import React from "react";

import styles from "./inlineButton.module.scss";

const InlineButton = ({ onClickFunction, title }) => {
  return (
    <button className={styles.inline_button} onClick={onClickFunction}>
      {title}
    </button>
  );
};

export default InlineButton;
