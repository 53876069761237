import React from "react";
import styles from "./chart.module.scss";

const ListChart = ({ data = [] }) => {
  return (
    <div className={styles.list_container}>
      {data &&
        data.map((item, key) => (
          <div key={key} className={styles.list_item}>
            <p>{item.content}</p>
            <p className={styles.value}>{item.value}</p>
          </div>
        ))}
    </div>
  );
};

export default ListChart;
