import React from "react";

import CollapsibleHeader from "./header/header";

import styles from "./container.module.scss";

const AnalyticsSection = ({
  smaller,
  title,
  subtitle,
  inputId,
  helpIcon,
  showUnlockBtn,
  showDropdown,
  enabled = false,
  callback = null,
  context = null,
  updateContext = null,
  index = null,
  children,
}) => {
  const collapseBodyRef = React.createRef();

  return (
    <div>
      <div className="header">
        <CollapsibleHeader
          smaller={smaller}
          title={title}
          subtitle={subtitle}
          inputId={inputId}
          helpIcon={helpIcon}
          showDropdown={showDropdown}
          showUnlockBtn={showUnlockBtn}
          enabled={enabled}
          callback={callback}
          context={context}
          updateContext={updateContext}
          index={index}
          bodyRef={collapseBodyRef}
        />
      </div>
      <div className={styles.collapse_body} ref={collapseBodyRef}>
        {children}
      </div>
    </div>
  );
};

export default AnalyticsSection;
