import { API_BASE } from "../../../api_links";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
// import { buildNotification } from "../../notification/buildNotification";

const parseDataForContext = (data) => {
  if (!data) return;
  const {
    enable_background_color,
    use_background_color,
    branding_background_color,
    use_background_image,
    background_image,
    enable_background_overlay,
    branding_background_overlay,
    enable_brand_business_name,
    brand_name,
    brand_name_font,
    brand_name_font_bold,
    brand_name_font_italic,
    brand_name_font_underline,
    brand_name_font_align_left,
    brand_name_font_align_right,
    brand_name_font_align_center,
    brand_name_color,
    enable_business_brand_slogan,
    brand_slogan,
    brand_slogan_font,
    brand_slogan_font_bold,
    brand_slogan_font_italic,
    brand_slogan_font_underline,
    brand_slogan_font_align_left,
    brand_slogan_font_align_right,
    brand_slogan_font_align_center,
    brand_slogan_color,
    enable_business_brand_logo,
    business_brand_logo,
    brand_logo_adjustments,
  } = data;

  return {
    background: {
      enable: enable_background_color,
      use_background_color,
      branding_background_color,
      use_background_image,
      background_image_preview_file: background_image,
      background_image_uploadable_file: "",
      background_image_upload: "",
    },
    background_overlay: {
      enable: enable_background_overlay,
      branding_background_overlay,
    },
    business_brand_name: {
      enable: enable_brand_business_name,
      brand_name,
      brand_name_font,
      brand_name_font_bold,
      brand_name_font_italic,
      brand_name_font_underline,
      brand_name_font_align_left,
      brand_name_font_align_right,
      brand_name_font_align_center,
      brand_name_color,
    },
    business_brand_slogan: {
      enable: enable_business_brand_slogan,
      brand_slogan,
      brand_slogan_font,
      brand_slogan_font_bold,
      brand_slogan_font_italic,
      brand_slogan_font_underline,
      brand_slogan_font_align_left,
      brand_slogan_font_align_right,
      brand_slogan_font_align_center,
      brand_slogan_color,
    },
    business_brand_logo: {
      enable: enable_business_brand_logo,
      business_brand_logo_preview_file: business_brand_logo,
      business_brand_logo_uploadable_file: "",
      business_brand_logo_upload: "",
    },
    brand_logo_adjustments: brand_logo_adjustments ?? { size: 90 },
  };
};

export const getBrandingDetailsFromDB = (
  setBrandingDetails,
  username = null
) => {
  if (!username) {
    const authToken = checkLoginCookie();
    if (!authToken) return;

    const url = `${API_BASE}:LiqXQBP3/branding_details`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setBrandingDetails(parseDataForContext(data));
          // buildNotification({
          //   type: "success",
          //   message: "Branding Settings Loaded",
          // });
        });
    } catch (error) {
      console.error(error);
    }
  } else {
    const url = `${API_BASE}:LiqXQBP3/branding_details/${username}`;

    try {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data || data?.code || data?.message) return;
          setBrandingDetails(parseDataForContext(data));
        });
    } catch (error) {
      console.error(error);
    }
  }
};
