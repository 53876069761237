import React from "react";
import { ReactSVG } from "react-svg";

import qr_svg from "../../../../assets/global/icon_qr.svg";
import sms_svg from "../../../../assets/global/icon_sms.svg";

const ShareButtons = ({ styles, qrCodeShow, setQrCodeShow, smsURL }) => {
  return (
    <div className={styles.share_buttons}>
      <button
        className={styles.share_grey_btn}
        onClick={() => {
          setQrCodeShow(!qrCodeShow);
        }}
      >
        <span className={styles.icon}>
          <ReactSVG src={qr_svg} wrapper={"span"} />
        </span>
        <p>Share via QR code</p>
      </button>
      <button className={styles.share_grey_btn}>
        <span className={styles.icon}>
          <ReactSVG src={sms_svg} wrapper={"span"} />
        </span>
        <p>
          <a href={smsURL} target="_blank" rel="noopener noreferrer">
            Share via text message
          </a>
        </p>
      </button>
    </div>
  );
};

export default ShareButtons;
