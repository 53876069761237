import React from "react";
import AnalyticsChart from "../../common/charts/chart";

export const data = [
  ["Country", "Popularity"],
  ["Croatia", 200],
  ["United States", 300],
  ["Italy", 400],
  ["Australia", 500],
  ["Iran", 600],
  ["Brazil", 700],
  ["India", 200],
];

export const options = {
  legend: "none",
  colors: ["#585168", "#1ba8f0", "#8FE8F3", "#FCC97B", "#E4F38F", "#6CC76B"],
  backgroundColor: "transparent",
};

const GeoMapChart = () => {
  return (
    <AnalyticsChart
      chartType="GeoChart"
      width="90%"
      data={data}
      options={options}
    />
  );
};

export default GeoMapChart;
