import React from "react";
import { Link } from "react-router-dom";

import TOCFooter from "../../common/tocFooter/tocFooter";

import styles from "../authFlow.module.scss";

import logo from "../../../assets/global/umicard_logo2.png";

const AuthFlowBasicContainer = ({
  title,
  help,
  cover,
  wide = false,
  children,
}) => {
  return (
    <>
      <div className={styles.auth_container}>
        <div className={styles.form_outer_container}>
          <div className={styles.logo_container}>
            <Link to="/">
              <img className={styles.logo} src={logo} alt="UmiCard" />
            </Link>
          </div>
          <div className={styles.form_inner_container}>
            <div className={`${styles.form_center} ${wide && styles.wide}`}>
              <h3 className={styles.main_heading}>{title}</h3>
              <p className={styles.help_text}>{help}</p>
              <div className={styles.form_contents}>{children}</div>
            </div>
          </div>
        </div>
        <div
          className={styles.cover_image}
          style={{ backgroundImage: `url(${cover})` }}
        ></div>
      </div>
      <TOCFooter />
    </>
  );
};

export default AuthFlowBasicContainer;
